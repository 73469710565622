import { styled } from "@mui/material/styles";
import { getZoom } from "../../../../utils/sizeUtils";
import { pad } from "lodash";

export const StyledUsageMonitoring = styled("div")(({ theme }) => {
  const zoom = getZoom();
  return {
    zoom: `${zoom}%`,
    display: "flex",
    flexGrow: 1,
    ".back": {
      position: "absolute",
      left: "0px",
      ".qtm-icon": {
        color: "black",
      },
    },
  };
});

import { CLIENT_ID, getIsAzure, TENANT_ID } from "./V2/auth/authConfig";

const checkAndDisableZoom = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.get("ignoreZoom") === "true") {
    localStorage.setItem("ignoreZoom", params.get("ignoreZoom"));
  }
};

async function bootApp() {
  if (!navigator.cookieEnabled) {
    return require("./roots/disabledCookies.js");
  }

  checkAndDisableZoom();

  const loginType = await getIsAzure();
  if (loginType.data.azure) {
    const { clientId, tenantId } = loginType.data;
    localStorage.removeItem("login.dev");
    if (clientId && tenantId) {
      localStorage.setItem(CLIENT_ID, clientId);
      localStorage.setItem(TENANT_ID, tenantId);
      return require("./roots/msalRoot.js");
    }
  } else {
    localStorage.setItem("login.dev", true);
    return require("./roots/appRoot.js");
  }
}

bootApp();

const isUserAuthorized = () => {
  const token = localStorage.getItem("adal.idtoken");

  if (localStorage.getItem("login.dev")) {
    let user = localStorage.getItem("user");

    if (user) {
      user = JSON.parse(user);

      if (user.username) {
        return true;
      }
    }
  } else {
    if (token) {
      return true;
    }
  }

  return false;
};

export { isUserAuthorized };

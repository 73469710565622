import React, { useState } from "react";
import { TdsIcon } from "@tds/react";
import { IconButton } from "@mui/material";
import EEIReadMe from "./EEIReadMe";
import EmwClickableTooltip from "../../../../../../../lib/commonv2/EmwClickableTooltip";

export default function KPIReadMeTooltip({ readMeData, isWFB, isByCountry }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isEeiReadMe = true;

  const handleReadMeClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  return (
    <EmwClickableTooltip
      isOpen={isTooltipOpen}
      setIsOpen={setIsTooltipOpen}
      content={<EEIReadMe readMeData={readMeData} isWFB={isWFB} isByCountry={isByCountry} />}
      placement="right-end"
      isEeiReadMe={isEeiReadMe}
    >
      <IconButton onClick={handleReadMeClick} disableRipple title="Read Me">
        <TdsIcon icon="help_outline" size="small" style={{ color: "#041295" }} />
      </IconButton>
    </EmwClickableTooltip>
  );
}

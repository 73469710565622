import React from "react";

export default function CheckedIcon() {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.49631 10.4027L1.3962 6.30257L0 7.68894L5.49631 13.1853L17.2952 1.38637L15.9088 0L5.49631 10.4027Z"
        fill="#041295"
      />
    </svg>
  );
}

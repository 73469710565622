import React, { useState } from "react";
import { EmwButton } from "../../../lib/common";
import { useDispatch, useSelector } from "react-redux";
import { biPropsSelector } from "../../../modules/header/selectors";
import { computeURL, fetch, fetchOptions } from "../../../util/fetch";
import useUser from "../../auth/useUser";
import EmwTypography from "../EmwTypography/EmwTypography";
import { StyledEmwUnderMaintenanceLogo } from "../../features/powerBi/components/UnderMaintenancePage/styled";
import EMWUnderConstructuion from "../../../styles/images/Under construction.svg";
import EmwDialog from "../EmwDialog/EmwDialog";
import { hzaIframeSelector, pbiC3UrlsSelector } from "../../features/header/redux/selectors";
import { StyledUnderMaintenanceDialog } from "./styled";
import useHzaSync from "../../features/hzaSync/useHzaSync";
import { setIsHza } from "../../features/footer/redux/actions";
import { useLocation } from "react-router-dom-v5-compat";

export default function EmwOpenHzaBtn() {
  const biProps = useSelector(biPropsSelector);
  const hzaIframeLoaded = useSelector(hzaIframeSelector);
  const pbiC3Urls = useSelector(pbiC3UrlsSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const hzaSync = useHzaSync();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenC3, setIsOpenC3] = useState(false);

  const handleClick = async () => {
    if (user.hzaUnderMaintenance) {
      return setIsOpen(true);
    }

    if (!hzaIframeLoaded) {
      return setIsOpenC3(true);
    }
    try {
      dispatch(setIsHza(true));

      hzaSync.showBiC3("moodAndConfidenceC3DrillDetailsUrl");

      const url = computeURL("logs", "pbiPageChanged/Drill%20to%20detail%20MC_HZA");
      const method = "GET";
      fetch(url, fetchOptions({ method }));

      hzaSync.showBiC3("moodAndConfidenceC3DrillDetailsUrl");
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const openTab = url => {
    const windowName = "hzaWindow";
    const newWindow = window.open(url, windowName);

    if (newWindow) {
      newWindow.focus();
    }
  };

  const addQueryParam = (url, key, value) => {
    if (url.includes("?")) {
      url += "&" + key + "=" + encodeURIComponent(value);
    } else {
      url += "?" + key + "=" + encodeURIComponent(value);
    }
    return url;
  };

  if (!biProps.list.hasOwnProperty("data")) {
    return null;
  }

  if (location.pathname === "/landingpage") {
    return null;
  }

  return (
    <>
      <EmwButton classes="mx-auto" variant="outline" onClick={handleClick} icon="compare_arrows">
        ANSWERS WITH PROJECT NAMES
      </EmwButton>
      {isOpen && (
        <StyledUnderMaintenanceDialog
          aria-labelledby="customized-dialog"
          open={isOpen}
          maxWidth="md"
        >
          <div className="flex flex-col flex-grow justify-center items-center pt-4xl pb-xl">
            <EmwTypography
              fontSize={3}
              classes="flex justify-center items-center text-primary-500 font-bold"
            >
              Page Under Maintenance
            </EmwTypography>
            <EmwTypography
              fontSize={7}
              classes="flex justify-center items-center mb-xs text-black-100"
            >
              We are currently working on it.
            </EmwTypography>
            <EmwButton
              variant="filled"
              size="small"
              classes="back-button"
              onClick={handleClose}
              id="btnEdit"
              title="Go back"
            >
              Go back
            </EmwButton>
            <div className="flex justify-center items-center">
              <StyledEmwUnderMaintenanceLogo src={EMWUnderConstructuion} alt="EMW" />
            </div>
          </div>
        </StyledUnderMaintenanceDialog>
      )}

      {isOpenC3 && (
        <EmwDialog
          isOpen={isOpenC3}
          setIsOpen={setIsOpenC3}
          callback={() => setIsOpenC3(false)}
          actionButton="Ok"
          title="HZA is not available"
          text="You are not allowed to use this service due to C3 restriction"
          icon="warning_amber"
          classes="reset-icon"
        />
      )}
    </>
  );
}

import { computeURL, fetch, fetchOptions } from "../../../../util/fetch";

export const putBackInTimeApi = async payload => {
  const url = computeURL("data", "setWorkDate");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const putUpdateSettingsApi = async payload => {
  const url = computeURL("basicdata", "updateSettings");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const getOrganizationsIndicatorsApi = async payload => {
  const url = computeURL("organization", "getOrganizationsIndicators");
  const body = JSON.stringify(payload);
  const method = "POST";

  return fetch(url, fetchOptions({ method, body }));
};

export const getUsageMonitoringIndicatorsApi = async payload => {
  const url = computeURL("usageMonitoring", "monthlyUsers");
  const body = JSON.stringify(payload);
  const method = "POST";

  return fetch(url, fetchOptions({ method, body }));
};

export const getUsageMonitoringPeriodFiltersApi = async payload => {
  const url = computeURL("usageMonitoring", "periodFilters");
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const getMonthlyUsersListApi = async payload => {
  const url = computeURL("usageMonitoring", "monthlyUsersList");
  const body = JSON.stringify(payload);
  const method = "POST";

  return fetch(url, fetchOptions({ method, body }));
};
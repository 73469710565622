import { useEffect, useLayoutEffect } from "react";
import {
  OPERATING_MODEL,
  MOOD_AND_CONFIDENCE,
  TALENTS_AND_CULTURES,
  ENGINEERING_COMPETITIVENESS,
} from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { reportFiltersSelector } from "../redux/selectors";
import { FiltersOperations } from "powerbi-models";
import { HZA_IFRAME_ID } from "../../hzaSync/constants";
import { setReportFilters, setReportFiltersHza } from "../redux/actions";
import store from "../../../../store";
import { setIsHza } from "../../footer/redux/actions";

export const setReportDefaultPage = async () => {
  const DEFAULT_PAGES = {
    [MOOD_AND_CONFIDENCE]: "ReportSection12cd800f5b41b0e04f27",
    [OPERATING_MODEL]: "ReportSectionfa523654a26976a87575",
    [TALENTS_AND_CULTURES]: "ReportSectiond6f33ab2a21e9e19a769",
  };

  Object.keys(window.report).map(async reportName => {
    const report = await window.report[reportName];
    const reportActivePage = await report.getActivePage();

    if (reportActivePage.name !== DEFAULT_PAGES[reportName]) {
      await window.report[reportName].setPage(DEFAULT_PAGES[reportName]);
    }
  });
};

export default function useReportRendering() {
  const dispatch = useDispatch();
  const biFilters = useSelector(reportFiltersSelector);

  useLayoutEffect(() => {
    (async () => {
      await syncHzaToTdpFilters();
      if (location.pathname === "/landingpage") {
        return hideAllReports();
      }
      if (location.pathname === "/moodandconfidence") {
        await checkElement(MOOD_AND_CONFIDENCE, callback);
      }

      if (location.pathname === "/engineeringcompetitivness/competitiveness") {
        await checkElement("myFrameHZA", callback, "engineeringCompetitivnessC3Url");
      }

      if (location.pathname === "/engineeringcompetitivness/operating-model") {
        if (document.getElementById("myFrameHZA")) {
          // un-comment this to disable the button
          document.getElementById("myFrameHZA").style.display = "none"; // TODO laurentiu disable button
        }
        await checkElement(OPERATING_MODEL, callback);
      }

      if (location.pathname === "/engineeringcompetitivness/talents-and-culture") {
        await checkElement(TALENTS_AND_CULTURES, callback);
      }

      await setReportDefaultPage();
    })();

    return () => hideAllReports();
  }, [location.pathname]);

  useEffect(() => {
    if (biFilters) {
      syncFiltersToHza(biFilters);
    }
  }, [biFilters]);

  const syncHzaToTdpFilters = async hzaFilters => {
    window.addEventListener("message", async event => {
      if (event.data.type === "SYNC_HZA_FILTERS") {
        const hzaFilters = store.getState().powerBi.report_filters_hza;
        if (JSON.stringify(event.data.filters) !== JSON.stringify(hzaFilters)) {
          dispatch(setReportFilters(event.data.filters));
        }
      }
    });
  };

  /**
   * Check if the report iframe exists and is loaded
   *
   * @param reportName string
   * @param callback function
   * @param hzaIframeName string
   */
  const checkElement = async (reportName, callback, hzaIframeName = "") => {
    const interval = setInterval(() => {
      let id = `${reportName}-report`;

      if (reportName === "myFrameHZA") {
        id = reportName;
        if (document.getElementById(id)) {
          clearInterval(interval);
          setTimeout(() => {
            const hzaIframe = document.getElementById(HZA_IFRAME_ID);
            if (!window.location.href.includes("/engineeringcompetitivness/competitiveness")) {
              hzaIframe.style.display = "none";
            } else {
              hzaIframe.style.display = "";
            }
            hzaIframe.contentWindow.postMessage({ type: "SHOW_IFRAME", hzaIframeName }, "*");
            hzaIframe.contentWindow.postMessage({ type: "AUTH_TOKEN", hzaIframeName }, "*");
            dispatch(setIsHza(true));
          }, 1500);
        }
      } else if (document.getElementById(id)) {
        const hzaIframe = document.getElementById(HZA_IFRAME_ID);
        hzaIframe.style.display = "none";
        clearInterval(interval);
        callback(document.getElementById(id), reportName);
      }
    });
  };

  /**
   * Display current powerBi report page
   *
   * @param iframe HtmlNode
   * @param reportName string
   */
  const callback = async (iframe, reportName) => {
    iframe.classList.remove("hidden");
    iframe.classList.add("block");

    if (biFilters) {
      await syncTdpFilters(reportName);
    }
  };

  /**
   * Automatic set the tdp/hza filters for all hidden report
   *
   * @param reportName string
   * @param hzaFilters
   * @returns {Promise<void>}
   */
  const syncTdpFilters = async (reportName, hzaFilters = null) => {
    const report = window.report[reportName];
    const reportFilters = await report.getFilters();

    // if (hzaFilters) {
    //   [...hzaFilters].map((filter, index) => {
    //     filter.target = reportFilters[index].target;
    //     return filter;
    //   });
    //   return await report.updateFilters(FiltersOperations.Replace, hzaFilters);
    // }

    [...biFilters].map((filter, index) => {
      filter.target = reportFilters[index].target;
      filter.displaySettings = reportFilters[index].displaySettings;
      return filter;
    });

    await report.updateFilters(FiltersOperations.Replace, biFilters);
  };

  /**
   * Post current tdp filters to hza iframe
   * @param currentFilters
   */
  const syncFiltersToHza = currentFilters => {
    const hzaIframe = document.getElementById(HZA_IFRAME_ID).contentWindow;
    hzaIframe.postMessage({ type: "SYNC_FILTERS", filters: currentFilters }, "*");
  };

  /**
   * Hide all powerBi report pages ad revert them back to the coresponding default page
   */
  const hideAllReports = async () => {
    for (const element of document.querySelectorAll(".bi-report-container")) {
      element.classList.remove("block");
      element.classList.add("hidden");
    }
  };
}

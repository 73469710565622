import React from "react";
import { createRoot } from "react-dom/client";
import "../styles/globals.css";
import {
  StyledEmwCookiesLogo,
  StyledCookiesContainer,
  StyledEmwCookiesStep,
  StyledEmwCookiesWarning,
} from "../layout/CookiesLayout/styled";
import EMWLogo from "../styles/images/icon/EMW_logo-01.svg";
import CookiesLayout from "../layout/CookiesLayout/CookiesLayout";
import EmwTypography from "../V2/components/EmwTypography/EmwTypography";
import EmwIcon from "../V2/components/EmwIcon/EmwIcon";

const container = document.getElementById("root");
const root = createRoot(container);

const browserType = (function (agent) {
  let firstText = "Looks like your Cookies are disabled.";
  let secondText = "In order to use EMW, please follow the steps:";
  if (!(agent.indexOf("edg/") > -1)) {
    firstText = "Seems that you are not using Microsoft Edge.";
    secondText = "For best experience using EMW, we recommend Microsoft Edge and turning on cookies:";
  }
  return { firstText, secondText };
})(window.navigator.userAgent.toLowerCase());

const app = (
  <CookiesLayout>
    <StyledCookiesContainer id="cookies-container">
      <div className="flex justify-center items-center mt-7xl">
        <StyledEmwCookiesLogo src={EMWLogo} alt="EMW" />
      </div>
      <div className="flex flex-col justify-start items-center container pl-8xl mt-3xl">
        <div className="flex justify-start items-center container my-xl">
          <StyledEmwCookiesWarning>
            <EmwIcon marginBottom="4px" icon="warning_amber" size="xxlarge" />
          </StyledEmwCookiesWarning>
          <div className="flex flex-col">
            <EmwTypography fontSize={5} classes="text-bluegrey-600">
              {browserType.firstText}
            </EmwTypography>
            <EmwTypography fontSize={5} classes="text-bluegrey-600">
              {browserType.secondText}
            </EmwTypography>
          </div>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledEmwCookiesStep>
            <EmwTypography fontSize={7} fontWeight="bold" classes="text-bluegrey-600">
              1
            </EmwTypography>{" "}
          </StyledEmwCookiesStep>
          <EmwTypography fontSize={6} classes="text-bluegrey-600">
            Click the Menu button
          </EmwTypography>
          <EmwIcon classes="cookie-menu text-bluegrey-600" size={"xlarge"} icon="more_horiz" />
          <EmwTypography fontSize={6} classes="text-bluegrey-600">
            found at the top-right corner of the browser, and then click Settings.
          </EmwTypography>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledEmwCookiesStep>
            <EmwTypography fontSize={7} fontWeight="bold" classes="text-bluegrey-600">
              2
            </EmwTypography>{" "}
          </StyledEmwCookiesStep>
          <EmwTypography fontSize={6} classes="text-bluegrey-600">
            Click Cookies and site permissions and then Manage and delete cookies and site data.
          </EmwTypography>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledEmwCookiesStep>
            <EmwTypography fontSize={7} fontWeight="bold" classes="text-bluegrey-600">
              3
            </EmwTypography>{" "}
          </StyledEmwCookiesStep>
          <EmwTypography fontSize={6} classes="text-bluegrey-600">
            Choose the option Allow sites to save and read cookie data.
          </EmwTypography>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledEmwCookiesStep>
            <EmwTypography fontSize={7} fontWeight="bold" classes="text-bluegrey-600">
              4
            </EmwTypography>{" "}
          </StyledEmwCookiesStep>
          <EmwTypography fontSize={6} classes="text-bluegrey-600">
            Refresh the page (CTRL + F5).
          </EmwTypography>
        </div>
      </div>
    </StyledCookiesContainer>
  </CookiesLayout>
);

root.render(app);

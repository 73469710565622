import * as actions from "./actions";
import { initialProps } from "../../../../util/reducer";
import {
  ORGANIZATION_PERSPECTIVE,
  ORGANIZATION_TYPE,
  ORGANIZATION_VIEW,
  ORGANIZATION_CC_FILTER,
} from "../constants";

export default {
  [actions.ORG_DETAILS]: {
    ...initialProps,
    list: {},
  },
  [actions.GUEST_DETAILS]: {
    ...initialProps,
    list: {},
  },
  [actions.EXTERNAL_PROJECTS]: {
    ...initialProps,
    list: {},
  },
  [actions.GET_TREE_NODES]: {
    ...initialProps,
    list: {},
  },
  [actions.EXPORT_ENG_ORG]: {
    ...initialProps,
    list: {},
  },
  [actions.EXPORT_EEI]: {
    ...initialProps,
    list: {},
  },
  [actions.UPLOAD_EEI]: {
    ...initialProps,
    list: {},
  },
  [actions.GET_EEI_LOGS]: {
    ...initialProps,
    list: {},
  },

  [actions.GET_EEI_LOGS_WEB_FORM]: {
    ...initialProps,
    list: {},
  },
  [actions.GET_DISCIPLINE_NAMES]: {
    ...initialProps,
    list: {},
  },
  [actions.GET_EEI_DATA_FOR_ORGANIZATION]: {
    ...initialProps,
    list: {},
  },
  [actions.GET_WFB_DATA_FOR_ORGANIZATION]: {
    ...initialProps,
    list: {},
  },
  [actions.GET_ALL_CCS]: {
    ...initialProps,
    list: {},
  },
  [actions.SAVE_CC_DETAILS]: {
    ...initialProps,
    list: {},
  },

  [actions.ORG_PERSPECTIVE]: ORGANIZATION_PERSPECTIVE.BY_GBU,
  [actions.ORG_CC_FILTER]: [],
  [actions.ORG_VIEW]: ORGANIZATION_VIEW.FAVORITES,
  [actions.DIAGRAM_LINK]: null,
  [actions.FAVORITE_ORGANIZATIONS]: [],
  [actions.UNDER_MY_RESPONSABILITY]: [],
  [actions.GBU_LIST]: [],
  [actions.DIAGRAM_SCHEMA]: {
    path: {
      selectedGROUP: { id: null, code: "", type: ORGANIZATION_TYPE.GROUP },
      selectedGBU: { id: null, code: "", type: ORGANIZATION_TYPE.GBU },
      selectedBL: { id: null, code: "", type: ORGANIZATION_TYPE.BL },
      selectedRegion: { id: null, code: "", type: ORGANIZATION_TYPE.REGION },
      selectedCountry: { id: null, code: "", name: "", type: ORGANIZATION_TYPE.COUNTRY },
      selectedCBU: { id: null, code: "", type: ORGANIZATION_TYPE.CBU },
    },
    headers: {
      [ORGANIZATION_PERSPECTIVE.BY_GBU]: [
        { type: ORGANIZATION_TYPE.GBU, name: "GBU", selectedUnit: "" },
        { type: ORGANIZATION_TYPE.BL, name: "BL", selectedUnit: "" },
        { type: ORGANIZATION_TYPE.CC, name: "CC", selectedUnit: "", canAddCC: false },
      ],
      [ORGANIZATION_PERSPECTIVE.BY_COUNTRY]: [
        { type: "REGION", name: "Regions", selectedUnit: "" },
        { type: "COUNTRY", name: "Country", selectedUnit: "" },
        { type: "CBU", name: "", selectedUnit: "" },
        { type: "BL", name: "", selectedUnit: "" },
        { type: "CC", name: "CC", selectedUnit: "", canAddCC: false },
      ],
    },
    columns: [
      {
        type: "GROUP",
        nodes: [
          {
            id: "00053af6-7fba-4db8-8572-2d7e23332b6e",
            code: "THALES GROUP",
            name: "Thales GROUP",
            type: "GROUP",
            selected: false,
            children: [],
          },
          {
            id: "0033a03c-91d0-43ab-90b5-824c6951d42a",
            code: "FAVORITES",
            name: "FAVORITES",
            type: "GROUP",
            selected: true,
            children: [],
          },
        ],
      },
    ],
    defaultChild: {
      [ORGANIZATION_PERSPECTIVE.BY_GBU]: {
        type: ORGANIZATION_TYPE.GBU,
        nodes: [],
      },
      [ORGANIZATION_PERSPECTIVE.BY_COUNTRY]: {
        type: ORGANIZATION_TYPE.REGION,
        nodes: [],
      },
    },
  },
  [actions.TOUR_STATUS]: false,
  [actions.TOGGLE_SECTION]: {
    name: "",
    isOpen: false,
  },
  [actions.REQUESTED_ORG_ID]: "",
  [actions.HAS_NODE_LINK]: false,
  [actions.NEW_ORG_DATA]: null,
  [actions.DISABLED_ENG_LIST]: [],
};

import { Dialog, styled } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  paddingLeft: "15px",
  paddingRight: "15px",
  ".MuiPaper-root": {
    borderRadius: "3px",
    // maxWidth: "650px",
  },

  "&#create-comment": {
    ".MuiPaper-root": {
      // maxWidth: "532px",
      "#comment-type, #comment-where": {
        // width: "103px"
      },
      "#comment-linkedTo": {
        // width: "149px"
      },
      "#comment-public-toggle": {
        minWidth: "100px",
      },
      "#comment-public-in": {
        // width: "88px"
      },
      "#comment-body": {
        height: "111px",
      },
    },
  },

  ".MuiDialogContent-root, .MuiDialogActions-root, .dialog-title": {
    paddingLeft: "15px",
    paddingRight: "15px",
  },

  "tds-text-input": {
    input: {
      height: "32px!important",
      fontSize: "12px!important",
    },
  },

  ".qtm-menu-item-list": {
    background: "white",
    // width: "200px",
    position: "fixed",
    zIndex: 1500,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  },

  ".alert-item": {
    display: "flex",
    flexWrap: "nowrap",
    height: "inherit",
    button: {
      "&:hover": {
        height: "30px",
      },
    },
  },

  ".MuiDialogActions-root": {
    justifyContent: "center",
    position: "relative",
    zIndex: 0,
  },

  ".alert-list": {
    "tds-typography": {
      position: "relative",
      top: "-4px",
    },
  },

  ".footer-info": {
    marginBottom: "14px",
  },
}));

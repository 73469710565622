import React from "react";
import { TdsToggleSwitch } from "@tds/react";

export default function AdministrationToggle({ checked, handleChecked, loading, disabled }) {
  return (
    <div className="flex items-center pb-l">
      <p className="text-scale-7">Off</p>
      <TdsToggleSwitch
        color="primary"
        checked={checked}
        disabled={disabled || loading}
        onValueChanged={handleChecked}
      />
      <p className="text-scale-7">On</p>
    </div>
  );
}

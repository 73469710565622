import React, { useContext, useState, useTransition } from "react";
import { Grid } from "@mui/material";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";
import { useSelector } from "react-redux";
import { orgDetailsSelector } from "../../../../../../redux/selectors";
import { EmwButton } from "../../../../../../../../../lib/common";
import { TdsIcon } from "@tds/react";
import EmwDialog from "../../../../../../../../components/EmwDialog/EmwDialog";

export default function ResetBtn({ name, callback }) {
  const context = useContext(OrganizationCardContext);
  const { pending, data } = useSelector(orgDetailsSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [isPending, startTransition] = useTransition();

  const handleClose = () => {
    context.reset();
    setIsOpen(false);
    callback(true);

    startTransition(() => callback(false));
  };

  return (
    <Grid item ml={0.5}>
      <EmwButton variant="ghost" title="Reset" id="btnReset" onClick={() => setIsOpen(!isOpen)}>
        <TdsIcon icon="sync" />
      </EmwButton>

      {isOpen && (
        <EmwDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          callback={handleClose}
          actionButton="Reset"
          title={`Do you want to reset the changes made to the ${
            name === "G/PG"
              ? ""
              : name === null
              ? "Engineering Organization Card?"
              : `Engineering Organization Card: ${name}?`
          }`}
          text="Your changes will be lost if you reset them."
          icon="warning_amber"
          classes="reset-icon"
          loading={pending}
        />
      )}
    </Grid>
  );
}

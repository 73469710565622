import React from "react";
import FilterDropdown from "./components/FilterDropdown/FilterDropdown";

export default function OrgFilters({ filters, setFilters, getChildOf }) {
  const handleCountryChange = selectedValues => {
    let value = selectedValues.map(value => value.id);

    getChildOf("countries", value);
  };

  const handleGbuChange = selectedValues => {
    let value = selectedValues.map(value => value.id);

    getChildOf("gbus", value);
  };

  const handleBlsChange = selectedValues => {
    let value = selectedValues.map(value => value.id);

    getChildOf("bls", value);
  };

  const handleCCsChange = selectedValues => {
    let value = selectedValues.map(value => value.id);

    updateParentState("ccs", value);
  };

  const updateParentState = (key, value) => {
    setFilters({
      ...filters,
      filterValues: {
        ...filters.filterValues,
        [key]: value,
      },
    });
  };

  const sort = (a, b) => {
    if (a.code < b.code) {
      return -1;
    }
    if (a.code > b.code) {
      return 1;
    }
    return 0;
  };

  return (
    <div className="bg-white-100 px-xxl py-l shadow-m-light rounded">
      <div className="flex">
        <div className="mr-xs">
          <FilterDropdown
            placeholder="Countries"
            type="Country"
            options={filters.filterOptions.countries.sort(sort)}
            onChange={handleCountryChange}
          />
        </div>
        <div className="mr-xs">
          <FilterDropdown
            placeholder="GBU's"
            type="GBU"
            options={filters.filterOptions.gbus.sort(sort)}
            onChange={handleGbuChange}
          />
        </div>
        <div className="mr-xs">
          <FilterDropdown
            placeholder="BL"
            type="BL"
            options={filters.filterOptions.bls.sort(sort)}
            onChange={handleBlsChange}
          />
        </div>
        <div className="mr-xs">
          <FilterDropdown
            placeholder="CC"
            type="CC"
            options={filters.filterOptions.ccs.sort(sort)}
            onChange={handleCCsChange}
          />
        </div>
      </div>
    </div>
  );
}

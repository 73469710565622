import React from "react";
import { styled } from "@mui/material/styles";
import { TdsButton } from "@tds/react";

const Root = styled("div")(() => ({
  display: "flex",
}));

export default function EmwButton({ size, disabled, variant, inputId, id, classes, onClick, title, children, color }) {
  const onButtonClick = event => {
    if (typeof onClick === "function") {
      onClick(event);
    }
  };

  return (
    <Root>
      {disabled && (
        <TdsButton
          title={title ? title : ""}
          disabled={disabled}
          variant={variant}
          size={size}
          inputId={inputId}
          id={id ? id : ""}
          classes={classes}
        >
          {children}
        </TdsButton>
      )}

      {!disabled && (
        <TdsButton
          title={title ? title : ""}
          disabled={disabled}
          variant={variant}
          size={size}
          inputId={inputId}
          id={id ? id : ""}
          classes={classes}
          color={color ? color : "primary"}
          onClick={onButtonClick}
        >
          {children}
        </TdsButton>
      )}
    </Root>
  );
}

import React from "react";

export default function ArrowIcon() {
  return (
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.45585 7.41L6.19511 2.83L10.9344 7.41L12.3902 6L6.19511 0L-9.96769e-08 6L1.45585 7.41Z"
        fill="#5D607E"
      />
    </svg>
  );
}

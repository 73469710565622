import React, { useState } from "react";
import { EmwButton } from "../../../../../../../lib/common";
import { Box, DialogContent, Divider, Grid } from "@mui/material";
import { StyledExportDialog } from "./styled";
import HeaderInfo from "./Header/HeaderInfo";
import HeaderCheckList from "./Header/HeaderCheckList";
import ExportOrganizationSectionLeft from "./SectionLeft/ExportOrganizationSectionLeft";
import ExportOrganizationSectionRight from "./SectionRight/ExportOrganizationSectionRight";
import ExportOrganizationContextProvider from "./context/ExportOrganizationContext";
import ExportFileIcon from "../../../../../../../lib/icons/ExportFileIcon";
import TourElement from "../../../../../../components/Tour/TourElement/TourElement";

export default function ExportOrganization({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ExportOrganizationContextProvider>
      <Box style={{ display: "flex", flexDirection: "column", height: "100%" }} id="export-organization-dialog">
        <Grid item flexGrow={1}>
          <Grid container>
            <EmwButton
              id="btnExportOrg"
              title="Export Engineering Organization"
              size="small"
              onClick={handleClick}
              disabled={false}
              variant="ghost"
            >
              <ExportFileIcon width={21} height={26} />
            </EmwButton>

            {isOpen && (
              <StyledExportDialog open={isOpen} fullWidth>
                <Grid className="px-l ">
                  <TourElement id="tour-header-export">
                    <HeaderInfo setIsOpen={setIsOpen} data={data} />
                    <HeaderCheckList data={data} />
                  </TourElement>
                  <Divider flexItem />
                </Grid>

                <DialogContent>
                  <Grid container id="export-dialog-content">
                    <Grid item sm={12} md={7} id="export-dialog-section-left">
                      <ExportOrganizationSectionLeft data={data} />
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ marginTop: "25px", marginBottom: "25px", marginLeft: "-5px" }}
                    />
                    <Grid item sm={12} md={4} id="export-dialog-section-right" sx={{ marginLeft: "25px" }}>
                      <ExportOrganizationSectionRight data={data} />
                    </Grid>
                  </Grid>
                </DialogContent>
              </StyledExportDialog>
            )}
          </Grid>
        </Grid>
      </Box>
    </ExportOrganizationContextProvider>
  );
}

import React, { useEffect, useState } from "react";
import { TextField, Chip } from "@mui/material";
import { StyledAutocomplete } from "./styled";
import { EmwCheckbox } from "../../../../../../../../lib/common";

export default function ExportDropdown({ options, handleChange, disabled, value, type }) {
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    if (!value) {
      return;
    }
    if (Array.isArray(value)) {
      setDefaultValue(value);
    } else {
      setDefaultValue([value]);
    }
  }, [value]);

  return (
    <StyledAutocomplete
      multiple
      disabled={disabled}
      disableCloseOnSelect
      id={`export-dropdown-list-${type}`}
      options={options ? options : []}
      size="small"
      onChange={handleChange}
      getOptionLabel={option => option.code}
      // getOptionSelected={(option, value) => option.id === defaultValue.id}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={defaultValue}
      defaultValue={defaultValue}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: "8px",
        paddingTop: "8px",
      }}
      renderTags={(tagValue, getTagProps) => {
        if (tagValue.length > 1) {
          return null;
        } else {
          return <Chip label={tagValue[0].code} {...getTagProps({})} />;
        }
      }}
      renderOption={(props, option, { selected }) => {
        const _props = {
          ...props,
          key: props.id,
        };

        return (
          <li {..._props}>
            <EmwCheckbox checked={selected} onChange={handleChange} />
            {option.code}
          </li>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={
            (value && value.length > 1) || defaultValue.length > 1
              ? `Multiple ${type}'s selected`
              : !value || value.length === 0
              ? "Select your answer"
              : ""
          }
          size="small"
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "16px",
          }}
        />
      )}
    />
  );
}

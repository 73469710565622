import React from "react";
import { TdsIcon } from "@tds/react";
import { Grid } from "@mui/material";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";

export default function CountryList({ data }) {
  return (
    <Grid item className="orgCountries">
      <TdsIcon variant="outlined" icon="flag_circle" size="large" title="Countries" />
      <Grid item className="orgCountries-list" ml={0.5}>
        <EmwTypography fontSize={9} fontWeight="bold">
          Countries:
        </EmwTypography>
        {data.section1.countries.value && (
          <EmwTypography fontSize={9}>
            {data.section1.countries.value == "WW" ? "Worldwide" : data.section1.countries.value.join(", ")}
          </EmwTypography>
        )}
      </Grid>
    </Grid>
  );
}

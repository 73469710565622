import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const StyledEmwToggleMenu = styled(Grid)(({ theme }) => ({
  ".toggle-menu": {
    backgroundColor: "#CCF2F8",
    borderRadius: 10,
    minHeight: "30px",
    alignItems: "center",
    fontSize: "12px",
    cursor: "pointer",
    flexWrap: "nowrap",
    overflow: "auto",
    padding: "13px",
    ".name": {
      marginLeft: "1px",
    },
  },
}));

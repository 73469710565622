import React, { useMemo, useState } from "react";
import {
  TdsDropdown,
  TdsDropdownOverlay,
  TdsDropdownTrigger,
  TdsIcon,
  TdsMenuItem,
  TdsMenuItemLabel,
  TdsMenuItemList,
} from "@tds/react";
import { NavLink } from "react-router-dom-v5-compat";
import { StyledUserMenu } from "./styled";
import clx from "classnames";
import { ClickAwayListener } from "@mui/material";
import EmwIcon from "../../../../components/EmwIcon/EmwIcon";
import UserDataTooltip from "../UserDataTooltip/UserDataTooltip";
import { logout } from "../../../../auth/utils/MsGraphApiCall";
import useUser from "../../../../auth/useUser";
import { ADMINISTRATION_PAGES, DATA_COLLECTION } from "../../../administration/constants";
import EmwButton from "../../../../components/EmwButton/EmwButton";
import EmwTypography from "../../../../components/EmwTypography/EmwTypography";
import useEnv from "../../../../hooks/useEnv";
import { ENVS } from "../../../../../modules/landingpage/constants";

export default function UserMenu() {
  const env = useEnv();
  const [visible, setVisible] = useState(false);
  const user = useUser();
  const className = clx("user-tooltip flex items-center", {
    "bg-orange-300": user.impersonated,
  });

  const administrationMenuItems = useMemo(() => {
    return ADMINISTRATION_PAGES.filter(page => user[page.permissions]).map(page => {
      if (page.disabled) {
        return (
          <TdsMenuItem key={page.key} disabled={true} title={page.name}>
            <TdsMenuItemLabel>
              <EmwIcon icon={page.icon} variant={page.variant} size="medium" classes="mr-xs" />
              {page.name}
            </TdsMenuItemLabel>
          </TdsMenuItem>
        );
      }

      return (
        <TdsMenuItem key={page.key}>
          <NavLink to={page.path}>
            <TdsMenuItemLabel>
              <EmwIcon icon={page.icon} variant={page.variant} size="medium" classes="mr-xs" />
              {page.name}
            </TdsMenuItemLabel>
          </NavLink>
        </TdsMenuItem>
      );
    });
  }, [user]);

  const dataCollectionMenuItems = useMemo(() => {
    return DATA_COLLECTION.filter(page => user[page.permissions]).map(page => {
      if (page.disabled) {
        return (
          <TdsMenuItem key={page.key} disabled={true}>
            <TdsMenuItemLabel>
              <EmwIcon icon={page.icon} variant={page.variant} size="medium" classes="mr-xs" />
              {page.name}
            </TdsMenuItemLabel>
          </TdsMenuItem>
        );
      }

      return (
        <TdsMenuItem key={page.key}>
          <NavLink to={page.path}>
            <TdsMenuItemLabel>
              <EmwIcon icon={page.icon} variant={page.variant} size="medium" classes="mr-xs" />
              {page.name}
            </TdsMenuItemLabel>
          </NavLink>
        </TdsMenuItem>
      );
    });
  }, [user]);

  return (
    <ClickAwayListener onClickAway={() => setVisible(false)}>
      <StyledUserMenu className={className}>
        <div className="flex flex-col">
          <div className="flex items-center">
            <UserDataTooltip />
            <TdsDropdown placement="bottom-right" visible={visible}>
              <TdsDropdownTrigger>
                <div className="trigger-menu" onClick={() => setVisible(!visible)}>
                  <EmwButton variant="ghost" title="User Menu">
                    <EmwIcon
                      icon="menu"
                      size="large"
                      variant="outlined"
                      classes="userMenu_icon text-primary-500"
                    />
                  </EmwButton>
                </div>
              </TdsDropdownTrigger>
              <TdsDropdownOverlay>
                <TdsMenuItemList>
                  <div>{administrationMenuItems}</div>
                  <div>{dataCollectionMenuItems}</div>
                  <TdsMenuItem>
                    <TdsMenuItemLabel onClick={logout}>
                      <TdsIcon icon="logout" variant="outlined" size="medium" classes="mr-xs" />
                      Logout
                    </TdsMenuItemLabel>
                  </TdsMenuItem>
                </TdsMenuItemList>
              </TdsDropdownOverlay>
            </TdsDropdown>
          </div>
          {env === ENVS.DIGITAL_PPD && (
            <EmwTypography fontSize={9} classes="banner">
              WORKING IN PREPROD
            </EmwTypography>
          )}
        </div>
      </StyledUserMenu>
    </ClickAwayListener>
  );
}

import { createAction } from "@reduxjs/toolkit";
import * as api from "./api";

const props = {
  error: false,
  meta: { fetching: false },
};

export const CHARTS = "charts";
export const ALERTS = "alerts";
export const COMMENTS = "comments";
export const CREATE_TARGET = "create_target";
export const GET_TARGETS = "get_targets";
export const SHOULD_RENDER_LINES = "should_render_lines";
export const GENERAL_FILTERS = "general_filters";
export const CAN_ADD_TARGETS = "can_add_targets";
export const CAN_ADD_ALERTS = "can_add_alerts";
export const USER_ORGANIZATIONS = "user_organizations";
export const ALERT_HISTORY_MODAL = "alert_history_modal";
export const POWER_BI_INITIALIZED = "powerBiInitialized";
export const MY_COMMENTS_HISTORY_MODAL = "comment_history_modal";
export const SELECTED_REPORT = "selected_report";
export const IS_DRILLED = "is_drilled";
export const COMMENTS_ROLES = "comments_roles";
export const ACCESS_TOKEN = "access_token";
export const REPORT_FILTERS = "report_filters";
export const REPORT_FILTERS_HZA = "report_filters_hza";
export const EMBEDDED_URL = "EMBEDDED_URL";

export const updateCharts = createAction(CHARTS);
export const updateAlerts = createAction(ALERTS);
export const updateComments = createAction(COMMENTS);
export const setShouldRenderLines = createAction(SHOULD_RENDER_LINES);
export const setCanAddTarget = createAction(CAN_ADD_TARGETS);
export const setCanAddAlerts = createAction(CAN_ADD_ALERTS);
export const setUserOrgs = createAction(USER_ORGANIZATIONS);
export const setPowerBiInitialized = createAction(POWER_BI_INITIALIZED);
export const getTargets = createAction(GET_TARGETS, payload => ({
  ...props,
  payload: api.getTargetsApi(payload),
}));
export const alertHistoryModal = createAction(ALERT_HISTORY_MODAL);
export const commentsHistoryModal = createAction(MY_COMMENTS_HISTORY_MODAL);
export const setSelectedReport = createAction(SELECTED_REPORT);
export const setIsDrilled = createAction(IS_DRILLED);
export const setCommentsRoles = createAction(COMMENTS_ROLES);
export const setAccessToken = createAction(ACCESS_TOKEN);
export const setReportFilters = createAction(REPORT_FILTERS);
export const setReportFiltersHza = createAction(REPORT_FILTERS_HZA);
export const setEmbeddedUrl = createAction(EMBEDDED_URL);

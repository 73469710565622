import iconErase12 from "./assets/icons8-erase-16.png";
import iconErase32 from "./assets/icons8-erase-32.png";
export const MODULE_NAME = "filters";
export const RESIZABLE = {
  style: {
    borderLeft: "1px solid #B3B0AD",
  },
  defaultSize: {
    width: 240,
    height: "100%",
  },
  maxWidth: 420,
  minWidth: 120,
};
export const RESIZABLE_CLOSED = {
  style: {
    borderLeft: "1px solid #B3B0AD",
  },
};

export const CUSTOM_ERASE_ICON = {
  small: iconErase12,
  large: iconErase32,
};

export const CLASS_NAME_PREFIX = "generalFilters__";

export const FILTERS_SECTIONS = {
  COUNTRY: "country",
  GBU: "gbu",
  BL: "bl",
  CC: "cc",
};

export const FILTERS_TYPE = {
  country: [],
  gbu: [],
  bl: [],
  cc: [],
};

export const DEFAULT_FILTERS = [
  { id: null, name: "Select all" },
  { id: null, name: "(Blank)" },
];

import * as api from "./api";
import * as constants from "./constants";
import { createAction } from "@reduxjs/toolkit";

export const GET_USER = `${constants.MODULE_NAME}/GET_USER`;
export const GET_NOTIFICATIONS = `${constants.MODULE_NAME}/GET_NOTIFICATIONS`;
export const GET_TREE_DATA = `${constants.MODULE_NAME}/GET_TREE_DATA`;
export const DELETE_LOGIN = `${constants.MODULE_NAME}/DELETE_LOGIN`;
export const GET_BI_PROPS = `${constants.MODULE_NAME}/GET_BI_PROPS`;
export const GET_BI_FILTERS = `${constants.MODULE_NAME}/GET_BI_FILTERS`;
export const SET_USER_DATE = `${constants.MODULE_NAME}/SET_USER_DATE`;
export const SET_USER = `${constants.MODULE_NAME}/SET_USER`;
export const HZA_IFRAME_LOADED = `${constants.MODULE_NAME}/HZA_IFRAME_LOADED`;

const props = {
  error: false,
  meta: { fetching: false },
};

export const getUser = payload => {
  return {
    ...props,
    type: GET_USER,
    payload: payload ? payload : api.getUser(),
  };
};

export const getNotification = createAction(GET_NOTIFICATIONS, payload => {
  return {
    ...props,
    payload: api.getNotifications(payload),
  };
});

export const setUserDate = payload => {
  return {
    ...props,
    type: SET_USER_DATE,
    payload: api.setUserDate(payload),
  };
};

export const setUser = payload => {
  return {
    ...props,
    type: SET_USER,
    payload: api.setUser(payload),
  };
};

export const getTreeData = () => {
  return {
    ...props,
    type: GET_TREE_DATA,
    payload: api.getTreeData(),
  };
};

export const deleteLogin = () => {
  return {
    ...props,
    type: DELETE_LOGIN,
    payload: api.deleteLogin(),
  };
};

export const getBiProps = () => {
  return {
    ...props,
    type: GET_BI_PROPS,
    payload: api.getBiProps(),
  };
};

export const getBiFilters = payload => {
  return {
    ...props,
    type: GET_BI_FILTERS,
    payload: payload,
  };
};

export const setHzaIframeLoaded = createAction(HZA_IFRAME_LOADED);

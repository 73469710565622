import { computeURL, fetch, fetchOptions } from "../../../../util/fetch";

export const createTargetApi = async payload => {
  const url = computeURL("chart", "createUpdateTargets");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const getTargetsApi = async payload => {
  const url = computeURL("chart", "getTargets");
  const body = JSON.stringify(payload);
  const method = "POST";

  return fetch(url, fetchOptions({ method, body }));
};

export const createAlertApi = async payload => {
  const url = computeURL("chart", "createAlert");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const getAlertsApi = async payload => {
  const url = computeURL("chart", "getAlerts");
  const body = JSON.stringify(payload);
  const method = "POST";

  return fetch(url, fetchOptions({ method, body }));
};

export const deleteOrganizationApi = async id => {
  const url = computeURL("chart", "deleteAlert", id);
  const method = "DELETE";

  return fetch(url, fetchOptions({ method }));
};

export const deleteTargetApi = async payload => {
  const url = computeURL("chart", "deleteTargets");
  const method = "DELETE";
  const body = JSON.stringify(payload);

  return fetch(url, fetchOptions({ method, body }));
};

export const createCommentApi = async payload => {
  const url = computeURL("chart", "createUpdateComment");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const getCommentsApi = async payload => {
  const url = computeURL("chart", "getComments");
  const body = JSON.stringify(payload);
  const method = "POST";

  return fetch(url, fetchOptions({ method, body }));
};

export const deleteCommentApi = async id => {
  const url = computeURL("chart", "deleteComment", id);
  const method = "DELETE";

  return fetch(url, fetchOptions({ method }));
};

export const markCommentAsApi = async (id, isRead = true) => {
  const url = computeURL("chart", "markCommentAsRead", id, isRead);
  const method = "PUT";

  return fetch(url, fetchOptions({ method }));
};

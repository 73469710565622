import React from "react";
import { goToNode } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";

export default function EmListItemOrgDetails({ orgDetails }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(goToNode({ ...orgDetails }));
  };

  return (
    <EmwTypography classes="text-bluegrey-500 text-underline" fontSize="9">
      <a href="#" onClick={handleClick}>
        {orgDetails.name}
      </a>
    </EmwTypography>
  );
}

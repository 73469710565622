import React, { useState, useEffect, useRef } from "react";
import { Grid, Divider } from "@mui/material";
import { TdsIcon, TdsProgressCircle } from "@tds/react";
import "./styled";
import { EmwButton, EmwDropDownList } from "../../../../../../../lib/common";
import KPIDownloadButton from "./KPIDownloadButton";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";
import KPIEditButton from "./KPIEditButton";
import KPIUploadButton from "./KPIUploadButton";

export default function KPIActions({
  handleSelectFile,
  selectedOption,
  selectedFile,
  cardDetails,
  yearList,
  selectedKPI,
  isGroup,
  isRegion,
  isLoading,
  handleUpload,
  defaultYear,
  countryName,
  errorComments,
  errorFileName,
  getKPIErrors,
  isFromByCountry,
  handleDelete,
  regionName,
}) {
  const [selectedDownloadYear, setSelectedDownloadYear] = useState("");
  const [selectedEditYear, setSelectedEditYear] = useState("");
  const source = "file";

  const inputRef = useRef(null);

  useEffect(() => {
    setSelectedDownloadYear(selectedOption ? defaultYear : "");
    setSelectedEditYear(selectedOption ? defaultYear : "");
  }, [selectedOption]);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleChangeEditYear = e => {
    setSelectedEditYear(e.target.value);
  };

  const handleChangeDownloadYear = e => {
    setSelectedDownloadYear(e.target.value);
  };

  return (
    <>
      <Grid id="kpi-actions">
        {!isGroup && (
          <>
            <Grid container className="pb-xxs flex justify-between mb-xxs" id="upload-container">
              <div id="upload-label">
                <EmwTypography classes="text-bluegrey-900" fontSize={7}>
                  Upload Excel
                </EmwTypography>
                {selectedFile && selectedFile.name && (
                  <EmwTypography
                    component="caption-1"
                    classes="text-bluegrey-500 text-scale-8 py-xxs"
                  >
                    Selected: {selectedFile.name}
                  </EmwTypography>
                )}
                {!selectedFile && (
                  <EmwTypography
                    component="caption-1"
                    classes="text-bluegrey-400 text-scale-8 py-xxs"
                  >
                    Upload KPI file
                  </EmwTypography>
                )}
              </div>
              <Grid item className="kpi-modal-btn flex">
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleSelectFile}
                />
                <EmwButton
                  variant="filled"
                  color="primary"
                  size="small"
                  onClick={handleClick}
                  classes="flex browse-button mr-xs p-0"
                  disabled={!selectedOption}
                >
                  <EmwTypography classes="font-bold">Browse</EmwTypography>
                </EmwButton>
                <KPIUploadButton
                  selectedOption={selectedOption}
                  source={source}
                  selectedFile={selectedFile}
                  handleUpload={handleUpload}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
            <Divider />

            <Grid
              container
              className="pt-xxs pb-xs flex mb-xxs justify-between"
              id="download-container"
            >
              <div id="download-label">
                <EmwTypography classes="text-bluegrey-900" fontSize={7}>
                  Download Excel
                </EmwTypography>
                <EmwTypography
                  component="caption-1"
                  classes="text-bluegrey-400 text-scale-8 pt-xxs"
                >
                  Download KPI File
                </EmwTypography>
              </div>

              <Grid item className="flex flex-row justify-between">
                <Grid item className="flex mr-xs" id="select-year-dropdown">
                  <EmwDropDownList
                    disabled={!selectedOption}
                    options={yearList}
                    onDropDownListChanged={handleChangeDownloadYear}
                    value={selectedDownloadYear}
                    placement="bottom"
                    inputId="year"
                    placeHolder="Year"
                  />
                </Grid>
                <Grid item className="flex kpi-modal-btn">
                  <KPIDownloadButton
                    selectedYear={selectedDownloadYear}
                    selectedKPI={selectedKPI}
                    isRegion={isRegion}
                    regionName={isRegion ? "North America" : ""}
                    cardDetails={cardDetails}
                    selectedOption={selectedOption}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Divider />

            <Grid
              container
              className="pt-xxs pb-xs flex mb-xxs justify-between"
              id="download-container"
            >
              <div id="download-label">
                <EmwTypography classes="text-bluegrey-900" fontSize={7}>
                  Edit Excel Form
                </EmwTypography>
                <EmwTypography
                  component="caption-1"
                  classes="text-bluegrey-400 text-scale-8 pt-xxs"
                >
                  Edit Excel Form
                </EmwTypography>
              </div>

              <Grid item className="flex flex-row justify-between">
                <Grid item className="flex mr-xs" id="select-year-dropdown">
                  <EmwDropDownList
                    disabled={!selectedOption}
                    options={yearList}
                    onDropDownListChanged={handleChangeEditYear}
                    value={selectedEditYear}
                    placement="bottom"
                    inputId="year"
                    placeHolder="Year"
                  />
                </Grid>
                <Grid item className="flex kpi-modal-btn">
                  <KPIEditButton
                    selectedOption={selectedOption}
                    handleUpload={handleUpload}
                    selectedEditYear={selectedEditYear}
                    selectedKPI={selectedKPI}
                    cardDetails={cardDetails}
                    regionName={isRegion ? regionName : ""}
                    countryName={countryName}
                    errorComments={errorComments}
                    errorFileName={errorFileName}
                    getKPIErrors={getKPIErrors}
                    isFromByCountry={isFromByCountry}
                    handleDelete={handleDelete}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {isGroup && (
          <Grid
            container
            className="pt-xxs pb-xs flex mb-xxs justify-between"
            id="download-container"
          >
            <div id="download-label">
              <EmwTypography classes="text-black-100 font-medium text-scale-7">
                Download Excel
              </EmwTypography>
              <EmwTypography component="caption-1" classes="text-bluegrey-400 text-scale-8 pt-xxs">
                Download KPI File
              </EmwTypography>
            </div>
            <Grid item className="flex flex-row justify-between">
              <Grid item className="flex mr-xs" id="select-year-dropdown">
                <EmwDropDownList
                  disabled={!selectedOption}
                  options={yearList}
                  onDropDownListChanged={handleChangeDownloadYear}
                  value={selectedDownloadYear}
                  placement="bottom"
                  inputId="year"
                  placeHolder="Year"
                />
              </Grid>
              <Grid item className="flex kpi-modal-btn">
                <KPIDownloadButton
                  selectedYear={selectedDownloadYear}
                  selectedKPI={selectedKPI}
                  regionName={isRegion ? "North America" : ""}
                  cardDetails={cardDetails}
                  selectedOption={selectedOption}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Divider />
      </Grid>
    </>
  );
}

import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const StyledOrganizationContainer = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  maxHeight: "100%",
  backgroundColor: "white",
  padding: "16px",
  borderRadius: "6px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",

  "#organization-card-header": {
    alignItems: "baseline",
    display: "flex",
    justifyContent: "space-between",
    minHeight: "95px",
    maxHeight: "95px",
    borderBottom: `2px solid ${theme.palette.primary.main}`,

    ".header-row": {
      minHeight: "35px",
      maxHeight: "35px",
      alignItems: "end",
      display: "flex",
      "&:first-of-type": {
        borderBottom: `2px solid ${theme.palette.bluegrey[200]}`,
        paddingBottom: "6px",
      },

      ".organization-card-name-wrapper": {
        height: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
        lineHeight: 1,
      },

      ".card-name": {
        maxWidth: "310px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },

    ".header-elements": {
      padding: "5px",
    },

    ".organization-card-actions": {
      display: "flex",
      justifyContent: "flex-end",

      ".qtm-icon i": {
        fontSize: "32px",
      },

      ".edit-button": {
        i: {
          fontSize: "16px",
        },
      },
    },
    ".tour-actions": {
      height: "40px",
      paddingTop: "2px",
      paddingRight: "2px",
    },
  },

  ".organization-card-section-1": {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #041295",
  },

  ".organization-card-section-2": {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
    marginLeft: "16px",
    marginBottom: "8px"
  },
}));

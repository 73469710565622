import { DATE_FORMAT, NO_RESOURCE_ALLOCATED } from "../../../../constants";
import moment from "moment";

const sortValidItems = (items, propName) => {
  return items.sort((a, b) => {
    const nameA = a.props[propName].name.value.toLowerCase();
    const nameB = b.props[propName].name.value.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

const reverseAndUnshift = (items, propName) => {
  let regExp;

  if (propName === "delivery") {
    regExp = /^Delivery [0-9]+$/;
  }

  if (propName === "solution") {
    regExp = /^Solution\/Project [0-9]+$/;
  }

  if (propName === "workPackage") {
    regExp = /^Work Package [0-9]+$/;
  }

  if (propName === "discipline") {
    regExp = /^Discipline [0-9]+$/;
  }

  const sortAsc = (a, b) => {
    const nameA = a.props[propName].name.value.toLowerCase();
    const nameB = b.props[propName].name.value.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const firstItems = items
    .filter(item => {
      return regExp.test(item.props[propName].name.value);
    })
    .sort(sortAsc);

  const lastItems = items
    .filter(item => {
      return !regExp.test(item.props[propName].name.value);
    })
    .sort(sortAsc);

  // firstItems.reverse();
  const lastItem = firstItems.pop();
  firstItems.unshift(lastItem);

  return [...firstItems, ...lastItems];
};

const dateIsExpired = date => {
  const today = moment(new Date()).format("yyyy-MM-DD");
  const expDate = moment(date).format("yyyy-MM-DD");

  return new Date(today).getTime() >= new Date(expDate).getTime();
};

/**
 * Split Deliveris, Solutions, Work Packes and Disciplines list in 2 part
 * part 1 is incomplete items (No name OR No leader OR Name with regex match and Leader with exp date lower or equeat with today)
 * part 2 is complete items (No name with regex match and leader with no exp date or exp date gte than today)
 *
 * @param list
 * @param regExp
 * @returns {{_completeItems: (*|*[]), _incompleteItems: (*|*[])}}
 */
const splitList = (list, regExp) => {
  let _incompleteItems = [];
  let _completeItems = [];

  _incompleteItems = list.filter((_item, index) => {
    const noHoed = !_item.leader.name.value || _item.leader.name.value === NO_RESOURCE_ALLOCATED;
    const noName = !_item.name.value && regExp.test(_item.name.value);

    _item.originalIndex = index;

    if (_item.leader.endDate.value) {
      // return dateIsExpired(_item.leader.endDate.value);
    }

    if (regExp.test(_item.name.value)) {
      return true;
    }

    if (noHoed) {
      return true;
    }

    if (noName) {
      return true;
    }
  });

  _completeItems = list.filter((_item, index) => {
    const hasHoD = _item.leader.name.value && _item.leader.name.value !== NO_RESOURCE_ALLOCATED;
    const hasName =
      _item.name.value ||
      _item.name.value !== NO_RESOURCE_ALLOCATED ||
      regExp.test(_item.name.value);
    const hasValidHodDate = dateIsExpired(_item.leader.endDate.value);

    _item.originalIndex = index;

    if (regExp.test(_item.name.value)) {
      return false;
    }

    return hasHoD && hasName /*&& !hasValidHodDate*/;
  });

  return {
    _incompleteItems,
    _completeItems,
  };
};

export { reverseAndUnshift, dateIsExpired, sortValidItems, splitList };

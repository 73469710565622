import React, { useContext, useState } from "react";
import { computeURL, fetchDownload, fetchOptions } from "../../../../../../../../util/fetch";
import { EmwButton } from "../../../../../../../../lib/common";
import EmwTypography from "../../../../../../../components/EmwTypography/EmwTypography";
import { styled } from "@mui/material/styles";
import {
  TdsIcon,
  TdsProgressCircle,
  TdsDropdown,
  TdsDropdownOverlay,
  TdsDropdownTrigger,
  TdsMenuItem,
  TdsMenuItemList,
  TdsButton,
  TdsMenuItemLabel,
} from "@tds/react";
import { ExportOrganizationContext } from "../context/ExportOrganizationContext";
import { useEffect } from "react";

const StyledDropdownOverlay = styled(TdsDropdownOverlay)(() => ({
  ".qtm-dropdown-overlay": {
    padding: "0px",
    left: "-200px !important",
    ".qtm-menu-item-label": {
      padding: "10px",
      width: "280px",
    },
  },
}));

const StyledExportMenu = styled("div")(() => ({
  ".qtm-btn.qtm-ghost": {
    color: "#131319",
    backgroundColor: "transparent !important",
    padding: "0px",
  },
  ".qtm-btn.qtm-ghost:hover": {
    backgroundColor: "transparent !important",
    color: "#131319",
  },
  ".eng-org-export": {
    padding: "0px !important",
  },
}));

export default function ExportOrganizationButton({ data, setIsOpen }) {
  const context = useContext(ExportOrganizationContext);
  const [isLoading, setIsLoading] = useState(false);
  const type = context.state.exportType;
  const exportFromEdge = (fileName, blob) => {
    window.navigator.msSaveBlob(new Blob([blob], { type: "data:application/vnd.ms-excel" }), fileName);
    return {};
  };

  useEffect(() => {
    context.setTimestamp(Date.now());
  }, []);

  useEffect(() => {
    if (!type) {
      return;
    } else {
      handleExport();
    }
  }, [type]);

  const exportFile = (fileName, blob) => {
    if (window.navigator.userAgent.match(/Edge/)) {
      return exportFromEdge(fileName, blob);
    }
    if (blob.byteLength < 1000) {
      return JSON.parse(new TextDecoder("utf-8").decode(blob));
    }
    const newBlob = new Blob([blob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement("a");
    link.href = data;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(data);
    }, 500);
    return {};
  };

  const exportData = async () =>
    fetchDownload(
      computeURL("export", "engineeringOrganization"),
      fetchOptions({
        method: "put",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/octet-stream",
          "Accept-Encoding": "gzip, deflate, br",
        },
        body: JSON.stringify(context.state),
      })
    );

  const handleClick = value => {
    context.setExport(value);
  };

  const handleExport = async () => {
    if (type) {
      setIsLoading(true);
      const response = await exportData();
      const fileName = context.state.fileName;

      response.arrayBuffer().then(function (buffer) {
        exportFile(fileName, buffer);
      });

      setIsLoading(false);
      context.resetOnClose();
      setIsOpen(false);
    }
  };

  return (
    <TdsDropdown placement="bottom-left">
      <TdsDropdownTrigger>
        <EmwButton variant="filled" id="btnExport" title="Export" size="small" disabled={isLoading || !data}>
          {isLoading && (
            <span className="mr-xxs">
              <TdsProgressCircle />
            </span>
          )}
          {!isLoading && <TdsIcon icon="arrow_circle_down" size="small" />}
          <EmwTypography classes="text-white-100">Export</EmwTypography>
        </EmwButton>
      </TdsDropdownTrigger>
      <StyledDropdownOverlay>
        <TdsMenuItemList>
          <TdsMenuItem>
            <TdsMenuItemLabel onClickEvent={() => handleClick("WORKER")}>
              <StyledExportMenu>
                <EmwButton
                  classes="eng-org-export"
                  variant="ghost"
                  title="Engineering Workers"
                  id="exportBtn"
                >
                  <EmwTypography classes="text-black-100">Engineering Workers</EmwTypography>
                </EmwButton>
              </StyledExportMenu>
            </TdsMenuItemLabel>
          </TdsMenuItem>
          <TdsMenuItem>
            <TdsMenuItemLabel onClickEvent={() => handleClick("ORGANIZATION")}>
              <StyledExportMenu>
                <EmwButton
                  classes="eng-org-export"
                  variant="ghost"
                  title="Engineering Organizations"
                  id="exportBtn"
                >
                  <EmwTypography classes="text-black-100">
                    Engineering Organizations
                  </EmwTypography>
                </EmwButton>
              </StyledExportMenu>
            </TdsMenuItemLabel>
          </TdsMenuItem>
        </TdsMenuItemList>
      </StyledDropdownOverlay>
    </TdsDropdown>
  );
}

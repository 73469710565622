import { styled } from "@mui/material/styles";

export const StyledTimeframe = styled("div")(({ theme }) => {
  return {
    ".toggle": {
      rotate: "180deg",
    },

    "#period": {
      maxWidth: "112px",
    },
  };
});

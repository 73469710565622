import React, { useEffect, useState, useContext } from "react";
import { Box, Divider, Grid, IconButton, styled } from "@mui/material";
import FormEditItem from "./FormEditItem";
import moment from "moment/moment";
import { DATE_FORMAT, NO_RESOURCE_ALLOCATED, ROLES_TYPE } from "../../../../../../constants";
import DoubleArrow from "../../../../../../../../../lib/icons/DoubleArrow";
import ArrowIcon from "../../../../../../../../../lib/icons/ArrowIcon";
import clx from "classnames";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";
import ItemName from "./ItemName";
import { TdsIcon } from "@tds/react";
import { EmwButton } from "../../../../../../../../../lib/common";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";

export const StyledTogglePanelItem = styled(Grid)(({ theme }) => ({
  fontSize: "10px",
  margin: "4px 0",
  borderRadius: "10px",
  padding: "10px",
  fontWeight: "bold",
  backgroundColor: "#F7F7F9",

  "&.no-allocation": {
    border: `1px solid ${theme.palette.orange[500]}`,

    "p:nth-child(2)": {
      color: theme.palette.orange[500] + "!important",
      fontSize: "12px",
      position: "relative",
      top: "-2px",
    },
  },
}));

export default function FormItem({
  type,
  item,
  isNew,
  index,
  noEdit,
  handleUpdate,
  showArrows,
  propKey,
  color,
  isOpen,
  setIsOpen,
  collapsable,
  isTextField,
  customName,
  targetKey,
  id,
  title,
  handleChangeHoD,
  text,
  noResource,
  beforeEditClick,
  beforeCancelClick,
  isEditOpen,
  customInfoMsg,
  childrenIndex,
  section,
}) {
  const context = useContext(OrganizationCardContext);
  const [editMode, setEditMode] = useState(!!isNew);
  const [name, setName] = useState("");
  const [canEdit, setCanEdit] = useState(false);
  const className = clx({
    "text-orange-500 no-allocation": name === NO_RESOURCE_ALLOCATED || !item.name.value,
    "ml-m": noEdit,
  });

  useEffect(() => {
    if (customName) {
      setName(customName);
    } else if (item.hasOwnProperty("name")) {
      item.name.value = item.name.value || NO_RESOURCE_ALLOCATED;
      setName(item.name.value || NO_RESOURCE_ALLOCATED);
    }
    if (item.name.value) {
      setName(item.name.value);
      if (item.baseName && item.comment.value) {
        setName(item.baseName + " - " + item.comment.value);
      }
    }
  }, [item]);

  useEffect(() => {
    if (item.key && item.key === targetKey && !editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }

    setCanEdit(item.btnEdit.visible);
  }, []);

  const toggle = () => {
    setEditMode(true);
  };

  if (editMode || isEditOpen) {
    return (
      <FormEditItem
        section={section}
        id={id}
        text={text}
        item={item}
        label={type}
        toggle={toggle}
        setEditMode={setEditMode}
        index={index}
        name={name}
        handleUpdate={handleUpdate}
        propKey={propKey}
        isTextField={isTextField}
        handleChangeHoD={handleChangeHoD}
        isNew={isNew}
        beforeCancelClick={() => {
          if (beforeCancelClick) return beforeCancelClick();
        }}
        childrenIndex={childrenIndex}
      />
    );
  }

  const renderDivider = () => {
    if (canEdit && !noEdit) {
      return <Divider orientation="vertical" flexItem sx={{ margin: "0 10px" }} />;
    }
    return null;
  };

  const renderType = () => {
    if (item.hasOwnProperty("type") && item.type.visible) {
      let typeName = "Effective";

      if (item.type.value) {
        typeName = ROLES_TYPE[item.type.value];
      }

      return (
        <EmwTypography fontSize="9" classes="ml-m text-black-100">
          {typeName}
        </EmwTypography>
      );
    }

    return null;
  };

  const renderDates = () => {
    let startDate;
    let endDate;

    if (item.hasOwnProperty("startDate")) {
      startDate = item.startDate;
    } else if (item.hasOwnProperty("date")) {
      startDate = item.date;
    }

    if (item.hasOwnProperty("endDate")) {
      endDate = item.endDate;
    }

    return (
      <Grid container direction="column" alignItems="end" sx={{ lineHeight: "normal" }}>
        {startDate.visible && (
          <Box>
            <span style={{ marginRight: "5px" }}>From:</span>{" "}
            {Boolean(startDate.value) && moment(startDate.value).format(DATE_FORMAT)}
          </Box>
        )}
        {endDate.visible && (
          <Box>
            <span style={{ marginRight: "5px" }}>To:</span>{" "}
            {Boolean(endDate.value) && moment(endDate.value).format(DATE_FORMAT)}
          </Box>
        )}
      </Grid>
    );
  };

  const handleEditClick = () => {
    if (!editMode && beforeEditClick) {
      beforeEditClick();
    }
    setEditMode(!editMode);
  };

  if (isNew) {
    return false;
  }

  return (
    <StyledTogglePanelItem
      item
      key={item.id}
      sx={{
        marginLeft: noEdit ? "20px" : 0,
        backgroundColor: color ? color : "#F7F7F9",
        borderRadius: isOpen ? "5px 5px 0 0" : "5px",
        border: () => {
          if (noResource) {
            return "1px solid #e07900";
          }
          return "";
        },
      }}
      data-key={item.key}
      className={className}
    >
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Grid container>
              {type && (
                <>
                  <Box marginRight="12px" title={title}>
                    <EmwTypography classes="text-bluegrey-800" fontSize="9" fontWeight="bold">
                      {type}:
                    </EmwTypography>
                  </Box>
                  <ItemName name={name} />
                </>
              )}

              {!type && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ItemName name={name} item={item} />
                  {customInfoMsg ? (
                    <EmwTypography classes="text-orange-500 ml-xs" fontWeight="bold" fontSize="8">
                      {customInfoMsg}
                    </EmwTypography>
                  ) : (
                    ""
                  )}
                </div>
              )}

              {renderType()}
            </Grid>
          </Grid>

          <Grid item ml="auto">
            {!noEdit && renderDates()}
          </Grid>

          {renderDivider()}

          {!noEdit && (
            <Grid item>
              <Grid container alignItems="self-end">
                {canEdit && (
                  <EmwButton
                    classes="edit-button"
                    variant="filled"
                    size="small"
                    onClick={handleEditClick}
                    disableRipple
                    id={`btnEdit-${id}`}
                  >
                    <TdsIcon icon="edit" variant="outlined" size="small" />
                  </EmwButton>
                )}
                {showArrows && (
                  <>
                    <IconButton
                      sx={{ padding: 0, marginRight: "8px" }}
                      disableRipple
                      id="btnDoubleArrow"
                    >
                      <DoubleArrow />
                    </IconButton>
                    <IconButton sx={{ padding: 0, marginRight: "8px" }} disableRipple>
                      <ArrowIcon />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {collapsable && (
            <Grid item>
              <Grid container alignItems="center">
                <IconButton
                  title={`${isOpen ? "Collapse section" : "Expand section"}`}
                  disableRipple
                  size="small"
                  onClick={() => setIsOpen(!isOpen)}
                  id={`${isOpen ? `btnCollapse-${id}` : `btnExpand-${id}`}`}
                >
                  {isOpen ? (
                    <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: "#4A4D65" }} />
                  ) : (
                    <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: "#4A4D65" }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </StyledTogglePanelItem>
  );
}

import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { getZoom } from "../../../utils/sizeUtils";

export const StyledLandingPage = styled(Grid)(({ theme }) => {
  let zoom = getZoom();

  return {
    zoom: `${zoom}%`,
    ".heading": {
      zoom: `${zoom}%`,
      textAlign: "center",
      width: "100%",
      h1: {
        color: theme.palette.primary[500],
      },
    },
    ".cards": {
      marginTop: "50px",
      width: "100%",
    },
  };
});

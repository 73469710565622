import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";
import { useSelector } from "react-redux";
import { orgDetailsSelector } from "../../../../../../redux/selectors";
import { EmwButton } from "../../../../../../../../../lib/common";
import { TdsIcon } from "@tds/react";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";
import EmwDialog from "../../../../../../../../components/EmwDialog/EmwDialog";

export default function SaveBtn({ name, parentCountryId }) {
  const context = useContext(OrganizationCardContext);
  const { loading, data } = useSelector(orgDetailsSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!loading && data && isOpen) {
      setIsOpen(false);
    }
  }, [loading, data]);

  const handleSave = async () => {
    setIsLoading(true);
    await context.submit(parentCountryId);
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <Grid item ml={0.5}>
      <EmwButton variant="filled" size="small" onClick={() => setIsOpen(!isOpen)} title="Save" id="btnSave">
        <TdsIcon icon="save" />
        <EmwTypography>Save</EmwTypography>
      </EmwButton>

      {isOpen && (
        <EmwDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          callback={handleSave}
          actionButton="Save"
          title={`Do you want to save the changes made to the ${
            name === "G/PG"
              ? ""
              : name === null
              ? "Engineering Organization Card?"
              : `Engineering Organization Card: ${name}?`
          }`}
          text="Your changes will be lost if you don't save them."
          icon="info"
          classes="save-icon"
          loading={isLoading}
        />
      )}
    </Grid>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom-v5-compat";
import { ThemeProvider } from "@mui/material/styles";
import { EventType } from "@azure/msal-browser";
import { loginRequest } from "../V2/auth/authConfig";
import MsalApp from "../V2/features/app/MsalApp";
import { thalesTheme } from "../theme";
import MsalInstance from "../V2/auth/utils/MsalInstance";

const msalInstance = MsalInstance.getInstance();
msalInstance.initialize().then(async () => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }

    if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      // Force user interaction login if the token renewing is disabled by azure config
      msalInstance.loginRedirect(loginRequest);
    }
  });

  msalInstance.handleRedirectPromise().then(authResult => {
    // Check if user signed in
    const account = msalInstance.getActiveAccount();

    if (!account && !localStorage.getItem("loggedOut")) {
      // redirect anonymous user to login page
      msalInstance.loginRedirect(loginRequest);
    }
  });

  const container = document.getElementById("root");
  const root = ReactDOM.createRoot(container);

  root.render(
    <Router>
      <ThemeProvider theme={thalesTheme}>
        <MsalApp msalInstance={msalInstance} />
      </ThemeProvider>
    </Router>
  );
});

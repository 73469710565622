import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { treeDataSelector } from "../../../features/header/redux/selectors";
import useAppSnackbar from "../../../hooks/useAppSnankbar";
import useChartUtils from "./useChartUtils";

export default function useChartFilters() {
  const treeData = useSelector(treeDataSelector);
  const [filteredData, setFilteredData] = useState(null);
  const [filters, setFilters] = useState({
    timeframe: {
      default: true,
      date: "",
      period: "12 months",
    },
    filterValues: {
      ccs: [],
      bls: [],
      gbus: [],
      countries: [],
    },
    filterOptions: {
      ccs: [],
      bls: [],
      gbus: [],
      countries: [],
    },
    loaded: false,
  });

  useEffect(() => {
    if (!treeData.list.hasOwnProperty("data")) {
      return;
    }
    setFilteredData(filter(treeData.list.data));
  }, [treeData]);

  useEffect(() => {
    if (filteredData) {
      initFilters("countries");
    }
  }, [filteredData]);

  const initFilters = type => {
    const countryList = [];
    const gbuList = [];
    const blList = [];
    const ccList = [];

    if (type === "countries") {
      filteredData.regions.forEach(region => {
        region.childs.forEach(country => {
          countryList.push({ id: country.id, code: country.name, childs: country.childs });
        });
      });

      filteredData.gbus.forEach(gbu => {
        gbuList.push({ id: gbu.id, code: gbu.code, childs: gbu.childs });
        gbu.childs.forEach(bl => {
          blList.push({ id: bl.id, code: bl.code, childs: bl.childs });
          bl.childs.forEach(cc => {
            ccList.push({ id: cc.id, code: cc.name, childs: cc.childs });
          });
        });
      });

      setFilters(currentFilters => {
        return {
          ...currentFilters,
          filterOptions: {
            ccs: ccList,
            bls: blList,
            gbus: gbuList,
            countries: countryList,
          },
          filterValues: {
            ccs: [],
            bls: [],
            gbus: [],
            countries: [],
          },
          loaded: true,
        };
      });
    }

    if (type === "gbus") {
      const gbuList = filters.filterOptions.gbus;
      const blList = [];
      const ccList = [];

      gbuList.forEach(gbu => {
        gbu.childs.forEach(bl => {
          blList.push(bl);
          bl.childs.forEach(cc => ccList.push({ ...cc, code: cc.name }));
        });
      });

      setFilters(currentFilters => {
        return {
          ...currentFilters,
          filterOptions: {
            ...currentFilters.filterOptions,
            bls: blList,
            ccs: ccList,
          },
          filterValues: {
            ...currentFilters.filterValues,
            gbus: [],
            ccs: [],
            bls: [],
          },
        };
      });
    }

    if (type === "bls") {
      const blList = filters.filterOptions.bls;
      const ccList = [];

      blList.forEach(bl => {
        bl.childs.forEach(cc => ccList.push({ ...cc, code: cc.name }));
      });

      setFilters(currentFilters => {
        return {
          ...currentFilters,
          filterOptions: {
            ...currentFilters.filterOptions,
            ccs: ccList,
          },
          filterValues: {
            ...currentFilters.filterValues,
            bls: [],
          },
        };
      });
    }
  };

  const getChildOf = (type, ids) => {
    if (type === "countries") {
      const countries = [];
      const gbus = [];
      const bls = [];
      const ccs = [];

      if (ids.length === 0) {
        return initFilters(type);
      }

      filteredData.regions.forEach(region => {
        return region.childs.forEach(country => {
          if (ids.includes(country.id)) {
            countries.push(country);
          }
        });
      });

      countries.forEach(country => {
        country.childs.forEach(gbu => {
          gbus.push(gbu);
          gbu.childs.forEach(bl => {
            bls.push(bl);
            bl.childs.forEach(cc => {
              ccs.push({ ...cc, code: cc.name });
            });
          });
        });
      });

      setFilters(currentFilters => {
        return {
          ...currentFilters,
          filterValues: {
            countries: countries.map(country => country.id),
            gbus: [],
            bls: [],
            ccs: [],
          },
          filterOptions: {
            ...currentFilters.filterOptions,
            gbus,
            bls,
            ccs,
          },
        };
      });
    }

    if (type === "gbus") {
      const bls = [];
      const ccs = [];

      if (ids.length === 0) {
        return initFilters(type);
      }

      filters.filterOptions.gbus
        .filter(gbu => ids.includes(gbu.id))
        .forEach(gbu => {
          gbu.childs.forEach(bl => {
            bls.push(bl);
            bl.childs.forEach(cc => ccs.push({ ...cc, code: cc.name }));
          });
        });

      setFilters(currentFilters => {
        return {
          ...currentFilters,
          filterValues: {
            ...currentFilters.filterValues,
            gbus: ids,
            bls: [],
            ccs: [],
          },
          filterOptions: {
            ...currentFilters.filterOptions,
            bls,
            ccs,
          },
        };
      });
    }

    if (type === "bls") {
      const ccs = [];

      if (ids.length === 0) {
        return initFilters(type);
      }

      filters.filterOptions.bls
        .filter(bl => ids.includes(bl.id))
        .forEach(bl => {
          bl.childs.forEach(cc => ccs.push({ ...cc, code: cc.name }));
        });

      setFilters(currentFilters => {
        return {
          ...currentFilters,
          filterValues: {
            ...currentFilters.filterValues,
            bls: ids,
            ccs: [],
          },
          filterOptions: {
            ...currentFilters.filterOptions,
            ccs,
          },
        };
      });
    }

    if (type === "bls") {
      setFilters(currentFilters => {
        return {
          ...currentFilters,
          filterValues: {
            ...currentFilters.filterValues,
            bls: ids,
            ccs: [],
          },
        };
      });
    }
  };

  const filter = data => {
    const _result = {};
    _result.gbus = removeUnknownAndDuplicateNameObjects(data.gbus);
    _result.regions = removeUnknownAndDuplicateNameObjects(data.regions);

    return _result;
  };

  function removeUnknownAndDuplicateNameObjects(obj, namesSeen = new Set()) {
    obj = obj.filter(item => {
      const isDuplicate = namesSeen.has(item.name);
      const isUnknown = item.name.toLowerCase().includes("unknown");
      if (!isDuplicate && !isUnknown) {
        namesSeen.add(item.name);
        return true;
      }
      return false;
    });

    obj.sort((a, b) => a.name.localeCompare(b.name));

    obj.forEach(item => {
      if (item.childs && Array.isArray(item.childs)) {
        item.childs = removeUnknownAndDuplicateNameObjects(item.childs, namesSeen);
      }
    });

    return obj;
  }

  return {
    filters,
    setFilters,
    getChildOf,
  };
}

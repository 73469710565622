import { styled } from "@mui/material/styles";

export const StyledBarChart = styled("div")(({ theme }) => {
  return {
    width: "100%",
    height: "100%",
    ".bullet": {
      "& >div": {
        marginBottom: "1px",
        width: "7px",
        height: "7px",
      },
    },

    "qtm-icon": {
      cursor: "help",
    },

    "qtm-tooltip": {
      "*": {
        background: "white!important",
        color: "black",
        opacity: 0.9,
      },
    },

    ".drill-button .qtm-btn": {
      padding: "4px 10px",
      height: "30px",
      backgroundColor: "##1D4395",
    },
  };
});

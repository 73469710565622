import React, { useContext} from "react";
import { Grid } from "@mui/material";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";
import EmwTextField from "../../../../../../../../../lib/commonv2/EmwTextField";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";

export default function CardDescription({
  setDescriptionCard,
  descriptionCard,
  abbreviation,
}) {
  const context = useContext(OrganizationCardContext);

  const onChangeDescriptionCard = event => {
    setDescriptionCard(event.target.value);
    context.updateDescription(abbreviation, event.target.value);
  };


  if (!context.data) {
    return null;
  }

  return (
    <Grid container alignItems="center" gap={0}>
      <Grid item mr={1}>
          <EmwTypography classes="text-primary-500" fontWeight="bold" fontSize={8}>
            Card Description:
          </EmwTypography>
      </Grid>
      <Grid container alignItems="center" sx={{ maxWidth: "420px" }}>
          <Grid container direction="row" alignItems={"end"}>
            <Grid item sx={{ maxWidth: "100px" }}>
              <EmwTextField
                id="organization-card-description"
                name={descriptionCard}
                value={descriptionCard}
                onChange={onChangeDescriptionCard}
                classes={"description"}
                placeholder={`( CC Description )`}
                inputProps={{
                  maxLength: 250,
                }}
              />
            </Grid>
          </Grid>
      </Grid>
    </Grid>
  );
}

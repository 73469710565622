import { styled } from "@mui/material/styles";
import { TdsHeader } from "@tds/react";
import { getZoom } from "../../../utils/sizeUtils";

export const StyledHeader = styled(TdsHeader)(({ theme }) => {
  let zoom = getZoom();

  return {
    ".qtm-header": {
      height: `56px`,
      paddingRight: 0,
      flexGrow: 1,
      alignItems: "center",
      boxShadow:
        "0px 2px 4px rgba(1, 5, 50, 0.14), 0px 4px 5px rgba(1, 5, 50, 0.12), 0px 1px 10px rgba(1, 5, 50, 0.2)",
      position: "relative",
      zIndex: 100,
      zoom: `${zoom}%`,

      ".qtm-header-brand": {
        marginRight: "23px",
      },

      ".competitiveness": {
        marginLeft: "49.5px",
        marginRight: "0px",
      },

      ".jobFamily": {
        marginLeft: "99px",
        marginRight: "0px",
      },
      ".emw-tab": {
        padding: "0px"
      },
      ".emw-tab a": {
        padding: "16px"
      },
      ".banner":{
        backgroundColor: "#E64B33",
        color: "white",
        padding: "0px 5px",
        display:"flex",
        alignItems: "center",
      }
    },
  };
});

export const StyledExportBtn = styled("div")(() => ({
  ".qtm-btn.qtm-ghost:not([disabled])": {
    color: "#131319",
    padding: "0px",
  },
  ".qtm-btn.qtm-ghost:hover:not([disabled])": {
    backgroundColor: "transparent !important",
    color: "#131319",
  },
  ".file-download": {
    backgroundColor: "transparent !important",
    padding: "0px",
  },
}));

import { styled, Box, Grid } from "@mui/material";

export const StyledColorDropdown = styled(Box)(({ theme, color }) => ({
  ".qtm-text-input.medium input, .qtm-text-input input": {
    backgroundColor: `${color}`,
  },
}));

export const StyledGridItem = styled(Grid)(({ theme, ...props }) => ({
  backgroundColor: props.isRef ? "#CCF2F8" : "#F7F7F9",
  borderRadius: 10,
  minHeight: "30px",
  marginBottom: "5px",
  marginTop: "5px",
  alignItems: "center",
}));

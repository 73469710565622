import React, { useState } from "react";
import GeneralFiltersTooltip from "../GeneralFiltersTooltip/GeneralFiltersTooltip";
import { CLASS_NAME_PREFIX, CUSTOM_ERASE_ICON } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { clearAllFiltersSelector } from "../../redux/selectors";
import { clearAllFilters, setSelectedFilters } from "../../redux/actions";

/**
 * @TODO: Add advanced filters in options state if required in the future
 * @returns {Element}
 * @constructor
 */
export default function GeneralFiltersMore() {
  const dispatch = useDispatch();
  const shouldClearFilters = useSelector(clearAllFiltersSelector);
  const [options, setOptions] = useState([]);

  const clearAll = () => {
    dispatch(clearAllFilters(Math.random()));
    dispatch(setSelectedFilters({ bl: [], cc: [], gbu: [], country: [] }));
  };

  return (
    <div className="flex justify-between items-center">
      <GeneralFiltersTooltip title="More options" placement="left">
        <div className={`${CLASS_NAME_PREFIX}moreOptions`}>...</div>
      </GeneralFiltersTooltip>
      <GeneralFiltersTooltip title="Clear all filters" placement="left">
        <div className={`${CLASS_NAME_PREFIX}clearAll`}>
          <img src={CUSTOM_ERASE_ICON.small} alt="" onClick={clearAll} />
        </div>
      </GeneralFiltersTooltip>
    </div>
  );
}

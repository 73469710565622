import { styled, Grid } from "@mui/material";

export const StyledExportReferentsHead = styled(Grid)(({ theme }) => ({
  backgroundColor: "#CCF2F8",
  borderRadius: 10,
  minHeight: "30px",
  alignItems: "center",
  fontSize: "12px",
}));

export const SelectAllRefs = styled(Grid)(({ theme }) => ({
  ".qtm-checkbox input[type=checkbox]:indeterminate": {
    backgroundColor: "#DEDEE6",
    border: "1px solid #DEDEE6",

    "&:after": {
      backgroundColor: "#797D9C",
    },
  },
}));

import * as api from "./api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

const props = {
  error: false,
  meta: { fetching: false },
};

export const ORG_DETAILS = "details";
export const UPDATE_ORGANIZATION = "details";
export const SET_ORG_DETAILS = "details";
export const ORG_PERSPECTIVE = "perspective";
export const ORG_VIEW = "view";
export const DIAGRAM_SCHEMA = "diagram_schema";
export const DIAGRAM_LINK = "diagram_link";
export const FAVORITE_ORGANIZATIONS = "favorite_organizations";
export const UNDER_MY_RESPONSABILITY = "under_my_responsability";
export const GBU_LIST = "gbu_list";
export const GUEST_DETAILS = "guest_details";
export const EXTERNAL_PROJECTS = "external_projects";
export const TOUR_STATUS = "tour_status";
export const TOGGLE_SECTION = "toggle_section";
export const GET_TREE_NODES = "tree_nodes";
export const EXPORT_ENG_ORG = "export_organization";
export const GET_DISCIPLINE_NAMES = "discipline_names";
export const REQUESTED_ORG_ID = "requested_org_id";
export const EXPORT_EEI = "export_eei";
export const UPLOAD_EEI = "upload_eei";
export const GET_EEI_LOGS = "get_eei_logs";
export const GET_EEI_LOGS_WEB_FORM = "get_eei_logs_web_form";
export const HIGHLIGHTED_TGI = "highlighted_tgi";
export const HAS_NODE_LINK = "has_node_link";
export const NEW_ORG_DATA = "new_org_data";
export const GET_EEI_DATA_FOR_ORGANIZATION = "get_eei_data_for_organization";
export const GET_WFB_DATA_FOR_ORGANIZATION = "get_wfb_data_for_organization";
export const GET_ALL_CCS = "get_all_ccs";
export const SAVE_CC_DETAILS = "save_cc_details";
export const DISABLED_ENG_LIST = "disabled_eng_list";
export const ORG_CC_FILTER = "ORG_CC_FILTER";
export const EXPAND_PATH = "EXPAND_PATH";

export const getOrganizationDetails = createAction(ORG_DETAILS, payload => ({
  ...props,
  payload: api.getOrganizationDetailsApi(payload),
}));

export const updateOrganizationDetails = createAction(UPDATE_ORGANIZATION, payload => ({
  ...props,
  payload: api.updateOrganizationApi(payload),
}));

export const getGuestDetails = createAction(GUEST_DETAILS, payload => ({
  ...props,
  payload: api.getOrgGuestDetailsApi(payload),
}));

export const getExternalProjects = createAction(EXTERNAL_PROJECTS, payload => ({
  ...props,
  payload: api.getExternalProjectsApi(payload),
}));

export const getDisciplineNames = createAction(GET_DISCIPLINE_NAMES, () => ({
  ...props,
  payload: api.getDisciplinesNamesApi(),
}));

export const exportEEI = createAction(EXPORT_EEI, payload => ({
  ...props,
  payload: api.exportEEIApi(payload),
}));

export const uploadEEI = createAction(UPLOAD_EEI, payload => ({
  ...props,
  payload: api.uploadEEIApi(payload),
}));

export const getEEILogs = createAction(GET_EEI_LOGS, payload => ({
  ...props,
  payload: api.getEEILogsApi(payload),
}));

export const getEEILogsWebForm = createAction(GET_EEI_LOGS_WEB_FORM, payload => ({
  ...props,
  payload: api.getEEILogsWebFormApi(payload),
}));

export const getEeiDataForOrganization = createAction(GET_EEI_DATA_FOR_ORGANIZATION, payload => ({
  ...props,
  payload: api.getEeiDataForOrganizationApi(payload),
}));

export const getWfbDataForOrganization = createAction(GET_WFB_DATA_FOR_ORGANIZATION, payload => ({
  ...props,
  payload: api.getWfbDataForOrganizationApi(payload),
}));

export const getAllCcs = createAction(GET_ALL_CCS, payload => ({
  ...props,
  payload: api.getAllCcsApi(payload),
}));

export const saveCcDetails = createAction(SAVE_CC_DETAILS, payload => ({
  ...props,
  payload: api.saveCcDetailsApi(payload),
}));

export const clearGuestDetails = createAction(GUEST_DETAILS);
export const setOrgDetails = createAction(SET_ORG_DETAILS);
export const changePerspective = createAction(ORG_PERSPECTIVE);
export const changeView = createAction(ORG_VIEW);
export const updateSchema = createAction(DIAGRAM_SCHEMA);
export const goToNode = createAction(DIAGRAM_LINK);
export const setFavoriteOrganizations = createAction(FAVORITE_ORGANIZATIONS);
export const setUnderMyResponsability = createAction(UNDER_MY_RESPONSABILITY);
export const setGbuList = createAction(GBU_LIST);
export const tourStatus = createAction(TOUR_STATUS);
export const toggleSection = createAction(TOGGLE_SECTION);
export const setRequestedOrgId = createAction(REQUESTED_ORG_ID);
export const setHighlightedTgi = createAction(HIGHLIGHTED_TGI);
export const hasNodeLink = createAction(HAS_NODE_LINK);
export const setNewOrgData = createAction(NEW_ORG_DATA);
export const setDisabledEngineersList = createAction(DISABLED_ENG_LIST);
export const changeCCType = createAction(ORG_CC_FILTER);
export const setExpandPath = createAction(EXPAND_PATH);

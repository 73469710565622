import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTERS,
  setAvailableFilters,
  setGeneralFilters,
  setSelectedFilters,
} from "../redux/actions";
import {
  availableFiltersSelector,
  clearAllFiltersSelector,
  generalFiltersSelectors,
  selectedGeneralFilters,
} from "../redux/selectors";
import { FILTERS_SECTIONS } from "../constants";

export default function useGeneralFilters() {
  const dispatch = useDispatch();
  const filters = useSelector(generalFiltersSelectors);
  const selectedFilters = useSelector(selectedGeneralFilters);
  const availableFilters = useSelector(availableFiltersSelector);
  const generalFilters = useSelector(generalFiltersSelectors);
  const clearAllFilters = useSelector(clearAllFiltersSelector);

  useEffect(() => {
    const selectedCountries = selectedFilters[FILTERS_SECTIONS.COUNTRY];
    const selectedGbus = selectedFilters[FILTERS_SECTIONS.GBU];
    const selectedBls = selectedFilters[FILTERS_SECTIONS.BL];
    const selectedCCs = selectedFilters[FILTERS_SECTIONS.CC];

    let countries = [];
    let gbus = [];
    let bls = [];
    let ccs = [];

    // const _avaiableFiltres = {
    //   [FILTERS_SECTIONS.COUNTRY]: [],
    //   [FILTERS_SECTIONS.GBU]: [],
    //   [FILTERS_SECTIONS.BL]: [],
    //   [FILTERS_SECTIONS.CC]: [],
    // };
    //
    // console.log(selectedCountries);

    const _filters = { ...generalFilters };

    if (selectedCountries.length) {
      gbus = [];
      bls = [];
      ccs = [];
      selectedCountries.forEach(selectedCountry => {
        selectedCountry.childs.forEach(gbu => {
          const gbuWithParent = _filters.gbu.find(item => item.id === gbu.id);
          if (!gbus.some(existingGbu => existingGbu.id === gbuWithParent.id)) {
            gbus.push(gbuWithParent);
          }

          gbu.childs.forEach(bl => {
            const blWithParent = _filters.bl.find(item => item.id === bl.id);
            if (!bls.some(existingBl => existingBl.id === blWithParent.id)) {
              bls.push(blWithParent);
            }

            bl.childs.forEach(cc => {
              const ccWithParent = _filters.cc.find(item => item.id === cc.id);
              if (!ccs.some(existingCc => existingCc.id === ccWithParent.id)) {
                ccs.push(ccWithParent);
              }
            });
          });
        });

        // gbus.forEach(gbu => {

        // });
      });

      _filters.gbu = gbus;
      _filters.bl = bls;
      _filters.cc = ccs;
    }

    if (selectedGbus.length) {
      countries = [];
      bls = [];
      ccs = [];

      selectedGbus.forEach(gbu => {
        gbu.parents.forEach(country => {
          const countryWithParent = _filters.country.find(item => item.id === country.id);
          if (!countries.some(existingCountry => existingCountry.id === countryWithParent.id)) {
            countries.push(countryWithParent);
          }
        });
        _filters.bl.filter(bl => {
          bl.parents.forEach(parent => {
            if (parent.id === gbu.id) {
              const blWithParent = _filters.bl.find(item => item.id === bl.id);
              if (!bls.some(existingBl => existingBl.id === blWithParent.id)) {
                bls.push(blWithParent);
              }

              _filters.cc.forEach(cc => {
                const ccWithParent = _filters.cc.find(item => item.id === cc.id);
                ccWithParent.parents.forEach(parent => {
                  if (parent.id === bl.id) {
                    if (!ccs.some(existingCc => existingCc.id === ccWithParent.id)) {
                      ccs.push(ccWithParent);
                    }
                  }
                });
              });
            }
          });
        });
      });

      _filters.country = countries;
      _filters.bl = bls;
      _filters.cc = ccs;
    }

    if (selectedBls.length) {
      countries = [];
      gbus = [];
      ccs = [];
      selectedBls.forEach(bl => {
        const gbuWithParent = _filters.gbu.find(gbu =>
          bl.parents.some(parent => parent.id === gbu.id)
        );
        if (gbuWithParent && !gbus.some(gbu => gbu.id === gbuWithParent.id)) {
          gbus.push(gbuWithParent);
        }

        _filters.cc.filter(cc => {
          cc.parents.forEach(parent => {
            if (parent.id === bl.id) {
              const ccWithParent = _filters.cc.find(item => item.id === cc.id);
              if (!ccs.some(existingCc => existingCc.id === ccWithParent.id)) {
                ccs.push(ccWithParent);
              }
            }
          });
        });
      });

      gbus.forEach(gbu => {
        gbu.parents.forEach(parent => {
          const countryWithParent = _filters.country.find(item => item.id === parent.id);
          if (
            countryWithParent &&
            !countries.some(country => country.id === countryWithParent.id)
          ) {
            countries.push(countryWithParent);
          }
        });
      });

      _filters.cc = ccs;
      _filters.gbu = gbus;
      _filters.country = countries;
    }

    if (selectedCCs.length) {
      countries = [];
      gbus = [];
      bls = [];

      selectedCCs.forEach(cc => {
        cc.parents.forEach(parent => {
          const blWithParent = _filters.bl.find(bl => bl.id === parent.id);
          if (blWithParent && !bls.some(bl => bl.id === blWithParent.id)) {
            bls.push(blWithParent);
          }
        });
        _filters.country.filter(country => {
          country.childs.forEach(gbu => {
            gbu.childs.forEach(bl => {
              bl.childs.forEach(child => {
                if (cc.id === child.id && !countries.some(item => item.id === country.id)) {
                  countries.push(country);
                }
              });
            });
          });
        });
      });

      bls.forEach(bl => {
        const gbuWithParent = _filters.gbu.find(gbu => gbu.id === bl.parent.id);
        if (gbuWithParent && !gbus.some(gbu => gbu.id === gbuWithParent.id)) {
          gbus.push(gbuWithParent);
        }
      });

      // gbus.forEach(gbu => {
      //   gbu.parents.forEach(parent => {
      //     const countryWithParent = _filters.country.find(item => item.id === parent.id);
      //     if (
      //       countryWithParent &&
      //       !countries.some(country => country.id === countryWithParent.id)
      //     ) {
      //       countries.push(countryWithParent);
      //     }
      //   });
      // });

      _filters.bl = bls;
      _filters.gbu = gbus;
      _filters.country = countries;
    }

    dispatch(setAvailableFilters(_filters));

    /**
     * 1. Verifica toate tarile selectate
     * 2. Cand o tara este selectata atunci ia toala lista de gbu -> bl -> cc
     * 3. Cand un gbu este selectat atunci ia country -> bl -> cc
     * 4. Cand un bl este selectat atunci ia country -> gbu -> cc
     * 5. Cand un cc este selectat atunci ia country -> gbu -> bl -> cc
     */
    // if (selectedCountries.length) {
    //   selectedCountries.forEach(selectedCountry => {
    //     selectedCountry.childs.forEach(gbu => {
    //       _avaiableFiltres[FILTERS_SECTIONS.GBU].push(gbu);
    //       gbu.childs.forEach(bl => {
    //         _avaiableFiltres[FILTERS_SECTIONS.BL].push(bl);
    //         bl.childs.forEach(cc => {
    //           _avaiableFiltres[FILTERS_SECTIONS.CC].push(cc);
    //         });
    //       });
    //     });
    //   });

    //   dispatch(setAvailableFilters(_avaiableFiltres));
    // }
  }, [selectedFilters]);

  /**
   * On clearAllFilters signal reset all filters to the original structure
   */
  useEffect(() => {
    if (clearAllFilters) {
      dispatch(setAvailableFilters(filters));
      dispatch(setSelectedFilters({ bl: [], cc: [], gbu: [], country: [] }));
    }
  }, [clearAllFilters]);

  /**
   * Generate inital filters structure
   * @param treeData
   */
  const buildFilters = treeData => {
    const countryList = [];
    const gbuList = [];
    const blList = [];
    const ccList = [];

    // this is the old one, keeping just to be safe
    const _removeDuplicates = data => {
      const _data = data.sort((a, b) => {
        const keyA = a.code || a.name;
        const keyB = b.code || b.name;
        if (keyA > keyB) {
          return 1;
        }
        if (keyA < keyB) {
          return -1;
        }
        return 0;
      });
      const newData = [..._data.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
      return newData;
    };

    const removeDuplicates = data => {
      const _data = data.sort((a, b) => {
        const keyA = a.code || a.name;
        const keyB = b.code || b.name;
        if (keyA > keyB) {
          return 1;
        }
        if (keyA < keyB) {
          return -1;
        }
        return 0;
      });

      const uniqueMap = new Map();
      _data.forEach(obj => {
        const objectId = obj.id;
        if (!uniqueMap.has(objectId)) {
          uniqueMap.set(objectId, { ...obj, parents: [] });
        }
        const uniqueObj = uniqueMap.get(objectId);
        if (obj.parent) {
          uniqueObj.parents.push(obj.parent);
        }
      });

      return Array.from(uniqueMap.values());
    };

    treeData.regions.forEach(region => {
      region.childs.forEach(country => {
        countryList.push({
          id: country.id,
          code: country.name,
          childs: country.childs,
          isVisible: true,
          checked: false,
        });

        country.childs.forEach(gbu => {
          gbuList.push({
            id: gbu.id,
            code: gbu.code,
            parent: country,
            childs: gbu.childs,
            isVisible: true,
            checked: false,
          });

          gbu.childs.forEach(bl => {
            blList.push({
              id: bl.id,
              code: bl.code,
              parent: gbu,
              childs: bl.childs.map(cc => {
                cc.parent = bl;
                return cc;
              }),
              isVisible: true,
              checked: false,
            });

            bl.childs.forEach(cc => {
              ccList.push({
                id: cc.id,
                code: cc.name,
                parent: bl,
                childs: cc.childs,
                isVisible: true,
                checked: false,
              });
            });
          });
        });
      });
    });

    const filters = {
      country: removeDuplicates(countryList),
      gbu: removeDuplicates(gbuList),
      bl: removeDuplicates(blList),
      cc: removeDuplicates(ccList),
    };

    dispatch(setGeneralFilters(filters));
    dispatch(setAvailableFilters(filters));
  };

  return {
    buildFilters,
  };
}

import React from "react";
import { TdsButton, TdsIcon } from "@tds/react";
import { uniqueId } from "lodash";
import { EmwTextField } from "../lib/common";
import { CHARTS } from "../V2/features/powerBi/constants";
import { useHistory } from "react-router-dom";

const getPreparedFilters = (array, table, column, displayName) => {
  if (displayName) {
    return array.length > 0
      ? {
          $schema: "http://powerbi.com/product/schema#basic",
          target: { table: table, column: column },
          filterType: 1,
          displaySettings: { displayName: displayName },
          operator: "In",
          values: array,
          requireSingleSelection: false,
        }
      : {
          $schema: "http://powerbi.com/product/schema#basic",
          target: { table: table, column: column },
          filterType: 1,
          displaySettings: { displayName: displayName },
          operator: "All",
          values: [],
          requireSingleSelection: false,
        };
  } else {
    return array.length > 0
      ? {
          $schema: "http://powerbi.com/product/schema#basic",
          target: { table: table, column: column },
          filterType: 1,
          operator: "In",
          values: array,
          requireSingleSelection: false,
        }
      : {
          $schema: "http://powerbi.com/product/schema#basic",
          target: { table: table, column: column },
          filterType: 1,
          operator: "All",
          values: [],
          requireSingleSelection: false,
        };
  }
};

const getContainer = obj => {
  //switch (obj.visualContainer.name)
  switch (obj.detail.visual.name) {
    case "b7c461e1b7002ace26b7": {
      return "Mood custom";
    }
    case "f59d52c0fb310f9a0e54": {
      return "Mood";
    }
    case "8230aa6ff0acbe1dd5ca": {
      return "Confidence to deliver";
    }
    case "21abb0600bbd54920bb9": {
      return "Confidence to deliver custom";
    }

    case "b54cc7ad54a7ac7197cb": {
      return "Mood rolling average";
    }
    case "b54cc7ad54a7ac7197cb": {
      return "Mood rolling average custom";
    }
    case "328afd7a9000c050369d": {
      return "Confidence rolling average";
    }
    case "21e5ddc31231873e14bc": {
      return "Confidence rolling average custom";
    }
    case "e1ca854763db9a013dc5": {
      return "DOTD";
    }
    case "ea9916974a9870619295": {
      return "DAVD";
    }
    case "4e51b59b5b8e2ba07068": {
      return "Engineering costs / Revenue";
    }
    case "4e5dd4033e12dbaddad6": {
      return "Project efficiency";
    }
    case "d34eafad21dc4217b33b": {
      return "Confidence to deliver II";
    }
    case "b2b4abcfa826ad3de083": {
      return "Best Cost ECC (#FTE)";
    }
    case "220bf36e0bd00d484001": {
      return "Make vs Buy (Costs (M€))";
    }
    case "d84de665770680234dd0": {
      return "HC Structure Ratio";
    }
    case "c1e4783a3330cb947d6e": {
      return "Best Cost ECC (Ratio)";
    }
    case "71ef9100626c89530a04": {
      return "Make vs Buy (Ratio)";
    }
    case "4c8689beeb90e3714849": {
      return "Average Span";
    }
    case "dc5c15caec078ecea469": {
      return "Maturity";
    }

    case "8c8ae549eb8d002d1339": {
      return "HoD Gate Survey";
    }
    case "02f300289da67b6e0250": {
      return "Recent Hires Attrition";
    }
    case "841b24ea3aa0e5285a4e": {
      return "HoD Gate Survey (Ratio)";
    }

    case "5722a234551d51006672": {
      return "Mood II";
    }
  }
};

const getIcons = visualNamesMap => {
  return Object.entries(visualNamesMap).map(item => {
    if (item[1].value && item[1].value.icon) {
      const icon = item[1].value.icon;

      // const pages = await window.report.getPages();
      // const hiddenPage = pages.filter(page => page.displayName === "HiddenPage")[0]
      // const icons = await hiddenPage.getVisuals()

      // if (icon.alert) {
      //   const cloneRequest = {
      //     layout: {
      //           height: 30,
      //           width: 30,
      //           x: icon.x,
      //           y: icon.y,
      //           z: 100010
      //           },
      //       displayState: {mode:0}
      //     };
      // }
      // if (icon.alert) {
      //   await icons[1].clone(cloneRequest); //alerts  - yellow arrow
      // } else if (icon.comment) {
      //   await icons[3].clone(cloneRequest); //alerts  - yellow arrow
      // } else if (icon.target) {
      //   await icons[2].clone(cloneRequest); //alerts  - yellow arrow
      // }

      // let icon = await icons[0].clone(cloneRequest); //targets - red heart
      // icon = await icons[1].clone(cloneRequest); //alerts  - yellow arrow
      // icon = await icons[3].clone(cloneRequest); //comments

      // let icon = await icons[0].clone(cloneRequest);
      //window.report.setVisualDisplayState(page.name, item, models.VisualContainerDisplayMode.Hidden)

      return (
        <React.Fragment key={uniqueId()}>
          {icon.alert && (
            <TdsIcon
              key={uniqueId()}
              icon={"warning"}
              style={{
                position: "absolute",
                top: icon.y + "px",
                left: icon.x + "px",
                color: "orange",
              }}
              title="You have alerts. Go to management"
            />
          )}
          {icon.comment && (
            <TdsIcon
              key={uniqueId()}
              icon={"comment"}
              style={{
                position: "absolute",
                top: icon.y + "px",
                left: icon.x + 20 + "px",
                color: "green",
              }}
              title="You have comments. Go to management"
            />
          )}
          {icon.target && (
            <TdsIcon
              key={uniqueId()}
              icon={"crisis_alert"}
              style={{
                position: "absolute",
                top: icon.y + "px",
                left: icon.x + 40 + "px",
                color: "blue",
              }}
              title="You have targets. Go to management"
            />
          )}
        </React.Fragment>
      );
    }
  });
};

const getFiltersForUser = user => {
  const filters = [];
  if (user.list && !user.list.data) {
    return [];
  }
  let orgs = user.list.data.orgsUnderMyResponsability;

  if (user.list.data.additionalUser) {
    orgs = user.list.data.additionalUser.orgsUnderMyResponsability;
  }

  filters.push(getPreparedFilters([], "Country", "Country"));
  const gbuArray = orgs.filter(t => t.type === "bu").map(item => item.name);
  const blArray =
    gbuArray.length > 0 ? [] : orgs.filter(t => t.type === "bl").map(item => item.name);
  const ccArray =
    gbuArray.length === 0 && blArray.length === 0
      ? orgs.filter(t => t.type === "cc").map(item => item.name)
      : [];

  filters.push(getPreparedFilters(gbuArray, "GBU_BL_CC", "GBU Code", "GBU"));
  filters.push(getPreparedFilters(blArray, "GBU_BL_CC", "BL Code", "BL"));
  filters.push(getPreparedFilters(ccArray, "GBU_BL_CC", "Competence Center Name", "CC"));
  return filters;
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getLabelsArray = selectedChart => {
  let d = new Date();
  let labelArray = [];
  const month = d.getMonth();
  if (month % 2 === 1 && ![CHARTS.DOTD, CHARTS.DAVD].includes(selectedChart.key)) {
    d.setMonth(month - 1);
  }

  if ([CHARTS.KEY_PROFILE_FIT, CHARTS.SKILL_COVERAGE].includes(selectedChart.key)) {
    for (var i = 0; i < 4; i++) {
      labelArray.push({
        label: `Q${getQuarter(i).quarter} ${getQuarter(i).year}`,
        value: `Q${getQuarter(i).quarter} ${getQuarter(i).year}`,
        fieldId: `Q${getQuarter(i).quarter} ${getQuarter(i).year}`,
      });
    }

    return labelArray;
  }

  const period = selectedChart.period;
  for (let i = 0; i < totalMonthInChart / period; i++) {
    const counter = (d.getMonth() + i * period) % totalMonthInChart;
    let label = "";
    for (let j = 0; j < period; j++) {
      label += monthNames[counter + j];
      if (j + 1 === period) {
        label += " ";
      } else {
        label += " - ";
      }
    }

    const value =
      totalMonthInChart > d.getMonth() + i * period
        ? selectedChart.values[counter / period]
        : labelArray[labelArray.length - 1].value;
    labelArray.push({
      label: label + (d.getMonth() + i * period >= 12 ? d.getYear() - 100 + 1 : d.getYear() - 100),
      value: value,
      fieldId: monthNames[counter],
    });
  }

  return labelArray;
};

const getQuarter = whichOne => {
  let currentDate = new Date();
  let d = new Date(currentDate.setMonth(currentDate.getMonth() + 3 * whichOne));
  return { quarter: Math.floor(d.getMonth() / 3 + 1), year: d.getFullYear() };
};

const getTargetForm = (
  period,
  selectedChart,
  onTargetChange,
  onButtonOkClick,
  onButtonCancelClick
) => {
  const chartTitle = selectedChart === 0 ? "Confidence to deliver" : "Mood";
  return (
    <div
      id="targetForm"
      style={{
        position: "absolute",
        top: "0px",
        background: "white",
        width: "100%",
        display: "flex",
        zIndex: "10000",
        alignItems: "center",
        justifyContent: "center",
        padding: "30px",
        margin: "30px",
      }}
    >
      Add / Edit targets for {chartTitle}
      <div style={{ display: "flex" }}>
        {getLabelsArray(period).map(function (value, index) {
          return (
            <div>
              <div>{value.label}</div>
              <div>
                <EmwTextField
                  defaultValue={"Enter value for target"}
                  inputId={"target" + value.id}
                  atributes={{ disabled: false, id: "fieldFor" + value.fieldId }}
                  textFieldStyle={{ width: "100px" }}
                  onTextChange={onTargetChange}
                  defaultValue={value.value}
                />
              </div>
            </div>
          );
        })}
        <TdsButton
          variant="outline"
          classes="w-full justify-center"
          color="primary"
          onClick={onButtonOkClick}
        >
          {" "}
          Ok{" "}
        </TdsButton>
        <TdsButton
          variant="outline"
          classes="w-full justify-center"
          color="primary"
          onClick={onButtonCancelClick}
        >
          {" "}
          Cancel{" "}
        </TdsButton>
      </div>
    </div>
  );
};

const totalMonthInChart = 12;

const getAlertSvgLine = (chart, chartIndex, isAdmin, value) => {
  let toReturn = "";
  let filters = getGeneralFilters();
  const className = `alert-svg-${chartIndex}`;

  if (filters.length > 0) {
    filters = filters[0].value;
  }

  if (filters.length > 1) {
    return;
  }

  if (chart.values.length) {
    for (let i = 0; i < chart.values.length; i++) {
      const myValue = chart.values[i];

      if (!isAdmin) {
        if (!filters.includes(myValue.orgName)) {
          continue;
        }
      } else if (filters.length > 0) {
        if (!filters.includes(myValue.orgName)) {
          continue;
        }
      }

      let y =
        chart.yMax - ((chart.yMax - chart.yMin) * (myValue.value - 1)) / (chart.max - chart.min);
      if (myValue.type === "above") {
        y = y - 8;
      } else if (myValue.type === "below") {
        y = y + 2;
      }
      //console.log(chart.key + " - " + myValue.value + ": " + y);
      if (myValue.type === "below") {
        toReturn +=
          "<svg class=" +
          className +
          ' x="590" y="' +
          y +
          '" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" title="Value goes below">' +
          ' <path d="M6.5 10.4678L0.870834 0.36708L12.1292 0.36708L6.5 10.4678Z" fill="#B31800"/>' +
          "</svg>" +
          "<svg class=" +
          className +
          ' x="560" y="' +
          y +
          '" width="28" height="20" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          ' <text style="fill:#B31800; font-size: 8px;" y="6">' +
          parseFloat(myValue.value).toFixed(2) +
          "</text>" +
          "</svg>";
      } else if (myValue.type === "above") {
        toReturn +=
          "<svg class=" +
          className +
          ' x="590" y="' +
          y +
          '" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" title="Value goes above">' +
          ' <path d="M6.5 0L12.1292 10.1007H0.870835L6.5 0Z" fill="#178244"/>' +
          "</svg>" +
          "<svg class=" +
          className +
          ' x="560" y="' +
          y +
          '" width="28" height="20" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          ' <text style="fill:#178244; font-size: 8px;" y="6">' +
          parseFloat(myValue.value).toFixed(2) +
          "</text>" +
          "</svg>";
      } else if (myValue.type === "target") {
        let targetValue = value > 0 ? value : 1;
        let targetY =
          chart.yMax - ((chart.yMax - chart.yMin) * (targetValue - 1)) / (chart.max - chart.min);

        toReturn +=
          "<svg class=" +
          className +
          ' x="580" y="' +
          (targetY - 12) +
          '" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          ' <path d="M6.26 11.4551C6.41 8.27512 9.03 5.74512 12.25 5.74512C15.56 5.74512 18.25 8.43512 18.25 11.7451C18.25 14.9651 15.72 17.5851 12.54 17.7351L13.17 15.6351C14.94 15.2251 16.25 13.6351 16.25 11.7451C16.25 9.53512 14.46 7.74512 12.25 7.74512C10.36 7.74512 8.77 9.05512 8.36 10.8251L6.26 11.4551ZM12.25 21.7451C11.95 21.7451 11.65 21.7351 11.35 21.7051L11.94 19.7351C12.04 19.7451 12.15 19.7451 12.25 19.7451C16.67 19.7451 20.25 16.1651 20.25 11.7451C20.25 7.32512 16.67 3.74512 12.25 3.74512C7.83 3.74512 4.25 7.32512 4.25 11.7451C4.25 11.8451 4.25 11.9551 4.26 12.0551L2.29 12.6451C2.26 12.3451 2.25 12.0451 2.25 11.7451C2.25 6.22512 6.73 1.74512 12.25 1.74512C17.77 1.74512 22.25 6.22512 22.25 11.7451C22.25 17.2651 17.77 21.7451 12.25 21.7451ZM7.99 17.9751L9.25 21.7451L12.25 11.7451L2.25 14.7451L6.02 16.0051L1.75 20.2751L3.73 22.2551L7.99 17.9751Z" fill="#E69433"></path>' +
          "</svg>";
      }
    }
    return toReturn;
  }
  return toReturn;

  // for (let i = 0; i >= chart.values; i++) {
  //   if (chart.values.period > 0) {
  //     toReturn += "" + (chart.xMin + chart.xDiff * i) + " " + ((chart.yMax - (chart.yMax - chart.yMin) * ( chart.values[i] - 1) / (chart.max - chart.min))) + " ";
  //     toReturn += "L "
  //   }
  // }

  // return  toReturn === "" ? "":'<svg style="" class="targetsline" id="myLine' + chartIndex + '">' +
  //   '<g>' +
  //   '<path d="M ' + toReturn + '" style="stroke: rgb(224, 121, 0); stroke-opacity: 1;fill: none;" class="bi-link-path">' +
  //   '</path>'
};

const getSVGLine = (chart, chartIndex) => {
  //arr, x0, xDiff, y0, y1, period, unitsHeigth, chartIndex, unit) => {
  let toReturn = "";
  let circles = "";
  let pointIndex = 0;
  let columnIndex = 0;

  for (let i = 0; i < chart.nrOfBars; i++) {
    columnIndex++;
    if (chart.values[i] > 0) {
      toReturn +=
        "" +
        (chart.xMin + chart.xDiff * i) +
        " " +
        (chart.yMax -
          ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min)) +
        " ";

      if (i < totalMonthInChart / chart.period - 1) {
        toReturn += "L ";
      }

      if (i >= chart.editableFrom) {
        circles +=
          '<svg class="targetsline" style="cursor:n-resize" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:gui="http://www.kevlindev.com/gui">' +
          '<svg onmo="parent.focusOut(evt)" onload="parent.makeDraggable(evt, ' +
          pointIndex +
          ", " +
          chart.yMin +
          ", " +
          chart.yMax +
          ", " +
          chart.min +
          ", " +
          chart.max +
          ", " +
          chartIndex +
          ", " +
          columnIndex +
          ');">' +
          '<g class="draggable-group">' +
          '<rect class="rect' +
          chartIndex +
          '" x="' +
          (chart.xMin + chart.xDiff * i - 15) +
          '"  y="' +
          (chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min) -
            18) +
          '" width="30" height="15" style="fill:rgb(255, 255, 255);stroke:rgb(255,255,255);stroke-width:1;fill-opacity:1;stroke-opacity:1" />' +
          '<rect class="rect' +
          chartIndex +
          '" x="' +
          (chart.xMin + chart.xDiff * i) +
          '"       y="' +
          (chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min)) +
          '" width="1" height="1" style="fill:rgb(224, 121, 0);stroke:rgb(224, 121, 0);stroke-width:5;fill-opacity:0.1;stroke-opacity:0.9" />' +
          '<text class="text' +
          chartIndex +
          '" x="' +
          (chart.xMin + chart.xDiff * i - 15) +
          '" y="' +
          (chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min) -
            5) +
          '" style="fill:#041295">' +
          parseFloat(chart.values[i]).toFixed(2) +
          (chart.unit === "%" ? chart.unit : "") +
          "</text>" +
          "</g>" +
          "</svg></svg>";
        pointIndex++;
      } else {
        circles +=
          '<svg class="targetsline" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:gui="http://www.kevlindev.com/gui">' +
          //'<svg>' +
          "<svg>" +
          "<g>" +
          '<rect class="rect' +
          chartIndex +
          '" x="' +
          (chart.xMin + chart.xDiff * i - 15) +
          '"  y="' +
          (chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min) -
            18) +
          '" width="30" height="15" style="fill:rgb(255, 255, 255);stroke:rgb(255,255,255);stroke-width:1;fill-opacity:1;stroke-opacity:1" />' +
          '<rect class="rect' +
          chartIndex +
          '" x="' +
          (chart.xMin + chart.xDiff * i) +
          '"       y="' +
          (chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min)) +
          '" width="1" height="1" style="fill:rgb(224, 121, 0);stroke:rgb(224, 121, 0);stroke-width:5;fill-opacity:0.1;stroke-opacity:0.9" />' +
          '<text class="text' +
          chartIndex +
          '" x="' +
          (chart.xMin + chart.xDiff * i - 10) +
          '" y="' +
          (chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min) -
            5) +
          '" style="fill:#041295">' +
          parseFloat(chart.values[i]).toFixed(2) +
          (chart.unit === "%" ? chart.unit : "") +
          "</text>" +
          "</g>" +
          "</svg></svg>";
        pointIndex++;
      }
    }
  }
  return toReturn === ""
    ? ""
    : '<svg style="" class="targetsline" id="myLine' +
        chartIndex +
        '">' +
        "<g>" +
        circles +
        '<path d="M ' +
        toReturn +
        '" style="stroke: rgb(224, 121, 0); stroke-opacity: 1;fill: none;" class="bi-link-path">' +
        "</path></g></svg>";
};

const removeElement = id => {
  const iframeContent = document.querySelector(".embed-container iframe").contentWindow.document;
  if (iframeContent) {
    const elem = iframeContent.getElementById("myLine" + id);
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
    iframeContent.querySelectorAll(".rect" + id).forEach(elem => {
      elem.parentNode.removeChild(elem);
    });
    iframeContent.querySelectorAll(".text" + id).forEach(elem => {
      elem.parentNode.removeChild(elem);
    });
  }
};

const addLine = (chart, chartIndex) => {
  removeElement(chartIndex);
  const iframeContent = document.querySelector(".embed-container iframe").contentWindow.document;
  if (iframeContent) {
    //const parents = iframeContent.querySelectorAll(".cartesianChart")
    const parents = iframeContent.querySelectorAll(".svgScrollable");
    const labelSeriesGraphicsContexts = iframeContent.querySelectorAll(
      ".labelSeriesGraphicsContext"
    );
    if (
      parents &&
      parents.length > 0 &&
      labelSeriesGraphicsContexts &&
      labelSeriesGraphicsContexts.length > 0
    ) {
      const svgScrollable = parents[chartIndex];
      const mySvg = getSVGLine(chart, chartIndex);
      //svgScrollable.parentNode.insertAdjacentHTML('beforeend', mySvg);
      if (svgScrollable) svgScrollable.insertAdjacentHTML("beforeend", mySvg);
    }
  }
};

function sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const addAlertsLine = (chart, chartIndex, isAdmin, value) => {
  document
    .querySelector(".embed-container iframe")
    .contentWindow.document.querySelectorAll(`.alert-svg-${chartIndex}`)
    .forEach(el => el.remove());

  setTimeout(() => {
    const iframeContent = document.querySelector(".embed-container iframe").contentWindow.document;
    if (iframeContent) {
      const parents = iframeContent.querySelectorAll(".svgScrollable");
      const labelSeriesGraphicsContexts = iframeContent.querySelectorAll(
        ".labelSeriesGraphicsContext"
      );
      if (
        parents &&
        parents.length > 0 &&
        labelSeriesGraphicsContexts &&
        labelSeriesGraphicsContexts.length > 0
      ) {
        const svgScrollable = parents[chartIndex];
        const mySvg = getAlertSvgLine(chart, chartIndex, isAdmin, value);
        svgScrollable.insertAdjacentHTML("beforeend", mySvg);
      }
    }
  }, 1000);
};

// TODO use this to get selected filters
const getGeneralFilters = () => {
  if (!document.querySelector(".embed-container iframe")) {
    return;
  }
  const myDoc = document.querySelector(".embed-container iframe").contentWindow.document;
  const abc = myDoc.explorationNavigation
    .getCurrentExploration()
    .filters.filter(item => item.filter)
    .map(p => {
      return {
        type: p.expression.ref,
        value: p.filter.whereItems.map(arr => arr),
      };
    });

  return abc.map(p => {
    return { type: p.type, value: p.value[0].condition.values.map(i => i[0].value) };
  });
};

const getTimeframeFilters = () => {
  const myDoc = document.querySelector(".embed-container iframe").contentWindow.document;
  const filterArray = myDoc.querySelectorAll(".slicer-restatement");
  const toReturn = Object.keys(filterArray).map(k => filterArray[k].innerHTML);

  return toReturn;
};

window.makeDraggable = function (
  evt,
  index,
  yMin,
  yMax,
  min,
  max,
  chartIndex,
  columnIndex,
  hasDecimals
) {
  const svg = evt.target;
  let pointValue;
  let timeout;
  svg.addEventListener("touchmove", drag);
  svg.addEventListener("mousemove", drag);
  svg.addEventListener("mousedown", startDrag);
  svg.addEventListener("touchstart", startDrag);
  svg.addEventListener("mouseup", endDrag);
  svg.addEventListener("mouseleave", endDrag);
  svg.addEventListener("touchend", endDrag);
  svg.addEventListener("touchleave", endDrag);
  svg.addEventListener("touchcancel", endDrag);
  svg.addEventListener("onmouseup", endDrag);

  function getMousePosition(evt) {
    //console.log("getMousePosition")
    var CTM = svg.getScreenCTM();
    if (evt.touches) {
      evt = evt.touches[0];
    }
    return {
      x: (evt.clientX - CTM.e) / CTM.a,
      y: (evt.clientY - CTM.f) / CTM.d,
    };
  }

  var selectedElement, offset, transform;

  function initialiseDragging(evt) {
    //console.log("initialiseDragging")
    offset = getMousePosition(evt);

    // Make sure the first transform on the element is a translate transform
    var transforms = selectedElement.transform.baseVal;

    if (
      transforms.length === 0 ||
      transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE
    ) {
      // Create an transform that translates by (0, 0)
      var translate = svg.createSVGTransform();
      translate.setTranslate(0, 0);
      selectedElement.transform.baseVal.insertItemBefore(translate, 0);
    }

    // Get initial translation
    transform = transforms.getItem(0);
    offset.x -= transform.matrix.e;
    offset.y -= transform.matrix.f;
  }

  function startDrag(evt) {
    //console.log("startDrag")
    if (evt.target.classList.contains("draggable")) {
      selectedElement = evt.target;
      initialiseDragging(evt);
    } else if (evt.target.parentNode.classList.contains("draggable-group")) {
      selectedElement = evt.target.parentNode;
      initialiseDragging(evt);
    }
  }

  function drag(evt) {
    if (selectedElement) {
      evt.preventDefault();
      var coord = getMousePosition(evt);
      const totalHeight = yMax - yMin;
      const value = (totalHeight - coord.y) / (totalHeight / (max - min));
      //console.log(value, yMin, max - min)
      // console.log(evt.target.localName)
      if (totalHeight - coord.y >= 0 && value <= max - min) {
        transform.setTranslate(0, coord.y - offset.y);
        selectedElement.querySelector("text").innerHTML = parseFloat(value + min).toFixed(2);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(
          (value, coord, evt) => alignLine(value, coord, evt),
          500,
          value,
          coord,
          evt
        );
      }
    }
  }

  function alignLine(value, coord, evt) {
    const iframeContent = document.querySelector(".embed-container iframe").contentWindow.document;
    const selectedLine = iframeContent.querySelector("#myLine" + chartIndex + " path");
    const currentD = selectedLine.getAttribute("d").split(" ");
    pointValue = parseFloat(value + min).toFixed(2);
    let addition = 12;
    if (evt.target.localName.indexOf("rect") === 0) {
      addition = 2;
    }
    currentD[(index + 1) * 3 - 1] = parseFloat(coord.y + addition).toFixed(2);
    selectedLine.setAttribute("d", currentD.join(" "));
  }

  function endDrag(evt, name) {
    selectedElement = false;

    if (pointValue && evt.type === "mouseleave") {
      const event = new CustomEvent("drag_event", {
        detail: {
          index: columnIndex - 1,
          chartIndex,
          value: Number(pointValue),
          name,
        },
      });

      document.dispatchEvent(event);
    }
  }
};
const findGetParameter = parameterName => {
  var result = null,
    tmp = [];
  var items = window.location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

function displayMessage(evt) {
  if (evt.data.indexOf && evt.data.indexOf("setImmediate") === 0) {
    return;
  }
  const host = window.location.host;

  if (host.includes("localhost:3000") || host.includes("thalesdigital.io")) {
    if (evt.data === "organization") {
      document.querySelector("#goHome").click();
      setTimeout(() => {
        document.querySelector("#engineeringOrganisation").click();
        document.querySelector("#myFrameHZA").style.height = "2px";
      }, 200);
    } else if (["moodandconfidence", "jobfamily", "engineeringcompetitivness"].includes(evt.data)) {
      document.querySelector("#goHome").click();
      setTimeout(() => {
        document.getElementById("myFrameHZA").contentWindow.postMessage(evt.data, "*");
        document.querySelector("#myFrameHZA").style.height = document.body.clientHeight + "px";
      }, 200);
    }
  } else if (host.includes("cloud.thales")) {
    if (evt.data.name && evt.data.name === "login") {
      document.querySelector("#username").value = evt.data.username;
      document.querySelector("#password").value = evt.data.password;
      document.querySelector(".qtm-btn").click();
    } else if (evt.data === "organization") {
      document.querySelector("#goHome").click();
      window.parent.postMessage("engineeringorganisation", "*");
    } else if (["moodandconfidence", "jobfamily", "engineeringcompetitivness"].includes(evt.data)) {
      document.querySelector("#goHome").click();
      setTimeout(() => {
        if ("moodandconfidence" === evt.data) {
          document.querySelector("#moodAndConfidence").click();
        } else if ("jobfamily" === evt.data) {
          document.querySelector("#jobFamilyFocus").click();
        }
        if ("engineeringcompetitivness" === evt.data) {
          document.querySelector("#enineeringCompetitiveness").click();
        }
      }, 200);
    }
  }
  const message = "Me: " + host + " got " + evt.data + " from " + evt.origin;
}

if (window.addEventListener) {
  // For standards-compliant web browsers
  window.addEventListener("message", displayMessage, false);
}

export {
  getPreparedFilters,
  getContainer,
  getIcons,
  getFiltersForUser,
  monthNames,
  getTargetForm,
  getSVGLine,
  addLine,
  addAlertsLine,
  getGeneralFilters,
  getTimeframeFilters,
  findGetParameter,
  removeElement,
};

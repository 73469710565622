import { TdsIcon } from "@tds/react";
import React, { useContext, useEffect } from "react";
import { EmwButton } from "../../../../../../../lib/common";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";
import { ORGANIZATION_CARD_MODAL_TYPE } from "../../../../constants";
import { OrganizationCardContext } from "../../context/OrganizationCardContextProvider";
import OrganizationCardModal from "../OrganizationCardModal/OrganizationCardModal";

export default function EditBtn({ btn, isOpen, setIsOpen, targetField, setTargetField, parentCountryId }) {
  const context = useContext(OrganizationCardContext);

  useEffect(() => {
    if (isOpen && targetField.key) {
      handleClick();
    } else {
      setTargetField({ section: "", key: "" });
    }
  }, [isOpen]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = async () => {
    await context.setType(ORGANIZATION_CARD_MODAL_TYPE.EDIT);
    setIsOpen(true);
  };

  return (
    <>
      <EmwButton
        variant="filled"
        size="small"
        onClick={handleClick}
        disabled={!btn.enabled}
        title="Edit"
        id="btnEdit"
        classes="edit-button"
      >
        <TdsIcon icon="edit" variant="outlined" size="small" />
        <EmwTypography>Edit</EmwTypography>
      </EmwButton>

      {isOpen && context.type == ORGANIZATION_CARD_MODAL_TYPE.EDIT && (
        <OrganizationCardModal
          parentCountryId={parentCountryId}
          isOpen={isOpen}
          toggle={toggle}
          targetField={targetField}
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";
import CalendarIcon from "../icons/Calendar";
import { styled } from "@mui/system";

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  " > div": {
    border: "1px solid #5d607e!important",
    borderRadius: "2px",

    "&.Mui-focused": {
      border: "2px solid #041295 !important",
      height: "32px",
    },

    "*": {
      border: "none",
    },
  },
}));

export default function EmwDatePicker({
  date,
  disabled,
  disableInput,
  handleChange,
  fullWidth,
  inputId,
  disablePast,
  minDate,
  maxDate,
}) {
  const [value, setValue] = useState(date ? date : null);

  useEffect(() => {
    setValue(date);
  }, [date]);

  const renderInput = params => {
    return (
      <TextField
        id={inputId}
        {...params}
        disabled={disableInput}
        sx={{
          width: fullWidth ? "100%" : "120px",
          backgroundColor: "white",
          ".MuiInputBase-input": {
            width: "120px",
            padding: "0 0 0 10px",
            height: "30px",
            fontSize: "14px",
          },
          ".MuiInputAdornment-root": {
            marginLeft: 0,
            padding: 0,
          },
          ".MuiButtonBase-root": {
            padding: "0 5px 0",
            borderRadius: "2px",
          },
        }}
      />
    );
  };

  const onChange = newValue => {
    setValue(newValue);
    handleChange(newValue);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledDatePicker
          value={value}
          onChange={onChange}
          inputFormat="dd/MM/yyyy"
          renderInput={renderInput}
          disabled={disabled}
          readOnly={disabled}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
        />
      </LocalizationProvider>
    </>
  );
}

import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import { TdsTypography } from "@tds/react";
import { EmwButton } from "../../../../../../../../lib/common";
import { ExportOrganizationContext } from "../context/ExportOrganizationContext";
import { useSelector } from "react-redux";
import { schemaSelector } from "../../../../../redux/selectors";
import ExportOrganizationButton from "./ExportOrganizationButton";
import EmwDialog from "../../../../../../../components/EmwDialog/EmwDialog";

export default function HeaderInfo({ setIsOpen, loading, data }) {
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const context = useContext(ExportOrganizationContext);
  const schema = useSelector(schemaSelector);

  useEffect(() => {
    let cardName = "";
    if (data) {
      switch (data.cardDetails.type) {
        case "CC":
          cardName = data.section1.name.value;
          break;
        case "BL":
          cardName = schema.path.selectedBL.code;
          break;
        case "GBU":
          cardName = schema.path.selectedGBU.code;
          break;
        case "GROUP":
          cardName = "THALES GROUP";
          break;
      }
      context.setPath(cardName);
    }
  }, []);

  const handleClose = () => {
    if (data) {
      return setOpenCloseModal(true);
    }
    setIsOpen(false);
  };

  const handleOpenCloseModal = () => {
    if (data) {
      context.resetOnClose();
    }
    setOpenCloseModal(!openCloseModal);
    setIsOpen(false);
  };

  return (
    <>
      <Grid container id="export-dialog-header" className="header">
        <Grid item>
          <Grid container direction="column">
            <TdsTypography component="body-1" classes="text-primary-500">
              Export Engineering Organization ({data.section1.name.value})
            </TdsTypography>
            <TdsTypography component="body-1" fontSize={9}>
              The export will be made only based on your visibility rights
            </TdsTypography>
          </Grid>
        </Grid>
        <Grid item ml="auto">
          <Grid container ml="auto" direction="row" spacing={0.5} id="export-buttons">
            <Grid item paddingRight={"5px"}>
              <EmwButton variant="outline" id="btnExportClose" title="Close" size="small" onClick={handleClose}>
                Close
              </EmwButton>
            </Grid>
            <Grid item>
              <ExportOrganizationButton setIsOpen={setIsOpen} loading={loading} data={data} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openCloseModal && (
        <EmwDialog
          isOpen={openCloseModal}
          setIsOpen={setOpenCloseModal}
          callback={handleOpenCloseModal}
          actionButton="Close"
          title="Do you want to close the form?"
          text="Your changes will be lost if you close the form"
          icon="warning_amber"
          classes="close-icon"
        />
      )}
    </>
  );
}

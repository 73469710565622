import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[12],
    fontSize: 12,
    padding: "6px 4px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
  },
}));

export default function GeneralFiltersTooltip({
  title,
  children,
  followCursor,
  placement,
  onClick,
}) {
  return (
    <LightTooltip
      title={title}
      followCursor={followCursor}
      placement={placement}
      arrow
      onClick={onClick}
    >
      {children}
    </LightTooltip>
  );
}

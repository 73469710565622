import reducer from "./reducer";
import apiMiddleware from "./apiMiddleware";
import loadingIndicatorMiddleware from "./loadingIndicatorMiddleware";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

const middlewares = [apiMiddleware, loadingIndicatorMiddleware, logger];

export default configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middlewares),
});

import React, { useState, useEffect, useContext } from "react";
import { TdsIcon, TdsProgressCircle } from "@tds/react";
import "./styled";
import { EmwButton } from "../../../../../../../lib/common";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";
import { fetchOptions, computeURL } from "../../../../../../../util/fetch";
import { useSelector } from "react-redux";
import {
  orgDetailsSelector,
  perspectiveSelector,
  schemaSelector,
} from "../../../../redux/selectors";
import { DATE_TIME_FORMAT, ORGANIZATION_PERSPECTIVE } from "../../../../constants";
import moment from "moment";
import { HzaContext } from "../../../../../hzaSync/context/HzaContext";

export default function KPIDownloadButton({
  selectedYear,
  selectedKPI,
  regionName,
  isRegion,
  selectedOption,
}) {
  const hzaContext = useContext(HzaContext);
  const perspective = useSelector(perspectiveSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [reqBody, setReqBody] = useState(null);
  const org = useSelector(orgDetailsSelector);
  const schema = useSelector(schemaSelector);
  const countryNode = schema.path.selectedCountry.name;
  const regionNode = schema.path.selectedRegion.code;
  const isByCountry = perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY;

  useEffect(() => {
    if (hzaContext.downloadStatus.name === "eei_download") {
      setIsLoading(hzaContext.downloadStatus.isLoading);
    }
  }, [hzaContext.downloadStatus]);

  useEffect(() => {
    setReqBody({
      orgId: org.list.data.cardDetails.id,
      type: org.list.data.cardDetails.type,
      selectedYear: selectedYear,
      selectedKPI: selectedKPI,
      regionName: isRegion ? regionName : regionNode,
      country: isByCountry ? countryNode : "",
    });
  }, [selectedYear, selectedKPI]);

  const handleDownload = async () => {
    const orgName = isRegion
      ? org.list.data.cardDetails.region
      : org.list && org.list.data.section1 && org.list.data.section1.name.value;
    let api = "";
    // replace "/" and "." from the filename because it crashes the server
    const regExp = /(\/)|(\.)/g;
    const formatOrgName = orgName.replace(regExp, "_");
    const date = moment(new Date()).format(DATE_TIME_FORMAT);
    const regexp = /(:)|(@)|(\/)|(\.)|(\\)/g;
    const fileName = `${selectedKPI}_${formatOrgName}_${selectedYear}_${date}`.replace(regexp, "_");

    const options = {
      method: "put",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept-Encoding": "gzip, deflate, br",
      },
      body: JSON.stringify(reqBody),
    };

    if (selectedKPI === "EEI") {
      if (isByCountry) {
        api = computeURL("eei", "exportEEIByCountry");
      } else {
        api = computeURL("eei", "exportEEI");
        options["Accept"] = "application/octet-stream";
      }
    } else if (selectedKPI === "WFB") {
      api = computeURL("wfb", "downloadWfb");
      options["Accept"] = "application/octet-stream";
    }

    const params = fetchOptions(options);
    const skipHza = selectedKPI.toLowerCase() === "wfb";

    hzaContext.downloadFile(fileName, api, params, "eei_download", skipHza, perspective);
  };

  return (
    <EmwButton
      variant="filled"
      title="Download KPI"
      size="small"
      onClick={handleDownload}
      id="download-kpi-btn"
      disabled={isLoading || !selectedOption}
    >
      <span className="flex">
        {isLoading ? (
          <TdsProgressCircle />
        ) : (
          <TdsIcon icon="download" size="small" variant="outlined"></TdsIcon>
        )}
        <EmwTypography classes="font-bold">Download</EmwTypography>
      </span>
    </EmwButton>
  );
}

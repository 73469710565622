import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsDrilled, setReportFilters } from "../../redux/actions";
import { MOOD_AND_CONFIDENCE, OPERATING_MODEL, TALENTS_AND_CULTURES } from "../../constants";
import { FiltersOperations } from "powerbi-models";

/**
 * PowerBi report event handler
 *
 * @param reportName the name of the report as defined in the PowerBi component iframes
 * @returns {{eventHandlers: Map<string, (function(*, *, *): Promise<void>)|*>}}
 */
export default function useEventHandlers({ reportName }) {
  const dispatch = useDispatch();
  let specificFilters = {};
  let defaultFilters = [];

  const onLoaded = async (event, report, param2) => {
    console.log("loaded");

    if (reportName === MOOD_AND_CONFIDENCE) {
      await hideFiltersForPage(report, "ReportSection6251a23cd0ba370500ea");
    }

    if (reportName === TALENTS_AND_CULTURES) {
      await hideFiltersForPage(report, "ReportSection6251a23cd0ba370500ea");
      await hideFiltersForPage(report, "ReportSection2dc3580b104b8469813d");
      await hideFiltersForPage(report, "ReportSectioncf3b17477c168409be22");
      await hideFiltersForPage(report, "ReportSection2dbb051b301dce8b8624");
      await hideFiltersForPage(report, "ReportSection039238e27e03cb25d370");
      await hideFiltersForPage(report, "ReportSectionbd36656017b066ad30be");
    }
  };

  const onError = async (event, report, param2) => {};

  const onRendered = async (event, report, param2) => {
    console.log("onRendered");
    try {
      // const currentReportFilters = await window.report[reportName].getFilters();
      const currentFilters = await report.getFilters();

      // Save selected filters in redux
      dispatch(setReportFilters(currentFilters));
    } catch (e) {
      console.error(e);
      debugger;
    }
  };

  const onSaved = async (event, report, param2) => {};

  const onSaveAsTriggered = async (event, report, param2) => {};

  const onDataSelected = async (event, report, param2) => {};

  const onButtonClicked = async (event, report, param2) => {
    console.log("onButtonClicked");
    if (
      [
        "0b9f5b13e83ebcd042e9",
        "e5c9120ed13b60a96082",
        "d1963b0f9233af45e63e",
        "8c78523cfba961895984",
        "2216d507b5fc2e69a578",
      ].includes(event.detail.id)
    ) {
      const filters = await report.getFilters();
      const _defaultFilters = filters.map((filter, index) => {
        return {
          ...filter,
          target: defaultFilters[index].target,
        };
      });
      await report.setFilters(_defaultFilters);
    }
  };

  const onInfo = async (event, report, param2) => {};

  const onFiltersApplied = async (event, report, param2) => {};

  const onPageChanged = async (event, report, param2) => {
    console.log("onPageChanged");
    const activePage = await report.getActivePage();

    if (reportName === MOOD_AND_CONFIDENCE) {
      dispatch(setIsDrilled(activePage.name === "ReportSection6251a23cd0ba370500ea"));

      if (activePage.name === "ReportSection6251a23cd0ba370500ea") {
        await updatePageFilters(report, "ReportSection6251a23cd0ba370500ea");
      }
    }

    if (reportName === TALENTS_AND_CULTURES) {
      if (activePage.name === "ReportSection2dc3580b104b8469813d") {
        await updatePageFilters(report, "ReportSection2dc3580b104b8469813d");
      }
      if (activePage.name === "ReportSectioncf3b17477c168409be22") {
        await updatePageFilters(report, "ReportSectioncf3b17477c168409be22");
      }
      if (activePage.name === "ReportSection2dbb051b301dce8b8624") {
        await updatePageFilters(report, "ReportSection2dbb051b301dce8b8624");
      }
      if (activePage.name === "ReportSection039238e27e03cb25d370") {
        await updatePageFilters(report, "ReportSection039238e27e03cb25d370");
      }
      if (activePage.name === "ReportSection6251a23cd0ba370500ea") {
        await updatePageFilters(report, "ReportSection6251a23cd0ba370500ea");
      }
    }
  };

  const onCommandTriggered = async (event, report, param2) => {};

  const onSwipeStart = async (event, report, param2) => {};

  const onSwipeEnd = async (event, report, param2) => {};

  const onBookmarkApplied = async (event, report, param2) => {};

  const onDataHyperlinkClicked = async (event, report, param2) => {};

  const onVisualRendered = async (event, report, param2) => {};

  const onVisualClicked = async (event, report, param2) => {};

  const onSelectionChanged = async (event, report, param2) => {};

  const onRenderingStarted = async (event, report, param2) => {};

  /**
   *
   * @param report
   * @param pageName
   * @returns {Promise<void>}
   */
  const hideFiltersForPage = async (report, pageName) => {
    const pages = await report.getPages();
    const specificPage = pages.find(page => page.name === pageName);
    if (specificPage) {
      const specificPageFilters = await specificPage.getFilters();
      await specificPage.setFilters([]);
      specificFilters[pageName] = specificPageFilters;
    }
  };

  const updatePageFilters = async (report, pageName) => {
    const pages = await report.getPages();
    const globalFilters = await report.getFilters();
    const specificPage = pages.find(page => page.name === pageName);
    if (specificPage) {
      const filters = globalFilters.map((filter, index) => {
        return {
          ...filter,
          target: specificFilters[specificPage.name][index].target,
        };
      });

      if (!defaultFilters.length) {
        defaultFilters = globalFilters;
      }
      await report.updateFilters(FiltersOperations.ReplaceAll, filters);
    }
  };

  const eventHandlers = new Map([
    ["loaded", onLoaded],
    ["error", onError],
    ["rendered", onRendered],
    ["saved", onSaved],
    ["saveAsTriggered", onSaveAsTriggered],
    ["dataSelected", onDataSelected],
    ["buttonClicked", onButtonClicked],
    ["info", onInfo],
    ["filtersApplied", onFiltersApplied],
    ["pageChanged", onPageChanged],
    ["commandTriggered", onCommandTriggered],
    ["swipeStart", onSwipeStart],
    ["swipeEnd", onSwipeEnd],
    ["bookmarkApplied", onBookmarkApplied],
    ["dataHyperlinkClicked", onDataHyperlinkClicked],
    ["visualRendered", onVisualRendered],
    ["visualClicked", onVisualClicked],
    ["selectionChanged", onSelectionChanged],
    ["renderingStarted", onRenderingStarted],
  ]);
  return {
    eventHandlers,
  };
}

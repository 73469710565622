import React, { useState, useContext } from "react";
import EmwWebExcelByCountry from "../../../../../../components/EmwWebExcel/EmwWebExcelByCountry";
import { computeURL, fetchOptions } from "../../../../../../../util/fetch";
import { HzaContext } from "../../../../../hzaSync/context/HzaContext";
import { useSelector } from "react-redux";
import { schemaSelector } from "../../../../redux/selectors";

export default function EEIForm({
  title,
  saveApi,
  isOpen,
  setIsOpen,
  regionName,
  orgId,
  type,
  selectedYear,
  selectedKPI,
  countryName,
  selectedOption,
  errorComments,
  handleDelete,
}) {
  const hzaContext = useContext(HzaContext);
  const schema = useSelector(schemaSelector);
  const handleClick = async () => {
    setIsOpen(!isOpen);
  };

  const selectedCountry = schema.path.selectedCountry.name;
  const selectedRegion = schema.path.selectedRegion.code;

  // TO-DO: replace with generic eei endpoint for byGbu
  const getEeiApi = async () => {
    const payload = {
      orgId: orgId,
      type: type,
      selectedYear: selectedYear,
      selectedKPI: selectedKPI,
      regionName: regionName,
      country: countryName,
    };
    const api = computeURL("eei", "getEeiDataForOrganization");
    const params = fetchOptions({ method: "PUT" });
    params.body = JSON.stringify(payload);

    await hzaContext.handleRequest(api, params, "getEeiDataForOrganization");
  };

  const getEeiByCountryApi = async () => {
    const payload = {
      orgId: orgId,
      type: type,
      selectedYear: selectedYear,
      selectedKPI: selectedKPI,
      regionName: type === "REGION" ? regionName : selectedRegion,
      country: type === "CC" ? selectedCountry : countryName,
      isFromWebForm: true,
    };
    const api = computeURL("eei", "exportEEIByCountry");
    const params = fetchOptions({ method: "PUT" });
    params.body = JSON.stringify(payload);

    return hzaContext.handleRequest(api, params, "exportEEIByCountry");
  };

  return (
    <EmwWebExcelByCountry
      title={title}
      id="eeiHotTable"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      fixedColumns={0}
      saveApi={saveApi}
      getApi={getEeiByCountryApi}
      orgId={orgId}
      selectedYear={selectedYear}
      selectedKPI={selectedKPI}
      regionName={regionName}
      selectedOption={selectedOption}
      errorComments={errorComments}
      handleDelete={handleDelete}
    />
  );
}

import React, { useEffect, useState } from "react";
import { Table, Column, AutoSizer, SortDirection } from "react-virtualized";
import "react-virtualized/styles.css";
import CellRenderer from "./components/CellRenderer/CellRenderer";
import { StyledEmwTable } from "./styled";

export default function EmwTable({ data }) {
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);

  // const [sortedList, setSortedList] = useState([]);
  // const [sortBy, setSortBy] = useState("GBU");
  // const [sortDirection, setSortDirection] = useState(SortDirection.ASC);

  useEffect(() => {
    if (data && data.header.length > 0) {
      const headers = generateHeaders(data.header);
      const rows = extractData(data.rows);

      setHeaders(headers);
      setRows(rows);
    }
  }, [data]);

  // const sortData = ({ sortBy, sortDirection }) => {
  //   const sortedList = rows;

  //   sortedList.sort((a, b) => {
  //     const aValue = a[sortBy].value.toString().toLowerCase();
  //     const bValue = b[sortBy].value.toString().toLowerCase();

  //     if (aValue === bValue) return 0;

  //     if (sortDirection === SortDirection.ASC) {
  //       return aValue < bValue ? -1 : 1;
  //     } else {
  //       return aValue > bValue ? -1 : 1;
  //     }
  //   });

  //   return sortedList;
  // };

  // const handleSort = ({ sortBy, sortDirection }) => {
  //   const newSortDirection =
  //     sortBy === sortBy
  //       ? sortDirection === SortDirection.ASC
  //         ? SortDirection.DESC
  //         : SortDirection.ASC
  //       : SortDirection.ASC;
  //   const sortedList = sortData({ sortBy, sortDirection: newSortDirection });
  //   setSortedList(sortedList);
  //   setSortBy(sortBy);
  //   setSortDirection(newSortDirection);
  // };

  function extractData(rows) {
    return rows.map(row => {
      const _row = {};
      row.cels.forEach(cell => (_row[cell.property] = cell));
      return _row;
    });
  }

  function generateHeaders(headers) {
    return headers.map(row => {
      return row.map(header => (
        <Column
          key={header.property}
          label={header.value}
          dataKey={header.property}
          flexGrow={1}
          flexShrink={1}
          width={200} // A default value, can be anything reasonable
          cellRenderer={({ cellData, columnData, dataKey, rowData, rowIndex }) => (
            <CellRenderer
              cellData={cellData}
              columnData={columnData}
              rowData={rowData}
              dataKey={dataKey}
              rowIndex={rowIndex}
            />
          )}
        />
      ));
    });
  }

  function getRowHeight({ index }) {
    // Implement logic to calculate row height based on cell content
    const row = rows[index];
    const cellHeights = Object.values(row).map(cell => {
      let cellValue = cell.value || "";
      const content = cellValue;
      const lineHeight = 20; // Example line height, adjust as needed
      const padding = 10; // Example padding, adjust as needed
      const lines = Math.ceil(content.length / 20); // Example calculation, adjust as needed
      return lines * lineHeight + padding;
    });
    return Math.max(...cellHeights);
  }

  return (
    <StyledEmwTable>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            // sort={handleSort}
            // sortBy={sortBy}
            // sortDirection={sortDirection}
            width={width}
            height={height}
            headerHeight={35}
            rowHeight={getRowHeight} // Use the custom row height function
            headerClassName="text-scale-8 text-center bg-black-5 flex justify-center py-xs"
            rowClassName="text-scale-8 text-center"
            rowCount={rows.length}
            rowGetter={({ index }) => rows[index]}
          >
            {headers.flat()}
          </Table>
        )}
      </AutoSizer>
    </StyledEmwTable>
  );
}

import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { ClickAwayListener, Popper } from "@mui/material";
import { getZoom } from "../../V2/utils/sizeUtils";

export default function EmwClickableTooltip({
  isOpen,
  setIsOpen,
  children,
  content,
  title,
  placement,
  isEeiReadMe,
}) {
  const zoom = getZoom();
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = event => {
    positionRef.current = { x: event.clientX, y: event.clientY };
  };

  const eeiReadMeStyle = {
    width: zoom < 100 ? "56%" : "863px",
    "& .MuiTooltip-tooltip": {
      minWidth: "100%",
      lineHeight: 1,
      maxHeight: "500px",
      backgroundColor: "#FFFFFF",
      border: "1px solid grey",
      marginLeft: "14px",
      overflow: "scroll",
    },
  };

  // force zoom workaround
  if (zoom < 100) {
    return (
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div title={title} className="flex">
          <Tooltip
            ref={areaRef}
            onMouseMove={handleMouseMove}
            placement={"bottom"}
            onClose={() => setIsOpen(false)}
            open={isOpen}
            leaveDelay={300}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow={true}
            title={content}
            PopperProps={{
              keepMounted: isEeiReadMe ? false : true,
              disablePortal: false,
              popperRef,
              anchorEl: () => {
                if (zoom < 100) {
                  return {
                    getBoundingClientRect: () => {
                      return new DOMRect(
                        positionRef.current.x + 3,
                        areaRef.current.getBoundingClientRect().y * (zoom / 100) + 10,
                        0,
                        0
                      );
                    },
                  };
                }
              },
              sx: () => {
                if (isEeiReadMe) {
                  return eeiReadMeStyle;
                }
              },
            }}
          >
            <div>{children}</div>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div title={title} className="flex">
        <Tooltip
          ref={areaRef}
          onMouseMove={handleMouseMove}
          placement={placement ? placement : "bottom"}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          leaveDelay={300}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow={true}
          title={content}
          PopperProps={{
            sx: () => {
              if (isEeiReadMe) {
                return eeiReadMeStyle;
              }
            },
          }}
        >
          <div>{children}</div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

import { styled } from "@mui/material/styles";
import { Grid, Dialog } from "@mui/material";
import { getZoom } from "../../../../utils/sizeUtils";

export const StyledGuestPowerGuestCard = styled(Grid)(({ theme }) => ({
  backgroundColor: "#ffffff",
  flexWrap: "nowrap",
  flexDirection: "column",
  padding: "24px 13px 0 13px",
  width: "100%",
  ".cardHeader": {
    alignItems: "center",
    borderBottom: "2px solid #041295",
  },
  ".divider": {
    borderBottom: "2px solid #041295",
  },
  ".guest-list-item": {
    backgroundColor: "#f7f7f9",
    borderRadius: 10,
    minHeight: "30px",
    marginTop: "10px",
    alignItems: "center",
    fontSize: "12px",
    cursor: "pointer",
    ".comment": {
      p: {
        maxWidth: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  ".guest-sections": {
    backgroundColor: "#CCF2F8",
    borderRadius: 10,
    minHeight: "30px",
    alignItems: "center",
    fontSize: "12px",
    cursor: "pointer",
    flexWrap: "nowrap",
    overflow: "auto",
    ".name": {
      marginLeft: "6px",
    },
  },
}));

export const StyledGuestPowerGuestDialog = styled(Dialog)(({ theme }) => {
  let zoom = getZoom();
  return {
    ".dialog-header": {
      zoom: `${zoom}%`,
      height: "72px",
      margin: "0 30px 12px 30px",
      padding: "23px 0 11px 0",
      borderBottom: `2px solid ${theme.palette.primary[500]}`,
    },
    ".dialog-content": {
      overflow: "hidden",
      marginBottom: "10px",
      paddingBottom: "10px",
    },
    ".form": {
      overflow: "auto",
      maxHeight: "746px",
      paddingRight: "14px",
    },
    ".divider": {
      borderBottom: "2px solid #041295",
    },
    ".edit-button": {
      width: "33px",
      height: "29px",
    },
    ".comment": {
      width: "200px",
    },
  };
});

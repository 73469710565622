import React from "react";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
}));

export default function EmwIframe(props) {
  const { height, width, inputId, classes, src, onLoad } = props;

  return (
    <Root>
      <iframe height={height} width={width} id={inputId} onLoad={onLoad} classes={classes} src={src}></iframe>
    </Root>
  );
}

import { API_ENDPOINT } from "./../constants";
import { callMsGraph } from "../V2/auth/utils/MsGraphApiCall";
import MsalInstance from "../V2/auth/utils/MsalInstance";

export const getUrlParam = name => {
  const urlParams = new URLSearchParams(location.search);

  return urlParams.get(name);
};

const redirectToLogin = () => {
  if (localStorage.getItem("login.dev")) {
    localStorage.clear();
    window.location.href = "/";
  } else {
    const logout = async () => {
      const url = computeURL("security", "logout");
      const method = "GET";
      return fetch(url, fetchOptions({ method }));
    };

    logout().then(async () => {
      if (localStorage.getItem("is.dev")) {
        window.location.href = "/logout";
      } else {
        await MsalInstance.getInstance().logoutRedirect();
      }
    });
  }
};

/**
 * Check the status and reject the promise if it's not in the 200 range
 */
const checkStatus = res => {
  if (res.status >= 200 && res.status < 300) {
    return res;
  } else {
    if (
      res.url &&
      !res.url.endsWith("/security/login") &&
      (res.status === 401 || res.status === 403 || res.status === 504)
    ) {
      return redirectToLogin();
    }
    if (!localStorage.getItem("dev.login") && res.status === 401) {
      return redirectToLogin();
    }
    throw res;
  }
};

/**
 * Deserialize the request body
 */
const deserialize = res => {
  const header = res.headers.get("Content-Type") || "";
  if (header.indexOf("application/json") > -1) {
    return res.json();
  }
  if (header.indexOf("application/ld+json") > -1) {
    return res.json();
  }
  if (header.indexOf("application/octet-stream") > -1) {
    return res.arrayBuffer();
  }
  return res.text();
};

/**
 * Get default fetch options
 * @param {*object} options
 */
const fetchOptions = (options = {}) => {
  const headers = { "Content-Type": "application/json", Accept: "application/json" };

  return {
    method: "get",
    credentials: "include",
    headers: headers,
    ...options,
  };
};

/**
 * Get default fetch options
 * @param {*object} options
 */
const fetchOptionsUpload = (options = {}) => {
  const requestOptions = {
    method: "put",
    credentials: "include",
    headers: {
      ContentType: "application/json",
      Accept: "application/json",
    },
    ...options,
  };

  //get authorization token if exist
  const authorizationToken = window.localStorage.getItem("authorization");
  if (authorizationToken) {
    requestOptions.headers["Authorization"] = authorizationToken;
  }

  return requestOptions;
};

const fetchWrapper = async (url = "", params = {}, responseCallback = () => {}) => {
  const isDev = localStorage.getItem("login.dev");
  if (params.hasOwnProperty("isPublic") || isDev) {
    return fetch(url, params)
      .then(response => {
        responseCallback(response);
        return checkStatus(response);
      })
      .then(response => deserialize(response));
  }

  return callMsGraph(url, params)
    .then(response => {
      responseCallback(response);
      return checkStatus(response);
    })
    .then(response => deserialize(response));
};

const fetchDownload = (url = "", params = {}, responseCallback = () => {}) => {
  const isDev = localStorage.getItem("login.dev");
  params.headers = {
    ...params.headers,
  };

  if (isDev) {
    return fetch(url, fetchOptions(params)).then(response => {
      responseCallback(response);
      return checkStatus(response);
    });
  }
  return callMsGraph(url, fetchOptions(params)).then(response => {
    responseCallback(response);
    return checkStatus(response);
  });
};

/**
 * Build query string
 * @param {*object} data
 */
const queryString = data => {
  return (
    "?" +
    Object.keys(data)
      .map(val => {
        return `${val}=${data[val]}`;
      })
      .join("&")
  );
};

const computeURL = (root, action, id, params, urlParams) => {
  let tmp = `${API_ENDPOINT}/${root}`;
  if (action) {
    tmp += `/${action}`;
  }
  if (id || id === false) {
    tmp += `/${id}`;
  }
  if (params || params === 0 || params === false) {
    tmp += `/${params}`;
  }
  if (urlParams) {
    tmp += `${urlParams}`;
  }
  return tmp;
};

export {
  fetchWrapper as fetch,
  fetchOptions,
  queryString,
  computeURL,
  fetchDownload,
  fetchOptionsUpload,
};

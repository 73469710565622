import * as actions from "./actions";
import {
  CHARTS,
  ENGINEERING_COMPETITIVENESS,
  MOOD_AND_CONFIDENCE,
  TALENTS_AND_CULTURES,
} from "../constants";
import { initialProps } from "../../../../util/reducer";
import { REPORT_FILTERS, REPORT_FILTERS_HZA } from "./actions";

export default {
  [actions.CREATE_TARGET]: {
    ...initialProps,
    list: {},
  },
  [actions.SHOULD_RENDER_LINES]: true,
  [actions.USER_ORGANIZATIONS]: [],
  [actions.CAN_ADD_TARGETS]: false,
  [actions.CAN_ADD_ALERTS]: false,
  [actions.GENERAL_FILTERS]: [],
  [actions.POWER_BI_INITIALIZED]: false,
  [actions.ALERT_HISTORY_MODAL]: {
    isOpen: false,
    chart: {
      parent: "",
      name: "",
    },
  },
  [actions.MY_COMMENTS_HISTORY_MODAL]: {
    isOpen: false,
    chart: {
      parent: "",
      name: "",
    },
  },
  [actions.ALERTS]: {
    [MOOD_AND_CONFIDENCE]: {
      [CHARTS.MOOD]: {
        period: 2,
        unit: "",
        min: 1,
        max: 5,
        xMin: 78,
        xDiff: 78,
        yMin: 10,
        yMax: 295,
        hasDecimals: 2,
        title: "Mood",
        key: "mood",
        values: [
          // id: 90789, type: 'above', period: '1', value: 2.2
        ],
      },
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        period: 2,
        unit: "",
        min: 1,
        max: 5,
        xMin: 78,
        xDiff: 78,
        yMin: 10,
        yMax: 295,
        hasDecimals: 2,
        key: "confidenceToDeliver",
        title: "Confidence to deliver",
        values: [
          // id: 90789, type: 'above', period: '1', value: 2.2
        ],
      },
    },
    [ENGINEERING_COMPETITIVENESS]: {
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        period: 2,
        unit: "",
        min: 1,
        max: 5,
        xMin: 78,
        xDiff: 78,
        yMin: 10,
        yMax: 180,
        hasDecimals: 2,
        title: "Confidence to deliver",
        key: "confidenceToDeliver",
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
      [CHARTS.DOTD]: {
        period: 1,
        unit: "%",
        min: 0,
        max: 100,
        xMin: 75,
        xDiff: 45,
        yMin: 10,
        yMax: 200,
        title: "DOTD (Development On Time Delivery)",
        key: CHARTS.DOTD,
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        editableFrom: 8,
        nrOfBars: 12,
      },
      [CHARTS.DAVD]: {
        period: 1,
        unit: "days",
        min: 0,
        max: 300,
        xMin: 65,
        xDiff: 45,
        yMin: 20,
        yMax: 150,
        title: "DAVD (Development Average Delay)",
        key: CHARTS.DAVD,
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        nrOfBars: 12,
      },
    },
    [TALENTS_AND_CULTURES]: {
      [CHARTS.MOOD]: {
        period: 2,
        unit: "",
        min: 1,
        max: 5,
        xMin: 78,
        xDiff: 78,
        yMin: 10,
        yMax: 180,
        hasDecimals: 2,
        title: "Mood",
        key: "mood",
        parent: TALENTS_AND_CULTURES,
        values: [],
      },
      [CHARTS.KEY_PROFILE_FIT]: {
        period: 2,
        unit: "days",
        min: 0,
        max: 600,
        yMin: 25,
        yMax: 175,
        key: "keyProfileFit",
        title: "Key Profile Fit",
        parent: TALENTS_AND_CULTURES,
        values: [],
      },
      [CHARTS.SKILL_COVERAGE]: {
        period: 2,
        unit: "days",
        min: 0,
        max: 600,
        yMin: 40,
        yMax: 205,
        key: "skillCoverage",
        title: "Skill Coverage",
        parent: TALENTS_AND_CULTURES,
        values: [],
      },
    },
  },
  [actions.CHARTS]: {
    [MOOD_AND_CONFIDENCE]: {
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        period: 2,
        unit: "",
        min: 1,
        max: 5,
        hasDecimals: 2,
        xMin: new Date().getMonth() % 2 === 1 ? 75 : 90,
        xDiff: new Date().getMonth() % 2 === 1 ? 78 : 88,
        yMin: 10,
        yMax: 295,
        key: "confidenceToDeliver",
        title: "Confidence to deliver",
        parent: MOOD_AND_CONFIDENCE,
        values: [0, 0, 0, 0, 0, 0],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
      [CHARTS.MOOD]: {
        period: 2,
        unit: "",
        min: 1,
        max: 5,
        hasDecimals: 2,
        xMin: new Date().getMonth() % 2 === 1 ? 75 : 90,
        xDiff: new Date().getMonth() % 2 === 1 ? 78 : 88,
        yMin: 10,
        yMax: 295,
        title: "Mood",
        key: "mood",
        parent: MOOD_AND_CONFIDENCE,
        values: [0, 0, 0, 0, 0, 0],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
    },
    [ENGINEERING_COMPETITIVENESS]: {
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        period: 2,
        unit: "",
        min: 1,
        max: 5,
        hasDecimals: 2,
        xMin: new Date().getMonth() % 2 === 1 ? 70 : 85,
        xDiff: new Date().getMonth() % 2 === 1 ? 80 : 90,
        yMin: 10,
        yMax: 180.7,
        title: "Confidence to deliver",
        key: "confidenceToDeliver",
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
      [CHARTS.DOTD]: {
        period: 1,
        unit: "%",
        min: 0,
        max: 100,
        hasDecimals: 0,
        xMin: 68,
        xDiff: 45,
        yMin: 10,
        yMax: 200,
        title: "DOTD (Development On Time Delivery)",
        key: CHARTS.DOTD,
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        editableFrom: 8,
        nrOfBars: 12,
      },
      [CHARTS.DAVD]: {
        period: 1,
        unit: "days",
        min: 0,
        max: 300,
        hasDecimals: 0,
        xMin: 65,
        xDiff: 45,
        yMin: 20,
        yMax: 150,
        title: "DAVD (Development Average Delay)",
        key: CHARTS.DAVD,
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        nrOfBars: 12,
      },
    },
    [TALENTS_AND_CULTURES]: {
      [CHARTS.MOOD]: {
        period: 2,
        unit: "",
        min: 1,
        max: 5,
        hasDecimals: 2,
        xMin: new Date().getMonth() % 2 === 1 ? 75 : 90,
        xDiff: new Date().getMonth() % 2 === 1 ? 78 : 88,
        yMin: 0,
        yMax: 190,
        title: "Mood",
        key: "mood",
        parent: TALENTS_AND_CULTURES,
        values: [0, 0, 0, 0, 0, 0],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
      [CHARTS.KEY_PROFILE_FIT]: {
        period: 2,
        unit: "",
        min: 0,
        max: 600,
        hasDecimals: 0,
        xMin: 72,
        xDiff: 110,
        yMin: 35,
        yMax: 175,
        title: "Key Profile Fit",
        key: "keyProfileFit",
        parent: TALENTS_AND_CULTURES,
        values: [0, 0, 0, 0, 0],
        editableFrom: 4,
        nrOfBars: 5,
      },
      [CHARTS.SKILL_COVERAGE]: {
        period: 2,
        unit: "",
        min: 0,
        max: 600,
        hasDecimals: 0,
        xMin: 88,
        xDiff: 104,
        yMin: 35,
        yMax: 200,
        title: "Skill Coverage",
        key: "skillCoverage",
        parent: TALENTS_AND_CULTURES,
        values: [0, 0, 0, 0, 0],
        editableFrom: 3,
        nrOfBars: 5,
      },
    },
  },
  [actions.COMMENTS]: {
    [MOOD_AND_CONFIDENCE]: {
      [CHARTS.MOOD]: {
        parent: MOOD_AND_CONFIDENCE,
        key: CHARTS.MOOD,
        title: "Mood",
        values: [],
      },
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        parent: MOOD_AND_CONFIDENCE,
        key: CHARTS.CONFIDENCE_TO_DELIVER,
        title: "Confidence to deliver",
        values: [],
      },
    },
    [ENGINEERING_COMPETITIVENESS]: {
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        parent: ENGINEERING_COMPETITIVENESS,
        key: CHARTS.CONFIDENCE_TO_DELIVER,
        title: "Confidence to deliver",
        values: [],
      },
      [CHARTS.DOTD]: {
        parent: ENGINEERING_COMPETITIVENESS,
        key: CHARTS.DOTD,
        title: "DOTD (Development On Time Delivery)",
        values: [],
      },
      [CHARTS.DAVD]: {
        parent: ENGINEERING_COMPETITIVENESS,
        key: CHARTS.DAVD,
        title: "DAVD (Development Average Delay)",
        values: [],
      },
    },
    [TALENTS_AND_CULTURES]: {
      [CHARTS.MOOD]: {
        parent: TALENTS_AND_CULTURES,
        key: CHARTS.MOOD,
        title: "Mood",
        values: [],
      },
      [CHARTS.KEY_PROFILE_FIT]: {
        parent: TALENTS_AND_CULTURES,
        key: CHARTS.KEY_PROFILE_FIT,
        title: "Key Profile Fit",
        values: [],
      },
      [CHARTS.SKILL_COVERAGE]: {
        parent: TALENTS_AND_CULTURES,
        key: CHARTS.SKILL_COVERAGE,
        title: "Skill Coverage",
        values: [],
      },
    },
  },
  [actions.SELECTED_REPORT]: null,
  [actions.IS_DRILLED]: false,
  [actions.COMMENTS_ROLES]: {
    canAddActionPlan: false,
    canAddComment: false,
    canAddRootCause: false,
    canMakePublic: false,
  },
  [actions.ACCESS_TOKEN]: null,
  [actions.REPORT_FILTERS]: null,
  [actions.REPORT_FILTERS_HZA]: null,
  [actions.EMBEDDED_URL]: "",
};

import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { StyledStackedChart } from "./styled";
import useChartUtils from "../../hooks/useChartUtils";
import ChartInfo from "../ChartInfo/ChartInfo";
import ChartTooltip from "../ChartTooltip/ChartTooltip";

const CustomBarComponent = ({ bars, xScale }) => (
  <>
    {bars.map(bar => {
      return (
        <rect
          key={bar.key}
          x={bar.x + bar.width / 2 - 10}
          y={bar.y + bar.height + 20}
          textAnchor="middle"
          style={{
            fontSize: "10px",
          }}
          transform={`rotate(-45 ${bar.x + bar.width / 2}, ${bar.y + bar.height + 20})`}
        >
          {bar.data.id}
        </rect>
      );
    })}
  </>
);

export default function GroupedChart({
  name,
  data,
  tooltipInfo,
  info,
  unit = "%",
  groupColors,
  comingSoon = false,
}) {
  const chartUtils = useChartUtils({});
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (data.length) {
      const _keys = [];

      Object.keys(data[0]).forEach(key => {
        if (key !== "label") {
          _keys.push(key);
        }
      });

      setKeys(_keys.sort(chartUtils.sortQuarters));
    }
  }, [data]);

  return (
    <StyledStackedChart className="flex flex-col bg-white-100 rounded p-s shadow-s-strong">
      <div className="flex flex-row justify-between">
        <p className="text-scale-7 font-bold ml-s">{name}</p>
        <ChartTooltip title={tooltipInfo} />
      </div>
      <div className="flex-1 chart">
        <ResponsiveBar
          data={data}
          keys={keys}
          enableLabel={false}
          indexBy="label"
          valueFormat={value => `${value}%`}
          margin={{ top: 10, right: 0, bottom: 55, left: 35 }}
          padding={0.2}
          groupMode="grouped"
          valueScale={{ type: "linear" }}
          indexScale={chartUtils.indexScale}
          colors={object =>
            groupColors ? groupColors[object.index] : chartUtils.groupColors[object.index]
          }
          minValue={0}
          maxValue={"auto"}
          layers={["grid", "axes", "bars", CustomBarComponent, "markers", "legends"]}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 42,
            tickRotation: 0,
            legendOffset: 32,
            truncateTickAt: 0,
          }}
          innerPadding={2.7}
          axisLeft={chartUtils.axisLeft(unit)}
          labelSkipWidth={12}
          labelSkipHeight={12}
          role="application"
          labelTextColor="white"
        />
      </div>

      <ChartInfo info={info} comingSoon={comingSoon} />
    </StyledStackedChart>
  );
}

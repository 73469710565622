import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const StyledOrganizationHeaderInfo = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  "p, tds-typography": {
    lineHeight: 1 + "!important",
  },

  ".orgCountries": {
    display: "flex",
    justifyContent: "center",

    label: {
      fontSize: "10px",
      lineHeight: 1,
      color: theme.palette.bluegrey[700],
    },

    "tds-icon": {
      ".material-icons, .material-icons-outlined": {
        color: theme.palette.primary[500],
      },
    },

    ".orgCountries-list": {
      display: "flex",
      flexDirection: "column",
      paddingTop: "2px",
    },
  },
}));

import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { TdsTypography } from "@tds/react";

export const Card = styled(Grid)(({ theme }) => ({
  cursor: "pointer",
  width: "320px",
  height: "85%",
  border: "1px solid #E9E7E7",
  margin: "0 auto",
  "&:hover": {
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
  },
}));

export const CardContent = styled(Grid)(({ theme }) => ({
  backgroundColor: "#F7F7F7",
  height: "210px",
  padding: "18px",
  textAlign: "center",
  marginTop: "auto",
}));

export const TitleTypography = styled(TdsTypography)(({ theme }) => ({
  maxWidth: "175px",
  p: {
    fontSize: "24px",
    color: "#000000",
  },
}));

import React from "react";
import EmwTypography from "../../../EmwTypography/EmwTypography";
import { CLASS_NAME_PREFIX } from "../../constants";
import cls from "classnames";
import EmwIcon from "../../../EmwIcon/EmwIcon";

export default function GeneralFiltersFilterType({ type, query, setQuery }) {
  const className = cls(`${CLASS_NAME_PREFIX}filtertype`);
  const searchClassName = cls(`${CLASS_NAME_PREFIX}filtertype-search flex`);

  const handleSearch = event => {
    setQuery(event.target.value);
  };

  return (
    <div id={`${className}-${type}`} className={className}>
      <EmwTypography fontSize={8} classes="mt-xxs">
        Filter type
      </EmwTypography>
      <select name={`${className}-${type}`} title={type}>
        <option value="all" disabled>
          Advanced filtering
        </option>
        <option value="blank" selected>
          Basic filtering
        </option>
      </select>
      <div className={searchClassName}>
        <div className="flex px-xxs reverse-mirror">
          <EmwIcon icon="search" variant="outlined" />
        </div>
        <input type="text" placeholder="Search" value={query} onChange={handleSearch} />
      </div>
    </div>
  );
}

export const getStyle = cellData => {
  if (cellData.style) {
    const _style = {
      display: "block",
      width: "100%",
      height: "100%",
      border: "1px solid red",
    };
    if (cellData.style.bgColor) {
      _style.backgroundColor = cellData.style.bgColor;
    }

    if (cellData.style.color) {
      _style.color = cellData.style.color;
    }

    return _style;
  }

  return {};
};

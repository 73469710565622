import React from "react";
import useChartFilters from "../../hooks/useChartFilters";
import TimeFrame from "./components/TimeFrame/TimeFrame";
import OrgFilters from "./components/OrgFilters/OrgFilters";

export default function ChartFilters({ filters, setFilters, getChildOf }) {
  return (
    <>
      <div className="flex items-center">
        <p className="text-scale-4 text-primary-500">Data Collection Evolution</p>
        <TimeFrame filters={filters} setFilters={setFilters} />
      </div>
      <div className="flex flex-col my-m">
        <OrgFilters filters={filters} setFilters={setFilters} getChildOf={getChildOf} />
      </div>
    </>
  );
}

import { Grid, styled } from "@mui/material";
import { getZoom } from "../../../utils/sizeUtils";

export const StyledOrganizationPage = styled(Grid)(({ theme }) => {
  let zoom = getZoom();

  return {
    flexGrow: 1,
    // flexWrap: "nowrap",
    overflow: "hidden",
    backgroundColor: "#F7F7F9",
    zoom: `${zoom}%`,
    // transform: `scale(${1 / 2}`,

    ".organization-content": {
      height: "100%",
      display: "flex",
      overflow: "hidden",

      ".organization-diagram-container": {
        flexGrow: 1,
        flexDirection: "column",
        flexWrap: "nowrap",
        overflow: "hidden",
        borderRight: "1px solid #dedee0",

        ".organization-diagram-header": {
          height: "50px",
          display: "flex",
        },

        ".organization-diagram-nodes": {
          padding: "50px 0 0 29px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
          flexGrow: 1,
          overflow: "auto",
          backgroundColor: "#FFFFFF",
        },
      },
    },

    ".aside": {
      // "&:not(button)": {
      //   zoom: `${zoom}%`,
      // },
      height: "100%",
      padding: "26px",
      display: "flex",
      flexDirection: "column",

      ".organization-card-container": {
        flexGrow: 1,
        overflow: "hidden",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
        borderRadius: "6px",
        display: "flex",
      },

      ".card": {
        backgroundColor: "#FFFFFF",
        borderRadius: "6px",
        overflow: "auto",

        ".heading": {
          color: theme.palette.primary.main,
          fontWeight: 500,
        },

        ".favorite-list-item": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: theme.palette.bluegrey[700],
          padding: "8px 0",

          ".list-item-link": {
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          },

          ".btn-favorite": {
            width: "20px",
            height: "20px",
            "tds-icon": {
              fontSize: "20px!important",
            },
          },
        },

        ".toggle-btn": {
          backgroundColor: "white",
        },
      },
    },
  };
});

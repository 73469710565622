import React, { useContext, useEffect, useState, Suspense } from "react";
import { DialogContent, DialogTitle, Divider, Grid } from "@mui/material";
import { StyledOrganizationDialog } from "./styled";
import CardName from "./components/CardName/CardName";

// TODO: Add the rest of the lazy imports
import CCTypes from "./components/CCTypes/CCTypes";
import CheckboxList from "./components/CheckboxList/CheckboxList";
import FromToDates from "./components/FromToDates/FromToDates";
import ResetBtn from "./components/ResetBtn/ResetBtn";
import SaveBtn from "./components/SaveBtn/SaveBtn";
import CardForm from "./components/CardForm/CardForm";
import CloseBtn from "./components/CloseBtn/CloseBtn";
import { OrganizationCardContext } from "../../context/OrganizationCardContextProvider";
import TourElement from "../../../../../../components/Tour/TourElement/TourElement";
import { ORGANIZATION_CARD_MODAL_TYPE, ORGANIZATION_TYPE } from "../../../../constants";
import { getNewOrganizationDetailsApi } from "../../../../redux/api";
import CardName2 from "./components/CardName/CardName2";
import { useDispatch, useSelector } from "react-redux";
import { schemaSelector } from "../../../../redux/selectors";
import { setNewOrgData } from "../../../../redux/actions";
import HeadCountJobFamily from "./components/HeadCountJobFamily/HeadCountJobFamily";
import CardDescription from "./components/CardDescription/CardDescription";
const OrganizationCardHc = React.lazy(() =>
  import("./components/OrganizationCardHc/OrganizationCardHc")
);
const HCDeclared = React.lazy(() => import("./components/HCDeclared/HCDeclared"));

export default function OrganizationCardModal({ isOpen, toggle, targetField, parentCountryId }) {
  const dispatch = useDispatch();
  const context = useContext(OrganizationCardContext);
  const schema = useSelector(schemaSelector);
  const [isReseted, setIsReseted] = useState(false);
  const [countryName, setCountryName] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [descriptionCard, setDescriptionCard] = useState("");
  const [abbreviation, setAbbreviation] = useState("");

  useEffect(() => {
    if (context.type === ORGANIZATION_CARD_MODAL_TYPE.CREATE) {
      (async () => await getNewOrgData())();
    }
  }, []);

  useEffect(() => {
    if(context.data){
      if(context.data.section1.description){
        setDescriptionCard(context.data.section1.description.value);
      }
    }
  }, [isEdit]);

  const getNewOrgData = async () => {
    try {
      const response = await getNewOrganizationDetailsApi(context.getOrgType().toLowerCase());
      const data = { ...response.data };
      let type = context.getOrgType();

      if (type === ORGANIZATION_TYPE.BU) {
        type = ORGANIZATION_TYPE.GBU;
      }

      if (type === ORGANIZATION_TYPE.BL) {
        data.cardDetails.buCode = schema.path.selectedGBU.code;
      }

      if (type === ORGANIZATION_TYPE.CC) {
        data.cardDetails.blCode = schema.path.selectedBL.code;
      }

      data.cardDetails.type = type;

      context.setData(data);
      dispatch(setNewOrgData(data));
    } catch (e) {
      console.error(e);
      debugger; // Let's leave this for now
    }
  };

  const getCardName = () => {
    if (context.data.cardDetails.type === ORGANIZATION_TYPE.CC) {
      return (
        <CardName
          countryName={countryName}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          descriptionCard={descriptionCard}
          abbreviation={abbreviation}
          setAbbreviation={setAbbreviation}
        />
      );
    }

    return <CardName2 countryName={countryName} />;
  };

  if (!context.data) {
    return null;
  }

  return (
    <StyledOrganizationDialog open={isOpen} maxWidth="xl" fullWidth>
      <Grid>
        <DialogTitle className="dialog-header">
          <TourElement id="tour-orgCard-modalTile">
            <Grid container alignItems="center" className="dialog-header-content">
              <TourElement id="tour-orgCard-modalName">{getCardName()}</TourElement>

              <TourElement id="tour-orgCard-headcount">
                <div className="flex items-center">
                  <Divider orientation="vertical" flexItem />
                  {context.data.section1.hc.visible && (
                    <Suspense fallback={<div />}>
                      <OrganizationCardHc data={context.data} />
                    </Suspense>
                  )}
                  {context.data.section1.jfHC.visible && (
                    <Suspense fallback={<div />}>
                      <HeadCountJobFamily data={context.data} />
                    </Suspense>
                  )}
                  {context.data.section1.hcDeclared.visible && (
                    <Suspense fallback={<div />}>
                      <HCDeclared />
                    </Suspense>
                  )}
                </div>
              </TourElement>

              <Divider orientation="vertical" flexItem sx={{ marginLeft: "8px" }} />
              {context.data.section1.type.visible && <CCTypes />}
              <TourElement id="tour-orgCard-engDomains">
                <CheckboxList />
              </TourElement>

              <Grid item ml="auto">
                <Grid container alignItems="center" justifyContent="end">
                  <Grid item sm="auto">
                    <Grid container alignItems="center">
                      <FromToDates />
                      <Divider orientation="vertical" flexItem />
                    </Grid>
                  </Grid>

                  <Grid item sm="auto">
                    <Grid container alignItems="center">
                      {context.data.section1.btnUndo.visible && (
                        <ResetBtn name={context.data.section1.name.value} callback={setIsReseted} />
                      )}

                      <CloseBtn name={context.data.section1.name.value} />

                      {context.data.section1.btnSave.visible && (
                        <SaveBtn
                          name={context.data.section1.name.value}
                          parentCountryId={parentCountryId}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {(isEdit && context.data.section1.description.visible) && (
              <CardDescription
                descriptionCard={descriptionCard}
                setDescriptionCard={setDescriptionCard}
                abbreviation={abbreviation}
              />
            )}
            <Grid></Grid>
          </TourElement>
        </DialogTitle>
      </Grid>
      <DialogContent className="dialog-content">
        <CardForm setCountryName={setCountryName} isReseted={isReseted} targetField={targetField} />
      </DialogContent>
    </StyledOrganizationDialog>
  );
}

import React, { useEffect, useState } from "react";
import { TableBody, TableContainer } from "@mui/material";
import {
  StyledEEIInfoCell,
  StyledEEIInfoTable,
  StyledEEIInfoData,
  StyledEEIInfoRow,
} from "./styled";

export default function EEIFormInfo({ eeiInfo, isFromByCountry }) {
  const [metadata, setMetadata] = useState([]);

  useEffect(() => {
    const tableRows = [];
    if (isFromByCountry) {
      eeiInfo.map(row => {
        tableRows.push(row.cells);
        setMetadata(tableRows);
      });
    } else {
      setMetadata(eeiInfo);
    }
  }, [eeiInfo]);

  return (
    <TableContainer>
      <StyledEEIInfoTable size="small">
        <TableBody>
          {isFromByCountry && (
            <>
              {metadata &&
                metadata.map(row => (
                  <StyledEEIInfoRow>
                    <StyledEEIInfoCell>{row[0].value}</StyledEEIInfoCell>
                    <StyledEEIInfoData>{row[1].value}</StyledEEIInfoData>
                  </StyledEEIInfoRow>
                ))}
            </>
          )}
          {!isFromByCountry && (
            <>
              {metadata.updateDate && (
                <StyledEEIInfoRow>
                  <StyledEEIInfoCell>Update Date:</StyledEEIInfoCell>
                  <StyledEEIInfoData>{metadata.updateDate}</StyledEEIInfoData>
                </StyledEEIInfoRow>
              )}
              {metadata.year && (
                <StyledEEIInfoRow>
                  <StyledEEIInfoCell>Year:</StyledEEIInfoCell>
                  <StyledEEIInfoData>{metadata.year}</StyledEEIInfoData>
                </StyledEEIInfoRow>
              )}
              {metadata.lastActualsQuarter && (
                <StyledEEIInfoRow>
                  <StyledEEIInfoCell>Last Actuals quarter:</StyledEEIInfoCell>
                  <StyledEEIInfoData>{metadata.lastActualsQuarter}</StyledEEIInfoData>
                </StyledEEIInfoRow>
              )}
            </>
          )}
        </TableBody>
      </StyledEEIInfoTable>
    </TableContainer>
  );
}

import React from "react";
import { Box, Grid } from "@mui/material";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";
import { TdsIcon } from "@tds/react";
import { HeadCount } from "../../../../../../constants";
import EmwTooltip from "../../../../../../../../components/EmwToggleMenu/EmwTooltip";
import EmwTag from "../../../../../../../../components/EmwTag/EmwTag";

export default function HeadCountCalculated({ data }) {
  const title = () => {
    return (
      <>
        <EmwTypography fontSize={7} fontWeight="bold" classes="mb-xs">
          {HeadCount.E_HC.title}
        </EmwTypography>
        <Box sx={{ borderBottom: "2px solid #ffffff" }} className="mb-s"></Box>
        <EmwTypography component="body-4">
          {data.section1.countryHc.map(obj => {
            return (
              <EmwTag
                classes="mr-xs mb-xs"
                size="small"
                dismissible={false}
                text={`${obj.name}: ${obj.counted}`}
                variant="STANDARD"
                color="#636BBE"
              />
            );
          })}
        </EmwTypography>
      </>
    );
  };

  return (
    <EmwTooltip maxWidth="630px" title={title()}>
      <Grid item className="orgCountries">
        <TdsIcon icon="person" size="large" />
        <Grid item className="orgCountries-list" ml={0.5} mr={2}>
          <EmwTypography fontSize={9} fontWeight="bold">
            {HeadCount.E_HC.label}:
          </EmwTypography>
          {data.section1.hc.visible && (
            <p className="text-scale-9" id="E_HC">
              {data.section1.hc.value}
            </p>
          )}
        </Grid>
      </Grid>
    </EmwTooltip>
  );
}

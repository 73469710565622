import { styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
import { TdsModal } from "@tds/react";
import EmwAutocomplete from "../../../../../lib/common/EmwAutocomplete";

export const StylesOrganizationListHead = styled(Grid)(({ theme }) => ({
  backgroundColor: "#CCF2F8",
  borderRadius: 2,
  minHeight: "30px",
  // marginBottom: "10px",
  // marginTop: "10px",
  alignItems: "center",
  fontSize: "12px",
  cursor: "pointer",
}));

export const StylesOrganizationListItem = styled(Grid)(({ theme }) => ({
  backgroundColor: "#F7F7F9",
  borderRadius: 2,
  minHeight: "30px",
  marginTop: "10px",
  // marginBottom: "10px",
  alignItems: "center",
  fontSize: "12px",
  cursor: "pointer",
}));

export const StyledGridItemRoot = styled(Grid)(({ theme }) => ({
  backgroundColor: "#CCF2F8",
  borderRadius: 2,
  minHeight: "30px",
  marginBottom: "10px",
}));

export const StyledSectionItem = styled(Grid)(({ theme }) => ({
  backgroundColor: "#F7F7F9",
  borderRadius: 2,
  minHeight: "30px",
  marginBottom: "10px",
}));

export const StyledSectionContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "2px solid #041295",
}));

export const StyledChildBox = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  marginLeft: theme.spacing(2),
}));

export const StyledOrganizationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: "100%",
  backgroundColor: "white",
  padding: "16px",
  borderRadius: "6px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  // flex: '2 2 10em',
  // borderRadius: theme.spacing(2),
  // boxShadow: theme.shadows[2],
  // backgroundColor: "white",
  // marginLeft: '1rem',
  // position: "relative",
  // top: "-66px",
  // padding: "16px"
}));

export const StylesEmwPopup = styled(TdsModal)(({ theme }) => ({
  color: "black",
  ".modal-content": {
    width: "536px!important",
  },
  "tds-button": {
    // display: "none"
  },
}));

export const StyledEmwDropDownList = styled(Box)(({ theme }) => ({
  width: "100%",
  ".qtm-text-input.medium input, .qtm-text-input input": {
    height: "30px",
    borderRadius: "2px",
    fontSize: "12px",
  },
  ".dropdownLabelText": {
    margin: 0,
    fontSize: "12px",
    fontWeight: "normal",
  },
}));

export const StyledEmwDropDownListSmall = styled(Box)(({ theme }) => ({
  width: "100px",
  ".text-input.medium input, .text-input input": {
    height: "32px",
    borderRadius: "2px",
    fontSize: "12px",
  },
  ".dropdownLabelText": {
    margin: 0,
    fontSize: "12px",
    fontWeight: "normal",
  },
}));

export const StyledEmwAutocomplete = styled(EmwAutocomplete)(({ theme }) => ({
  width: "100%",
  ".text-input.medium input, .text-input input": {
    height: "30px",
    borderRadius: "2px",
    fontSize: "12px",
  },
  ".dropdownLabelText": {
    margin: 0,
    fontSize: "12px",
    fontWeight: "normal",
  },
}));

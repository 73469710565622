import * as actions from "./actions";
import { getState, reduceAction } from "../../../../util/reducer";
import initialState from "./initialstate";
import { DISABLED_ENG_LIST } from "./actions";

const reducerConfig = {
  [actions.ORG_DETAILS]: reduceAction,
  [actions.GUEST_DETAILS]: reduceAction,
  [actions.SET_ORG_DETAILS]: reduceAction,
  [actions.ORG_PERSPECTIVE]: reduceAction,
  [actions.ORG_VIEW]: reduceAction,
  [actions.DIAGRAM_SCHEMA]: reduceAction,
  [actions.DIAGRAM_LINK]: reduceAction,
  [actions.FAVORITE_ORGANIZATIONS]: reduceAction,
  [actions.UNDER_MY_RESPONSABILITY]: reduceAction,
  [actions.GBU_LIST]: reduceAction,
  [actions.EXTERNAL_PROJECTS]: reduceAction,
  [actions.TOUR_STATUS]: reduceAction,
  [actions.TOGGLE_SECTION]: reduceAction,
  [actions.GET_TREE_NODES]: reduceAction,
  [actions.EXPORT_ENG_ORG]: reduceAction,
  [actions.EXPORT_EEI]: reduceAction,
  [actions.UPLOAD_EEI]: reduceAction,
  [actions.GET_EEI_LOGS]: reduceAction,
  [actions.GET_EEI_LOGS_WEB_FORM]: reduceAction,
  [actions.GET_DISCIPLINE_NAMES]: reduceAction,
  [actions.REQUESTED_ORG_ID]: reduceAction,
  [actions.HIGHLIGHTED_TGI]: reduceAction,
  [actions.HAS_NODE_LINK]: reduceAction,
  [actions.NEW_ORG_DATA]: reduceAction,
  [actions.GET_EEI_DATA_FOR_ORGANIZATION]: reduceAction,
  [actions.GET_WFB_DATA_FOR_ORGANIZATION]: reduceAction,
  [actions.GET_ALL_CCS]: reduceAction,
  [actions.SAVE_CC_DETAILS]: reduceAction,
  [actions.DISABLED_ENG_LIST]: reduceAction,
  [actions.ORG_CC_FILTER]: reduceAction,
  [actions.EXPAND_PATH]: reduceAction,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);

import React, { useEffect, useState } from "react";
import { EmwButton } from "../../../../../lib/common";
import { TdsIcon } from "@tds/react";
import { Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import useAppSnackbar from "../../../../hooks/useAppSnankbar";
import { addFavoriteOrganizationApi, deleteFavoriteOrganizationApi } from "../../redux/api";
import { favoriteOrganizationsSelector } from "../../redux/selectors";
import { setFavoriteOrganizations } from "../../redux/actions";

const favoriteClasses = {
  isAdded: {
    color: "#E64B33",
    fontSize: "28px",
  },
  isNotAdded: {
    color: "#041295",
    fontSize: "28px",
  },
};

export default function FavoriteIconBtn({ item }) {
  const dispatch = useDispatch();
  const snackbar = useAppSnackbar();
  const [isAdded, setIsAdded] = useState(true);
  const favorites = useSelector(favoriteOrganizationsSelector);

  useEffect(() => {
    if (item.hasOwnProperty("isAdded")) {
      setIsAdded(item.isAdded);
    }
  }, [item]);

  const handleClick = async (event, id) => {
    let resp;
    event.currentTarget.disabled = true;
    if (isAdded) {
      resp = await deleteFavoriteOrganizationApi(id);
      const favs = [...favorites].map(fav => {
        if (fav.id === resp.data) {
          return {
            ...fav,
            isAdded: false,
          };
        }

        return fav;
      });

      dispatch(setFavoriteOrganizations(favs));
      setIsAdded(false);
    } else {
      resp = await addFavoriteOrganizationApi(id);

      const favs = [...favorites].map(fav => {
        if (fav.id === resp.data) {
          return {
            ...fav,
            isAdded: true,
          };
        }

        return fav;
      });

      dispatch(setFavoriteOrganizations(favs));
      setIsAdded(true);
    }

    snackbar.show(resp.message, resp.messageType);
  };

  return (
    <Grid item>
      <EmwButton
        variant="ghost"
        size="small"
        disabled={false}
        onClick={event => handleClick(event, item.id)}
        classes={["btn-favorite"]}
        id={`btnFavorite-${item.id}`}
      >
        <TdsIcon
          icon={isAdded ? "favorite" : "favorite_border"}
          title={isAdded ? "Remove from favorite" : "Add to favorite"}
          style={isAdded ? favoriteClasses.isAdded : favoriteClasses.isNotAdded}
        ></TdsIcon>
      </EmwButton>
    </Grid>
  );
}

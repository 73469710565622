import React, { useState } from "react";
import { Grid } from "@mui/material";
import { TdsIcon } from "@tds/react";
import { StyledWarningButton } from "./styled";
import { EmwButton } from "../../../../../../../lib/common";
import EmwClickableTooltip from "../../../../../../../lib/commonv2/EmwClickableTooltip";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";
import EmwDialog from "../../../../../../components/EmwDialog/EmwDialog";

export default function Warnings({ warnings, goToField, handleEditBtnHandler }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [section, setSection] = useState("");
  const [key, setKey] = useState("");

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleFixClick = ({ section, key, type, info }) => {
    setSection(section);
    setKey(key);
    if (type === "HodWarning") {
      setIsConfirmOpen(true);
      setWarningMessage(info);
    } else {
      setIsOpen(false);
      handleEditBtnHandler();
      goToField({ section, key });
    }
  };

  const handleFixHod = () => {
    setIsConfirmOpen(false);
    setIsOpen(false);
    handleEditBtnHandler();
    goToField({ section, key });
    
  };

  const renderContent = () => {
    return (
      <Grid container>
        <Grid item>
          <EmwTypography fontWeight="bold">Multiple items need your attention:</EmwTypography>
        </Grid>

        {warnings.map((warning, index) => {
          return (
            <React.Fragment key={index}>
              <Grid container alignItems="center">
                <Grid item>
                  <EmwTypography>{warning.roleName}</EmwTypography>
                </Grid>
                <Grid item ml="auto">
                  <EmwTypography>
                    <a
                      id={`btnFix-${index}`}
                      href="#"
                      onClick={() =>
                        handleFixClick({
                          section: "section1",
                          key: warning.key,
                          type: warning.type,
                          info: warning.info,
                        })
                      }
                    >
                      Fix{` >>`}
                    </a>
                  </EmwTypography>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
        {isConfirmOpen && (
            <EmwDialog
            isOpen={isConfirmOpen}
            setIsOpen={setIsConfirmOpen}
            callback={handleFixHod}
            actionButton="Fix >>"
            title={warningMessage}
            icon="warning_amber"
            classes="reset-icon"
            loading={false}
          />
        )}
      </Grid>
    );
  };

  return (
    <EmwClickableTooltip isOpen={isOpen} setIsOpen={setIsOpen} content={renderContent()}>
      <StyledWarningButton>
        <EmwButton
          variant="ghost"
          size="small"
          id="btnWarnings"
          title="Warnings"
          onClick={handleClick}
        >
          <TdsIcon icon="warning_amber" size="large" />
        </EmwButton>
      </StyledWarningButton>
    </EmwClickableTooltip>
  );
}

import React, { useEffect } from "react";
import { StyledFooter } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { biPropsSelector } from "../../../../modules/header/selectors";
import { Box, Tooltip } from "@mui/material";
import MailOutlined from "../../../../lib/icons/MailOutlinedIcon";
import { TdsTypography } from "@tds/react";
import EmwTypography from "../../../components/EmwTypography/EmwTypography";
import { setIsHza } from "../redux/actions";
import { isHzaSelector } from "../redux/selectors";
import GdprBtn from "./GdprBtn";
import EmwVersionBtn from "./EmwVersionBtn";
import { hzaIframeSelector } from "../../header/redux/selectors";

export default function Footer() {
  const dispatch = useDispatch();
  const isHza = useSelector(isHzaSelector);
  const hzaIframeLoaded = useSelector(hzaIframeSelector);
  const biProps = useSelector(biPropsSelector);

  useEffect(() => {
    if (biProps.list && biProps.list.hasOwnProperty("data")) {
      dispatch(setIsHza(biProps.list.data.appEnviroment === "hza"));
    }
  }, [biProps]);

  return (
    <StyledFooter>
      <div className="footer-item">
        <EmwVersionBtn />
      </div>

      <Box ml="auto">
        <MailOutlined width="20" height="16" />
      </Box>

      <Box mr={1} />
      <div className="footer-item">
        <Tooltip arrow title="Send a mail to the EMW Support Team" className="emw-email">
          <TdsTypography
            style={{ fontWeight: 500 }}
            component="body-2"
            fontWeight="bold"
            className="text-black-500 medium:block hasTmpZoom"
          >
            <a href="mailto:FR-TGS-CONTACT-ENG-EMW@thalesgroup.com">
              FR-TGS-CONTACT-ENG-EMW@thalesgroup.com
            </a>
          </TdsTypography>
        </Tooltip>
      </div>

      <Box mr={2} />

      <div className="footer-item">
        <GdprBtn />
      </div>

      <div className="footer-env-info">
        {isHza ? (
          <EmwTypography classes="text-orange-500 hasTmpZoom" fontWeight="bold" fontSize={8}>
            THALES GROUP CONFIDENTIAL
          </EmwTypography>
        ) : (
          <EmwTypography classes="text-green-500 hasTmpZoom" fontWeight="bold" fontSize={8}>
            THALES GROUP LIMITED DISTRIBUTION
          </EmwTypography>
        )}
      </div>
    </StyledFooter>
  );
}

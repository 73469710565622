import React from "react";
import { ORGANIZATION_PERSPECTIVE } from "../../../../constants";
import { Box, Grid } from "@mui/material";
import ThalesLogo from "../../../../../../../styles/images/Thaleslogo.svg";
import DiagramHeaderItems from "../DiagramHeaderItems/DiagramHeaderItems";

export default function DiagramHeader({ perspective, schema }) {
  return (
    <Grid item className="diagram-header">
      <Grid item xs={2} display="flex" alignItems="center" justifyContent="center" className="diagram-logo-container">
        <Box className="thales-logo">
          <img src={ThalesLogo} alt="thales logo" />
        </Box>
      </Grid>

      <Grid item xs={"auto"} className="diagram-headings">
        {ORGANIZATION_PERSPECTIVE.BY_GBU === perspective && (
          <DiagramHeaderItems items={schema.headers[ORGANIZATION_PERSPECTIVE.BY_GBU]} />
        )}

        {ORGANIZATION_PERSPECTIVE.BY_COUNTRY === perspective && (
          <DiagramHeaderItems items={schema.headers[ORGANIZATION_PERSPECTIVE.BY_COUNTRY]} />
        )}
      </Grid>
    </Grid>
  );
}

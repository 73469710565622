import * as api from "./api";
import * as constants from "./constants";

export const POST_LOGIN = `${constants.MODULE_NAME}/POST_LOGIN`;
export const USER_LOGIN = `${constants.MODULE_NAME}/USER_LOGIN`;
export const AZURE_LOGIN = `${constants.MODULE_NAME}/AZURE_LOGIN`;

const props = {
  error: false,
  meta: { fetching: false },
};

export const postLogin = data => {
  return {
    ...props,
    type: POST_LOGIN,
    payload: api.postLogin(data),
  };
};

export const loginUser = data => {
  return {
    ...props,
    type: USER_LOGIN,
    payload: api.loginUser(data),
  };
};

export const isAzure = () => {
  return {
    ...props,
    type: AZURE_LOGIN,
    payload: api.isAzure(),
  };
};

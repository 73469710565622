import React, { useContext, useEffect, useMemo, useState } from "react";
import { Collapse } from "@mui/material";
import { cloneDeep } from "lodash";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";
import FormItem from "../FormItem/FormItem";
import { ENGINEER_TYPE, NO_RESOURCE_ALLOCATED } from "../../../../../../constants";
import { dateIsExpired } from "../../utils";

export default function WorkPackage({
  workPackage,
  color,
  index,
  deliveryIndex,
  solutionIndex,
  targetField,
  openNewWpm,
}) {
  const context = useContext(OrganizationCardContext);
  const [isOpen, setIsOpen] = useState(workPackage.isOpen);
  const [name, setName] = useState(workPackage.name.value || `Work package`);
  const [values, setValues] = useState(cloneDeep(workPackage));
  const [item, setItem] = useState(workPackage);
  const [isDropdownAlreadyOpen, setIsDropdownAlreadyOpen] = useState(isOpen);
  const [forceOpen, setForceOpen] = useState(false);

  useEffect(() => {
    setName(workPackage.name.value || `Work Package ${index + 1}`);
    setItem(workPackage);

    if (!isOpen && !forceOpen) {
      const shouldForceOpen = context.shouldBeOpen(workPackage, targetField.key);
      setIsOpen(shouldForceOpen);
      setForceOpen(shouldForceOpen);
    }
  }, [workPackage]);

  const handleIsMainObjOpen = open => {
    const _workPackage = {
      ...workPackage,
      isOpen: open,
    };
    context.updateWorkPackage(_workPackage, deliveryIndex, solutionIndex, index);
  };

  const handleIsMainObjInEditMode = isEdit => {
    const _workPackage = {
      ...workPackage,
      isEdit: isEdit,
    };
    context.updateWorkPackage(_workPackage, deliveryIndex, solutionIndex, index);
  };

  const handleUpdate = (objectValues, key) => {
    const updatedValues = { ...values };

    if (key) {
      updatedValues[key] = objectValues;
      updatedValues.wpms = objectValues;
      updatedValues.isOpen = item.isOpen;
      updatedValues.isEdit = item.isEdit;
      context.updateWorkPackage(updatedValues, deliveryIndex, solutionIndex, index);
      setValues(updatedValues);
    } else {
      objectValues.leader = updatedValues.leader;
      objectValues.isOpen = true;
      objectValues.isEdit = false;
      context.updateWorkPackage(objectValues, deliveryIndex, solutionIndex, index);
      setValues(objectValues);
    }
  };

  const handleEditToggle = () => {
    setIsOpen(true);
  };

  const setWarningBorder = () => {
    let hasBorder = false;
    const regExp = /^Work Package [0-9]+$/;
    if (!values.leader.name.value || values.leader.name.value === NO_RESOURCE_ALLOCATED) {
      hasBorder = true;
    } else if (values.name.value && regExp.test(values.name.value)) {
      hasBorder = true;
    } else if (dateIsExpired(values.leader.endDate.value)) {
      hasBorder = true;
    }
    return hasBorder;
  };

  return (
    <>
      <FormItem
        isNew={false}
        type={null}
        customName={name}
        item={item}
        handleUpdate={handleUpdate}
        showArrows={false}
        color={color}
        isOpen={isOpen}
        isEditOpen={workPackage.isEdit}
        setIsOpen={value => {
          setIsOpen(value);
          setIsDropdownAlreadyOpen(value);
          handleIsMainObjOpen(value);
        }}
        collapsable={true}
        isTextField={true}
        targetKey={targetField.key}
        id={`wpm-${index}`}
        handleEditToggle={handleEditToggle}
        noResource={setWarningBorder()}
        beforeEditClick={() => {
          if (!isDropdownAlreadyOpen) {
            setIsOpen(true);
          }
          handleIsMainObjInEditMode(true);
        }}
        beforeCancelClick={() => {
          if (!isDropdownAlreadyOpen) {
            setIsOpen(false);
          }
          handleIsMainObjInEditMode(false);
        }}
        customInfoMsg={!item.btnEdit.visible ? "C3 THALES GROUP CONFIDENTIAL (anonymous name)" : ""}
      />

      <Collapse
        in={isOpen}
        sx={{
          width: "100%",
          display: "flex",
          border: "1px solid #CCF2F8",
          padding: "0 4px 4px 4px",
        }}
      >
        <FormItem
          isNew={false}
          type="WPM"
          item={workPackage.leader}
          handleUpdate={handleUpdate}
          showArrows={false}
          propKey="leader"
          targetKey={targetField.key}
          id={`wpm-${index}-WPM`}
          title={ENGINEER_TYPE.WPM.title}
        />
      </Collapse>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { treeDataSelector } from "../../../../header/redux/selectors";
import useChartUtils from "./useChartUtils";
import useAppSnackbar from "../../../../../hooks/useAppSnankbar";
import { getOrganizationsIndicatorsApi } from "../../../redux/api";

export default function useEngineeringCompliance() {
  const treeData = useSelector(treeDataSelector);
  const [filteredData, setFilteredData] = useState(null);
  const chartUtils = useChartUtils();
  const snackbar = useAppSnackbar();
  const [stateData, setStateData] = useState({
    timeframe: {
      default: true,
      date: "",
      period: "12 months",
    },
    filterValues: {
      ccs: [],
      bls: [],
      gbus: [],
      countries: [],
    },
    filterOptions: {
      ccs: [],
      bls: [],
      gbus: [],
      countries: [],
    },
  });

  const [chartsData, setChartsData] = useState({
    emHoeos: [],
    disciplines: [],
    allComponents: [],
    referents: [],
    allReferents: [],
    hodsNoJfOoEm: [],
  });

  useEffect(() => {
    if (!treeData.list.hasOwnProperty("data")) {
      return;
    }
    setFilteredData(filter(treeData.list.data));
  }, [treeData]);

  useEffect(() => {
    if (filteredData) {
      initFilters("countries");
    }
  }, [filteredData]);

  useEffect(() => {
    (async () => {
      const response = await getOrganizationsIndicatorsApi(stateData.filterValues);
      if (response.data) {
        transformData(response.data);
      } else {
        snackbar.show(response.message, "warning");
      }
    })();
  }, [stateData.filterValues]);

  const initFilters = type => {
    const countryList = [];
    const gbuList = [];
    const blList = [];
    const ccList = [];

    if (type === "countries") {
      filteredData.regions.forEach(region => {
        region.childs.forEach(country => {
          countryList.push({ id: country.id, code: country.name, childs: country.childs });
        });
      });

      filteredData.gbus.forEach(gbu => {
        gbuList.push({ id: gbu.id, code: gbu.code, childs: gbu.childs });
        gbu.childs.forEach(bl => {
          blList.push({ id: bl.id, code: bl.code, childs: bl.childs });
          bl.childs.forEach(cc => {
            ccList.push({ id: cc.id, code: cc.name, childs: cc.childs });
          });
        });
      });

      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterOptions: {
            ccs: ccList,
            bls: blList,
            gbus: gbuList,
            countries: countryList,
          },
          filterValues: {
            ccs: [],
            bls: [],
            gbus: [],
            countries: [],
          },
        };
      });
    }

    if (type === "gbus") {
      const gbuList = stateData.filterOptions.gbus;
      const blList = [];
      const ccList = [];

      gbuList.forEach(gbu => {
        gbu.childs.forEach(bl => {
          blList.push(bl);
          bl.childs.forEach(cc => ccList.push({ ...cc, code: cc.name }));
        });
      });

      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterOptions: {
            ...currentStateData.filterOptions,
            bls: blList,
            ccs: ccList,
          },
          filterValues: {
            ...currentStateData.filterValues,
            gbus: [],
            ccs: [],
            bls: [],
          },
        };
      });
    }

    if (type === "bls") {
      const blList = stateData.filterOptions.bls;
      const ccList = [];

      blList.forEach(bl => {
        bl.childs.forEach(cc => ccList.push({ ...cc, code: cc.name }));
      });

      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterOptions: {
            ...currentStateData.filterOptions,
            ccs: ccList,
          },
          filterValues: {
            ...currentStateData.filterValues,
            bls: [],
          },
        };
      });
    }
  };

  const transformData = data => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentQuarter = chartUtils.getQuarter(date);
    const emHoeos = chartUtils.formatData(data.emHoeos, currentQuarter, currentYear);
    const disciplines = chartUtils.formatData(data.disciplines, currentQuarter, currentYear);
    const allComponents = chartUtils.formatData(data.allComponents, currentQuarter, currentYear);
    const referents = chartUtils.formatData(data.referents, currentQuarter, currentYear);
    const hodsNoJfOoEm = chartUtils.formatData(data.hodsNoJfOoEm, currentQuarter, currentYear);
    const allReferents = Object.keys(data.allReferents)
      .map(groupKey => {
        const group = {};
        group.label = chartUtils.mapLabel(groupKey);

        Object.keys(data.allReferents[groupKey]).forEach(key => {
          const barKey = chartUtils.formatKey(key, currentQuarter, currentYear);
          group[barKey] = data.allReferents[groupKey][key];
        });

        return group;
      })
      .sort(chartUtils.sortGroups);

    setChartsData(currentData => ({
      ...currentData,
      emHoeos,
      disciplines,
      allComponents,
      referents,
      allReferents,
      hodsNoJfOoEm,
    }));
  };

  // TODO: getParent and getChild should not be separate methods
  const getParentOf = (type, ids) => {
    if (type === "gbus") {
      if (ids.length === 0) {
        return initFilters("countries");
      }

      const countries = [];
      filteredData.regions.forEach(region => {
        return region.childs.forEach(country => {
          country.childs?.forEach(gbu => {
            ids.forEach(id => {
              if (id === gbu.id && !countries.includes(country)) {
                countries.push(country);
              }
            });
          });
        });
      });

      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterOptions: {
            ...currentStateData.filterOptions,
            countries: countries,
          },
          filterValues: {
            ...currentStateData.filterValues,
          },
        };
      });
    }
    if (type === "bls") {
      if (ids.length === 0) {
        return initFilters("countries");
      }

      const countries = [];
      const gbus = [];

      stateData.filterOptions.gbus.forEach(gbu => {
        gbu.childs.forEach(bl => {
          ids.forEach(id => {
            if (id === bl.id && !gbus.some(item => gbu.id === item.id)) {
              gbus.push(gbu);
            }
          });
        });
      });

      const gbuIds = gbus.map(gbu => gbu.id);

      filteredData.regions.forEach(region => {
        return region.childs.forEach(country => {
          country.childs?.forEach(gbu => {
            if (gbuIds.includes(gbu.id)) {
              countries.push(country);
            }
          });
        });
      });

      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterOptions: {
            ...currentStateData.filterOptions,
            countries: countries,
            gbus: gbus,
          },
          filterValues: {
            ...currentStateData.filterValues,
          },
        };
      });
    }

    if (type === "ccs") {
      if (ids.length === 0) {
        return initFilters("countries");
      }

      const countries = [];
      const gbus = [];
      const bls = [];

      stateData.filterOptions.bls.forEach(bl => {
        bl.childs.forEach(cc => {
          if (ids.includes(cc.id) && !bls.some(item => bl.id === item.id)) {
            bls.push(bl);
          }
        });
      });

      const blIds = bls.map(bl => bl.id);

      stateData.filterOptions.gbus.forEach(gbu => {
        gbu.childs.forEach(bl => {
          if (blIds.includes(bl.id)) {
            gbus.push(gbu);
          }
        });
      });

      const gbuIds = gbus.map(gbu => gbu.id);

      filteredData.regions.forEach(region => {
        return region.childs.forEach(country => {
          country.childs?.forEach(gbu => {
            if (gbuIds.includes(gbu.id)) {
              countries.push(country);
            }
          });
        });
      });

      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterOptions: {
            ...currentStateData.filterOptions,
            countries: countries,
            gbus: gbus,
            bls: bls,
          },
          filterValues: {
            ...currentStateData.filterValues,
            ccs: ids,
          },
        };
      });
    }
  };

  const getChildOf = (type, ids) => {
    if (type === "countries") {
      const countries = [];
      const gbus = [];
      const bls = [];
      const ccs = [];

      if (ids.length === 0) {
        return initFilters(type);
      }

      filteredData.regions.forEach(region => {
        return region.childs.forEach(country => {
          if (ids.includes(country.id)) {
            countries.push(country);
          }
        });
      });

      countries.forEach(country => {
        country.childs.forEach(gbu => {
          gbus.push(gbu);
          gbu.childs.forEach(bl => {
            bls.push(bl);
            bl.childs.forEach(cc => {
              ccs.push({ ...cc, code: cc.name });
            });
          });
        });
      });

      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterValues: {
            countries: countries.map(country => country.id),
            gbus: [],
            bls: [],
            ccs: [],
          },
          filterOptions: {
            ...currentStateData.filterOptions,
            gbus,
            bls,
            ccs,
          },
        };
      });
    }

    if (type === "gbus") {
      const bls = [];
      const ccs = [];

      if (ids.length === 0) {
        return initFilters(type);
      }

      stateData.filterOptions.gbus
        .filter(gbu => ids.includes(gbu.id))
        .forEach(gbu => {
          gbu.childs.forEach(bl => {
            bls.push(bl);
            bl.childs.forEach(cc => ccs.push({ ...cc, code: cc.name }));
          });
        });

      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterValues: {
            ...currentStateData.filterValues,
            gbus: ids,
            bls: [],
            ccs: [],
          },
          filterOptions: {
            ...currentStateData.filterOptions,
            bls,
            ccs,
          },
        };
      });
    }

    if (type === "bls") {
      const ccs = [];

      if (ids.length === 0) {
        return initFilters(type);
      }

      stateData.filterOptions.bls
        .filter(bl => ids.includes(bl.id))
        .forEach(bl => {
          bl.childs.forEach(cc => ccs.push({ ...cc, code: cc.name }));
        });

      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterValues: {
            ...currentStateData.filterValues,
            bls: ids,
            ccs: [],
          },
          filterOptions: {
            ...currentStateData.filterOptions,
            ccs,
          },
        };
      });
    }
    // i don't think we use this
    // if (type === "bls") {
    //   setStateData(currentStateData => {
    //     return {
    //       ...currentStateData,
    //       filterValues: {
    //         ...currentStateData.filterValues,
    //         bls: ids,
    //         ccs: [],
    //       },
    //     };
    //   });
    // }

    if (type === "ccs") {
      if (ids.length === 0) {
        return initFilters(type);
      }
      setStateData(currentStateData => {
        return {
          ...currentStateData,
          filterValues: {
            ...currentStateData.filterValues,
            ccs: ids,
          },
        };
      });
    }
  };

  const filter = data => {
    const _result = {};
    _result.gbus = removeUnknownAndDuplicateNameObjects(data.gbus);
    _result.regions = removeUnknownAndDuplicateNameObjects(data.regions);

    return _result;
  };

  function removeUnknownAndDuplicateNameObjects(obj, namesSeen = new Set()) {
    obj = obj.filter(item => {
      const isDuplicate = namesSeen.has(item.name);
      const isUnknown = item.name.toLowerCase().includes("unknown");
      if (!isDuplicate && !isUnknown) {
        namesSeen.add(item.name);
        return true;
      }
      return false;
    });

    obj.sort((a, b) => a.name.localeCompare(b.name));

    obj.forEach(item => {
      if (item.childs && Array.isArray(item.childs)) {
        item.childs = removeUnknownAndDuplicateNameObjects(item.childs, namesSeen);
      }
    });

    return obj;
  }

  return {
    stateData,
    chartsData,
    setStateData,
    getChildOf,
    getParentOf,
  };
}

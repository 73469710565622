import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TourElement from "../../../../../../../../components/Tour/TourElement/TourElement";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";
import DeliveriesPanel from "./DeliveriesPanel";

export default function Section2({ section2, targetField }) {
  const context = useContext(OrganizationCardContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      setOpen(context.shouldBeOpen(section2, targetField.key));
    }
  }, []);

  return (
    <>
      <TourElement id="tour-orgCard-Section2DeliveriesPanel">
        <DeliveriesPanel deliveries={section2.deliveries} targetField={targetField} open={open} />
      </TourElement>
    </>
  );
}

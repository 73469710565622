import React from "react";
import { Box } from "@mui/material";
import Referees from "./Referees";
import TogglePanel from "./TogglePanel";
import { ENGINEER_TYPE } from "../../../../../../constants";
import TourElement from "../../../../../../../../components/Tour/TourElement/TourElement";
import SectionCer from "../SectionCer/SectionCer";
import OperationsPanel from "../OperationsPanel/OperationsPanel";

export default function Section1({ section1, targetField, sectionCer }) {
  return (
    <>
      <TourElement id="tour-orgCard-ENGINEERING_MANAGER_SECTION_ID">
        <Box mb={1}>
          <TogglePanel
            name="ENGINEERING MANAGER"
            type="EM"
            propKey="ems"
            list={section1.ems}
            open={true}
            showArrows={section1.hoEOs.length > 1}
            targetField={targetField}
            section={"section1"}
            title={ENGINEER_TYPE.EM.title}
          />
        </Box>
      </TourElement>
      <TourElement id="tour-orgCard-HEAD_OF_ENGINEERING_OPERATIONS_SECTION_ID">
        <Box mb={1}>
          <OperationsPanel
            name="OPERATIONS"
            open={true}
            targetField={targetField}
            section={"section1"}
            section1={section1}
          />
        </Box>
      </TourElement>
      {/* {sectionCer && sectionCer.visible && sectionCer.cers.length > 0 && ( */}
      {sectionCer && sectionCer.visible && (
        <TourElement id="tour-orgCard-CER_SECTION_ID">
          <Box mb={1}>
            <SectionCer sectionCer={sectionCer} targetField={targetField} />
          </Box>
        </TourElement>
      )}

      <TourElement id="tour-orgCard-REFEREES_SECTION_ID">
        <Box>
          <Referees section1={section1} targetField={targetField} open={true} />
        </Box>
      </TourElement>
    </>
  );
}

import React from "react";
import { styled } from "@mui/material/styles";
import { TdsDropdownSelect, TdsDropdownSelectOption, TdsTypography } from "@tds/react";

const Root = styled("div")(() => ({
  display: "flex",
}));

const LabelText = styled("div")(() => ({
  fontSize: "16px",
  whiteSpace: "nowrap",
  marginTop: "10px",
}));

export default function EmwDropDownList(props) {
  const { onDropDownListChanged, options, colors, placeHolder, value, inputId, disabled, additionalCss, placement } =
    props;

  const onClickOption = event => {
    if (typeof onDropDownListChanged === "function") {
      onDropDownListChanged(event, inputId);
    }
  };

  const getSelectedText = (optionsList, selectedValue) => {
    const selectedOptions = optionsList.filter(p => p.value === selectedValue);
    return selectedOptions.length > 0 && selectedOptions[0].text;
  };
  // if (disabled) {
  //     const x = getSelectedText(options, value)
  //
  //     return <LabelText className="dropdownLabelText">{getSelectedText(options, value)}</LabelText>
  // }

  return (
    <Root key={inputId + Math.random() * 1000000}>
      <TdsDropdownSelect
        key={"cheie"}
        placement={placement ? placement : "top"}
        value={value}
        placeholder={placeHolder}
        inputId={inputId}
        disabled={disabled}
        style={additionalCss}
        // classes="bg-primary-500"
        size="small"
      >
        {options && options.length > 0
          ? options.map((el, index) => {
              return (
                <TdsDropdownSelectOption
                  key={index}
                  value={el.value}
                  title={el.info ? el.info : ""}
                  onClickOption={onClickOption}
                  classes={el.disabled ? "pointer-events-none" : ""}
                >
                  <TdsTypography
                    classes={el.disabled ? "text-bluegrey-200 cursor-not-allowed" : ""}
                    component="caption-1"
                  >
                    {el.text}
                  </TdsTypography>
                </TdsDropdownSelectOption>
              );
            })
          : null}
      </TdsDropdownSelect>
    </Root>
  );
}

import { styled } from "@mui/material/styles";

export const StyledStackedChart = styled("div")(({ theme }) => {
  return {
    width: "100%",
    height: "100%",
    maxHeight: "281px",

    ".bullet": {
      "& >div": {
        marginBottom: "1px",
        width: "7px",
        height: "7px",
      },
    },

    "qtm-icon": {
      cursor: "help",
    },

    "qtm-tooltip": {
      "*": {
        background: "white!important",
        color: "black",
        opacity: 0.9,
      },
    },
  };
});

import React, { useEffect, useState } from "react";
import AdministrationToggle from "../../../../../AdministrationToggle/AdministrationToggle";
import { TdsDivider } from "@tds/react";
import { useLoadDependencies } from "../../../../../../../header/components/useLoadDependencies";
import { putUpdateSettingsApi } from "../../../../../../redux/api";
import useAppSnackbar from "../../../../../../../../hooks/useAppSnankbar";

export default function TDPUnderMaintenance({ user }) {
  const { init } = useLoadDependencies();
  const snackBar = useAppSnackbar();
  const [checked, setChecked] = useState(user.tdpUnderMaintenance);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChecked(user.tdpUnderMaintenance);
  }, [user.tdpUnderMaintenance]);

  const handleCheck = async () => {
    try {
      setLoading(true);
      setChecked(!checked);
      await putUpdateSettingsApi({ tdpUnderMaintenance: !user.tdpUnderMaintenance });
      await init();
    } catch (reason) {
      snackBar.show(reason.message, "error");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="flex justify-between mt-s">
        <p className="text-scale-6 text-blugrey-500">TDP under maintenance</p>

        <AdministrationToggle checked={checked} handleChecked={handleCheck} disabled={loading} />
      </div>

      <TdsDivider size="small" />
    </>
  );
}

import { combineReducers } from "redux";
import app from "./modules/app";
import header from "./V2/features/header";
import footer from "./V2/features/footer";
import organization from "./V2/features/organization";
import administration from "./V2/features/administration";
import powerBi from "./V2/features/powerBi";
import login from "./modules/login";
import filters from "./V2/components/EmwGeneralFilters";

export default combineReducers({
  [app.constants.MODULE_NAME]: app.reducer,
  [login.constants.MODULE_NAME]: login.reducer,
  [header.constants.MODULE_NAME]: header.reducer,
  [footer.constants.MODULE_NAME]: footer.reducer,
  [organization.constants.MODULE_NAME]: organization.reducer,
  [powerBi.constants.MODULE_NAME]: powerBi.reducer,
  [administration.constants.MODULE_NAME]: administration.reducer,
  [filters.constants.MODULE_NAME]: filters.reducer,
});

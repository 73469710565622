import React, { useEffect, useState } from "react";
import EmwToggleMenu from "../../../../../../../../components/EmwToggleMenu/EmwToggleMenu";
import TogglePanel from "../Section1/TogglePanel";
import { ENGINEER_TYPE } from "../../../../../../constants";
import { Box } from "@mui/material";

export default function OperationsPanel({name, section1, open, section, targetField }) {

  return (
    <EmwToggleMenu shouldOpen={open} name={name}>
      <Box ml={2}>
        <Box mt={1}>
          <TogglePanel
            name="HEAD OF ENGINEERING OPERATIONS"
            type="HoEO"
            propKey="hoEOs"
            list={section1.hoEOs}
            open={open}
            showArrows={section1.hoEOs.length > 1}
            targetField={targetField}
            section={section}
            title={ENGINEER_TYPE.HoEO.title}
          />
        </Box>
        
        <Box mt={1}>
          <TogglePanel
            name={ENGINEER_TYPE.KPI.title.toLocaleUpperCase()}
            type={ENGINEER_TYPE.KPI.title}
            propKey="kpis"
            list={section1.kpis}
            open={open}
            showArrows={section1.kpis.length > 1}
            targetField={targetField}
            section={section}
            title={ENGINEER_TYPE.KPI.title}
          />
        </Box>
      </Box>
    </EmwToggleMenu>
  );
}

import { buildStep } from "../tour";

export const buildCbuSteps = (
  dinamycStep,
  tour,
  orgType,
  name,
  btnEditTitle,
  hasHc,
  hasHcDeclared,
  hasCountries,
  hasBtnWarning,
  btnWarningsTitle,
  hasBtnGuests,
  btnManageGPGTitle,
  hasBtnEdit,
  hasBtnEditEnabled,
  text
) => {
  dinamycStep.push(
    buildStep(tour, null, {
      id: "organization-diagram-nodes",
      title: "CBU Column",
      text: `This column shows all the CBU’s available. In this example, ${name} has been selected, revealing all the Domains that are part of it.
    The nodes that have a darker gray colour represent "Intuitive Path" - they lead to nodes that are under your responsibility. 
    The nodes that have a star symbol are under your responsibility.
    Click Next to continue.`,
      element: ".organization-diagram-nodes",
      on: "right-start",
      scroll: true,
    })
  );

  dinamycStep.push(buildStep(tour, null, {
    id: "orgCard",
    title: "Engineering Organization Card",
    text: `Country Business Unit - "CBU" represents Global Business Unit - "GBU" equivalent and is used only for navigation purposes. From here the user can select a certain Domain to reach required Domain - "BL" equivalent.`,
    element: ".organization-card-container",
    on: "right-start"
  }));

  if (hasBtnWarning && hasBtnEdit && hasBtnEditEnabled) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: "btnWarnings",
        title: btnWarningsTitle,
        text: `Alerts (highlights missing items in your organization).`,
        element: "#btnWarnings",
        on: "right-start",
      })
    );
  }

  if (hasBtnGuests) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: "btnGuests",
        title: btnManageGPGTitle,
        text: `Guest/Power Guest (opens a modal where you can manage Guest/Power Guest access).`,
        element: "#btnGuests",
        on: "right-start",
      })
    );
  }
  if (hasBtnEdit) {
    dinamycStep.push(buildStep(tour, null, {
      id: "btnEdit",
      title: btnEditTitle,
      text: `Edit  (make changes to the Engineering Organization Card).`,
      element: ".tour-edit",
      on: "right-start"
    }, null, null, 100, () => {
      if (!hasHc && !hasHcDeclared){
        if (hasBtnEdit && hasBtnEditEnabled && document.querySelector("#btnEdit")) {
          document.querySelector("#btnEdit").click();
        }
      }
    }));
  }
  if (hasHc || hasHcDeclared) {
    dinamycStep.push(buildStep(tour, null, {
      id: "headcount",
      title: "Engineering Organization Card Info Area",
      text: `In this area you can visualise information related to the selected organization, such as head count (calculated, declared), you can view what countries the organization belongs to, CC Type, and Industries it belongs to.`,
      element: ".header-elements",
      on: "right-start"
    }, null, null, 100, () => {
      if (hasBtnEdit && hasBtnEditEnabled && document.querySelector("#btnEdit")) {
        document.querySelector("#btnEdit").click();
      }
    }));
  }
};

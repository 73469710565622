import React from "react";
import { TdsIcon, TdsTypography } from "@tds/react";
import { NavLink } from "react-router-dom-v5-compat";
import {
  changePerspective,
  getOrganizationDetails,
  goToNode,
  hasNodeLink,
} from "../../../../../organization/redux/actions";
import EmwButton from "../../../../../../components/EmwButton/EmwButton";
import { ORGANIZATION_PERSPECTIVE } from "../../../../../organization/constants";
import { useDispatch, useSelector } from "react-redux";
import { perspectiveSelector } from "../../../../../organization/redux/selectors";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";
import { setUser } from "../../../../redux/api";
import { useLoadDependencies } from "../../../useLoadDependencies";
import { StyledOrgRole } from "./styled";
import clx from "classnames";

export default function OrgRole({ orgRole, index, user, canSegregate }) {
  const { init } = useLoadDependencies();
  const dispatch = useDispatch();
  const perspective = useSelector(perspectiveSelector);

  const isActive = () => {
    if (user.usedRole && orgRole.unId === user.usedRole.unId) {
      return true;
    }

    return false;
  };

  const className = clx({ active: isActive() });

  const handleCerGoToNode = async orgRole => {
    if (perspective === "BY_COUNTRY") {
      dispatch(getOrganizationDetails({ id: null, type: "GROUP" }));
      dispatch(changePerspective(ORGANIZATION_PERSPECTIVE.BY_GBU));
    }
    setTimeout(async () => {
      await dispatch(goToNode({ ...orgRole.additionalOrgDetails, code: orgRole.code }));
    }, 500);
  };

  const handleGoToNode = orgRole => {
    const orgDetails = orgRole.orgDetails;
    if (!orgRole.orgDetails.hasOwnProperty("unId") && orgRole.hasOwnProperty("unId")) {
      orgDetails.unId = orgRole.unId;
    }
    dispatch(goToNode({ ...orgDetails, code: orgRole.code }));
  };

  const handleLinkClick = () => {
    dispatch(hasNodeLink(true));
    setTimeout(() => {
      orgRole.code === "CER" ? handleCerGoToNode(orgRole) : handleGoToNode(orgRole);
    }, 1000);
  };

  const handleClick = async () => {
    const payload = { usedRole: orgRole, tgi: user.tgi };

    if (isActive()) {
      payload.usedRole = null;
    }

    await setUser(payload);
    await init();

    document.querySelectorAll(".diagram-node-link")[1].click();
  };

  return (
    <StyledOrgRole className={`flex items-center ${className}`}>
      <div className="py-xxs mr-xs mb-xs">
        <TdsTypography component="qtm-caption-2" fontWeight="bold">
          Role {index + 1}:
        </TdsTypography>
        <NavLink to="/organization" onClick={handleLinkClick}>
          <TdsTypography component="qtm-caption-2" className="list-item-link">
            {orgRole.name}
          </TdsTypography>
        </NavLink>
      </div>
      {canSegregate && (
        <div className="ml-auto" style={{ minWidth: "120px", border: "2px solid white" }}>
          <EmwButton size="small" color="danger" onClick={handleClick}>
            <EmwTypography component="qtm-caption-2">Use this Role</EmwTypography>
            <div className="ml-s">
              {!isActive() && <TdsIcon icon="play_circle" size="small" />}
              {isActive() && <TdsIcon icon="cancel" size="small" />}
            </div>
          </EmwButton>
        </div>
      )}
    </StyledOrgRole>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { perspectiveSelector, schemaSelector } from "../../redux/selectors";
import { StyledDiagram } from "./style";
import useLink from "./hooks/useLink";
import DiagramHeader from "./components/DiagramHeader/DiagramHeader";
import DiagramBody from "./components/DiagramBody/DiagramBody";

export default function Diagram() {
  useLink();
  const perspective = useSelector(perspectiveSelector);
  const schema = useSelector(schemaSelector);

  return (
    <>
      <StyledDiagram container direction="column" id="diagram" wrap="nowrap">
        <DiagramHeader perspective={perspective} schema={schema} />
        <DiagramBody perspective={perspective} schema={schema} />
      </StyledDiagram>
    </>
  );
}

import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme();

export const appBarHeight = 50;

export const thalesPaletteTheme = {
  //blue
  primaryBlueColor: "#478FC8",
  lightBlueColor: "#478FC899",
  hoverBlueColor: "#478FC87a",
  extraLightBlueColor: "#478fc84d",
  inactiveBlueColor: "#478FC829",
  darkBlueColor: "#080b4a",

  //gray
  black: "#000000",
  darkGreyColor: "#696969",
  defaultGreyColor: "#909191",
  borderGreyColor: "#E0E0E0",
  lightGreyColor: "#F1F3F6",
  backgroundGreyColor: "#F7F8F9",
  white: "#FFFFFF",

  //other
  redColor: "#F44336",
  lightRedColor: "#FFCDD2",
  orangeColor: "#FF9800",
  greenColor: "#46B969",
};

export const iconStyles = {
  marginRight: 24,
};

export const textDimensions = {
  large: "1.25rem",
  medium: "1rem",
  small: "0.875rem",
  xSmall: "0.750rem",
};

export const iconDimensions = {
  small: "16px",
  medium: "24px",
  large: "32px",
  reduceViewSize: "38px",
  xlarge: "64px",
};

const fontStyle = {
  regular: {
    fontStyle: "normal",
    fontWeight: 400,
  },
  medium: {
    fontStyle: "normal",
    fontWeight: 500,
  },
};

const fonts = {
  xSmallText: {
    ...fontStyle.regular,
    fontSize: textDimensions.xSmall,
  },
  smallText: {
    ...fontStyle.regular,
    fontSize: textDimensions.small,
  },
};

const grid = {
  height: {
    x1: "160px",
    x2: "410px",
  },
};

const widget = {
  width: 250,
  height: 250,
};

const header = {
  height: "60px",
  contentHeight: "40px",
};

const footer = {
  height: "60px",
};

const iconButton = {
  small: {
    button: {
      width: 32,
      height: 32,
      padding: "6px",
      zIndex: "initial", // This forces tooltip display on top of the button
    },
    icon: {
      width: 20,
      height: 20,
    },
  },
  medium: {
    button: {
      width: 72,
      height: 72,
      padding: 16,
    },
    icon: {
      width: 36,
      height: 36,
    },
  },
};

const input = {
  height: "15px",
  lineHeight: "15px",
  fontSize: "13px",
  padding: "5px 3px",
  color: thalesPaletteTheme.black,
  border: `1px solid ${thalesPaletteTheme.borderGreyColor}`,
  borderRadius: "4px",
  boxShadow: "0px 0px",
};

// This replaces the textColor value on the palette
// and then update the keys for each component that depends on it.
// More on Colors: http://www.material-ui.com/#/customization/colors
export const thalesTheme = createTheme({
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#041295",
      50: "#e6e7f4",
      100: "#cdd0ea",
      200: "#9ba1d5",
      300: "#6871bf",
      400: "#3642aa",
      500: "#041295",
      600: "#030f77",
      700: "#020b59",
      800: "#02083c",
      900: "#01041e",
    },
    secondary: {
      main: "#00bcdd",
      50: "#e6f8fc",
      100: "#ccf2f8",
      200: "#99e4f1",
      300: "#66d7eb",
      400: "#33c9e4",
      500: "#00bcdd",
      600: "#0096b1",
      700: "#007185",
      800: "#004b58",
      900: "#00262c",
    },
    bluegrey: {
      main: "#5d607e",
      25: "#f7f7f9",
      50: "#eeeff3",
      100: "#dedee6",
      200: "#bcbece",
      300: "#9a9db5",
      400: "#797d9c",
      500: "#5d607e",
      600: "#4a4d65",
      700: "#383a4b",
      800: "#252733",
      850: "#1c1d26",
      900: "#131319",
    },
    red: {
      main: "#e01e00",
      50: "#fce9e6",
      100: "#f9d2cc",
      200: "#f3a599",
      300: "#ec7866",
      400: "#e64b33",
      500: "#e01e00",
      600: "#b31800",
      700: "#861200",
      800: "#5a0c00",
      900: "#2d0600",
    },
    orange: {
      main: "#e01e00",
      50: "#fcf2e6",
      100: "#f9e4cc",
      200: "#f3c999",
      300: "#ecaf66",
      400: "#e69433",
      500: "#e07900",
      600: "#b36100",
      700: "#864900",
      800: "#5a3000",
      900: "#2d1800",
    },
    white: {
      main: "#ffffff",
    },
    text: {
      primary: "#696969",
    },
    ...thalesPaletteTheme,
  },
  size: textDimensions,
  iconSize: iconDimensions,
  iconButton,
  input,
  appBar: {
    height: appBarHeight,
  },
  drawer: {
    color: thalesPaletteTheme.white,
  },
  menuItem: {
    hoverColor: thalesPaletteTheme.lightGreyColor,
    selectedTextColor: thalesPaletteTheme.primaryBlueColor,
  },
  fonts,
  grid,
  widget,
  header,
  footer,
  popupTitle: {
    backgroundColor: thalesPaletteTheme.primaryBlueColor,
    padding: "5px 0px 5px 15px",
    color: thalesPaletteTheme.white,
  },
  typography: {
    h1: {
      fontFamily: "Roboto",
      fontSize: "3rem",
      lineHeight: "4.5rem",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Roboto",
      fontSize: "2.5rem",
      lineHeight: "3.75rem",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "Roboto",
      fontSize: "2rem",
      lineHeight: "3rem",
      fontWeight: 700,
    },
    "body-1": {
      fontFamily: "Roboto",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 400,
    },
    "body-2": {
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: 400,
    },
    "caption-1": {
      fontFamily: "Roboto",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      fontWeight: 400,
    },
    "caption-2": {
      fontFamily: "Roboto",
      fontSize: "0.625rem",
      lineHeight: "1rem",
      fontWeight: 400,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: "#041295",
        },
        tooltip: {
          padding: "9px",
          backgroundColor: "#041295",
          zIndex: 5000,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "12px",
          fontFamily: "Roboto",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        paddingBottom: "25px",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontSize: "12px",
        },
        paper: {
          padding: 0,
        },
        option: {
          padding: "3px 5px!important",
          fontSize: "13px",
        },
        noOptions: {
          padding: "3px 5px",
          fontSize: "13px",
        },
        loading: {
          padding: "3px 5px!important",
          fontSize: "13px!important",
        },
      },
      defaultProps: {
        size: "small",
      },
    },
  },
});

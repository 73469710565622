import React from "react";
import { styled } from "@mui/material/styles";
import { TdsCheckbox } from "@tds/react";
import { InputLabel } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
}));

const InputLabelStyled = styled(InputLabel)(({ theme }) => ({
  marginLeft: 5,
  fontSize: "1.1rem",
  whiteSpace: "nowrap",
}));

export default function EmwCheckbox(props) {
  const {
    size,
    disabled,
    checked,
    indeterminate,
    required,
    error,
    inputId,
    color,
    name,
    classes,
    label,
    onCheckBoxChanged,
  } = props;

  const onCheckBoxClicked = event => {
    if (typeof onCheckBoxChanged === "function") {
      onCheckBoxChanged(event, inputId);
    }
  };

  return (
    <Root>
      <TdsCheckbox
        size={size}
        disabled={disabled}
        checked={checked}
        indeterminate={indeterminate}
        required={required}
        error={error}
        inputId={inputId}
        color={color}
        name={name}
        classes={classes}
        onInput={onCheckBoxClicked}
      >
        <InputLabelStyled>{label}</InputLabelStyled>
      </TdsCheckbox>
    </Root>
  );
}

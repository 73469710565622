import React, { useCallback, useEffect, useState } from "react";
import { StyledDataCollection } from "./styled";
import MultipleBarChart from "../../components/EmwCharts/components/BarChart/MultipleBarChart";
import { Grid } from "@mui/material";
import Status from "./components/Status/Status";
import {
  getOrganizationsDataCollectionIndicatorsApi,
  getOrganizationsDataCollectionStatusApi,
} from "./redux/api";
import useChartFilters from "../../components/EmwCharts/hooks/useChartFilters";
import useChartUtils from "../../components/EmwCharts/hooks/useChartUtils";
import ChartFilters from "../../components/EmwCharts/components/Filters/ChartFilters";
import { defs, fill } from "./constants";
import useAppSnackbar from "../../hooks/useAppSnankbar";
import EmwTable from "../../components/EmwTable/EmwTable";
import DiagramContextProvider from "../organization/components/Diagram/context/DiagramContext";

const colorMapping = {
  eei: {
    color: "#0F186E",
  },
  wfb: {
    color: "#6871BF",
  },
};

const info = [
  { text: "EEI", color: "#0F186E" },
  { text: "WFB", color: "#6871BF" },
];

export default function DataCollection({}) {
  const snackbar = useAppSnackbar();
  const chartUtils = useChartUtils({ colorMapping });
  const { filters, setFilters, getChildOf } = useChartFilters();
  const [chartData, setChartData] = useState({
    dataCollection: [],
    actualsDataCollection: [],
  });
  const [statusData, setStatusData] = useState();

  useEffect(() => {
    // if (filters.loaded) {
    (async () => {
      await fetchIndicators();
      await fetchCollectionStatus();
    })();
    // }
  }, [filters.filterValues]);

  const fetchIndicators = async () => {
    try {
      const response = await getOrganizationsDataCollectionIndicatorsApi(filters.filterValues);
      transformData(response.data);
    } catch (reason) {
      snackbar.show(reason.message, "warning");
    }
  };

  const fetchCollectionStatus = async () => {
    try {
      const response = await getOrganizationsDataCollectionStatusApi(filters.filterValues);
      console.log(statusData);
      setStatusData(response.data);
    } catch (reason) {
      snackbar.show(reason.message, "warning");
    }
  };

  function transformData(data) {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentQuarter = chartUtils.getQuarter(date);
    const dataCollection = chartUtils
      .formatData(data.dataCollection, currentQuarter, currentYear)
      .map(mapForChart);
    const actualsDataCollection = chartUtils
      .formatData(data.actualsDataCollection, currentQuarter, currentYear)
      .map(mapForChart);

    function mapForChart(barData) {
      return {
        label: barData.label,
        ...barData.value,
      };
    }

    setChartData(currentChartData => {
      return {
        ...currentChartData,
        dataCollection,
        actualsDataCollection,
      };
    });
  }

  return (
    <DiagramContextProvider>
      <StyledDataCollection className="bg-bluegrey-25 px-3xl">
        <div className="flex flex-col flex-1 mt-3xl">
          <ChartFilters filters={filters} setFilters={setFilters} getChildOf={getChildOf} />
          <div className="flex flex-wrap flex-1 justify-between overflow-hidden">
            <div className="flex flex-col flex-1 charts-container px-xxs">
              <div className="mb-xs">
                <Grid container spacing={1}>
                  <Grid item sm={12} md={6}>
                    <MultipleBarChart
                      data={chartData.dataCollection}
                      name="Data collection evolution"
                      keys={["eei", "wfb"]}
                      colorMapping={colorMapping}
                      defs={defs}
                      fill={fill}
                      info={info}
                      tooltipInfo={
                        <>
                          <p className="mb-xxs">Tooltip info</p>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <MultipleBarChart
                      data={chartData.actualsDataCollection}
                      name="Actuals Data collection evolution"
                      keys={["eei", "wfb"]}
                      colorMapping={colorMapping}
                      defs={defs}
                      fill={fill}
                      info={info}
                      tooltipInfo={
                        <>
                          <p className="mb-xxs">Tooltip info</p>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="mb-xs flex-1">
                <Status
                  title={`Data collection status for Q${chartUtils.getQuarter(
                    new Date()
                  )} ${new Date().getFullYear()}`}
                  tooltipInfo={"Data collection status"}
                  data={statusData}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledDataCollection>
    </DiagramContextProvider>
  );
}

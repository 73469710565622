import React from "react";
import { Box, Grid } from "@mui/material";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";
import HeadCountJobFamilyIcon from "../../../../../../../../../lib/icons/HeadCountJobFamilyIcon";
import { HeadCount } from "../../../../../../constants";
import EmwTooltip from "../../../../../../../../components/EmwToggleMenu/EmwTooltip";
import EmwTag from "../../../../../../../../components/EmwTag/EmwTag";

export default function HeadCountJobFamily({ data }) {
  const title = () => {
    return (
      <>
        <EmwTypography fontSize={7} fontWeight="bold" classes="mb-xs">
          {HeadCount.EJF_HC.title}
        </EmwTypography>
        <Box sx={{ borderBottom: "2px solid #ffffff" }} className="mb-s"></Box>
        <EmwTypography component="body-4">
          {data.section1.countryHc.map(obj => {
            return (
              <EmwTag
                classes="mr-xs mb-xs"
                size="small"
                dismissible={false}
                text={`${obj.name}: ${obj.counted}`}
                variant="STANDARD"
                color="#636BBE"
              />
            );
          })}
        </EmwTypography>
      </>
    );
  };
  return (
    <EmwTooltip maxWidth="570px"  title={title()}>
      <Grid item ml={2}>
        <Grid container direction="column" alignItems="center">
          <HeadCountJobFamilyIcon height="16" />
          <EmwTypography classes="text-bluegrey-600" fontSize="9">
            {data.section1.jfHC.value}
          </EmwTypography>
        </Grid>
      </Grid>
    </EmwTooltip>
  );
}

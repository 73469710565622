import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { TdsButton, TdsIcon } from "@tds/react";

const IconsArea = styled("div")(({ theme }) => ({
  display: "inline-block",
  width: "100%",
}));

const CancelArea = styled("div")(({ theme }) => ({}));

const TdsButtonStyled = styled(TdsButton)(({ theme }) => ({
  float: "right",
}));

const ContentArea = styled("div")(({ theme }) => ({
  minHeight: "1rem",
  padding: "0 0 3px 0.5rem",
  width: "100%",
}));

const Root = styled("div")(({ theme }) => ({}));

const ChildrenDiv = styled("div")(({ theme }) => ({
  width: "94%",
}));

export default function EmwRepeater(props) {
  const [collapsed, setCollapsed] = useState(typeof props.collapsed === "undefined" ? true : props.collapsed);

  const { inputId, onAdd, onDelete, onMoveUp, onMoveTop, title, sortVisible, visibleAddButton, deleteVisible } = props;

  const initialChildren = props.children.props.children;
  const [additionalData, setAdditionalData] = useState([]);
  const [idData, setIdData] = useState(["ChEl" + Math.random()]);

  const addButtonDisabled = props.disableAddButton ? props.disableAddButton : false;
  const addButtonVisible = typeof visibleAddButton === "undefined" || visibleAddButton;
  const showSortButtons = typeof sortVisible === "undefined" || sortVisible;
  const showDeleteButton = typeof deleteVisible === "undefined" || deleteVisible;

  const arrowClick = () => {
    setCollapsed(!collapsed);
  };

  const addClick = () => {
    if (onAdd) {
      onAdd(inputId);
    } else {
      const newData = [];
      const newId = [];
      additionalData.forEach((element, index) => {
        newData.push(element);
        newId.push(idData[index]);
      });
      newData.push(props.children);
      newId.push("ChEl" + Math.random());

      setAdditionalData(newData);
      setIdData(newId);
    }
    setCollapsed(false);
  };

  const moveUp = pos => {
    if (onMoveUp) {
      onMoveUp(inputId, pos);
    }
  };

  const moveTop = pos => {
    if (onMoveTop) {
      onMoveTop(inputId, pos);
    }
  };

  const cancelClick = pos => {
    if (onDelete) {
      onDelete(inputId, pos);
    } else {
      const newData = [];
      const newId = [];

      additionalData.forEach((element, index) => {
        if (index !== pos) {
          newData.push(element);
          newId.push(idData[index]);
        }
      });

      if (newData.length === 1) {
        setCollapsed(false);
      }

      setIdData(newId);
      setAdditionalData(newData);
    }
  };

  useEffect(() => {
    setAdditionalData(initialChildren);
  }, [initialChildren]);

  const styleHandler = index => {
    if (index === 0) {
      return { display: "flex" };
    }
    if (!collapsed) {
      return { display: "flex" };
    }
    return { display: "none" };
  };

  return (
    <Root key={inputId + "_" + Math.random() * 100000}>
      <IconsArea>
        <div
          style={{
            fontSize: "1.3rem",
            fontWeight: "bolder",
            marginLeft: "0px",
            whiteSpace: "nowrap",
            display: "inline-block",
            paddingLeft: "0.5rem",
          }}
        >
          {title}
        </div>
        {additionalData.length > 1 && (
          <TdsButtonStyled variant="ghost" color="primary" size="small" onClick={arrowClick}>
            <TdsIcon icon={collapsed ? "keyboard_arrow_right" : "keyboard_arrow_down"}></TdsIcon>
          </TdsButtonStyled>
        )}
        {addButtonVisible &&
          (addButtonDisabled ? (
            <TdsButtonStyled disabled={true} variant="ghost" color="primary" size="small">
              <TdsIcon icon="add"></TdsIcon>
            </TdsButtonStyled>
          ) : (
            <TdsButtonStyled variant="ghost" color="primary" size="small" onClick={addClick}>
              <TdsIcon icon="add"></TdsIcon>
            </TdsButtonStyled>
          ))}
      </IconsArea>
      {additionalData.map((element, index) => {
        return (
          <ContentArea style={styleHandler} key={inputId + "_ " + index}>
            <ChildrenDiv>{element}</ChildrenDiv>
            {additionalData.length > 1 && (
              <CancelArea>
                {element.props.children[0].props.value ? (
                  <React.Fragment>
                    {showDeleteButton && (
                      <TdsButtonStyled
                        variant="ghost"
                        color="primary"
                        size="small"
                        onClick={cancelClick.bind(this, index)}
                      >
                        <TdsIcon icon="cancel_presentation" title={"Delete item"}></TdsIcon>
                      </TdsButtonStyled>
                    )}
                    {showSortButtons && index > 0 && (
                      <React.Fragment>
                        <TdsButtonStyled
                          variant="ghost"
                          color="primary"
                          size="small"
                          onClick={moveUp.bind(this, index)}
                        >
                          <TdsIcon icon="expand_less" title={"Move one position up"}></TdsIcon>
                        </TdsButtonStyled>
                        <TdsButtonStyled
                          variant="ghost"
                          color="primary"
                          size="small"
                          onClick={moveTop.bind(this, index)}
                        >
                          <TdsIcon icon="keyboard_double_arrow_up" title={"Move to the top"}></TdsIcon>
                        </TdsButtonStyled>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {showDeleteButton && (
                      <TdsButtonStyled variant="ghost" disabled={true} color="primary" size="small">
                        <TdsIcon icon="cancel_presentation" title={"Remove item"}></TdsIcon>
                      </TdsButtonStyled>
                    )}
                    {showSortButtons && (
                      <TdsButtonStyled variant="ghost" disabled={true} color="primary" size="small">
                        <TdsIcon icon="expand_less" title={"Move one position up"}></TdsIcon>
                      </TdsButtonStyled>
                    )}
                    {showSortButtons && (
                      <TdsButtonStyled variant="ghost" disabled={true} color="primary" size="small">
                        <TdsIcon icon="keyboard_double_arrow_up" title={"Move to the top"}></TdsIcon>
                      </TdsButtonStyled>
                    )}
                  </React.Fragment>
                )}
              </CancelArea>
            )}
          </ContentArea>
        );
      })}
    </Root>
  );
}

import * as actions from "./actions";
import { getState, reduceAction } from "../../../../util/reducer";
import initialState from "./initialState";
import { REPORT_FILTERS, REPORT_FILTERS_HZA } from "./actions";

const reducerConfig = {
  [actions.CHARTS]: reduceAction,
  [actions.ALERTS]: reduceAction,
  [actions.COMMENTS]: reduceAction,
  [actions.CREATE_TARGET]: reduceAction,
  [actions.SHOULD_RENDER_LINES]: reduceAction,
  [actions.GENERAL_FILTERS]: reduceAction,
  [actions.CAN_ADD_TARGETS]: reduceAction,
  [actions.CAN_ADD_ALERTS]: reduceAction,
  [actions.USER_ORGANIZATIONS]: reduceAction,
  [actions.ALERT_HISTORY_MODAL]: reduceAction,
  [actions.MY_COMMENTS_HISTORY_MODAL]: reduceAction,
  [actions.POWER_BI_INITIALIZED]: reduceAction,
  [actions.SELECTED_REPORT]: reduceAction,
  [actions.IS_DRILLED]: reduceAction,
  [actions.COMMENTS_ROLES]: reduceAction,
  [actions.ACCESS_TOKEN]: reduceAction,
  [actions.REPORT_FILTERS]: reduceAction,
  [actions.REPORT_FILTERS_HZA]: reduceAction,
  [actions.EMBEDDED_URL]: reduceAction,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);

import { computeURL, fetch, fetchOptions, fetchOptionsUpload } from "../../../../util/fetch";

export const getOrganizationDetailsApi = async payload => {
  const url = computeURL("organization", "getOrganizationDetails/" + payload.id);
  const body = JSON.stringify(payload);
  const method = "POST";

  return fetch(url, fetchOptions({ method, body }));
};

export const updateOrganizationApi = async payload => {
  const url = computeURL("organization", "createUpdateOrganization");
  const body = JSON.stringify(payload);
  const method = "PUT";
  return fetch(url, fetchOptions({ method, body }));
};

export const getAllTreeNodesApi = async () => {
  const url = computeURL("data", "allTreesNodes");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const deleteOrganizationApi = async id => {
  const url = computeURL("organization", "deleteOrganization", id);
  const method = "DELETE";

  return fetch(url, fetchOptions({ method }));
};

export const getEmployeeEngineersApi = async (id, orgId, parentInEngineerActivity) => {
  const url = computeURL("employee", "employeeEngineers", id, orgId, parentInEngineerActivity);
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const publishOrganizationApi = async id => {
  const url = computeURL("organization", "publishOrganization", id);
  const method = "PUT";

  return fetch(url, fetchOptions({ method }));
};

export const getEmployeeDetailsApi = async id => {
  const url = computeURL("employee", "employeeDetails", id);
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const getCountriesApi = async id => {
  const url = computeURL("basicdata", "getCountries", id);
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const getUserApi = async () => {
  return fetch(computeURL("user", "shortUser"), fetchOptions());
};

export const addFavoriteOrganizationApi = async id => {
  const url = computeURL("organization", "addToFavorite", id);
  const method = "PUT";

  return fetch(url, fetchOptions({ method }));
};

export const deleteFavoriteOrganizationApi = async id => {
  const url = computeURL("organization", "removeFromFavorite", id);
  const method = "DELETE";

  return fetch(url, fetchOptions({ method }));
};

export const getOrgGuestDetailsApi = async payload => {
  const url = computeURL("organization", "getOrgGuestPowerGuest");
  const body = JSON.stringify(payload);
  const method = "POST";

  return fetch(url, fetchOptions({ method, body }));
};

export const updateOrgGuestDetailsApi = async payload => {
  const url = computeURL("organization", "updateOrgGuestPowerGuest");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const allocateCCApi = async payload => {
  const url = computeURL("organization", "allocateCC");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const getExternalProjectsApi = async id => {
  const url = computeURL("organization", "getExternalProjects", id);
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const getNewEngineersList = async id => {
  const url = computeURL("employee", "employeeEngineers", id);
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const getDisciplinesNamesApi = async payload => {
  const url = computeURL("organization", "getDisciplinesNames");
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const exportEEIApi = async payload => {
  const url = computeURL("eei", "exportEEI");
  const body = JSON.stringify(payload);
  const method = "POST";

  return fetch(url, fetchOptions({ method, body }));
};

//@TO-DO: uploadEEIApi should be removed, but check first
export const uploadEEIApi = async payload => {
  const url = computeURL("eei", "uploadEEI");
  const body = payload;
  const method = "PUT";

  return fetch(url, fetchOptionsUpload({ method, body }));
};

export const uploadWFBApi = async payload => {
  const url = computeURL("wfb", "uploadWfbFromWebForms");
  const body = payload;
  const method = "PUT";

  return fetch(url, fetchOptionsUpload({ method, body }));
};

export const uploadEeiFromWebFormsApi = async payload => {
  const url = computeURL("eei", "uploadEeiFromWebForms");
  const body = payload;
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const getEEILogsApi = async fileName => {
  const url = computeURL("eei", "getEEILogs", fileName);
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const getEEILogsWebFormApi = async fileName => {
  const url = computeURL("eei", "getEEILogsWebForm", fileName);
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};
export const getAllSurveys = async () => {
  const url = computeURL("survey", "allSurveysExtended/0");
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const getNewOrganizationDetailsApi = async orgType => {
  const url = computeURL("organization", "getNewOrganizationDetails", orgType);
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const getEeiDataForOrganizationApi = async payload => {
  const url = computeURL("eei", "getEeiDataForOrganization");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const getWfbDataForOrganizationApi = async payload => {
  const url = computeURL("wfb", "getWfbDataForOrganization");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const getAllCcsApi = async () => {
  const url = computeURL("organization", "getAllCcs");
  const method = "GET";

  return fetch(url, fetchOptions({ method }));
};

export const getAllBccsApi = async () => {
  const url = computeURL("organization", "bccConfiguration");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const getFixedPriceOutsourcingTargets = async () => {
  const url = computeURL("organization", "fixedPriceOutsourcingTargets");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const getOffshoringTargets = async () => {
  const url = computeURL("organization", "offshoringTargets");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const getUserEmailsMapping = async () => {
  const url = computeURL("user", "userEmailsMapping");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const getExternalEmployees = async () => {
  const url = computeURL("employee", "externalEmployees");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const getAdministrators = async () => {
  const url = computeURL("user", "administrators");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const saveAdministrator = async payload => {
  const url = computeURL("user", "saveAdministrator");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const saveUserEmailsMapping = async payload => {
  const url = computeURL("user", "saveUserEmailsMapping");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const saveExternalEmployees = async payload => {
  const url = computeURL("employee", "saveExternalEmployee");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const saveCcDetailsApi = async payload => {
  const url = computeURL("organization", "saveCCDetails");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const saveBccDetailsApi = async payload => {
  const url = computeURL("organization", "saveBccConfiguration");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const saveFixedPriceOutsourcingTargets = async payload => {
  const url = computeURL("organization", "saveFixedPriceOutsourcingTargets");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const saveOffshoringTargets = async payload => {
  const url = computeURL("organization", "saveOffshoringTargets");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const setEngineerActivityApi = async (id, isEngineer) => {
  const url = computeURL("employee", "setEngineerActivity", id, isEngineer);
  const method = "PUT";
  const body = JSON.stringify({ id, isEngineer });

  return fetch(url, fetchOptions({ method, body }));
};

export const getAllRoleGroupRightsApi = async () => {
  const url = computeURL("role", "roleGroupRightsConfiguration");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const saveRoleGroupRightsApi = async payload => {
  const url = computeURL("role", "saveRoleGroupRightsConfiguration");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

export const getKpiConfiguration = async () => {
  const url = computeURL("role", "kpiConfiguration");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const saveKpiConfiguration = async payload => {
  const url = computeURL("role", "saveKpiConfiguration");
  const body = JSON.stringify(payload);
  const method = "PUT";

  return fetch(url, fetchOptions({ method, body }));
};

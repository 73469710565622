import React from "react";
import "./styled";
import { StyledOrganizationHeaderInfo } from "./styled";
import CountryList from "./components/CountryList/CountryList";
import HeadCountsDeclared from "./components/HeadCountsDeclared/HeadCountsDeclared";
import EngDomains from "./components/EngDomains/EngDomains";
import { Grid } from "@mui/material";
import HeadCountJobFamily from "./components/HeadCountJobFamily/HeadCountJobFamily";
import HeadCountCalculated from "./components/HeadCountCalculated/HeadCountCalculated";

export default function OrganizationCardHeaderInfo({ data }) {
  return (
    <StyledOrganizationHeaderInfo item className="header-row header-elements" mt={1}>
      <Grid item container sm={8} alignItems="center">
        {data.section1.hc.visible && <HeadCountCalculated data={data} />}
        {data.section1.jfHC.visible && <HeadCountJobFamily data={data} />}
        {data.section1.hcDeclared.visible && <HeadCountsDeclared data={data} />}
        {data.section1.countries.visible && <CountryList data={data} />}
      </Grid>

      <Grid item sm={4}>
        <EngDomains data={data} />
      </Grid>
    </StyledOrganizationHeaderInfo>
  );
}

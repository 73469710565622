import React, { useEffect, useState } from "react";
import AdministrationToggle from "../../../../../AdministrationToggle/AdministrationToggle";
import { useLoadDependencies } from "../../../../../../../header/components/useLoadDependencies";
import { putUpdateSettingsApi } from "../../../../../../redux/api";
import useAppSnackbar from "../../../../../../../../hooks/useAppSnankbar";

export default function HZAUnderMaintenance({ user }) {
  const { init } = useLoadDependencies();
  const snackBar = useAppSnackbar();
  const [checked, setChecked] = useState(user.hzaUnderMaintenance);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChecked(user.hzaUnderMaintenance);
  }, [user.hzaUnderMaintenance]);

  const handleCheck = async () => {
    try {
      setLoading(true);
      setChecked(!checked);
      await putUpdateSettingsApi({ hzaUnderMaintenance: !user.hzaUnderMaintenance });
      await init();
    } catch (reason) {
      snackBar.show(reason.message, "error");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="flex justify-between mt-s">
        <p className="text-scale-6 text-blugrey-500">HZA under maintenance</p>

        <AdministrationToggle checked={checked} handleChecked={handleCheck} disabled={loading} />
      </div>
    </>
  );
}

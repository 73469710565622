import { styled } from "@mui/material/styles";
import { getZoom } from "../../utils/sizeUtils";

export const StyledDataCollection = styled("div")(() => {
  const zoom = getZoom();
  return {
    zoom: `${zoom}%`,
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    overflowX: "hidden",

    ".box-element": {
      borderRadius: "5px",
      background: "white",
      boxShadow: "0px 4px 5px 0px #01053214",
    },

    ".admin-tools": {
      ".box-element": {
        width: "1200px",
      },
      ".export-buttons": {
        width: "200px",
        "button, tds-button, p": {
          width: "100%",
        },
      },
    },

    ".left-column": {
      borderRight: "1px solid #B2B2B2",
      width: "50%",
    },
    ".right-column": {
      width: "50%",
    },

    ".charts-container": {
      height: "100%",
      overflow: "auto",
      overflowX: "hidden",

      ".MuiGrid-item": {
        height: "281px",
      },
    },
  };
});

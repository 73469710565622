const smallQ = ["For.", "Act."];
const bigQ = ["Tar.", "For.", "Act."];

export const getNestedHeaders = (data, tableData) => {
  const isMissingSheet = tableData.length === 4;
  const commonTabs = [0, 1];
  const specificTab = isMissingSheet ? 3 : 4;
  const tableWithoutTarget = [...commonTabs, specificTab].includes(data.activeTab);

  const columns = tableWithoutTarget ? smallQ : bigQ;
  const quarterColSpan = tableWithoutTarget ? 2 : 3;
  const topColSpan = tableWithoutTarget ? 8 : 12;
  const nestedHeaders = [
    [" ", " ", " ", " "],
    [" ", " ", " ", " "],
    [" ", " ", " ", " "],
    ["GBU", "BL", "Countries ", "CC "],
  ];
  if (data && data.labels) {
    for (let i = 0; i < data.labels.length; i++) {
      nestedHeaders[0].push({
        label: data.labels[i],
        colspan: topColSpan,
      });
      nestedHeaders[1].push({ label: data.year, colspan: topColSpan });
      nestedHeaders[2].push(
        { label: "Q1", colspan: quarterColSpan },
        { label: "Q2", colspan: quarterColSpan },
        { label: "Q3", colspan: quarterColSpan },
        { label: "Q4", colspan: quarterColSpan }
      );
    }

    nestedHeaders[2].forEach(quarter => {
      nestedHeaders[3].push(...columns);
    });
  }

  return nestedHeaders;
};

import React from "react";
import PublicLayout from "../../../../layout/PublicLayout/PublicLayout";
import { StyledEmwLogo, StyledLoginContainer, StyledLoginForm, StyledThalesLogo } from "../../../auth/styled";
import { Grid } from "@mui/material";
import ThalesLogo from "../../../../styles/images/icon/Thales_logo.svg";
import EMWLogo from "../../../../styles/images/icon/EMW_logo-01.svg";
import EmwTypography from "../../../components/EmwTypography/EmwTypography";
import EmwButton from "../../../components/EmwButton/EmwButton";

export default function LoggedOut() {
  const handleNewLogin = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <PublicLayout>
      <StyledLoginContainer id="login-container">
        <Grid container alignItems="center" justifyContent="center">
          <StyledThalesLogo src={ThalesLogo} alt="Thales" />
        </Grid>
        <StyledLoginForm item id="login-form" className="mx-md text-center flex flex-col items-center pb-l px-xxl">
          <Grid container alignItems="center" justifyContent="center">
            <StyledEmwLogo src={EMWLogo} alt="EMW Logo" />
          </Grid>
          <EmwTypography fontSize={8} classes="text-bluegrey-400 mt-xl">
            Thank you for using EMW!
          </EmwTypography>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} sx={{ "*": { width: "100%" } }}>
              <EmwButton size="small" classes="mt-l" onClick={handleNewLogin}>
                Login
              </EmwButton>
            </Grid>
          </Grid>
        </StyledLoginForm>
      </StyledLoginContainer>
    </PublicLayout>
  );
}

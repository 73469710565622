import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import useHzaSync from "../../../V2/features/hzaSync/useHzaSync";
import { setIsHza } from "../../../V2/features/footer/redux/actions";
import { biPropsSelector } from "../../../V2/features/header/redux/selectors";
import { embeddedUrlSelector } from "../../../V2/features/powerBi/redux/selectors";
import { QtmTab, QtmTabs } from "@qtm/react";
import { setReportDefaultPage } from "../../../V2/features/powerBi/hooks/useReportRendering";

const StyledTabsContainer = styled(Box)(() => ({
  position: "relative",
  height: "inherit",
  "tds-tabs": {
    height: "inherit",
  },
}));

const TABS = {
  COMPETITIVENESS: {
    name: "Competitiveness",
    slug: "competitiveness",
    disabled: false, // TODO laurentiu disable button
    id: "competitiveness",
  },
  OPERATING_MODEL: {
    name: "Operating Model",
    slug: "operating-model",
    disabled: false,
    id: "competitiveness-operating-model",
  },
  CRAFTMANSHIP: {
    name: "Craftmanship",
    slug: "craftmanship",
    disabled: true,
    id: "competitiveness-craftmanship",
  },
  DIGITAL_TRANSFORMATION: {
    name: "Digital Transformation",
    slug: "digital-transformation",
    disabled: true,
    id: "competitiveness-digital-transformation",
  },
  TALENTS_AND_CULTURE: {
    name: "Talents & Culture",
    slug: "talents-and-culture",
    disabled: false,
    id: "competitiveness-talents-and-culture",
  },
};

export default function EngineeringCompetitivenessMenu({ className }) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedReport, setSelectedReport] = useState(params.report);

  useEffect(() => {
    if (/\/engineeringcompetitivness\/(.*)/.test(location.pathname)) {
      return setShow(true);
    }

    return setShow(false);
  }, [location]);

  useEffect(() => {
    setSelectedReport(params.report);

    if (params.hasOwnProperty("report")) {
      dispatch(setIsHza(params.report === TABS.COMPETITIVENESS.slug));
    }
  }, [params]);

  const handleClick = async reportPage => {
    await setReportDefaultPage();

    if (reportPage !== TABS.COMPETITIVENESS.slug) {
      document.getElementById("myFrameHZA").style.display = "none";
    }

    if (reportPage === TABS.COMPETITIVENESS.slug) {
      document.getElementById("myFrameHZA").style.display = "block";
    }

    navigate({
      pathname: `/engineeringcompetitivness/${reportPage}`,
    });
  };

  const isSelected = reportPage => {
    if (selectedReport === reportPage) {
      return "qtm-active";
    }

    return "";
  };

  return (
    <StyledTabsContainer className={className}>
      {show && (
        <>
          <QtmTabs scrollable fullHeight scrollHorizontally>
            {Object.keys(TABS).map(key => (
              <QtmTab
                key={key}
                classes={isSelected(TABS[key].slug)}
                disabled={TABS[key].disabled}
                onClickedTabEvent={() => handleClick(TABS[key].slug)}
              >
                {TABS[key].name}
              </QtmTab>
            ))}
          </QtmTabs>
        </>
      )}
    </StyledTabsContainer>
  );
}

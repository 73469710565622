import { Dialog, styled } from "@mui/material";
import { StyledDialog } from "../../styled";
import { TdsDropdownSelectOption } from "@tds/react";

export const StyledAlertOption = styled(TdsDropdownSelectOption)(({ theme }) => ({
  ".alert-option": {
    display: "flex",
    // background: "white!important",
    alignItems: "center",

    "> div": {
      position: "relative",
      top: "-1px",
    },
  },
}));

export const StyledHistoryModal = styled(StyledDialog)(({ theme }) => ({
  ".MuiPaper-root": {
    borderRadius: "3px",
    maxWidth: "750px",
    // minHeight: "450px",

    ".dialog-title": {
      padding: "18px 16px 0px 16px",
      // maxHeight: "120px",
      overflow: "hidden",
    },

    ".orgName": {
      width: "102px",
    },

    ".filters": {
      // maxHeight: "47px",
      paddingBottom: "12px",

      ".type-selector, .visibility-selector": {
        position: "relative",
        top: "-3px",
        height: "35px",

        "tds-typography": {
          position: "relative",
          top: "3px",
        },
      },

      ".visibility-selector": {
        borderRight: "1px solid #DEDEE6",
      },

      ".type-selector": {
        maxWidth: "210px",
      },

      ".sort-filter": {
        borderLeft: "1px solid #DEDEE6",
        borderRight: "1px solid #DEDEE6",
      },

      ".qtm-text-input > input": {
        fontSize: "12px",
      },

      ".tds-dropdown-select": {
        maxWidth: "90px",
      },
    },

    ".MuiDialogContent-root": {
      padding: "0 16px",

      ".comment-history-item": {
        border: "2px solid white",
        borderRadius: "6px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
        // padding: "0 7px 0 7px",

        "&.not-read": {
          border: "2px solid #1D4395",
          ".comment-history-item-text": {
            cursor: "pointer",
          },
        },
      },
    },
  },
}));

import styled from "@emotion/styled";
import { Grid } from "@mui/material";

/**
 * id selector pattern = tour-[tourSection]-[tourElement]
 */
export const StyledTourElement = styled(Grid)(({ theme }) => ({
  "&#tour-orgCard-modalTile": {
    //#tour-orgCard-modalTile
    marginBottom: "-8px",
    marginRight: "-40px",
    marginLeft: "-12px",
    marginTop: "-16px",
    paddingBottom: "8px",
    paddingRight: "32px",
    paddingLeft: "12px",
    paddingTop: "16px",
  },
  "&#tour-orgCard-modalName": {
    marginBottom: "-12px",
    marginRight: "-8px",
    marginLeft: "-12px",
    marginTop: "-16px",
    paddingBottom: "12px",
    paddingRight: "8px",
    paddingLeft: "12px",
    paddingTop: "16px",
  },
  "&#tour-orgCard-countrySelectorId": {
    marginBottom: "-1px",
    marginRight: "-6px",
    marginLeft: "-6px",
    marginTop: "-5px",
    paddingBottom: "1px",
    paddingRight: "6px",
    paddingLeft: "6px",
    paddingTop: "5px",
  },
}));

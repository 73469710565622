import React, { cloneElement } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import "../style.css";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
  confirmCancel: false,
};

const defaultSteps = [

  {
    id: "tour-filterButtons",
    title: "Filter Buttons",
    text: [
      "These are the filter buttons, and they will help you visualise the graph By GBU or By Country perspective.",
    ],
    attachTo: { element: ".tour-filterButtons", on: "right-start" },
    classes: "",
    highlightClass: "highlightClass",
    buttons: [
      {
        type: "cancel",
        classes: "qtm-btn qtm-ghost qtm-neutral",
        text: "Cancel",
      },
      {
        type: "next",
        classes: "qtm-btn qtm-filled qtm-primary",
        text: "Next",
      },
    ],
  },
  {
    id: "tour-search",
    title: "Search Function",
    text: ["Use the Search function to find any person in the organization, any GBU, BL or CC."],
    attachTo: { element: ".tour-search", on: "right-start" },
    classes: "",
    highlightClass: "highlightClass",
    buttons: [
      {
        type: "cancel",
        classes: "qtm-btn qtm-ghost qtm-neutral",
        text: "Cancel",
      },
      // TODO: UNCOMMENT when By Country option will be available again
      //   type: "back",
      //   classes: "qtm-btn qtm-outline qtm-primary previous",
      //   text: "Previous",
      // },
      {
        type: "next",
        classes: "qtm-btn qtm-filled qtm-primary",
        text: "Next",
      },
    ],
  },
];

export default function OrganizationTour({ children }) {
  return (
    <ShepherdTour steps={defaultSteps} tourOptions={tourOptions}>
      {cloneElement(children, { tourContext: ShepherdTourContext })}
    </ShepherdTour>
  );
}

import { useEffect, useState } from "react";
import { ENVS } from "../../modules/landingpage/constants";

export default function useEnv() {
  const [env, setEnv] = useState("");

  useEffect(() => {
    setEnv(env => {
      const host = window.location.host;
      if (host.includes("localhost")) {
        return ENVS.DEV;
      }

      if (host.includes("thalesdigital.io") && host.includes("dev")) {
        return ENVS.DIGITAL_DEV;
      }

      if (host.includes("thalesdigital.io") && host.includes("test")) {
        return ENVS.DIGITAL_TEST;
      }

      if (host.includes("thalesdigital.io") && host.includes("int.emw")) {
        return ENVS.DIGITAL_INT;
      }

      if (host.includes("thalesdigital.io") && host.includes("ppd")) {
        return ENVS.DIGITAL_PPD;
      }

      if (host.includes("cloud.thales") && host.includes("dev")) {
        return ENVS.CLOUD_DEV;
      }

      if (host.includes("cloud.thales") && host.includes("test")) {
        return ENVS.CLOUD_TEST;
      }

      if (host.includes("cloud.thales") && host.includes("int-emw")) {
        return ENVS.CLOUD_INT;
      }

      if (host.includes("thalesdigital.io")) {
        return ENVS.DIGITAL;
      }

      if (host.includes("cloud.thales")) {
        return ENVS.CLOUD;
      }
    });
  }, []);

  return env;
}

import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { StyledBarChart } from "./styled";
import useChartUtils from "../../hooks/useChartUtils";
import ChartInfo from "../ChartInfo/ChartInfo";
import ChartTooltip from "../ChartTooltip/ChartTooltip";
import EmwButton from "../../../EmwButton/EmwButton";
import EmwTypography from "../../../EmwTypography/EmwTypography";
import ArrowOutlined from "../../../../../lib/icons/ArrowOutlined";

/**
 * @desc https://nivo.rocks/bar/
 */
export default function BarChart({
  name,
  data,
  info,
  tooltipInfo,
  comingSoon = false,
  unit = "%",
  hasDrillToDetailButton,
  handleClick,
  padding,
}) {
  const chartUtils = useChartUtils({});

  return (
    <StyledBarChart className="flex flex-col bg-white-100 rounded p-s shadow-s-strong relative">
      <div className="flex flex-row justify-between">
        <p className="text-scale-7 font-bold ml-s">{name}</p>
        <ChartTooltip title={tooltipInfo} />
      </div>
      <div className="flex-1 chart">
        <ResponsiveBar
          data={data}
          keys={["value"]}
          indexBy="label"
          margin={chartUtils.margin}
          axisLeft={chartUtils.axisLeft(unit)}
          padding={padding ? padding : 0.4}
          valueScale={{ type: "linear" }}
          colors="#0F186E"
          animate={true}
          enableLabel={true}
          label={d => `${d.value}${unit}`}
          tooltipLabel={tooltip => `${tooltip.data.label}`}
          valueFormat={value => `${value}${unit}`}
          labelTextColor={"white"}
          axisTop={null}
          axisRight={null}
          indexScale={{ type: "band", round: true }}
          minValue={0}
          maxValue={"auto"}
          renderWrapper={true}
        />
      </div>

      <ChartInfo info={info} comingSoon={comingSoon} />
      {hasDrillToDetailButton && (
        <div className="ml-auto drill-button">
          <EmwButton
            variant="filled"
            title="Drill to detail"
            id="drillToDetail"
            onClick={handleClick}
            classes={"rounded-md"}
          >
            <EmwTypography classes="mr-xxs">Drill to detail</EmwTypography>

            <ArrowOutlined />
          </EmwButton>
        </div>
      )}
    </StyledBarChart>
  );
}

import { useSnackbar } from "notistack";
import EmwButton from "../components/EmwButton/EmwButton";
import EmwIcon from "../components/EmwIcon/EmwIcon";

export default function useAppSnackbar() {
  const snackbar = useSnackbar();
  const { closeSnackbar } = useSnackbar();
  const show = (message, variant) => {
    snackbar.enqueueSnackbar(message, {
      variant,
      action: key =>
        variant === "warning" && (
          <EmwButton onClick={() => closeSnackbar(key)} classes="close-warning">
            <EmwIcon variant="outlined" size="medium" icon="close"></EmwIcon>
          </EmwButton>
        ),
      persist: variant === "warning" ? true : false,
      style: { whiteSpace: "pre-line" },
    });
  };

  return {
    show,
  };
}

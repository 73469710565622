import React from "react";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";
import ExportCcList from "../../../AdminToolsExport/components/ExportCcList/ExportCcList";
import ExportSurveyBtn from "../../../AdminToolsExport/components/ExportSurvey/ExportSurvey";
import ExportEHC from "../../../AdminToolsExport/components/ExportEHC/ExportEHC";
import ExportEJFHC from "../../../AdminToolsExport/components/ExportEJFHC/ExportEJFHC";
import { ROLES } from "../../../../../../../../auth/useUser";

export default function ExportSections({ user }) {
  return (
    <>
      <div className="mt-s">
        <EmwTypography classes="text-scale-6 text-blugrey-500">Specific Exports</EmwTypography>
      </div>
      <div className="flex flex-col mt-s export-buttons">
        <div className="mb-xs">
          <ExportCcList />
        </div>

        <div className="mb-xs">
          <ExportSurveyBtn />
        </div>

        {user.admin && (
          <>
            <div className="mb-xs flex-1">
              <ExportEHC />
            </div>
            <div className="mb-xs flex-1">
              <ExportEJFHC />
            </div>
          </>
        )}
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { TdsToggleSwitch } from "@tds/react";
import clx from "classnames";
import EmwDatePicker from "../../../../../../../lib/commonv2/EmwDatePicker";
import moment from "moment/moment";
import EmwDropdownSelect from "../../../../../../components/EmwDropdownSelect/EmwDropdownSelect";
import EmwDropdownSelectOption from "../../../../../../components/EmwDropdownSelect/EmwDropdownSelectOption";
import { StyledTimeframe } from "./styled";
import { DATE_TIME_FORMAT } from "../../../../../../features/organization/constants";
import { periodOptions } from "../../../../../../features/administration/components/UsageMonitoring/components/periodFilters";
import { getUsageMonitoringIndicatorsApi } from "../../../../../../features/administration/redux/api";
import useAppSnackbar from "../../../../../../hooks/useAppSnankbar";

export default function TimeFrame({ filters, setFilters, setActiveUsersData, isUsageMonitoring }) {
  const snackbar = useAppSnackbar();
  const mappedPeriods = periodOptions.map((value, index) => {
    return { key: index.toString(), value: value, active: false };
  });
  const periodValues = isUsageMonitoring
    ? mappedPeriods
    : [
        { key: "12", value: "12 months", active: false },
        { key: "6", value: "6 months", active: false },
        { key: "3", value: "3 months", active: false },
      ];
  const [checked, setChecked] = useState(true);
  const [isDefault, setIsDefault] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [startDate, setStartDate] = useState();
  const [period, setPeriod] = useState(filters.period);
  const [date, setDate] = useState(filters.date);
  let dates = [];

  const className = clx("text-scale-7", {
    "text-primary-500 font-bold": isDefault,
    "text-bluegrey-200": !isDefault,
  });

  const classNameCustom = clx("text-scale-7", {
    "text-primary-500 font-bold": isCustom,
    "text-bluegrey-200": !isCustom,
  });

  const classNameDropdownLabel = clx("text-scale-9 mr-xs", {
    "text-bluegrey-500": !isDefault,
    "text-bluegrey-200": isDefault,
  });

  useEffect(() => {
    setIsDefault(checked);
    setIsCustom(!checked);
  }, [checked]);

  const onValueChanged = event => {
    setChecked(event.detail.checked);
    // setFilters({
    //   ...filters,
    //   periodFilters: {
    //     ...filters.periodFilters,
    //     default: event.detail.checked,
    //   },
    // });
  };

  const handlePeriodChange = async event => {
    setPeriod(event.detail.valueChanged);
    const payload = {
      ...filters,
      periodFilters: {
        ...filters.periodFilters,
        period: event.detail.valueChanged,
      },
    };
    setFilters(payload);

    await fetchActiveUsers(payload);
  };

  const handleMonthChange = async event => {
    setDate(event.detail.valueChanged);
    const payload = {
      ...filters,
      periodFilters: {
        ...filters.periodFilters,
        date: event.detail.valueChanged,
      },
    };
    setFilters(payload);
    await fetchActiveUsers(payload);
  };

  const handleDateChange = date => {
    setFilters({
      ...filters,
      timeframe: {
        ...filters.timeframe,
        date: moment(date).format(DATE_TIME_FORMAT),
      },
    });
  };

  const fetchActiveUsers = async payload => {
    try {
      const response = await getUsageMonitoringIndicatorsApi(payload);
      setActiveUsersData(response.data);
    } catch (reason) {
      snackbar.show(reason.message, "warning");
    }
  };

  const generateDateOptions = months => {
    for (let i = 0; i < months; i++) {
      const date = moment().subtract(i, "months");
      dates.push(date.format("MMM YY"));
    }
    return dates;
  };

  return (
    <StyledTimeframe className="flex items-center ml-auto">
      <p className="text-scale-9 mr-xs">TIMEFRAME</p>
      <p className={className}>Default</p>
      <label className="flex items-center mr-xs" id="toggle">
        <TdsToggleSwitch
          labelPosition="left"
          checked={checked}
          onValueChanged={onValueChanged}
          classes="toggle"
        />
        <p className={classNameCustom}>Custom</p>
      </label>
      <label className="flex items-center mr-xs">
        <p className={classNameDropdownLabel}>DATE</p>
        {isUsageMonitoring && (
          <EmwDropdownSelect
            inputId="date"
            disabled={isDefault}
            options={generateDateOptions(21)}
            onValueChanged={handleMonthChange}
            placeholder={generateDateOptions(21)[0]}
            value={date}
            size="small"
          >
            {generateDateOptions(21).map((option, key) => (
              <EmwDropdownSelectOption key={key} value={option} />
            ))}
          </EmwDropdownSelect>
        )}
        {!isUsageMonitoring && (
          <EmwDatePicker
            inputId="date"
            date={startDate}
            disableInput
            handleChange={handleDateChange}
            maxDate={moment(new Date()).format(DATE_TIME_FORMAT)}
          />
        )}
      </label>

      <label className="flex items-center">
        <p className={classNameDropdownLabel}>PERIOD</p>
        <EmwDropdownSelect
          inputId="period"
          disabled={isDefault}
          options={generateDateOptions(21)}
          onValueChanged={handlePeriodChange}
          value={period}
          placeholder={periodValues[periodValues.length - 2].value}
          size="small"
        >
          {periodValues.map(option => (
            <EmwDropdownSelectOption key={option.key} value={option.value} active={option.active} />
          ))}
        </EmwDropdownSelect>
      </label>
    </StyledTimeframe>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import DiagramColumnSVGPath from "../DiagramColumnSVGPath/DiagramColumnSVGPath";
import DiagramNode from "../DiagramNode/DiagramNode";

export default function DiagramColumn({ nodes, handleClick, index }) {
  const ref = useRef();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [nodes]);

  return (
    <>
      {index > 0 && (
        <Grid item key={index} className="diagram-svg-column">
          <DiagramColumnSVGPath nodes={nodes} height={height} />
        </Grid>
      )}
      <Grid item ref={ref}>
        {nodes.map(node => {
          return (
            <DiagramNode
              key={`${node.type}-${node.code}-${node.id}`}
              id={`${node.type}-${node.code}-${node.id}`}
              index={index}
              node={node}
              hasSvg={index > 0}
              handleClick={handleClick}
            />
          );
        })}
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from "react";
import MaterialSnackbard from "@mui/material/Snackbar";

export default function Snackbar(props) {
  const { message } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message && message !== "") {
      setOpen(true);
    }
  }, [message]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MaterialSnackbard
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={6000}
      message={<span id="message-id">{message}</span>}
    />
  );
}

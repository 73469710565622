import React, { useState } from "react";
import { Box, DialogActions, DialogContent, Grid } from "@mui/material";
import NotificationIcon from "../../../../../lib/icons/NoyificationIcon";
import { TdsTypography } from "@tds/react";
import { EmwButton } from "../../../../../lib/common";
import EmwDialog from "../../../../../lib/commonv2/EmwDialog";
import UserUpdatedModalImage from "../../../../../styles/images/UserUpdatedModalImage.svg";
import NotificationBoxItem from "../../../../../modules/landingpage/components/NotificationBoxItem";
import { Root, Root2 } from "./styled";

export default function NotificationBox({ notifications }) {
  const [open, setOpen] = useState(false);

  if (notifications && notifications.list.data && notifications.list.data.notificationCount > 0) {
    return (
      <>
        <Root
          className="notification-box notification-box-highlight"
          container
          direction="column"
          alignItems="center"
        >
          <Grid item>
            <Grid container justifyContent="center" alignItems="center">
              <NotificationIcon />

              <Box ml={1} />

              <TdsTypography component="body-2" fontWeight="medium" classes={["text-black"]}>
                YOU HAVE {notifications.list.data.notificationCount} NEW UPDATES
              </TdsTypography>
            </Grid>
          </Grid>

          <Grid item mt={2} />

          <Grid item>
            <EmwButton id="btnViewUpdates" variant="filled" onClick={() => setOpen(true)}>
              View Updates
            </EmwButton>
          </Grid>
        </Root>
        {open && (
          <EmwDialog title="Updates about your organization" open={open} size="800px">
            <DialogContent
              sx={{
                height: "auto",
                maxHeight: "500px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ maxWidth: "1000px" }}
              >
                <Grid item sm={12}>
                  <img
                    src={UserUpdatedModalImage}
                    style={{ width: "100%", height: "auto", maxHeight: "151px" }}
                    alt="Mood and confidence"
                  />
                </Grid>
                {notifications.list.data.notifications.map((notification, index) => (
                  <NotificationBoxItem
                    key={notification.id || index}
                    notification={notification}
                    title={notification.info}
                    index={index + 1}
                  />
                ))}
              </Grid>
            </DialogContent>

            <DialogActions>
              <EmwButton variant="filled" onClick={() => setOpen(false)}>
                Close
              </EmwButton>
            </DialogActions>
          </EmwDialog>
        )}
      </>
    );
  }

  return (
    <Root2 className="notification-box-highlight" mb={4}>
      <TdsTypography component="body-2" fontWeight="medium" classes={["text-black"]}>
        You currently have no updates <br /> since your last login.
      </TdsTypography>
    </Root2>
  );
}

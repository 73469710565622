import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, selectClasses } from "@mui/material";
import { ENGINEER_TYPE } from "../../../../../constants";
import ExportEngineersList from "./Section1/ExportEngineersList";
import ExportOrganizationList from "./Section2/ExportOrganizationList";
import { ExportOrganizationContext } from "../context/ExportOrganizationContext";
import { StyledExportReferentsHead, SelectAllRefs } from "./styled";
import { TdsIcon } from "@tds/react";
import EmwTypography from "../../../../../../../components/EmwTypography/EmwTypography";
import { EmwCheckbox } from "../../../../../../../../lib/common";
import TourElement from "../../../../../../../components/Tour/TourElement/TourElement";

const ems = [
  {
    value: "emRequested",
    label: "EM",
    title: ENGINEER_TYPE.EM.title,
    displayColor: false,
  },
  {
    value: "hoeoRequested",
    label: "HoEO",
    title: ENGINEER_TYPE.HoEO.title,
    displayColor: false,
  },
  {
    value: "kpisRequested",
    title: ENGINEER_TYPE.KPI.title,
    label: ENGINEER_TYPE.KPI.label,
    defaultColor: false,
  },
];

const refs = [
  {
    value: "dataReferentRequested",
    title: ENGINEER_TYPE.DATARRef.title,
    label: "Data",
  },
  {
    value: "cybersecurityReferentRequested",
    title: ENGINEER_TYPE.CYBSRRef.title,
    label: "Cybersecurity",
  },
  {
    value: "ecodesignReferentRequested",
    title: ENGINEER_TYPE.ECODRRef.title,
    label: "Ecodesign",
  },
  {
    value: "uxDesignReferentRequested",
    title: ENGINEER_TYPE.UXDRRef.title,
    label: "UX Design",
  },
  {
    value: "dataProtectionReferentRequested",
    title: ENGINEER_TYPE.DPRef.title,
    label: "Data Protection",
  },
  {
    value: "makeBuyFootprintReferentRequested",
    title: ENGINEER_TYPE.MOBFRef.title,
    label: "Make or Buy & Footprint",
  },
  {
    value: "engineeringQualityReferentRequested",
    title: ENGINEER_TYPE.EQ.title,
    label: "Engineering Quality",
  },
  {
    value: "otherReferentRequested",
    title: ENGINEER_TYPE.OTHERRef.title,
    label: "Other",
  },
];

const deliveries = [
  {
    value: "deliveriesRequested",
    delivery: {
      name: "Delivery",
      title: "Delivery",
      value: "deliveryNameRequested",
    },
    leader: {
      name: "HoED",
      title: ENGINEER_TYPE.HoED.title,
      label: ENGINEER_TYPE.HoED.label,
      value: "hoedRequested",
    },
    edmManager: {
      name: "EDM",
      title: ENGINEER_TYPE.EDM.title,
      label: ENGINEER_TYPE.EDM.label,
      value: "edmManagerRequested",
    },
    wpmManager: {
      name: "WPM",
      title: ENGINEER_TYPE.WPM.title,
      label: ENGINEER_TYPE.WPM.label,
      value: "wpmManagerRequested",
    },
    solutions: [
      {
        value: "solutionsRequested",
        da: {
          name: "DA",
          title: ENGINEER_TYPE.DA.title,
          label: ENGINEER_TYPE.DA.label,
          value: "daRequested",
        },
        pda: {
          name: "PDA",
          title: ENGINEER_TYPE.PDA.title,
          label: ENGINEER_TYPE.PDA.label,
          value: "pdaRequested",
        },
        leader: {
          name: "EDM",
          title: ENGINEER_TYPE.EDM.title,
          label: ENGINEER_TYPE.EDM.label,
          value: "edmRequested",
        },
        wpms: [
          {
            value: "wpRequested",
            leader: {
              name: "WPM",
              title: ENGINEER_TYPE.WPM.title,
              label: ENGINEER_TYPE.WPM.label,
              value: "wpmRequested",
            },
          },
        ],
      },
    ],
  },
];

const disciplines = [
  {
    value: "disciplinesRequested",
    discipline: {
      name: "Discipline",
      value: "disciplineNameRequested",
      title: "Discipline",
    },
    leader: {
      name: "HoD",
      title: ENGINEER_TYPE.HoD.title,
      label: ENGINEER_TYPE.HoD.label,
      value: "hodRequested",
    },
    engineers: {
      name: "Eng",
      title: "Engineers",
      value: "engineersRequested",
    },
  },
];

const initialChecks = refs.map(item => item.value);

export default function ExportOrganizationSectionLeft() {
  const context = useContext(ExportOrganizationContext);
  const [selectAllRefs, setSelectAllRefs] = useState(true);
  const [selectedRefs, setSelectedRefs] = useState(initialChecks);
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    if (selectedRefs.length === 0) {
      setSelectAllRefs(false);
      setIndeterminate(false);
    }
    if (selectedRefs.length === initialChecks.length) {
      setSelectAllRefs(true);
      setIndeterminate(false);
    }
    if (selectedRefs.length > 0 && selectedRefs.length < initialChecks.length) {
      setSelectAllRefs(false);
      setIndeterminate(true);
    }
  }, [selectedRefs]);

  const handleEmClick = (event, value) => {
    const { checked } = event.target;
    context.setEngRequested(value, checked);
  };

  const handleRefClick = (event, value) => {
    const { checked } = event.target;
    context.setRefsRequested(value, checked);
    const isIncluded = selectedRefs.includes(value);
    if (checked && selectedRefs) {
      setSelectedRefs([...selectedRefs, value]);
    }
    if (!checked || isIncluded) {
      const updatedChecks = selectedRefs.filter(item => item !== value);
      setSelectedRefs(updatedChecks);
    }
  };

  const handleSelectAllRefs = event => {
    const { checked } = event.target;
    setSelectAllRefs(true);
    setIndeterminate(false);
    checked ? setSelectedRefs(initialChecks) : setSelectedRefs([]);
    refs.map(ref => {
      context.setRefsRequested(ref.value, checked);
    });
  };

  const renderEms = () => {
    return ems.map(item => {
      return (
        <ExportEngineersList
          label={item.label}
          value={item.value}
          title={item.title}
          handleClick={handleEmClick}
          checked={context.state[item.value]}
        />
      );
    });
  };

  const renderRefs = () => {
    return refs.map(item => {
      return (
        <ExportEngineersList
          label={item.label}
          value={item.value}
          title={item.title}
          handleClick={handleRefClick}
          checked={context.state[item.value]}
        />
      );
    });
  };

  const renderDeliveries = () => {
    return (
      <ExportOrganizationList
        name="DELIVERY DISCIPLINE"
        value={deliveries[0].value}
        list={deliveries}
        icon={"construction"}
      />
    );
  };

  const renderDisciplines = () => {
    return (
      <ExportOrganizationList
        name="DISCIPLINES"
        value={disciplines[0].value}
        list={disciplines}
        icon={"factory"}
      />
    );
  };

  const renderHeaderReferents = () => {
    return (
      <Grid container id="export-refs-header" sx={{ display: "flex" }} xs={12}>
        <StyledExportReferentsHead container xs={9} id="export-refs-list-head">
          <Grid item mx={1}>
            <TdsIcon icon="construction" variant="outlined" classes="text-bluegrey-600"></TdsIcon>
          </Grid>
          <EmwTypography classes="text-bluegrey-600" fontSize="9">
            REFERENTS
          </EmwTypography>
        </StyledExportReferentsHead>
        <SelectAllRefs item ml={2} className="mt-xs ml-m">
          <EmwCheckbox
            size="small"
            // indeterminate={indeterminate}
            checked={selectAllRefs}
            value="referents"
            onCheckBoxChanged={handleSelectAllRefs}
          />
        </SelectAllRefs>
        <Grid item className="mt-xs">
          <EmwTypography fontSize="8" fontWeight="bold" classes="text-bluegrey-800">
            Select All
          </EmwTypography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <TourElement id="tour-export-list">
        <Grid item id="export-dialog-eng-list" className="pl-xxs">
          {renderEms()}
        </Grid>
        <TourElement id="tour-colors">
          <Grid item id="export-referents-list" className="pl-xxs pt-xxs pb-xxs">
            {renderHeaderReferents()}
          </Grid>

          <Grid item className="pl-xxs">
            {renderRefs()}
          </Grid>
        </TourElement>
      </TourElement>
      <Box borderBottom="2px solid #041295" className="mt-s mb-xs mr-l" />
      <TourElement id="tour-bottom-export">
        <Grid container>
          <Grid item xs={12} className="mt-xs pl-xxs mb-xs" id="export-dialog-deliveries-lists">
            {renderDeliveries()}
          </Grid>
          <Grid item xs={12} className="pl-xxs mb-xs" id="export-dialog-disciplines-lists">
            {renderDisciplines()}
          </Grid>
        </Grid>
      </TourElement>
    </>
  );
}

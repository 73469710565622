import React from "react";
import { styled } from "@mui/material/styles";
import backgroundImg from "./Bacgkround.svg";

const Wrapper = styled("div")(() => ({
  // height: "100vh",
  display: "flex",
  backgroundColor: "#ffffff",
  background: `url(${backgroundImg}) no-repeat center center fixed`,
  backgroundSize: "cover",
  height: "100%",
  overflow: "hidden",
  fontFamily: "Roboto",
}));

export default function PublicLayout({ children }) {
  return (
    <>
      <Wrapper>{children}</Wrapper>
    </>
  );
}

import { getState, reduceAction } from "../../../../util/reducer";
import * as actions from "./actions";
import { initialState } from "./initialState";

const reducerConfig = {
  [actions.IS_HZA]: reduceAction,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);

import React, { useEffect, useState } from "react";
import EmwTypography from "../../../EmwTypography/EmwTypography";
import TooltipAction from "../../../../features/organization/components/OrganizationCard/components/Section1/TooltipAction";

export default function CellEngineer({ cell, style, className }) {
  const [engineers, setEngineers] = useState([]);

  useEffect(() => {
    setEngineers(
      cell.value.map(engineer => {
        return (
          <div className="flex">
            <EmwTypography>{engineer.value}</EmwTypography>
            <TooltipAction em={null} employeeId={engineer.id} />
          </div>
        );
      })
    );
  }, [cell]);

  return <div className={className}>{engineers}</div>;
}

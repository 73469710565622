import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  schemaSelector,
  perspectiveSelector,
  orgDetailsSelector,
} from "../../../../redux/selectors";
import Warnings from "../Warnings/Warnings";
import AddToFavorites from "../AdToFavorite/AddToFavorites";
import AllocateCC from "../AllocateCC/AllocateCC";
import AddGuestPowerGuest from "../AddGuestPowerGuest/AddGuestPowerGuest";
import { OrganizationCardContext } from "../../context/OrganizationCardContextProvider";
import EditBtn from "../EditBtn/EditBtn";
import DeleteBtn from "../DeleteBtn/DeleteBtn";
import PublishBtn from "../PublishBtn/PublishBtn";
import AddOrganizationBtn from "../AddOrganizationBtn/AddOrganizationBtn";
import ExportOrganization from "../ExportOrganization/ExportOrganization";
import { ORGANIZATION_PERSPECTIVE } from "../../../../constants";
import ExportKPI from "../ExportKPI/ExportKPI";

export default function CardActions({ data, targetField, setTargetField, goToField }) {
  const context = useContext(OrganizationCardContext);
  const schema = useSelector(schemaSelector);
  const perspective = useSelector(perspectiveSelector);
  const orgDetails = useSelector(orgDetailsSelector);
  const [name, setName] = useState("");
  const isGroupCountry = orgDetails.list.data.cardDetails.type === "GROUP_COUNTRY";

  useEffect(() => {
    const regExp = /\((.*?)\)/;
    if (regExp.test(data.section1.name.value)) {
      const parts = regExp.exec(data.section1.name.value);
      if (parts[1]) {
        setName(parts[1]);
      } else {
        setName("");
      }
    } else {
      setName("");
    }
  }, [data]);

  const handleEditBtnHandler = () => {
    context.setFormModalOpen(true);
  };

  return (
    <Grid item xs={12} sm={"auto"} className="organization-card-actions tour-actions" ml={"auto"}>
      {data.section1.btnEdit.visible &&
        data.section1.warnings &&
        data.section1.warnings.length > 0 && (
          <Grid item ml={0.5} paddingBottom={"3px"} paddingTop="3px">
            <Warnings
              warnings={data.section1.warnings}
              goToField={goToField}
              handleEditBtnHandler={handleEditBtnHandler}
            />
          </Grid>
        )}
      {data.section1 && perspective === ORGANIZATION_PERSPECTIVE.BY_GBU && (
        <Grid
          item
          paddingBottom={"3px"}
          paddingTop="3px"
          sx={{ width: "32px", marginRight: "5px" }}
        >
          <ExportOrganization data={data} />
        </Grid>
      )}
      {data.section1.btnFavorite.visible && !["CBU"].includes(data.cardDetails.type) && (
        <Grid item ml={0.5} paddingBottom={"3px"} paddingTop="3px">
          <AddToFavorites
            name="btnFavorite"
            id={data.cardDetails.id}
            type={data.cardDetails.type}
            btn={data.section1.btnFavorite}
          />
        </Grid>
      )}
      {data.section1.btnAlocateCC.visible && (
        <Grid item ml={0.5} paddingBottom={"3px"} paddingTop="3px">
          <AllocateCC
            btn={data.section1.btnAlocateCC}
            data={data}
            name={name}
            cardName={data.section1.name.value}
            setName={setName}
          />
        </Grid>
      )}
      {data.section1.btnGuests.visible && (
        <Grid item ml={0.5} paddingBottom={"3px"} paddingTop="3px">
          <AddGuestPowerGuest btn={data.section1.btnGuests} data={data} />
        </Grid>
      )}
      {data.section1.btnDelete.visible && (
        <Grid item ml={0.5} paddingBottom={"3px"} paddingTop="3px">
          <DeleteBtn
            parentCountryId={schema.path.selectedCountry.id}
            btn={data.section1.btnDelete}
            id={data.cardDetails.id}
            name={data.section1.name.value}
          />
        </Grid>
      )}
      {data.section1.btnPublish.visible && (
        <Grid item ml={0.5} paddingBottom={"3px"} paddingTop="3px">
          <PublishBtn
            parentCountryId={schema.path.selectedCountry.id}
            btn={data.section1.btnDelete}
            id={data.cardDetails.id}
            name={data.section1.name.value}
            blCode={schema.path.selectedBL.code}
          />
        </Grid>
      )}
      {data.section1.btnAddChild.visible && (
        <Grid item ml={0.5} paddingBottom={"3px"} paddingTop="3px">
          <AddOrganizationBtn
            parentCountryId={schema.path.selectedCountry.id}
            btn={data.section1.btnAddChild}
            isOpen={context.formModalOpen}
            setIsOpen={context.setFormModalOpen}
            targetField={targetField}
            setTargetField={setTargetField}
          />
        </Grid>
      )}

      {data.section1.btnEEI.visible && !isGroupCountry && (
        <Grid item ml={0.5} paddingBottom={"3px"} paddingTop="3px">
          <ExportKPI btn={data.section1.btnEEI} data={data} />
        </Grid>
      )}
      {data.section1.btnEdit.visible && (
        <Grid item marginLeft="6px" padding={"3px"} className="tour-edit">
          <EditBtn
            parentCountryId={schema.path.selectedCountry.id}
            btn={data.section1.btnEdit}
            isOpen={context.formModalOpen}
            setIsOpen={context.setFormModalOpen}
            targetField={targetField}
            setTargetField={setTargetField}
          />
        </Grid>
      )}
    </Grid>
  );
}

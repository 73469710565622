import React from "react";
import { styled } from "@mui/material/styles";
import { TdsButton, TdsIcon } from "@tds/react";

export default function EmwButton({
  size,
  disabled,
  variant,
  inputId,
  id,
  classes,
  onClick,
  title,
  children,
  color,
  icon,
}) {
  const onButtonClick = event => {
    if (typeof onClick === "function") {
      onClick(event);
    }
  };

  return (
    <div className="flex">
      {disabled && (
        <TdsButton
          title={title ? title : ""}
          disabled={disabled}
          variant={variant}
          size={size}
          inputId={inputId}
          id={id ? id : ""}
          classes={classes}
        >
          {children}
        </TdsButton>
      )}

      {!disabled && (
        <TdsButton
          title={title ? title : ""}
          disabled={disabled}
          variant={variant}
          size={size}
          inputId={inputId}
          id={id ? id : ""}
          classes={classes}
          color={color ? color : "primary"}
          onClick={onButtonClick}
        >
          {icon && <TdsIcon classes="mr-xxs" icon={icon} />}
          {children}
        </TdsButton>
      )}
    </div>
  );
}

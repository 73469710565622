import { styled } from "@mui/material/styles";
import { getZoom } from "../../../utils/sizeUtils";

export const StyledFooter = styled("footer")(({ theme }) => {
  let zoom = getZoom();
  return {
    zoom: `${zoom}%`,
    backgroundColor: "#D9D9D933",
    display: "flex",
    alignItems: "center",
    padding: "10px 93px 10px 26px",
    flexWrap: "wrap",

    // ".hasTmpZoom": {
    //   zoom: `${zoom + 22}%`,
    // },

    ".footer-item": {
      position: "relative",
      zIndex: 2,
    },

    ".footer-env-info": {
      position: "absolute",
      left: 0,
      right: 0,
      textAlign: "center",
      zIndex: 1,
    },

    [theme.breakpoints.down("lg")]: {
      ".footer-env-info": {
        bottom: "26px",
      },
    },
  };
});

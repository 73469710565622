import React, { useEffect, useState } from "react";
import { createTargetApi, getAlertsApi, getCommentsApi, getTargetsApi } from "../redux/api";
import { cloneDeep } from "lodash";
import {
  CHARTS,
  ENGINEERING_COMPETITIVENESS,
  MOOD_AND_CONFIDENCE,
  PBI_BUTTON_TYPE,
  TALENTS_AND_CULTURES,
} from "../constants";
import {
  setCanAddAlerts,
  setCanAddTarget,
  setCommentsRoles,
  setShouldRenderLines,
  updateAlerts,
  updateCharts,
  updateComments,
} from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../hooks/useApi";
import { useLocation } from "react-router-dom-v5-compat";
import {
  alertsSelector,
  canAddAlertsSelector,
  canAddTargetsSelector,
  chartsSelector,
  commentsRolesSelector,
  commentsSelector,
} from "../redux/selectors";
import { userSelector } from "../../../../modules/header/selectors";
import useDraggable from "./useDraggable";
import { subscribe } from "../../../../modules/engineeringcompetitivness/components/events";
import { getFiltersForUser } from "../../../../util/biUtils";
import { models } from "powerbi-client";
import useHzaSync from "../../hzaSync/useHzaSync";

export default function usePowerBi(kpiName, iframeRef) {
  useDraggable();

  const totalMonthInChart = 12;
  const dispatch = useDispatch();
  const api = useApi();
  const location = useLocation();
  const canAddTargets = useSelector(canAddTargetsSelector);
  const canAddAlerts = useSelector(canAddAlertsSelector);
  const user = useSelector(userSelector);
  const chartsData = useSelector(chartsSelector);
  const alertsData = useSelector(alertsSelector);
  const commentsData = useSelector(commentsSelector);
  const commentsRoles = useSelector(commentsRolesSelector);
  const [chartsIndex, setChartIndex] = useState([]);

  const { pathname } = useLocation();

  /**
   * Listen to targets line drag and drop
   */
  subscribe("drag_event", event => {
    const { index, chartIndex, kpiName, chartName, value, charts } = event.detail;

    if (charts[kpiName][chartName].values[index] === value) {
      return;
    }

    charts[kpiName][chartName].values[index] = value;
    dispatch(updateCharts(charts));

    let inputValues;

    if (["dotd", "davd"].includes(chartName)) {
      inputValues = [...charts[kpiName][chartName].values].splice(8, 12);
    } else if (["keyProfileFit", "skillCoverage"].includes(chartName)) {
      inputValues = [...charts[kpiName][chartName].values].splice(2, 12);
    } else {
      inputValues = [...charts[kpiName][chartName].values].splice(4, 6);
    }

    const payload = {
      chartType: [event.detail.chartName],
      values: inputValues,
      generalFilters: getGeneralFilters(),
      timeframeFilters: getTimeframeFilters(),
      notify: false,
    };

    api.init(createTargetApi, payload, true);
  });

  useEffect(() => {
    if (iframeRef && iframeRef.current) {
      renderButtons();
    }
  }, [commentsRoles, iframeRef]);

  /**
   * Get current targets
   *
   * @param requestPayload
   * @param chartsData
   */
  const fetchTargets = (requestPayload, chartsData) => {
    api
      .init(getTargetsApi, requestPayload, true)
      .then(response => {
        const page = location.pathname;
        const charts = cloneDeep(chartsData);
        const {
          mood,
          confidenceToDeliver,
          davd,
          dotd,
          ecConfidenceToDeliver,
          skillCoverage,
          keyProfileFit,
          showTargetButton,
        } = response.data;
        const name =
          page === "/moodandconfidence" ? MOOD_AND_CONFIDENCE : ENGINEERING_COMPETITIVENESS;

        if (name === MOOD_AND_CONFIDENCE) {
          charts[name][CHARTS.MOOD].values = mood ? mood : [0, 0, 0, 0, 0, 0];
          charts[name][CHARTS.CONFIDENCE_TO_DELIVER].values = confidenceToDeliver
            ? confidenceToDeliver
            : [0, 0, 0, 0, 0, 0];
        }

        if (name === ENGINEERING_COMPETITIVENESS) {
          if (confidenceToDeliver) {
            charts[name][CHARTS.CONFIDENCE_TO_DELIVER].values = confidenceToDeliver;
          }

          if (davd) {
            charts[name][CHARTS.DAVD].values = davd;
          }

          if (dotd) {
            charts[name][CHARTS.DOTD].values = dotd;
          }

          if (mood) {
            charts[TALENTS_AND_CULTURES][CHARTS.MOOD].values = mood;
          }

          if (skillCoverage) {
            charts[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE].values = skillCoverage;
          }

          if (keyProfileFit) {
            charts[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT].values = keyProfileFit;
          }
        }

        dispatch(updateCharts(charts));
        dispatch(setShouldRenderLines(true));
        dispatch(setCanAddTarget(showTargetButton));
      })
      .catch(reason => {
        console.log(reason);
      });
  };

  /**
   * Get current alerts
   *
   * @param requestPayload
   * @param alertsData
   */
  const fetchAlerts = (requestPayload, alertsData) => {
    api.init(getAlertsApi, requestPayload, true).then(response => {
      const page = location.pathname;
      const alerts = cloneDeep(alertsData);

      const {
        mood,
        confidenceToDeliver,
        davd,
        dotd,
        ecConfidenceToDeliver,
        skillCoverage,
        keyProfileFit,
        showAlertButton,
      } = response.data;
      const name =
        page === "/moodandconfidence" ? MOOD_AND_CONFIDENCE : ENGINEERING_COMPETITIVENESS;

      if (name === MOOD_AND_CONFIDENCE) {
        alerts[name][CHARTS.MOOD].values = mood;
        alerts[name][CHARTS.CONFIDENCE_TO_DELIVER].values = confidenceToDeliver;
        alerts[ENGINEERING_COMPETITIVENESS][CHARTS.CONFIDENCE_TO_DELIVER].values =
          confidenceToDeliver;
      }

      if (name === ENGINEERING_COMPETITIVENESS) {
        alerts[name][CHARTS.DOTD].values = dotd;
        alerts[name][CHARTS.DAVD].values = davd;
        alerts[name][CHARTS.CONFIDENCE_TO_DELIVER].values = confidenceToDeliver;
        alerts[TALENTS_AND_CULTURES][CHARTS.MOOD].values = mood;
        alerts[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT].values = keyProfileFit;
        alerts[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE].values = skillCoverage;
      }

      dispatch(updateAlerts(alerts));
      dispatch(setShouldRenderLines(true));
      dispatch(setCanAddAlerts(showAlertButton));
    });
  };

  const filterComments = (comments, organization) => {
    return comments.filter(comment => {
      return comment.orgName === organization;
    });
  };

  const fetchComments = (requestPayload, commentsData) => {
    api.init(getCommentsApi, requestPayload, true).then(response => {
      const page = location.pathname;
      const comments = cloneDeep(commentsData);
      const { organization, mood, confidenceToDeliver, davd, dotd, skillCoverage, keyProfileFit } =
        response.data;
      const name =
        page === "/moodandconfidence" ? MOOD_AND_CONFIDENCE : ENGINEERING_COMPETITIVENESS;

      if (name === MOOD_AND_CONFIDENCE) {
        comments[name][CHARTS.MOOD].values = filterComments(mood, organization) || [];
        comments[name][CHARTS.CONFIDENCE_TO_DELIVER].values =
          filterComments(confidenceToDeliver, organization) || [];
      }

      if (name === ENGINEERING_COMPETITIVENESS) {
        comments[name][CHARTS.DOTD].values = filterComments(dotd, organization) || [];
        comments[name][CHARTS.DAVD].values = filterComments(davd, organization) || [];
        comments[name][CHARTS.CONFIDENCE_TO_DELIVER].values =
          filterComments(confidenceToDeliver, organization) || [];
        comments[TALENTS_AND_CULTURES][CHARTS.MOOD].values =
          filterComments(mood, organization) || [];
        comments[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT].values =
          filterComments(keyProfileFit, organization) || [];
        comments[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE].values =
          filterComments(skillCoverage, organization) || [];
      }

      dispatch(updateComments(comments));

      dispatch(
        setCommentsRoles({
          canAddActionPlan: response.data.canAddActionPlan,
          canAddComment: response.data.canAddComment,
          canAddRootCause: response.data.canAddRootCause,
          canMakePublic: response.data.canMakePublic,
        })
      );
    });
  };

  /**
   * Build a button for each chart
   *
   * @param type
   * @param index
   * @param fill
   * @param displayName
   * @param commentsColor
   * @returns {string}
   */
  const getButton = (type, index, fill, displayName, commentsColor) => {
    const filters = getGeneralFilters();

    const hasMultipleFilters = filters.find(filter => {
      return filter.value.length > 1;
    });

    if (hasMultipleFilters) {
      return "";
    }

    switch (type) {
      case PBI_BUTTON_TYPE.TARGET:
        if (!canAddTargets) {
          return "";
        }
        return `
          <div class="targetsButton text-orange-500" id="btn-target-${index}" style="margin: 2px 5px 0 5px; padding-top:2px; width:20px; height: 20px; cursor:pointer;" title="Add / Edit targets">
            <svg class="targetIconbutton" onclick="parent.iconClick('target', ${index}, '${displayName}')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.26 11.4551C6.41 8.27512 9.03 5.74512 12.25 5.74512C15.56 5.74512 18.25 8.43512 18.25 11.7451C18.25 14.9651 15.72 17.5851 12.54 17.7351L13.17 15.6351C14.94 15.2251 16.25 13.6351 16.25 11.7451C16.25 9.53512 14.46 7.74512 12.25 7.74512C10.36 7.74512 8.77 9.05512 8.36 10.8251L6.26 11.4551ZM12.25 21.7451C11.95 21.7451 11.65 21.7351 11.35 21.7051L11.94 19.7351C12.04 19.7451 12.15 19.7451 12.25 19.7451C16.67 19.7451 20.25 16.1651 20.25 11.7451C20.25 7.32512 16.67 3.74512 12.25 3.74512C7.83 3.74512 4.25 7.32512 4.25 11.7451C4.25 11.8451 4.25 11.9551 4.26 12.0551L2.29 12.6451C2.26 12.3451 2.25 12.0451 2.25 11.7451C2.25 6.22512 6.73 1.74512 12.25 1.74512C17.77 1.74512 22.25 6.22512 22.25 11.7451C22.25 17.2651 17.77 21.7451 12.25 21.7451ZM7.99 17.9751L9.25 21.7451L12.25 11.7451L2.25 14.7451L6.02 16.0051L1.75 20.2751L3.73 22.2551L7.99 17.9751Z" fill="${fill}"/>
            </svg>
          </div>
        `;

      case PBI_BUTTON_TYPE.ALERT:
        if (!canAddAlerts) {
          return "";
        }
        return `
          <div class="alertsButton" id="btn-alert-${index}" style="margin: 2px 5px 0 5px; padding-top: 2px; width:20px; height: 20px; cursor:pointer;" title="Add / Edit alerts">
              <svg onclick="parent.iconClick('alert', ${index}, '${displayName}')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 6.49L19.53 19.5H4.47L12 6.49ZM12 2.5L1 21.5H23L12 2.5ZM13 16.5H11V18.5H13V16.5ZM13 10.5H11V14.5H13V10.5Z" fill="${fill}"/>
              </svg>
          </div>
        `;

      case PBI_BUTTON_TYPE.COMMENT:
        if (!commentsRoles.canAddComment) {
          return "";
        }
        let notificationBullet = "";

        if (commentsColor) {
          notificationBullet = `<div style="position: absolute; background-color: ${commentsColor}; width: 9px; height: 9px; border-radius: 10px; margin-left: 17px; margin-top: -2px"></div>`;
        }

        return `
          <div class="commentsButton"  id="btn-comment-${index}" style="margin: 2px 5px 0 5px; padding-top: 3px; width:20px; height: 20px; cursor:pointer;" title="Add / Edit comments">
            ${notificationBullet}
            <svg onclick="parent.iconClick('comment', ${index}, '${displayName}')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path xmlns="http://www.w3.org/2000/svg" d="M21.99 4C21.99 2.9 21.1 2 20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H18L22 22L21.99 4ZM20 4V17.17L18.83 16H4V4H20ZM6 12H18V14H6V12ZM6 9H18V11H6V9ZM6 6H18V8H6V6Z" fill="#041295"/>
            </svg>
          </div>
        `;
    }
  };

  /**
   * Change kpi report based on sectionIndex
   *
   * @param iframeRef
   * @param targetSectionIndex
   */
  const changeReport = (iframeRef, targetSectionIndex) => {
    openAndSetFilters();

    iframeRef.current.containerRef.current.firstChild.contentWindow.document.explorationNavigation.legacyService.updateActiveSection(
      { targetSectionIndex: targetSectionIndex }
    );
  };

  /**
   * Callback used after each section change on bi action
   *
   * @param e
   * @param t
   * @param r
   */
  const sectionObjectsEvaluated = (e, t, r) => {
    if (r.displayName.includes("Default")) {
      renderButtons();
    }
  };

  /**
   * No longer in use
   *
   * @deprecated
   * @param parameterName
   * @returns {null}
   */
  const findGetParameter = parameterName => {
    let result = null;
    let tmp = [];

    const items = window.location.search.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
      tmp = items[index].split("=");
      if (tmp[0] === parameterName) {
        result = decodeURIComponent(tmp[1]);
      }
    }
    return result;
  };

  /**
   * Get and select current user org in the <i>Filters</i> section selected filter from the right sidebar
   */
  const openAndSetFilters = () => {
    if (isAdmin()) {
      return;
    }
    if (window.report) {
      async function changeFilters(filters) {
        await window.report.updateFilters(models.FiltersOperations.ReplaceAll, filters);
        // filterWorker(filters);
      }
      if (!window.filtersPBIAreSet) {
        const filters = getFiltersForUser(user);
        changeFilters(filters);
        window.filtersPBIAreSet = true;
      }
    } else if (document.querySelector("iframe")) {
      const myDoc = iframeRef.current.containerRef.current.firstChild.contentWindow.document;
      const myWindow = iframeRef.current.containerRef.current.firstChild.contentWindow;

      if (
        myDoc.querySelectorAll(".filterCardTitleSection") &&
        myDoc.querySelectorAll(".filterCardTitleSection").length > 0
      ) {
        if (
          user.list.data.orgsUnderMyResponsability &&
          user.list.data.orgsUnderMyResponsability[0]
        ) {
          const organization = user.list.data.orgsUnderMyResponsability[0].name;
          const orgType = user.list.data.orgsUnderMyResponsability[0].type;
          const type = orgType === "bu" ? 1 : orgType === "bl" ? 2 : 3;
          if (!window.filtersAreOpen) {
            myDoc.querySelectorAll(".filterCardTitleSection")[type].click();
            window.filtersAreOpen = true;
            setFilters(organization, type);
          }
        }
      }
    }
  };

  /**
   * Use on dynamicaly/manualy chosing a org filter
   *
   * @param organization
   * @param type
   */
  const setFilters = (organization, type) => {
    debugger;
    if (window.filtersAreSet) {
      return;
    }
    if (document.querySelector("iframe")) {
      const myDoc = iframeRef.current.containerRef.current.firstChild.contentWindow.document;
      const myWindow = iframeRef.current.containerRef.current.firstChild.contentWindow;

      myWindow.ccScroller = myDoc.querySelector(".scroll-scrolly_visible");
      if (myWindow.ccScroller) {
        if (myDoc.querySelectorAll(".scrollRegion .slicerItemContainer").length === 0) {
          setTimeout(
            function (organization) {
              setFilters(organization, type);
            },
            1,
            organization
          );
        } else {
          const chkItems = myDoc.querySelectorAll(".scrollRegion .slicerItemContainer");
          if (chkItems.length > 0) {
            const selectedChkIndex = Object.keys(chkItems).filter(
              key => chkItems[key].innerText === organization
            );
            if (chkItems[selectedChkIndex]) {
              if (!window.filtersAreSet) {
                chkItems[selectedChkIndex].click();
                myDoc.querySelectorAll(".filterCardTitleSection")[type].click();
                window.filtersAreSet = true;
                console.log("click on " + organization);
              }
            } else {
              if (chkItems[chkItems.length - 1].innerText === "Unknown") {
                myWindow.searchDirection = "up";
                myWindow.ccScroller.scrollTop -= 170;
              } else if (chkItems[0].innerText === "Select") {
                myWindow.searchDirection = "down";
              }

              if (myWindow.searchDirection === "up") {
                myWindow.ccScroller.scrollTop -= 170;
              } else {
                myWindow.ccScroller.scrollTop += 170;
              }

              setTimeout(
                function (organization) {
                  setFilters(organization, type);
                },
                150,
                organization
              );
            }
          } else {
            setTimeout(
              function (organization) {
                setFilters(organization, type);
              },
              1,
              organization
            );
          }
        }
      } else {
        setTimeout(
          organization => {
            setFilters(organization, type);
          },
          1,
          organization
        );
      }
    }
  };

  const getGeneralFilters = () => {
    debugger;
    if (!document.querySelector(".embed-container iframe")) {
      return;
    }
    const myDoc = document.querySelector(".embed-container iframe").contentWindow.document;
    const abc = myDoc.explorationNavigation
      .getCurrentExploration()
      .filters.filter(item => item.filter)
      .map(p => {
        return {
          type: p.expression.ref,
          value: p.filter.whereItems.map(arr => arr),
        };
      });

    return abc.map(p => {
      return { type: p.type, value: p.value[0].condition.values.map(i => i[0].value) };
    });
  };

  const getTimeframeFilters = () => {
    const myDoc = document.querySelector(".embed-container iframe").contentWindow.document;
    const filterArray = myDoc.querySelectorAll(".slicer-restatement");

    return Object.keys(filterArray).map(k => filterArray[k].innerHTML);
  };

  const isAdmin = () => {
    if (user.list && user.list.data && user.list.data.additionalUser) {
      return (
        (user.list &&
          user.list.data &&
          user.list.data.additionalUser.orgRoles.filter(p =>
            ["admin", "su"].includes(p.code.toLowerCase())
          ).length > 0) ||
        user.list.data.tgi === "T0009680" // TODO: Gypsy logic for temporarily fix
      );
    }
    return (
      (user.list &&
        user.list.data &&
        user.list.data.orgRoles.filter(p => ["admin", "su"].includes(p.code.toLowerCase())).length >
          0) ||
      (user.list.hasOwnProperty("data") && user.list.data.tgi === "T0009680") // TODO: Gypsy logic for temporarily fix
    );
  };

  const showTargets = () => {
    try {
      if (document.querySelector("iframe")) {
        const myDoc = document.querySelector("iframe").contentWindow.document;
        const generalFilters = getGeneralFilters();
        if (user.list) {
          // const isAdmin = user.list && user.list.data && user.list.data.orgRoles.filter(p => p.code.toLowerCase() === "admin").length > 0;
          if (
            !isAdmin() ||
            generalFilters.length === 0 ||
            generalFilters.length > 1 ||
            generalFilters[0].value.length > 1
          ) {
            myDoc.querySelectorAll(".targetsButton").forEach(but => {
              but.style.visibility = "hidden";
            });
            myDoc.querySelectorAll(".targetsline").forEach(but => {
              but.style.visibility = "hidden";
            });
            myDoc.querySelectorAll(".alert-svg").forEach(but => {
              but.remove();
              // but.style.visibility = "hidden"
            });
          } else {
            const myOrgs = user.list.data.orgRoles.filter(
              p => p.code === "EM" || p.code === "HOEO"
            );
            const myOrgsDetails = myOrgs.map(item => item.orgDetails);
            if (
              isAdmin() ||
              myOrgsDetails.filter(p => p.name === generalFilters[0].value[0]).length === 1
            ) {
              myDoc.querySelectorAll(".targetsButton").forEach(but => {
                but.style.visibility = "";
              });
              myDoc.querySelectorAll(".targetsline").forEach(but => {
                but.style.visibility = "";
              });
              myDoc.querySelectorAll(".alert-svg").forEach(but => {
                but.style.visibility = "";
              });
            } else {
              myDoc.querySelectorAll(".targetsButton").forEach(but => {
                but.style.visibility = "hidden";
              });
              myDoc.querySelectorAll(".targetsline").forEach(but => {
                but.style.visibility = "hidden";
              });
              myDoc.querySelectorAll(".alert-svg").forEach(but => {
                but.remove();
              });
            }
          }

          // Only allow the filter request for the default pages
          // TODO: Rethink thi restriction because in the future a chart name could include "Deefault"
          if (
            !myDoc.explorationNavigation.legacyService.currentSection.displayName.includes(
              "Default"
            )
          ) {
            // return;
          }

          const timeframeFilters = getTimeframeFilters();
          const requestPayload = {
            chartType: Object.keys(CHARTS).map(key => CHARTS[key]),
            generalFilters,
            timeframeFilters,
          };

          if (
            Array.isArray(generalFilters) &&
            (generalFilters.length > 0 ||
              (user.list.data &&
                user.list.data.orgRoles.filter(p => p.code.toLowerCase() === "admin").length > 0))
          ) {
            fetchTargets(requestPayload, chartsData);
            fetchAlerts(requestPayload, alertsData);
            fetchComments(requestPayload, commentsData);
            renderButtons();
          }
        }
      }
    } catch (ex) {
      const timeframeFilters = getTimeframeFilters();
      const generalFilters = getGeneralFilters();
      const requestPayload = {
        chartType: Object.keys(CHARTS).map(key => CHARTS[key]),
        generalFilters,
        timeframeFilters,
      };
      fetchTargets(requestPayload, chartsData);
      fetchAlerts(requestPayload, alertsData);
      fetchComments(requestPayload, commentsData);
      console.error(ex);
    }
  };

  const handleChangeFilters = () => {
    debugger;
    const generalFilters = getGeneralFilters();
    const timeframeFilters = getTimeframeFilters();
    const requestPayload = {
      chartType: Object.keys(CHARTS).map(key => CHARTS[key]),
      generalFilters,
      timeframeFilters,
    };

    if (
      Array.isArray(generalFilters) &&
      (generalFilters.length > 0 ||
        (user.list.data &&
          user.list.data.orgRoles.filter(p => p.code.toLowerCase() === "admin").length > 0))
    ) {
      fetchTargets(requestPayload, chartsData);
      fetchAlerts(requestPayload, chartsData);
      fetchComments(requestPayload, chartsData);
    }
    console.log(generalFilters);
  };

  const hasTargets = chart => {
    return !!chart.values.find(value => value > 0);
  };

  const hasAlerts = alert => {
    return alert.values.length > 0;
  };

  const hasComments = comments => {
    return comments.values.length > 0;
  };

  const getCommentNotificationColor = comments => {
    if (comments.values.find(comment => !comment.readIt)) {
      return "#E07900";
    }

    return "#041295";
  };

  const getSectionName = () => {
    const iframeContent = iframeRef.current.containerRef.current.firstChild.contentWindow.document;
    return iframeContent.explorationNavigation.legacyService.currentSection.displayName;
  };

  /**
   * Add buttons for each kpi graph
   */
  const renderButtons = () => {
    if (!iframeRef.current) {
      return;
    }

    try {
      const iframeContent =
        iframeRef.current.containerRef.current.firstChild.contentWindow.document;

      if (iframeContent.querySelector(".targetIconbutton")) {
        iframeContent.querySelectorAll(".targetIconbutton").forEach(el => el.remove());
      }

      if (!iframeContent.querySelector(".targetIconbutton")) {
        const titles = iframeContent.querySelectorAll(".visual-textbox");
        if (titles.length > 0) {
          titles.forEach((element, index) => {
            if (element.parentElement.querySelector(".element-buttons")) {
              element.parentElement.querySelector(".element-buttons").remove();
            }
            let targetsFill = "#041295";
            let alertsFill = "#041295";
            let commentsFill = "#041295";
            let commentBulletColor = false;
            let commentsCollor = "";
            const displayName = getSectionName();

            if (kpiName === MOOD_AND_CONFIDENCE && pathname === "/moodandconfidence") {
              if (index === chartsIndex[0]) {
                if (hasTargets(chartsData[MOOD_AND_CONFIDENCE][CHARTS.MOOD], CHARTS.MOOD)) {
                  targetsFill = "#E69433";
                }
                if (hasComments(commentsData[MOOD_AND_CONFIDENCE][CHARTS.MOOD], CHARTS.MOOD)) {
                  commentsFill = "#E69433";
                  commentsCollor = getCommentNotificationColor(
                    commentsData[MOOD_AND_CONFIDENCE][CHARTS.MOOD]
                  );
                }
                if (hasAlerts(alertsData[MOOD_AND_CONFIDENCE][CHARTS.MOOD], CHARTS.MOOD)) {
                  alertsFill = "#E69433";
                }
              }

              if (index === chartsIndex[1]) {
                if (
                  hasTargets(
                    chartsData[MOOD_AND_CONFIDENCE][CHARTS.CONFIDENCE_TO_DELIVER],
                    CHARTS.CONFIDENCE_TO_DELIVER
                  )
                ) {
                  targetsFill = "#E69433";
                }
                if (
                  hasComments(
                    commentsData[MOOD_AND_CONFIDENCE][CHARTS.CONFIDENCE_TO_DELIVER],
                    CHARTS.CONFIDENCE_TO_DELIVER
                  )
                ) {
                  commentsFill = "#E69433";
                  commentsCollor = getCommentNotificationColor(
                    commentsData[MOOD_AND_CONFIDENCE][CHARTS.CONFIDENCE_TO_DELIVER]
                  );
                }
                if (
                  hasAlerts(
                    alertsData[MOOD_AND_CONFIDENCE][CHARTS.CONFIDENCE_TO_DELIVER],
                    CHARTS.CONFIDENCE_TO_DELIVER
                  )
                ) {
                  alertsFill = "#E69433";
                }
              }
            }

            if (
              kpiName === ENGINEERING_COMPETITIVENESS &&
              pathname === "/engineeringcompetitivness"
            ) {
              try {
                if (displayName === "Competitiveness_Default") {
                  if (index === 7) {
                    if (
                      hasAlerts(alertsData[ENGINEERING_COMPETITIVENESS][CHARTS.DOTD], CHARTS.DOTD)
                    ) {
                      alertsFill = "#E69433";
                    }
                    if (
                      hasTargets(chartsData[ENGINEERING_COMPETITIVENESS][CHARTS.DOTD], CHARTS.DOTD)
                    ) {
                      targetsFill = "#E69433";
                    }

                    if (
                      hasComments(
                        commentsData[ENGINEERING_COMPETITIVENESS][CHARTS.DOTD],
                        CHARTS.DOTD
                      )
                    ) {
                      commentsFill = "#E69433";
                      commentsCollor = getCommentNotificationColor(
                        commentsData[ENGINEERING_COMPETITIVENESS][CHARTS.DOTD]
                      );
                    }
                  }

                  if (index === 11) {
                    if (
                      hasAlerts(alertsData[ENGINEERING_COMPETITIVENESS][CHARTS.DAVD], CHARTS.DAVD)
                    ) {
                      alertsFill = "#E69433";
                    }

                    if (
                      hasTargets(chartsData[ENGINEERING_COMPETITIVENESS][CHARTS.DAVD], CHARTS.DAVD)
                    ) {
                      targetsFill = "#E69433";
                    }

                    if (
                      hasComments(
                        commentsData[ENGINEERING_COMPETITIVENESS][CHARTS.DAVD],
                        CHARTS.DAVD
                      )
                    ) {
                      commentsFill = "#E69433";
                      commentsCollor = getCommentNotificationColor(
                        commentsData[ENGINEERING_COMPETITIVENESS][CHARTS.DAVD]
                      );
                    }
                  }

                  if (index === 10) {
                    if (
                      hasAlerts(
                        alertsData[ENGINEERING_COMPETITIVENESS][CHARTS.CONFIDENCE_TO_DELIVER],
                        CHARTS.CONFIDENCE_TO_DELIVER
                      )
                    ) {
                      alertsFill = "#E69433";
                    }

                    if (
                      hasTargets(
                        chartsData[ENGINEERING_COMPETITIVENESS][CHARTS.CONFIDENCE_TO_DELIVER],
                        CHARTS.CONFIDENCE_TO_DELIVER
                      )
                    ) {
                      targetsFill = "#E69433";
                    }

                    if (
                      hasComments(
                        commentsData[ENGINEERING_COMPETITIVENESS][CHARTS.CONFIDENCE_TO_DELIVER],
                        CHARTS.CONFIDENCE_TO_DELIVER
                      )
                    ) {
                      commentsFill = "#E69433";
                      commentsCollor = getCommentNotificationColor(
                        commentsData[ENGINEERING_COMPETITIVENESS][CHARTS.CONFIDENCE_TO_DELIVER]
                      );
                    }
                  }
                }
                if (displayName === "Talents Culture_Default") {
                  if (index === 11) {
                    if (hasAlerts(alertsData[TALENTS_AND_CULTURES][CHARTS.MOOD], CHARTS.MOOD)) {
                      alertsFill = "#E69433";
                    }

                    if (hasTargets(chartsData[TALENTS_AND_CULTURES][CHARTS.MOOD], CHARTS.MOOD)) {
                      targetsFill = "#E69433";
                    }

                    if (hasComments(commentsData[TALENTS_AND_CULTURES][CHARTS.MOOD], CHARTS.MOOD)) {
                      commentsFill = "#E69433";
                      commentsCollor = getCommentNotificationColor(
                        commentsData[TALENTS_AND_CULTURES][CHARTS.MOOD]
                      );
                    }
                  }

                  if (index === 10) {
                    if (
                      hasAlerts(
                        alertsData[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT],
                        CHARTS.KEY_PROFILE_FIT
                      )
                    ) {
                      alertsFill = "#E69433";
                    }

                    if (
                      hasTargets(
                        chartsData[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT],
                        CHARTS.KEY_PROFILE_FIT
                      )
                    ) {
                      targetsFill = "#E69433";
                    }

                    if (
                      hasComments(
                        commentsData[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT],
                        CHARTS.KEY_PROFILE_FIT
                      )
                    ) {
                      commentsFill = "#E69433";
                      commentsCollor = getCommentNotificationColor(
                        commentsData[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT]
                      );
                    }
                  }

                  if (index === 7) {
                    if (
                      hasAlerts(
                        alertsData[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE],
                        CHARTS.SKILL_COVERAGE
                      )
                    ) {
                      alertsFill = "#E69433";
                    }

                    if (
                      hasTargets(
                        chartsData[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE],
                        CHARTS.SKILL_COVERAGE
                      )
                    ) {
                      targetsFill = "#E69433";
                    }

                    if (
                      hasComments(
                        commentsData[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE],
                        CHARTS.SKILL_COVERAGE
                      )
                    ) {
                      commentsFill = "#E69433";
                      commentsCollor = getCommentNotificationColor(
                        commentsData[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE]
                      );
                    }
                  }
                }
              } catch (e) {}
            }

            if (chartsIndex.includes(index)) {
              if (kpiName === ENGINEERING_COMPETITIVENESS) {
                if (
                  ![
                    "ReportSectionf445ac6e2fc5f81534b1",
                    "ReportSection966676f274e368f511af",
                  ].includes(iframeContent.explorationNavigation.legacyService.currentSection.name)
                ) {
                  return;
                }
                if (excludeCharts(element)) {
                  return;
                }
              }
              element.insertAdjacentHTML(
                "beforebegin",
                `
                <div class="element-buttons" style="display:flex">
                  ${getButton("target", index, targetsFill, displayName)}
                  ${getButton("alert", index, alertsFill, displayName)}
                  ${getButton("comment", index, commentsFill, displayName, commentsCollor)}
                </div>
                `
              );
            }
          });
        }
      }
    } catch (ex) {}
  };

  const excludeCharts = element => {
    if (!element.querySelector("div")) {
      return;
    }
    const chartNameWrapper = element.querySelector("div").querySelector("div");

    let foundChartsToExclude = false;

    if (chartNameWrapper.innerHTML.includes("Skill Coverage")) {
      foundChartsToExclude = false;
    }

    if (chartNameWrapper.innerHTML.includes("Key profile fit")) {
      foundChartsToExclude = true;
    }

    if (chartNameWrapper.innerHTML.includes("Attrition")) {
      foundChartsToExclude = true;
    }

    if (chartNameWrapper.innerHTML.includes("Engineering Efficiency Index")) {
      foundChartsToExclude = true;
    }

    if (chartNameWrapper.innerHTML.includes("Engineering costs / Revenue")) {
      foundChartsToExclude = true;
    }
    if (chartNameWrapper.innerHTML.includes("Recent Hires Attrition")) {
      foundChartsToExclude = true;
    }
    if (chartNameWrapper.innerHTML.includes("Coming soon")) {
      foundChartsToExclude = true;
    }
    if (chartNameWrapper.innerHTML.includes("Best Cost")) {
      foundChartsToExclude = true;
    }
    if (chartNameWrapper.innerHTML.includes("Make vs Buy")) {
      foundChartsToExclude = true;
    }
    if (chartNameWrapper.innerHTML.includes("E-HC Structure Ratio")) {
      foundChartsToExclude = true;
    }
    if (chartNameWrapper.innerHTML.includes("Best Cost ECC (Ratio)")) {
      foundChartsToExclude = true;
    }
    if (chartNameWrapper.innerHTML.includes("Make vs Buy (Ratio)")) {
      foundChartsToExclude = true;
    }
    if (chartNameWrapper.innerHTML.includes("E-Average Span")) {
      foundChartsToExclude = true;
    }

    return foundChartsToExclude;
  };

  const renderAlerts = (alerts, targets) => {
    for (let i = 0; i < alerts.length; i++) {
      let targetValue;
      const chartKey = targets[i].key;

      if ([CHARTS.DOTD, CHARTS.DAVD].includes(chartKey)) {
        let values = targets[i].values.slice(8, 12);
        targetValue = values[values.length - 1];
      } else if (CHARTS.SKILL_COVERAGE === chartKey) {
        targetValue = targets[i].values[4];
      } else {
        targetValue = new Date().getMonth() % 2 === 1 ? targets[i].values[6] : targets[i].values[5];
      }

      renderAlert(alerts[i], i, isAdmin(), targetValue);
    }
  };

  const renderAlert = (chart, chartIndex, isAdmin, value) => {
    const iframeContent = document.querySelector(".embed-container iframe").contentWindow.document;
    if (iframeContent) {
      document
        .querySelector(".embed-container iframe")
        .contentWindow.document.querySelectorAll(`.alert-svg-${chartIndex}`)
        .forEach(el => el.remove());
      const parents = iframeContent.querySelectorAll(".svgScrollable");
      const labelSeriesGraphicsContexts = iframeContent.querySelectorAll(
        ".labelSeriesGraphicsContext"
      );
      if (
        parents &&
        parents.length > 0 &&
        labelSeriesGraphicsContexts &&
        labelSeriesGraphicsContexts.length > 0
      ) {
        let svgScrollable = parents[chartIndex];

        if (chart.key === CHARTS.SKILL_COVERAGE) {
          svgScrollable = parents[0];
        }

        if (chart.key === "mood" && chart.parent === "talents_and_cultures") {
          svgScrollable = parents[1];
        }

        const mySvg = getAlertSvgLine(chart, chartIndex, isAdmin, value);
        svgScrollable && svgScrollable.insertAdjacentHTML("beforeend", mySvg);
      }
    }
  };

  const getAlertSvgLine = (chart, chartIndex, isAdmin, value) => {
    let toReturn = "";
    let filters = getGeneralFilters();
    const className = `alert-svg alert-svg-${chartIndex}`;

    if (filters.length > 0) {
      filters = filters[0].value;
    }

    if (filters.length > 1) {
      return;
    }

    if (chart.values.length) {
      for (let i = 0; i < chart.values.length; i++) {
        const myValue = chart.values[i];

        if (!isAdmin) {
          if (!filters.includes(myValue.orgName)) {
            continue;
          }
        } else if (filters.length > 0) {
          if (!filters.includes(myValue.orgName)) {
            continue;
          }
        } else {
          if (filters.length === 0 && myValue.orgName) {
            continue;
          }
        }

        let targetsValue = myValue.value;
        let chartMaxValue = chart.max;
        if (chart.key === CHARTS.SKILL_COVERAGE) {
          chartMaxValue = getMaxChart(0, 1);
        } else if (chart.key === CHARTS.DAVD) {
          chartMaxValue = getMaxChart(10, 1000);
        }

        let y =
          chart.yMax -
          ((chart.yMax - chart.yMin) * (myValue.value - 1)) / (chartMaxValue - chart.min);
        let arrowX = 590;
        let numberX = 560;
        let targetX = 580;

        if (myValue.type === "above") {
          y = y - 8;
        } else if (myValue.type === "below") {
          y = y + 2;
        }

        targetsValue = parseFloat(myValue.value).toFixed(chart.hasDecimals);

        if (["dotd", "davd"].includes(chart.key)) {
          arrowX = arrowX - 4;
          numberX = numberX + 1;
          targetX = targetX - 4;
        }

        if (
          [ENGINEERING_COMPETITIVENESS].includes(chart.parent) &&
          chart.key === "confidenceToDeliver"
        ) {
          arrowX = arrowX + 8;
          numberX = numberX + 8;
          targetX = targetX + 8;

          if (myValue.type === "above") {
            y = y - 4;
          } else if (myValue.type === "below") {
            y = y - 3;
          }
        }

        if (myValue.type === "below") {
          toReturn += `
            <svg class="${className}" x="${arrowX}" y="${y}" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" title="Value goes below"> 
              <path d="M6.5 10.4678L0.870834 0.36708L12.1292 0.36708L6.5 10.4678Z" fill="#B31800"/>
            </svg>
            <svg class="${className}" x="${numberX}" y="${y}" width="28" height="20" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"> 
              <text style="fill:#B31800; font-size: 8px;" y="6">${targetsValue}</text>
            </svg>`;
        } else if (myValue.type === "above") {
          toReturn += `
            <svg class="${className}" x="${arrowX}" y="${y}" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" title="Value goes above"> 
              <path d="M6.5 0L12.1292 10.1007H0.870835L6.5 0Z" fill="#178244"/>
            </svg>
            <svg class="${className}" x="${numberX}" y="${y}" width="28" height="20" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"> 
              <text style="fill:#178244; font-size: 8px;" y="6">${targetsValue}</text>
            </svg>`;
        } else if (myValue.type === "target") {
          let targetValue = value > 0 ? value : 1;
          let targetY =
            chart.yMax -
            ((chart.yMax - chart.yMin) * (targetValue - 1)) / (chartMaxValue - chart.min);

          toReturn += `
            <svg class="${className}" x="580" y="${
            targetY - 12
          }" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> 
              <path d="M6.26 11.4551C6.41 8.27512 9.03 5.74512 12.25 5.74512C15.56 5.74512 18.25 8.43512 18.25 11.7451C18.25 14.9651 15.72 17.5851 12.54 17.7351L13.17 15.6351C14.94 15.2251 16.25 13.6351 16.25 11.7451C16.25 9.53512 14.46 7.74512 12.25 7.74512C10.36 7.74512 8.77 9.05512 8.36 10.8251L6.26 11.4551ZM12.25 21.7451C11.95 21.7451 11.65 21.7351 11.35 21.7051L11.94 19.7351C12.04 19.7451 12.15 19.7451 12.25 19.7451C16.67 19.7451 20.25 16.1651 20.25 11.7451C20.25 7.32512 16.67 3.74512 12.25 3.74512C7.83 3.74512 4.25 7.32512 4.25 11.7451C4.25 11.8451 4.25 11.9551 4.26 12.0551L2.29 12.6451C2.26 12.3451 2.25 12.0451 2.25 11.7451C2.25 6.22512 6.73 1.74512 12.25 1.74512C17.77 1.74512 22.25 6.22512 22.25 11.7451C22.25 17.2651 17.77 21.7451 12.25 21.7451ZM7.99 17.9751L9.25 21.7451L12.25 11.7451L2.25 14.7451L6.02 16.0051L1.75 20.2751L3.73 22.2551L7.99 17.9751Z" fill="#E69433"></path>
            </svg>`;
        }
      }
      return toReturn;
    }
    return toReturn;
  };

  const renderLines = data => {
    if (data.length === 0) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      addLine(data[i], i);
    }
  };

  const addLine = (chart, chartIndex) => {
    const iframeContent = document.querySelector(".embed-container iframe").contentWindow.document;
    if (chart.parent === "talents_and_cultures") {
      if (chart.key === "mood") {
        chartIndex = 2;
      }

      if (chart.key === "keyProfileFit") {
        chartIndex = 1;
      }

      if (chart.key === CHARTS.SKILL_COVERAGE) {
        chartIndex = 3;
      }
    }
    removeElement(chartIndex);

    if (iframeContent) {
      const parents = iframeContent.querySelectorAll(".svgScrollable");
      const labelSeriesGraphicsContexts = iframeContent.querySelectorAll(
        ".labelSeriesGraphicsContext"
      );
      if (
        parents &&
        parents.length > 0 &&
        labelSeriesGraphicsContexts &&
        labelSeriesGraphicsContexts.length > 0
      ) {
        let svgScrollable = parents[chartIndex];
        if (chart.key === CHARTS.SKILL_COVERAGE) {
          svgScrollable = parents[0];
        }

        if (chart.key === "mood" && chart.parent === "talents_and_cultures") {
          svgScrollable = parents[1];
        }

        if (chart.key === CHARTS.SKILL_COVERAGE) {
          if (iframeContent.querySelectorAll(".showLinesOnAxis .tick").length > 0) {
            createDynamicChart(chart, svgScrollable, chartIndex, 0, 1);
          } else {
            setTimeout(function () {
              createDynamicChart(chart, svgScrollable, chartIndex, 0, 1);
            }, 200);
          }
        } else if (chart.key === CHARTS.DAVD) {
          if (iframeContent.querySelectorAll(".showLinesOnAxis .tick").length > 0) {
            createDynamicChart(chart, svgScrollable, chartIndex, 10, 1000);
          } else {
            setTimeout(function () {
              createDynamicChart(chart, svgScrollable, chartIndex, 10, 1000);
            }, 200);
          }
        } else {
          const mySvg = getSVGLine(chart, chartIndex);
          if (svgScrollable) {
            svgScrollable.insertAdjacentHTML("beforeend", mySvg);
          }
        }
      }
    }
  };

  //TODO for now only skillcoverage
  const createDynamicChart = (
    chart,
    svgScrollable,
    chartIndex,
    chartIndexInPage,
    multiplicator
  ) => {
    try {
      const iframeContent =
        document.querySelector(".embed-container iframe").contentWindow.document;
      if (iframeContent.querySelectorAll(".showLinesOnAxis .tick").length > 0) {
        chart.max = getMaxChart(chartIndexInPage, multiplicator);
        const mySvg = getSVGLine(chart, chartIndex);
        if (svgScrollable) {
          svgScrollable.insertAdjacentHTML("beforeend", mySvg);
        }
      } else {
        setTimeout(function () {
          createDynamicChart(chart, svgScrollable, chartIndex, chartIndexInPage, multiplicator);
        }, 200);
      }
    } catch (e) {}
  };

  const getMaxChart = (indexInPage, multiplicator) => {
    const iframeContent = document.querySelector(".embed-container iframe").contentWindow.document;
    const yValues = iframeContent
      .querySelectorAll(".y.axis.showLinesOnAxis .tick")
      [indexInPage].closest("visual-container")
      .querySelectorAll(".y .tick title");
    const step =
      parseInt(yValues[yValues.length - 1].innerHTML) -
      parseInt(yValues[yValues.length - 2].innerHTML);
    return parseInt((yValues[yValues.length - 1].innerHTML.replace("K", "") - 0) * multiplicator);
  };

  const getSVGLine = (chart, chartIndex) => {
    let styleForDisabled = !canAddTargets ? `style="cursor: not-allowed"` : "";
    let toReturn = "";
    let circles = "";
    let pointIndex = 0;
    let columnIndex = 0;

    for (let i = 0; i < chart.nrOfBars; i++) {
      columnIndex++;
      if (chart.values[i] > 0) {
        toReturn +=
          "" +
          (chart.xMin + chart.xDiff * i) +
          " " +
          (chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min)) +
          " ";

        if (i < totalMonthInChart / chart.period - 1) {
          toReturn += "L ";
        }

        let textY =
          chart.yMax -
          ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min) -
          5;

        if (chart.values[i] === chart.max) {
          textY =
            chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min) +
            15;
        }

        let value = chart.values[i];
        value = parseFloat(chart.values[i]).toFixed(chart.hasDecimals);
        if (i >= chart.editableFrom) {
          circles += `<svg class="targetsline" style="cursor:n-resize" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:gui="http://www.kevlindev.com/gui">
                <svg data-kpiName="${chart.parent}" data-chartName="${
            chart.key
          }" onload="parent.makeDraggable(evt, ${pointIndex}, ${chart.yMin}, ${chart.yMax}, ${
            chart.min
          }, ${chart.max}, ${chartIndex}, ${columnIndex}, ${chart.hasDecimals}, '${chart.unit}');">
                    <g class="draggable-group" ${styleForDisabled}>
                        <rect class="rect${chartIndex}" x="${
            chart.xMin + chart.xDiff * i - 15
          }" y="${
            chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min) -
            18
          }" width="30" height="15" style="fill:rgb(255, 255, 255);stroke:rgb(255,255,255);stroke-width:1;fill-opacity:1;stroke-opacity:1" />
                        <rect class="rect${chartIndex}" x="${chart.xMin + chart.xDiff * i}" y="${
            chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min)
          }" width="1" height="1" style="fill:rgb(224, 121, 0);stroke:rgb(224, 121, 0);stroke-width:5;fill-opacity:0.1;stroke-opacity:0.9" />
                        <text style="font-size: 13px; fill:#041295" class="text${chartIndex}" x="${
            chart.xMin + chart.xDiff * i - 15
          }" y="${textY}">${value}${chart.unit === "%" ? chart.unit : ""}</text>
                    </g>
                </svg>
            </svg>`;
          pointIndex++;
        } else {
          circles += `<svg class="targetsline" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:gui="http://www.kevlindev.com/gui">
                <svg>
                    <g>
                        <rect class="rect${chartIndex}" x="${
            chart.xMin + chart.xDiff * i - 15
          }"  y="${
            chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min) -
            18
          }" width="30" height="15" style="fill:rgb(255, 255, 255);stroke:rgb(255,255,255);stroke-width:1;fill-opacity:1;stroke-opacity:1" />
                        <rect class="rect${chartIndex}" x="${chart.xMin + chart.xDiff * i}"  y="${
            chart.yMax -
            ((chart.yMax - chart.yMin) * (chart.values[i] - 1)) / (chart.max - chart.min)
          }" width="1" height="1" style="fill:rgb(224, 121, 0);stroke:rgb(224, 121, 0);stroke-width:5;fill-opacity:0.1;stroke-opacity:0.9" />
                        <text style="font-size: 13px; fill:#041295" class="text${chartIndex}" x="${
            chart.xMin + chart.xDiff * i - 10
          }" y="${textY}">${value}${chart.unit === "%" ? chart.unit : ""}</text>
                    </g>
                </svg>
            </svg>`;
          pointIndex++;
        }
      }
    }
    if (toReturn.endsWith("L ")) {
      toReturn = toReturn.substring(0, toReturn.length - 3);
    }
    return toReturn === ""
      ? ""
      : '<svg style="" class="targetsline" id="myLine' +
          chartIndex +
          '">' +
          "<g>" +
          circles +
          '<path d="M ' +
          toReturn +
          '" style="stroke: rgb(224, 121, 0); stroke-opacity: 1;fill: none;" class="bi-link-path">' +
          "</path></g></svg>";
  };

  const removeElement = id => {
    const iframeContent = document.querySelector(".embed-container iframe").contentWindow.document;
    if (iframeContent) {
      const elem = iframeContent.getElementById("myLine" + id);
      if (elem) {
        elem.parentNode.removeChild(elem);
      }
      iframeContent.querySelectorAll(".rect" + id).forEach(elem => {
        elem.parentNode.removeChild(elem);
      });
      iframeContent.querySelectorAll(".text" + id).forEach(elem => {
        elem.parentNode.removeChild(elem);
      });
    }
  };

  const clearAllDrawings = () => {
    const iframeContent = document.querySelector(".embed-container iframe").contentWindow.document;
    const buttonNodes = iframeContent.querySelectorAll(
      ".targetsButton, .alertsButton, .commentsButton"
    );
    const targetLineNodes = iframeContent.querySelectorAll(".targetsline");
    const alterNodes = iframeContent.querySelectorAll(".alert-svg");

    // Clear buttons
    if (buttonNodes) {
      buttonNodes.forEach(node => node.remove());
    }

    // Clear targets
    if (targetLineNodes) {
      targetLineNodes.forEach(node => node.remove());
    }

    // Clear alerts
    if (alterNodes) {
      alterNodes.forEach(node => node.remove());
    }
  };

  /**
   * Build org name bu the selected filters (eg: Gbu/Bl/CC)
   */
  const filterToOrgName = () => {
    const generalFilters = getGeneralFilters();
    if (generalFilters.length === 0) {
      return "Group";
    } else {
      return generalFilters[generalFilters.length - 1].value[0];
    }
  };

  return {
    fetchTargets,
    fetchAlerts,
    fetchComments,
    getButton,
    changeReport,
    sectionObjectsEvaluated,
    findGetParameter,
    openAndSetFilters,
    showTargets,
    renderButtons,
    setChartIndex,
    renderLines,
    renderAlerts,
    getGeneralFilters,
    getTimeframeFilters,
    handleChangeFilters,
    getSectionName,
    clearAllDrawings,
    filterToOrgName,
  };
}

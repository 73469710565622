import React, { useEffect, useMemo, useState } from "react";
import EmwTypography from "../../../EmwTypography/EmwTypography";
import EmwIcon from "../../../EmwIcon/EmwIcon";
import GeneralFiltersTooltip from "../GeneralFiltersTooltip/GeneralFiltersTooltip";
import { CLASS_NAME_PREFIX, CUSTOM_ERASE_ICON } from "../../constants";
import cls from "classnames";
import GeneralFiltersFilterType from "../GeneralFiltersFilterType/GeneralFiltersFilterType";
import GeneralFiltersFilterList from "../GeneralFiltersFilterList/GeneralFiltersFilterList";
import { setSelectedFilters } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { clearAllFiltersSelector, selectedGeneralFilters } from "../../redux/selectors";
import "./../styled";

export default function GeneralFiltersCard({ type, items }) {
  const dispatch = useDispatch();
  const shouldClearAllFilters = useSelector(clearAllFiltersSelector);
  const selectedFilters = useSelector(selectedGeneralFilters);
  const [isVisible, setIsVisible] = useState(false);
  const [originalList, setOriginalList] = useState(items);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedFiltersInfo, setSelectedFiltersInfo] = useState("(All)");
  const [query, setQuery] = useState();
  const cardClassName = cls(`${CLASS_NAME_PREFIX}filterCard flex flex-col mb-xs`, {
    isSelected: selectedFilters[type].length > 0,
  });
  const listClassName = cls(`${CLASS_NAME_PREFIX}filterCard__list`, {
    visible: isVisible,
    hidden: !isVisible,
  });
  let filterTypeClassName = type === "country" ? "capitalize" : "uppercase";
  filterTypeClassName += selectedFilters[type].length > 0 ? " font-black" : "";

  useEffect(() => {
    if (items.length) {
      setFilteredItems(items);
      setOriginalList(items);
    }
  }, [items]);

  useEffect(() => {
    if (shouldClearAllFilters) {
      setQuery("");
      setFilteredItems(items);
      setSelectedFiltersInfo([]);
    }
  }, [shouldClearAllFilters]);

  useEffect(() => {
    return () => {
      setSelectedFiltersInfo([]);
    };
  }, []);

  useEffect(() => {
    if (selectedFilters[type].length) {
      setSelectedFiltersInfo(filtersToString());
    } else {
      setSelectedFiltersInfo([]);
    }
  }, [selectedFilters]);

  useEffect(() => {
    setFilteredItems(() => {
      if (!query) return items;
      const queryResult = items.filter(item => {
        const _item = item.code ? item.code : item.name;
        return _item.toLowerCase().includes(query.toLowerCase());
      });

      return queryResult.length ? queryResult : [];
    });
  }, [query]);

  const handleOpen = () => {
    setIsVisible(!isVisible);
  };

  const clearFilters = event => {
    event.stopPropagation();
    setQuery("");
    setFilteredItems(items);
    dispatch(setSelectedFilters({ ...selectedFilters, [type]: [] }));
  };

  const filtersToString = () => {
    const cardFilters = selectedFilters[type];
    const names = cardFilters.map(filter => {
      return filter.name || filter.code;
    });

    return names.join(", ");
  };

  return (
    <div className={cardClassName}>
      <div className="flex" onClick={handleOpen}>
        <div className="flex-1">
          <EmwTypography fontSize={8} classes={filterTypeClassName}>
            {type}
          </EmwTypography>
        </div>
        <div className="flex items-center">
          <GeneralFiltersTooltip title="Expand or collapse filter card" placement="left">
            <div>
              <EmwIcon icon="keyboard_arrow_down" variant="outlined" size="small" classes="mr-xs" />
            </div>
          </GeneralFiltersTooltip>
          <GeneralFiltersTooltip title="Clear filter" placement="left">
            <img
              src={CUSTOM_ERASE_ICON.small}
              alt="clear filters"
              width={12}
              onClick={clearFilters}
            />
          </GeneralFiltersTooltip>
        </div>
      </div>
      <div>
        <EmwTypography fontSize={8}>
          {selectedFiltersInfo.length ? "is " + selectedFiltersInfo : "is (All)"}
        </EmwTypography>
      </div>

      <div className={listClassName}>
        <GeneralFiltersFilterType type={type} query={query} setQuery={setQuery} />
        <GeneralFiltersFilterList type={type} items={filteredItems} />
      </div>
    </div>
  );
}

import { DialogActions, DialogContent, Box } from "@mui/material";
import { TdsTypography } from "@tds/react";
import React, { useState } from "react";
import EmwDialog from "../../../../lib/commonv2/EmwDialog";
import EmwButton from "../../../components/EmwButton/EmwButton";

export default function GdprBtn() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <TdsTypography
        component="body-2"
        fontWeight="bold"
        className="text-black-500 medium:block gdpr-notes hasTmpZoom"
      >
        <a href="#" onClick={() => setIsOpen(true)}>
          DP Notes
        </a>
      </TdsTypography>
      {isOpen && (
        <EmwDialog
          open={isOpen}
          handleClose={handleClose}
          title="DP Notes"
          stretch
          logoOff={true}
          blackTittle={true} // TODO: should remove this and have default title colors for all modals?
        >
          <DialogContent>
            <Box textAlign="center" mb={2} mt={2}>
              <TdsTypography component="body-2">
                In order to build usage statistics and improve the solution, EMW collects and keeps
                for a 3-year period data associated to your access to its various services and
                dashboards. Only EMW administrators will have access to these data.
                <br />
              </TdsTypography>
            </Box>
            <Box textAlign="center">
              <TdsTypography component="body-2">
                For more information or for managing your rights, please send your questions or
                requests to
                <br />
                <a
                  href="mailto:engineering-dataprotection@thalesgroup.com"
                  style={{ color: "#041295", textDecoration: "underline" }}
                >
                  {" "}
                  engineering-dataprotection@thalesgroup.com
                </a>
              </TdsTypography>
            </Box>
          </DialogContent>
          <DialogActions  sx={{borderTop: "1px solid rgba(0, 0, 0, 0.12)"}}>
            <EmwButton id="btnCloseGDPR" variant="filled" onClick={handleClose}>
              Close
            </EmwButton>
          </DialogActions>
        </EmwDialog>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";
import AdministrationToggle from "../../../../../AdministrationToggle/AdministrationToggle";
import { EmwAutocomplete } from "../../../../../../../../../lib/common";
import { USERS_API_ENDPOINT } from "../../../../../../../../../constants";
import { TdsDivider } from "@tds/react";
import { setUser } from "../../../../../../../header/redux/actions";
import { setUserSelector, userSelector } from "../../../../../../../header/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useLoadDependencies } from "../../../../../../../header/components/useLoadDependencies";

const calculateAutocompleteLabel = value => {
  return value && value.label ? value.label[0] : "";
};

const calculateAutocompleteSugestion = value => {
  const info = [value.firstName + " " + value.lastName + " " + value.tgi];
  value.roles.forEach(element => {
    info.push(element);
  });

  return info;
};
export default function ImpersonatingSection({ user }) {
  const { init } = useLoadDependencies();
  const dispatch = useDispatch();
  const impersonatedUser = useSelector(setUserSelector);
  const [value, setValue] = useState({
    id: "",
    firstName: "",
    tgi: "",
    lastName: "",
    updatedDate: null,
    email: "",
    endDate: null,
    jobProfile: "",
    roles: [],
    rolesInfo: [],
  });

  const [checked, setChecked] = useState(user.impersonated);
  const shortUser = useSelector(userSelector);
  const[isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (
      impersonatedUser.list.hasOwnProperty("messageType") &&
      impersonatedUser.list.messageType === "success"
    ) {
      init();
    }
  }, [impersonatedUser]);

  useEffect(() => {
    if(shortUser.list.data && shortUser.list.hasOwnProperty("data")){
      setChecked(shortUser.list.data.impersonated);
      setIsDisabled(shortUser.list.data.impersonated)
    }
  }, [shortUser.list.data]);

  const onAutocompleteChange = event => {
    if (!event) {
      return;
    }
    setValue(event.object);
    dispatch(setUser({ tgi: event.object.tgi }));
  };

  const handleCheck = () => {
    setChecked(currentChecked => {
      if (currentChecked) {
        dispatch(setUser({ tgi: null }));
      }

      return !currentChecked;
    });
  };

  return (
    <>
      <div className="flex flex-col mt-s" id="adminTools-impersonating">
        <div className="flex justify-between items-center">
          <EmwTypography classes="text-scale-6 text-blugrey-500">Impersonating user</EmwTypography>

          <div className="flex flex-1 items-center justify-end">
            <label htmlFor="user-input" className="mr-xxs">
              <EmwTypography classes="text-scale-9 text-bluegrey-500">USER</EmwTypography>
            </label>
            <div>
              <EmwAutocomplete
                value={`${value.firstName} ${value.lastName}`}
                defaultValue={`${value.firstName} ${value.lastName}`}
                inputId="adminTools-user-search"
                urlToFetch={USERS_API_ENDPOINT}
                additionalCSS={{ width: "280px" }}
                placement="top"
                variant="outlined"
                size="small"
                disabled={false}
                placeHolder={"Search"}
                calculateAutocompleteSugestion={calculateAutocompleteSugestion}
                calculateAutocompleteLabel={calculateAutocompleteLabel}
                onAutocompleteChange={onAutocompleteChange}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div>
            {user.impersonated && (
              <>
                <EmwTypography classes="text-scale-9 text-bluegrey-200">{user.email}</EmwTypography>
                <EmwTypography classes="text-scale-9 text-bluegrey-200">
                  TGI: {user.tgi}
                </EmwTypography>
                {user.orgRoles.map(role => {
                  return (
                    <EmwTypography classes="text-scale-9 text-bluegrey-200">
                      {role.roleName} in:{" "}
                      {role.orgDetails.name ? role.orgDetails.name : "Thales group"}
                    </EmwTypography>
                  );
                })}
              </>
            )}
          </div>

          <div className="mt-xs">
            <AdministrationToggle
              checked={checked}
              loading={user.loading}
              disabled={!isDisabled}
              handleChecked={handleCheck}
            />
          </div>
        </div>
      </div>
      <TdsDivider size="small" />
    </>
  );
}

export const MODULE_NAME = "ADMINISTRATION";
export const ADMINISTRATION_PAGES = [
  {
    key: "admin-tools",
    path: "/administration",
    name: "Admin Tools",
    icon: "settings",
    variant: "filled",
    disabled: false,
    permissions: "showAdminPage",
    admin: true,
  },
  {
    key: "usage-monitoring",
    path: "/administration/usage-monitoring",
    name: "Usage Monitoring",
    icon: "bar_chart",
    variant: "filled",
    disabled: false,
    permissions: "showAdminPage",
    admin: true,
  },
  {
    key: "engineering-compliance",
    path: "/administration/engineering-compliance",
    name: "Engineering Compliance",
    icon: "engineering",
    variant: "filled",
    disabled: false,
    permissions: "showAdminPage",
    admin: true,
  },
];

export const DATA_COLLECTION = [
  {
    key: "data-collection",
    path: "/data-collection-evolution",
    name: "Data Collection Evolution",
    icon: "assessment",
    variant: "outlined",
    disabled: false,
    permissions: "showAdminPage",
    admin: false,
  },
];

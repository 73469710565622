import { connect } from "react-redux";

import { App } from "./../components";
import * as actions from "./../actions";
import * as headerActions from "../../header/actions";
import * as constants from "./../constants";

const mapStateToProps = state => {
  const moduleState = state[constants.MODULE_NAME];

  return {
    requestPending: moduleState[actions.REQUEST_PENDING],
    snackbarMessage: moduleState[actions.SNACKBAR_MESSAGE],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteLogin: () => dispatch(headerActions.deleteLogin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { useContext, useEffect, useState } from "react";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";
import { useSelector } from "react-redux";
import { newOrgDataSelector } from "../../../../../../redux/selectors";
import { Grid } from "@mui/material";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";
import EmwTextField from "../../../../../../../../../lib/commonv2/EmwTextField";
import { EmwButton } from "../../../../../../../../../lib/common";
import CheckedIcon from "../../../../../../../../../lib/icons/CheckedIcon";
import EmwIcon from "../../../../../../../../components/EmwIcon/EmwIcon";

export default function CardName2() {
  const context = useContext(OrganizationCardContext);
  const newData = useSelector(newOrgDataSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [cardName, setCardName] = useState({
    name: "",
    code: "",
    countryCode: "",
  });
  const cardType = context.data.cardDetails.type;

  useEffect(() => {
    setCardName({
      name: context.data.section1.name.value ? context.data.section1.name.value : "",
      code: context.data.section1.code.value ? context.data.section1.code.value : "",
      countryCode: context.data.cardDetails.countryCode,
    });
  }, [newData]);

  const onChange = (event, type) => {
    const _cardName = { ...cardName };

    if (type === "name") {
      _cardName[type] = event.target.value;
    } else {
      _cardName[type] = event.target.value.toUpperCase();
    }

    setCardName(_cardName);
    context.updateName2(_cardName);
  };

  const onChecked = () => {
    setIsEdit(!isEdit);
  };

  const handleEditClick = () => {
    setIsEdit(!isEdit);
  };

  const renderName = () => {
    if (!isEdit) {
      return `${cardName.name} ${cardName.code}`;
    }

    return null;
  };

  return (
    <Grid item mr={1}>
      <Grid container alignItems="center" gap={0}>
        <Grid item mr={1}>
          <EmwTypography classes="text-primary-500" fontWeight="bold" fontSize={6}>
            {renderName()}
          </EmwTypography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" sx={{ maxWidth: "400px" }}>
            <Grid item>
              {isEdit && (
                <Grid container direction="row" gap={1} alignItems={"end"}>
                  <Grid item sx={{ maxWidth: "100px" }}>
                    <EmwTextField
                      id="organization-card-name"
                      name={cardName.name}
                      value={cardName.name}
                      onChange={e => onChange(e, "name")}
                      placeholder={`( ${cardType.toUpperCase()} name )`}
                    />
                  </Grid>
                  <Grid item sx={{ maxWidth: "100px" }}>
                    <EmwTextField
                      id="organization-card-code"
                      name={cardName.code.toUpperCase()}
                      value={cardName.code.toUpperCase()}
                      onChange={e => onChange(e, "code")}
                      placeholder={`( ${cardType.toUpperCase()} code )`}
                      inputProps={{ maxlength: 3 }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item>
              {isEdit && (
                <EmwButton variant="ghost" size="small" onClick={onChecked} id="btnChecked">
                  <CheckedIcon />
                </EmwButton>
              )}
              {!isEdit && context.data.section1.name.visible && (
                <EmwButton
                  variant="filled"
                  size="small"
                  onClick={handleEditClick}
                  disabled={!context.data.section1.name.enabled}
                  title="Edit"
                  classes="ml-xs cardName-editBtn"
                  id={`btnEditCardName`}
                >
                  <EmwIcon icon="edit" variant="outlined" size="small" />
                </EmwButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import React from "react";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";
import { NO_RESOURCE_ALLOCATED } from "../../../../constants";

export default function EmwListItemName({ em }) {
  if (!em.name.value || em.name.value === NO_RESOURCE_ALLOCATED) {
    return (
      <EmwTypography classes="text-orange-500 ml-xxs" fontSize="8" fontWeight="bold">
        {NO_RESOURCE_ALLOCATED}
      </EmwTypography>
    );
  }

  if (em.hasOwnProperty("functionalRole") && em.functionalRole) {
    return (
      <EmwTypography classes="text-black-100 italic ml-xxs underline" fontSize="8" fontWeight="bold">
        {em.name.value}
      </EmwTypography>
    );
  }

  return (
    <EmwTypography classes="text-black-100 ml-xxs" fontSize="8">
      {em.name.value}
    </EmwTypography>
  );
}

import React, { useEffect, useState } from "react";
import { Box, Collapse, Grid, IconButton } from "@mui/material";
import EmwTypography from "../EmwTypography/EmwTypography";
import PlusIcon from "../../../lib/icons/PlusIcon";
import { StyledEmwToggleMenu } from "./styled";
import { TdsIcon } from "@tds/react";

export default function EmwToggleMenu({ name, children, label, addItem, canEdit, shouldOpen, id }) {
  const [isOpen, setIsOpen] = useState(name === "EXCOM Member" ? false : true);

  useEffect(() => {
    if (shouldOpen) {
      setIsOpen(shouldOpen);
    }
  }, [shouldOpen]);

  return (
    <StyledEmwToggleMenu>
      <Grid container className={`toggle-menu tour-${id} mt-xs`}>
        <EmwTypography classes="text-bluegrey-600 name" fontSize="9">
          {name}
        </EmwTypography>
        <Grid item ml={"auto"}>
          <Grid container>
            <IconButton
              title={`${isOpen ? "Collapse section" : "Expand section"}`}
              disableRipple
              size="small"
              id={`btnToggle${id}`}
              onClick={() => setIsOpen(!isOpen)}
              sx={{ marginLeft: "auto" }}
            >
              {isOpen ? (
                <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: "#4A4D65" }} />
              ) : (
                <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: "#4A4D65" }} />
              )}
            </IconButton>
            {canEdit && (
              <IconButton
                title="Add new"
                sx={{ padding: 0, marginRight: "8px" }}
                disableRipple
                onClick={addItem}
                id={`btnAdd${id}`}
              >
                <PlusIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpen} sx={{ width: "100%" }}>
        {children}
      </Collapse>
    </StyledEmwToggleMenu>
  );
}

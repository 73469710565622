import React from "react";
import EmwIcon from "../../../EmwIcon/EmwIcon";
import EmwTypography from "../../../EmwTypography/EmwTypography";
import GeneralFiltersTooltip from "../GeneralFiltersTooltip/GeneralFiltersTooltip";
import { CLASS_NAME_PREFIX } from "../../constants";

export default function GeneralFiltersHeader({ toggle, isExpanded }) {
  return (
    <>
      {isExpanded && (
        <div className="">
          <div
            className={`${CLASS_NAME_PREFIX}toggleHeader flex items-center justify-between pt-s px-xs`}
          >
            <GeneralFiltersTooltip title="Show/hide filter pane">
              <div className="flex items-center">
                <EmwIcon
                  className="filter_list"
                  icon="filter_list"
                  size="small"
                  variant="outlined"
                />
                <EmwTypography fontWeight="bold" fontSize={6} classes="px-xxs">
                  Filters
                </EmwTypography>
              </div>
            </GeneralFiltersTooltip>

            <GeneralFiltersTooltip title="Show/hide filter pane" placement="left" onClick={toggle}>
              <div>
                <EmwIcon icon="keyboard_double_arrow_right" size="small" variant="outlined" />
              </div>
            </GeneralFiltersTooltip>
          </div>
          <div className={`${CLASS_NAME_PREFIX}mainSearch flex ml-xs mr-xs mt-s`}>
            <div className="flex px-xs">
              <EmwIcon icon="search" variant="outlined" />
            </div>
            <div className="flex-1">
              <input
                type="text"
                id={`${CLASS_NAME_PREFIX}mainSearch`}
                name={`${CLASS_NAME_PREFIX}mainSearch`}
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      )}
      {!isExpanded && (
        <div
          className={`flex flex-col items-center justify-center ${CLASS_NAME_PREFIX}toggleHeader`}
        >
          <div className="flex items-center justify-between pt-m px-xs">
            <GeneralFiltersTooltip title="Show/hide filter pane" onClick={toggle}>
              <div>
                <EmwIcon icon="keyboard_double_arrow_left" size="small" variant="outlined" />
              </div>
            </GeneralFiltersTooltip>
          </div>
          <GeneralFiltersTooltip onClick={toggle}>
            <div className="flex filter-icon pt-m px-xs">
              <EmwIcon
                className="filter_list"
                icon="filter_list"
                size="small"
                variant="outlined"
                style={{ transform: "rotate(90deg)" }}
              />
            </div>
          </GeneralFiltersTooltip>

          <GeneralFiltersTooltip onClick={toggle}>
            <div className="flex pt-m">
              <EmwTypography fontWeight="bold" fontSize={6} style={{ transform: "rotate(90deg)" }}>
                Filters
              </EmwTypography>
            </div>
          </GeneralFiltersTooltip>
        </div>
      )}
    </>
  );
}

import { initialState } from "./initialState";
import { getState, reduceAction } from "../../../../util/reducer";
import * as actions from "./actions";
import { AVAILABLE_FILTERS } from "./actions";

const reducerConfig = {
  [actions.FILTERS]: reduceAction,
  [actions.SELECTED_FILTERS]: reduceAction,
  [actions.CLEAR_ALL_FILTERS]: reduceAction,
  [actions.AVAILABLE_FILTERS]: reduceAction,
  [actions.LOADING]: reduceAction,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);

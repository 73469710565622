import { MODULE_NAME } from "../constants";
import { createSelector } from "@reduxjs/toolkit";
import * as actions from "./actions";
import { DISABLED_ENG_LIST } from "./actions";

export const organization = state => state[MODULE_NAME];
export const orgDetailsSelector = createSelector(organization, state => state[actions.ORG_DETAILS]);
export const perspectiveSelector = createSelector(
  organization,
  state => state[actions.ORG_PERSPECTIVE]
);
export const viewSelector = createSelector(organization, state => state[actions.ORG_VIEW]);
export const schemaSelector = createSelector(organization, state => state[actions.DIAGRAM_SCHEMA]);
export const diagramLinkSelector = createSelector(
  organization,
  state => state[actions.DIAGRAM_LINK]
);
export const favoriteOrganizationsSelector = createSelector(
  organization,
  state => state[actions.FAVORITE_ORGANIZATIONS]
);
export const underMyResponsabilitySelector = createSelector(
  organization,
  state => state[actions.UNDER_MY_RESPONSABILITY]
);
export const gbuListSelector = createSelector(organization, state => state[actions.GBU_LIST]);
export const guestDetailsSelector = createSelector(
  organization,
  state => state[actions.GUEST_DETAILS]
);
export const externalProjectsSelector = createSelector(
  organization,
  state => state[actions.EXTERNAL_PROJECTS]
);
export const tourStatusSelector = createSelector(organization, state => state[actions.TOUR_STATUS]);
export const toggleSectionSelector = createSelector(
  organization,
  state => state[actions.TOGGLE_SECTION]
);
export const disciplineNamesSelector = createSelector(
  organization,
  state => state[actions.GET_DISCIPLINE_NAMES]
);
export const requestedOrgIdSelector = createSelector(
  organization,
  state => state[actions.REQUESTED_ORG_ID]
);
export const highlightedTgiSelector = createSelector(
  organization,
  state => state[actions.HIGHLIGHTED_TGI]
);
export const hasNodeLinkSelector = createSelector(
  organization,
  state => state[actions.HAS_NODE_LINK]
);
export const newOrgDataSelector = createSelector(
  organization,
  state => state[actions.NEW_ORG_DATA]
);
export const getEeiDataForOrganization = createSelector(
  organization,
  state => state[actions.GET_EEI_DATA_FOR_ORGANIZATION]
);

export const getWfbDataForOrganization = createSelector(
  organization,
  state => state[actions.GET_WFB_DATA_FOR_ORGANIZATION]
);
export const getAllCcsSelector = createSelector(organization, state => state[actions.GET_ALL_CCS]);
export const saveCcDetailsSelector = createSelector(
  organization,
  state => state[actions.SAVE_CC_DETAILS]
);

export const disabledEngListSelector = createSelector(
  organization,
  state => state[actions.DISABLED_ENG_LIST]
);

export const filterCCTypeSelector = createSelector(
  organization,
  state => state[actions.ORG_CC_FILTER]
);

export const expandPathSelector = createSelector(organization, state => state[actions.EXPAND_PATH]);

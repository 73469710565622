import { Grid, styled } from "@mui/material";

export const StyledCommentsList = styled(Grid)(({ theme }) => ({
  ".comments-list": {
    height: "30px",
    button: {
      height: "30px",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  ".comment-item": {
    height: "38px",
    overflow: "hidden",
    flexGrow: 1,
  },

  ".type-comment, .type-rootCause, .type-actionType": {
    minWidth: "85px",
    maxWidth: "100px",
    height: "19px",
    display: "inline-flex",
    fontSize: "8px",
    alignItems: "center",
    position: "relative",
  },

  ".left-corner": {
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderBottom: "9px solid transparent",
  },

  ".bullet": {
    width: "3px",
    height: "3px",
    left: "7px",
    position: "absolute",
    borderRadius: "3px",
    backgroundColor: "white",
  },

  ".type": {
    height: "inherit",
    flexGrow: 1,
    lineHeight: "19px",
    color: "white",
    paddingLeft: "7px",
    paddingRight: "7px",
    borderRadius: "0 4px 4px 0",
  },

  ".type-comment .left-corner": {
    borderRight: "9px solid #74B48F",
  },

  ".type-comment .type": {
    backgroundColor: "#74B48F",
  },

  ".type-actionType .left-corner": {
    borderRight: "9px solid #192ACC",
  },

  ".type-actionType .type": {
    backgroundColor: "#192ACC",
  },

  ".type-rootCause .left-corner": {
    borderRight: "9px solid #CE7CAB",
  },

  ".type-rootCause .type": {
    backgroundColor: "#CE7CAB",
  },

  "tds-tag": {
    ".qtm-tag": {
      width: "54px",
      height: "24px",
    },
  },
}));

import React, { useContext, useEffect, useState } from "react";
import { Collapse } from "@mui/material";
import { cloneDeep } from "lodash";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";
import FormItem from "../FormItem/FormItem";
import { ENGINEER_TYPE, NO_RESOURCE_ALLOCATED, ORGANISATION_FORM_ELEMENT } from "../../../../../../constants";
import { getNewEngineersList } from "../../../../../../redux/api";
import useAppSnackbar from "../../../../../../../../hooks/useAppSnankbar";
import cls from "classnames";
import TourElement from "../../../../../../../../components/Tour/TourElement/TourElement";
import { dateIsExpired } from "../../utils";

export default function Discipline({ discipline, index, color, targetField, openNewDiscipline }) {
  const context = useContext(OrganizationCardContext);
  // const [isOpen, setIsOpen] = useState(!!openNewDiscipline);
  const [isOpen, setIsOpen] = useState(discipline.isOpen);
  const [name, setName] = useState(discipline.name.value || `Discipline ${index + 1}`);
  const [values, setValues] = useState(cloneDeep(discipline));
  const [engineers, setEngineers] = useState([]);
  const [item, setItem] = useState(discipline);
  const snackBar = useAppSnackbar();
  const [isDropdownAlreadyOpen, setIsDropdownAlreadyOpen] = useState(isOpen);
  const [forceOpen, setForceOpen] = useState(false);
  const rootId = `root-discipline-${index}`;

  const className = cls("my-xs mt-xss root-item", {
    "bg-green-300 px-xxs py-xxs rounded-md": false, // || rootId === context.focusedItem,
  });

  useEffect(() => {
    setItem(discipline);
  }, [discipline]);

  useEffect(() => {
    if (!isOpen && !forceOpen) {
      const shouldForceOpen = context.shouldBeOpen(discipline, targetField.key);
      setIsOpen(shouldForceOpen);
      setForceOpen(shouldForceOpen);
      return;
    }

    if (discipline.isNew) {
      context.setFocusedItem(rootId);
    }

    if (item.hasOwnProperty("engineers") && item.leader.name.value !== NO_RESOURCE_ALLOCATED) {
      const _engineers = discipline.engineers
        .filter(engineer => engineer.name.value)
        .map(engineer => {
          return (
            <div className="my-xs">
              <FormItem key={engineer.id} type={`${engineer.label || "Engineer"}`} item={engineer} noEdit={true} />
            </div>
          );
        });

      setEngineers(_engineers);
    } else {
      setEngineers([]);
    }

    setItem(discipline);
  }, [discipline, isOpen]);

  const handleIsMainObjOpen = open => {
    const _discipline = {
      ...discipline,
      isOpen: open,
    };
    context.updateDiscipline(_discipline, index);
  };

  const handleIsMainObjInEditMode = isEdit => {
    const _discipline = {
      ...discipline,
      isEdit: isEdit,
    };
    context.updateDiscipline(_discipline, index);
  };

  const handleChangeHoD = Hod => {
    const updatedDiscipline = cloneDeep(discipline);

    updatedDiscipline.leader = Hod;
    context.updateDiscipline(updatedDiscipline, index);
  };

  const handleUpdate = (objectValues, key) => {
    const updatedValues = { ...values };

    if (key) {
      updatedValues[key] = objectValues;
      updatedValues.isOpen = item.isOpen;
      updatedValues.isEdit = item.isEdit;
      context.updateDiscipline(updatedValues, index);
      setValues(updatedValues);
    } else {
      objectValues.leader = updatedValues.leader;
      objectValues.engineers = discipline.engineers;
      objectValues.isOpen = true;
      objectValues.isEdit = false;
      context.updateDiscipline(objectValues, index);
      setValues(objectValues);
    }
  };

  const setWarningBorder = () => {
    let hasBorder = false;
    const regExp = /^Discipline [0-9]+$/;
    if (!values.leader.name.value || values.leader.name.value === NO_RESOURCE_ALLOCATED) {
      hasBorder = true;
    } else if (values.name.value && regExp.test(values.name.value)) {
      hasBorder = true;
    } else if (dateIsExpired(values.leader.endDate.value)) {
      hasBorder = true;
    }
    return hasBorder;
  };

  return (
    <div className={className} id={rootId}>
      <TourElement id={`tour-orgCard-DisciplineFormItem-${index}`}>
        <FormItem
          text="discipline"
          isNew={false}
          type={null}
          customName={name}
          item={item}
          handleUpdate={handleUpdate}
          showArrows={false}
          color={color}
          isOpen={isOpen}
          // isOpen={discipline.isOpen}
          isEditOpen={discipline.isEdit}
          setIsOpen={value => {
            setIsOpen(value);
            setIsDropdownAlreadyOpen(value);
            handleIsMainObjOpen(value);
          }}
          collapsable={true}
          isTextField={true}
          targetKey={targetField.key}
          id={`discipline-${index}`}
          handleEditToggle={() => {
            setIsOpen(true);
          }}
          noResource={setWarningBorder()}
          beforeEditClick={() => {
            context.setFocusedItem(rootId);
            if (!isDropdownAlreadyOpen) {
              setIsOpen(true);
            }
            handleIsMainObjInEditMode(true);
          }}
          beforeCancelClick={() => {
            context.setFocusedItem("");
            if (!isDropdownAlreadyOpen) {
              setIsOpen(false);
            }
            handleIsMainObjInEditMode(false);
          }}
        />
      </TourElement>
      <Collapse in={isOpen} className="flex flex-grow" sx={{ width: "100%", display: "flex" }}>
        <FormItem
          text="hod"
          isNew={false}
          type="HoD"
          item={discipline.leader}
          handleUpdate={handleUpdate}
          showArrows={false}
          propKey="leader"
          targetKey={targetField.key}
          id={`discipline-${index}-HoD`}
          title={ENGINEER_TYPE.HoD.title}
          handleChangeHoD={handleChangeHoD}
        />

        {/*{engineers}*/}
      </Collapse>
    </div>
  );
}

import React from "react";
import { Box, Grid } from "@mui/material";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";

export default function EngDomains({ data }) {
  return (
    <Grid container alignItems="flex-end" direction="column">
      <Grid item>
        <Grid container justifyContent="end">
          {data.section1.type.visible && (
            <>
              <EmwTypography fontSize={9} fontWeight="black">
                Org. Type:
              </EmwTypography>
              {data.section1.type.value && data.section1.type.value !== "0" ? (
                <Grid item ml={0.5}>
                  <Box
                    sx={{
                      p: {
                        fontWeight: 700,
                        fontSize: 9,
                      },
                    }}
                  >
                    <EmwTypography fontSize={9} fontWeight="black">
                      {data.section1.type.value}
                    </EmwTypography>
                  </Box>
                </Grid>
              ) : (
                <Box
                  ml={0.5}
                  sx={{
                    p: {
                      color: "#E07900",
                      fontWeight: 700,
                      fontSize: 9,
                    },
                  }}
                >
                  <EmwTypography fontSize={9} fontWeight="black">
                    Not set
                  </EmwTypography>
                </Box>
              )}
            </>
          )}
        </Grid>
        <Grid item>
          <Grid container>
            {(data.section1.hardware.visible ||
              data.section1.software.visible ||
              data.section1.system.visible ||
              data.section1.industry.visible) && (
              <>
                <EmwTypography fontSize={9} fontWeight="black">
                  Eng. Domain:
                </EmwTypography>
                {!(
                  data.section1.hardware.value ||
                  data.section1.software.value ||
                  data.section1.system.value ||
                  data.section1.industry.value
                ) && (
                  <Box
                    ml={0.5}
                    sx={{
                      p: {
                        color: "#E07900",
                        fontWeight: 700,
                        fontSize: 9,
                      },
                    }}
                  >
                    <EmwTypography fontSize={9} fontWeight="black">
                      Not set
                    </EmwTypography>
                  </Box>
                )}
              </>
            )}

            {data.section1.hardware.value && data.section1.hardware.visible && (
              <Grid item ml={0.5}>
                <EmwTypography fontSize={9} fontWeight="black" title="Hardware">
                  HW
                </EmwTypography>
              </Grid>
            )}

            {data.section1.software.value && data.section1.software.visible && (
              <Grid item ml={0.5}>
                <EmwTypography fontSize={9} fontWeight="black" title="Software">
                  SW
                </EmwTypography>
              </Grid>
            )}

            {data.section1.system.value && data.section1.system.visible && (
              <Grid item ml={0.5}>
                <EmwTypography fontSize={9} fontWeight="black" title="System">
                  SYS
                </EmwTypography>
              </Grid>
            )}

            {data.section1.industry.value && data.section1.industry.visible && (
              <Grid item ml={0.5}>
                <EmwTypography fontSize={9} fontWeight="black" title="Industry">
                  IND
                </EmwTypography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

/**
 * Generate a tour step
 *
 * @todo: Mode to
 *
 * @param tour
 * @param id
 * @param title
 * @param text
 * @param element
 * @param on
 * @returns {{buttons: [{classes: string, action, text: string, type: string},{classes: string, action: () => void, text: string, type: string},{classes: string, action, text: string, type: string}], classes: string, highlightClass: string, id, text: *[], title, attachTo: {element, on}}}
 */
export const buildStep = (
  tour,
  buttonPrevAction,
  { id, title, text, element, on, identifierClass, scroll },
  whenShown,
  whenHidden,
  wait = 0,
  buttonNextAction,
  buttonCancelAction,
  beforeShowPromiseAction
) => {
  return {
    id,
    title,
    text: [text],
    attachTo: { element, on },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        if (beforeShowPromiseAction) {
          beforeShowPromiseAction();
        }
        setTimeout(function () {
          resolve();
        }, wait);
      });
    },
    classes: identifierClass,
    scrollTo: scroll,
    when: {
      show: () => {
        if (whenShown) {
          whenShown();
        }
      },
      hide: () => {
        if (whenHidden) {
          whenHidden();
        }
      },
    },
    highlightClass: "highlightClass",
    buttons: [
      {
        type: "cancel",
        classes: "qtm-btn qtm-ghost qtm-neutral",
        text: "Cancel",
        action: async () => {
          if (buttonCancelAction) {
            buttonCancelAction();
          }
          tour.cancel();
        },
      },
      {
        type: "back",
        classes: "qtm-btn qtm-outline qtm-primary previous",
        text: "Previous",
        action: async () => {
          if (buttonPrevAction) {
            buttonPrevAction();
          }
          tour.back();
        },
      },
      {
        type: "next",
        classes: "qtm-btn qtm-filled qtm-primary",
        text: "Next",
        action: async () => {
          if (buttonNextAction) {
            buttonNextAction();
          }
          tour.next();
        },
      },
    ],
  };
};

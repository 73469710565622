import React from "react";

export default function CalendarIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3333 1.49984H11.6666V0.166504H10.3333V1.49984H3.66659V0.166504H2.33325V1.49984H1.66659C0.933252 1.49984 0.333252 2.09984 0.333252 2.83317V13.4998C0.333252 14.2332 0.933252 14.8332 1.66659 14.8332H12.3333C13.0666 14.8332 13.6666 14.2332 13.6666 13.4998V2.83317C13.6666 2.09984 13.0666 1.49984 12.3333 1.49984ZM12.3333 13.4998H1.66659V6.1665H12.3333V13.4998ZM12.3333 4.83317H1.66659V2.83317H12.3333V4.83317Z"
        fill="#131319"
      />
    </svg>
  );
}

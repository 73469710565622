import React from "react";

export default function InfoIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 4.01546H7.33333V5.3488H6V4.01546ZM6 6.68213H7.33333V10.6821H6V6.68213ZM6.66667 0.682129C2.98667 0.682129 0 3.6688 0 7.3488C0 11.0288 2.98667 14.0155 6.66667 14.0155C10.3467 14.0155 13.3333 11.0288 13.3333 7.3488C13.3333 3.6688 10.3467 0.682129 6.66667 0.682129ZM6.66667 12.6821C3.72667 12.6821 1.33333 10.2888 1.33333 7.3488C1.33333 4.4088 3.72667 2.01546 6.66667 2.01546C9.60667 2.01546 12 4.4088 12 7.3488C12 10.2888 9.60667 12.6821 6.66667 12.6821Z"
        fill="#5D607E"
      />
    </svg>
  );
}

import React, { useEffect, useState } from "react";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";
import AdministrationToggle from "../../../../../AdministrationToggle/AdministrationToggle";
import EmwDatePicker from "../../../../../../../../../lib/commonv2/EmwDatePicker";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../../../../../organization/constants";
import { TdsDivider } from "@tds/react";
import { putBackInTimeApi } from "../../../../../../redux/api";
import useAppSnackbar from "../../../../../../../../hooks/useAppSnankbar";
import { useLoadDependencies } from "../../../../../../../header/components/useLoadDependencies";

export default function BackInTimeSection({ user }) {
  const { init } = useLoadDependencies();
  const snackBar = useAppSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(user.backInTimeDate);
  const [checked, setChecked] = useState(user.backInTimeDate);

  useEffect(() => {
    setSelectedDate(user.backInTimeDate);
    setChecked(!!user.backInTimeDate);
  }, [user.backInTimeDate]);

  const handleDateChange = async value => {
    setChecked(!checked);
    let date = null;
    if (value) {
      date = moment(value).format(DATE_TIME_FORMAT);
    }
    setSelectedDate(date);
    await putBackInTime({ date });
  };

  const putBackInTime = async payload => {
    try {
      setLoading(true);
      await putBackInTimeApi(payload);
      await init();
    } catch (reason) {
      snackBar.show(reason.message || reason.statusText, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCheck = async () => {
    setSelectedDate(null);
    setChecked(false);
    await putBackInTime({ date: null });
  };

  return (
    <>
      <div className="flex flex-col mt-s pb-xxs" id="adminTools-backInTime">
        <div className="flex items-center justify-between">
          <p className="text-scale-6 text-blugrey-500">Go back to specific time</p>
          <div className="flex flex-row items-center">
            <EmwTypography classes="text-scale-8 text-blugrey-500 mr-xxs">DATE</EmwTypography>
            <EmwDatePicker
              inputId="adminTools-date"
              date={selectedDate}
              disableInput
              handleChange={handleDateChange}
              maxDate={moment(new Date()).format(DATE_TIME_FORMAT)}
            />
          </div>
        </div>
        <div className="flex items-center ml-auto mt-xs">
          <AdministrationToggle
            checked={checked}
            loading={loading}
            disabled={!selectedDate}
            handleChecked={handleCheck}
          />
        </div>
      </div>
      <TdsDivider size="small" />
    </>
  );
}

import React from "react";
import { StyledAdministration } from "../styled";
import TimeFrame from "./components/TimeFrame/TimeFrame";
import Filters from "./components/Filters/Filters";
import { Grid } from "@mui/material";
import useEngineeringCompliance from "./hooks/useEngineeringCompliance";
import { BarChart, GroupedChart } from "../../../../components/EmwCharts";
import { getOrganizationsIndicatorsApi } from "../../redux/api";

export default function EngineeringCompliance({ user }) {
  const { chartsData, stateData, setStateData, getChildOf } = useEngineeringCompliance({
    getOrganizationsIndicatorsApi,
  });

  return (
    <StyledAdministration className="bg-bluegrey-25 px-3xl">
      <div className="flex flex-col flex-1 mt-3xl">
        <div className="flex items-center">
          <p className="text-scale-4 text-primary-500">Engineering Compliance</p>
          <TimeFrame data={stateData} setData={setStateData} />
        </div>
        <div className="flex flex-col my-m">
          <Filters data={stateData} setData={setStateData} getChildOf={getChildOf} />
        </div>

        <div className="flex flex-wrap flex-1 justify-between overflow-hidden">
          <div className="flex flex-col flex-1 charts-container px-xxs">
            <div className="mb-xs">
              <Grid container spacing={1}>
                <Grid item sm={12} md={4}>
                  <BarChart
                    data={chartsData.emHoeos}
                    name="CCs with EM and HoEO defined"
                    tooltipInfo={
                      <>
                        <p className="mb-xxs">
                          &#8226; We count only the Competence Centers with both EM/HoEO defined.
                          The ratio is provided according with total nr of Competence Centers.
                        </p>
                        <p>
                          &#8226; Quarterly – snapshot at the end of each quarter of cc’s, evaluated
                          on each day. At the end of Q, the value will be fixed. The current quarter
                          will be the last one on right end side, and will be shifted left at each
                          quarter beginning.
                        </p>
                      </>
                    }
                    info="Percentage with EM and HoEO defined"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <BarChart
                    data={chartsData.disciplines}
                    name="CCs with disciplines defined with HoDs"
                    tooltipInfo={
                      <>
                        <p>Show Competence Centers with at least 1 discipline and 1 HoD defined</p>
                      </>
                    }
                    info="Percentage of CCs having Disciplines defined with HoDs"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <BarChart
                    data={chartsData.allComponents}
                    name="CCs with all organisation components defined"
                    tooltipInfo={
                      <>
                        <p>
                          &#8226; Show Competence Centers that have an EM, HoEO, HoED, HoD defined
                          with disciplines names and HoD’s,
                          <p>Organization type defined (cc, dcc, etc),</p>
                          <p>
                            Engineering Domain defined (software, hard - At least one checked) and
                            at least one referent, country and declared headcount.
                          </p>
                        </p>
                      </>
                    }
                    info={""}
                    comingSoon={false}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="mb-xs">
              <Grid container spacing={1}>
                <Grid item sm={12} md={4}>
                  <BarChart
                    data={chartsData.referents}
                    name="CCs with 50% of referents defined"
                    tooltipInfo={
                      <>
                        <p className="mb-xxs">
                          &#8226; We count only the Competence Centers having equal or above 50%
                          referents defined
                        </p>
                        <p className="mb-xxs">&#8226; All referents (7) without KPI Stewart</p>
                        <p>
                          &#8226; The ratio is provided according to total nr of Competence Centers
                        </p>
                      </>
                    }
                    info="CC with 50% referents defined"
                  />
                </Grid>
                <Grid item sm={12} md={8}>
                  <GroupedChart
                    data={chartsData.allReferents}
                    name="Percentage of referents defined, per type"
                    tooltipInfo={
                      <>
                        <p className="mb-xxs">
                          &#8226; Evaluate only at the Competence Centers level the reference
                          according with governance (no BL, BU GBU)
                        </p>
                        <p className="mb-xxs">
                          &#8226; Evolution over time: show at each quarter per referant type (Data
                          referent: Q1,Q2, Q3,Q4), Ecodesign (Q1,q2,q3,q4), etc…
                        </p>
                      </>
                    }
                    info=""
                  />
                </Grid>
              </Grid>
            </div>
            <div className="pb-xs">
              <Grid container spacing={1}>
                <Grid item sm={12} md={4}>
                  <BarChart
                    data={chartsData.hodsNoJfOoEm}
                    name="HoD who do not have their EM as Manager or do not belong to JFG"
                    tooltipInfo={
                      <>
                        <p className="mb-xxs">
                          &#8226; We count HoDs that do not have EM as Manager and do not belong to
                          a Discipline Management Job Family.
                        </p>
                      </>
                    }
                    info="HoD who do not have their EM as Manager or do not belong to JFG"
                    unit=""
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </StyledAdministration>
  );
}

import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import EmwClickableTooltip from "../../../../../../../lib/commonv2/EmwClickableTooltip";
import InfoIcon from "../../../../../../../lib/icons/InfoIcon";
import { getEmployeeDetailsApi } from "../../../../redux/api";
import TooltipActionContent from "./TooltipActionContent";
import EmwIcon from "../../../../../../components/EmwIcon/EmwIcon";

export default function TooltipAction({
  em,
  employeeId,
  placement,
  isEngineer,
  setIsEngineer,
  parentId,
  orgId,
  reloadParent,
  reload,
  setChildren,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState("");
  const [hasData, setHasData] = useState(false);
  const [data, setData] = useState({
    email: null,
    firstName: null,
    id: null,
    lastName: null,
    roles: [],
    rolesInfo: [],
    tgi: null,
  });

  useEffect(() => {
    if (isOpen && employeeId) {
      setIsLoading(true);
      getEmployeeDetailsApi(employeeId).then(response => {
        setIsLoading(false);
        setData(response.data);

        if (response.data) {
          setData(response.data);
          setHasData(true);
        } else {
          setNoDataMessage(response.message);
        }
      });
    }
  }, [isOpen]);

  const renderIcon = () => {
    if (em && !isEngineer) {
      return (
        <EmwIcon
          icon="do_not_disturb"
          variant="outlined"
          size="small"
          style={{ color: "#f3a599" }}
        />
      );
    }

    return <InfoIcon />;
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <EmwClickableTooltip
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      content={
        <TooltipActionContent
          em={em}
          data={data}
          hasData={hasData}
          noDataMessage={noDataMessage}
          isEngineer={isEngineer}
          setIsEngineer={setIsEngineer}
          parentId={parentId}
          orgId={orgId}
          reloadParent={reloadParent}
          reload={reload}
          setChildren={setChildren}
        />
      }
      placement={placement}
    >
      <div>
        <IconButton
          onClick={handleClick}
          title="Information"
          disableRipple
          id="btnInformations"
          size="small"
          className="ml-xs p-none"
        >
          {renderIcon()}
        </IconButton>
      </div>
    </EmwClickableTooltip>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { HzaContext } from "../../../../../../../hzaSync/context/HzaContext";
import moment from "moment/moment";
import { DATE_TIME_FORMAT } from "../../../../../../../organization/constants";
import { computeURL, fetchOptions } from "../../../../../../../../../util/fetch";
import { EmwButton } from "../../../../../../../../../lib/common";
import EmwTypography from "../../../../../../../../components/EmwTypography/EmwTypography";
import EmwProgressCircle from "../../../../../../../../components/EmwProgressCircle/EmwProgressCircle";
import { TdsIcon } from "@tds/react";
import ExportBtn from "../ExportBtn/ExportBtn";

export default function ExportSurveyBtn({}) {
  const hzaContext = useContext(HzaContext);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    if (hzaContext.downloadStatus.name === "export_survey") {
      setIsLoading(hzaContext.downloadStatus.isLoading);
    }
  }, [hzaContext.downloadStatus]);

  const handleClick = async () => {
    let fileName = `All_MCtD_surveys-${moment(Date.now()).format(DATE_TIME_FORMAT)}.xlsx`;
    const api = computeURL("survey", "allSurveys/0");
    const params = fetchOptions({ method: "GET" });
    await hzaContext.downloadFile(fileName, api, params, "export_survey");
  };

  return (
    <ExportBtn
      classes="file-download flex-1"
      id="exportSyrveyBtn"
      variant="filled"
      text="M&CtD Survey answers"
      title="M&CtD Survey answers"
      onClick={handleClick}
      loading={loading}
    />
  );
}

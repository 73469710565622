import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { highlightedTgiSelector } from "../features/organization/redux/selectors";

export default function useScrollIntoView({ uniqueId }) {
  const highlightedTgi = useSelector(highlightedTgiSelector);

  const ref = useRef();

  useEffect(() => {
    if (uniqueId !== highlightedTgi) {
      return;
    }
    if (ref.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }, [ref]);

  return {
    ref,
  };
}

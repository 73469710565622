import React, { useEffect, useState } from "react";
import { QtmTab, QtmTabs } from "@qtm/react";

export default function WFBFormSheets({ wfbSheets, handleSheetClick, activeTab }) {
  return (
    <QtmTabs fullWidth size="small">
      {wfbSheets &&
        wfbSheets.length &&
        wfbSheets.map((sheet, index) => {
          return (
            <QtmTab
              label={sheet}
              value={index}
              active={activeTab === index}
              onClickedTabEvent={e => handleSheetClick(e)}
            ></QtmTab>
          );
        })}
    </QtmTabs>
  );
}

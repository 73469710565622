import React, { useContext } from "react";
import { EmwDropDownList } from "../../../../../../../../../lib/common";
import { Box, Grid } from "@mui/material";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";

const options = [
  {
    value: "CC",
    text: "CC",
    info: "Competence Center", // TODO: bug add hover on text name
  },
  {
    value: "DCC",
    text: "DCC",
    info: "Digital Competence Center",
  },
  {
    value: "DPT",
    text: "DPT",
    info: "Department",
  },
  {
    value: "Other",
    text: "Other",
    info: "Other",
  },
  {
    value: null,
    text: "Not Set",
    info: "Org. Type Not Set",
  },
];

export default function CCTypes() {
  const context = useContext(OrganizationCardContext);

  const handleChange = event => {
    context.updateCard("section1", "type", {
      value: event.detail.value,
      enabled: true,
      visible: true,
    });
  };

  return (
    <Grid item mx={2}>
      <Box id="organizationCard-cc-types" title="Org. Type">
        <EmwDropDownList
          options={options}
          placement="bottom"
          onDropDownListChanged={handleChange}
          size="small"
          placeHolder="CC Type"
          disabled={!context.data.section1.type.enabled}
          value={context.data.section1.type.value}
          inputId={"cc-type"}
        />
      </Box>
    </Grid>
  );
}

import React from "react";

export default function ChartInfo({ info, comingSoon }) {
  if (Array.isArray(info)) {
    return (
      <div className="flex items-center ml-m">
        {info.map(value => {
          return (
            <>
              <div
                className="rounded-xl mx-xxs"
                style={{ backgroundColor: value.color, width: "8px", height: "8px" }}
              ></div>
              <p className="text-scale-8">{value.text}</p>
            </>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {info && (
        <div className="flex bullet items-center ml-m">
          <div className="bg-primary-500 rounded-xl mr-xxs" />
          <p className="text-scale-8">{info}</p>
        </div>
      )}
      {comingSoon && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            top: "45%",
          }}
        >
          <p className="text-primary-500">Coming soon</p>
        </div>
      )}
    </>
  );
}

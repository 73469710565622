import React, { useContext, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import DiagramColumn from "../DiagramColumn/DiagramColumn";
import { DiagramContext } from "../../context/DiagramContext";
import { useSelector } from "react-redux";
import { perspectiveSelector, filterCCTypeSelector } from "../../../../redux/selectors";

export default function DiagramBody({ schema }) {
  const context = useContext(DiagramContext);
  const perspective = useSelector(perspectiveSelector);
  const orgFilter = useSelector(filterCCTypeSelector);

  useEffect(() => {
    const nodes = schema.defaultChild[perspective].nodes;
    context.addColumn(context.generateNodesSvgPath(1, nodes));
  }, [perspective]);

  const scrollScreen = nOColumnsToStartScroll => {
    const element = document.getElementById("diagramScrollingPoint");
    const incrementValue = 226;
    const currentScrollXValue = incrementValue * (schema.columns.length - nOColumnsToStartScroll);
    if (schema.columns.length > nOColumnsToStartScroll) {
      element.scroll(currentScrollXValue, 0);
    } else {
      element.scroll(0, 0);
    }
  };

  useEffect(() => {
    if (document.getElementById("diagramScrollingPoint").clientWidth < 600) {
      scrollScreen(2);
    } else if (document.getElementById("diagramScrollingPoint").clientWidth < 768) {
      scrollScreen(3);
    } else {
      scrollScreen(4);
    }
  }, [schema.columns]);

  const shouldFilterNodes = node =>
    node.type === "BL" || node.type === "Domain" || node.type === "CC";

  const filterNodes = nodes => nodes.filter(item => orgFilter.includes(item.ccType));

  const updateColumn = (node, nodes) => {
    if (orgFilter.length === 0 || orgFilter.includes("ALL")) {
      context.selectNode(node, nodes);
    } else {
      const updatedNodes = shouldFilterNodes(node) ? filterNodes(nodes) : nodes;
      context.selectNode(node, updatedNodes);
    }
  };

  return (
    <Grid container id="diagram-body" flexWrap="nowrap">
      <Box
        className="tour-diagram-body"
        display="flex"
        position="relative"
        padding="16px 10px 0 20px"
        left="-20px"
        top="-16px"
      >
        {schema.columns.map((column, index) => {
          return (
            <DiagramColumn
              key={column.type}
              name={column.type}
              nodes={column.nodes}
              index={index}
              handleClick={updateColumn}
            />
          );
        })}
      </Box>
    </Grid>
  );
}

import React from "react";
import { TdsIcon } from "@tds/react";
import EmwTypography from "../../../../../components/EmwTypography/EmwTypography";
import { EmwButton } from "../../../../../../lib/common";
import { Grid } from "@mui/material";
import { changeView } from "../../../redux/actions";
import { ORGANIZATION_VIEW } from "../../../constants";
import { useDispatch } from "react-redux";
import GuestPowerGuestModal from "./GuestPowerGuestModal";

export default function GuestPowerGuestHeader({
  guests,
  powerGuests,
  excoms,
  showExcoms,
  showGuests,
  showPowerGuests,
  setExcoms,
  setGuests,
  setPowerGuests,
  defaultUser,
  canEdit,
  handleSave,
  handleCancel,
  btnSave,
  setIsOpen,
  isOpen,
  initialGuests,
  initialPowerGuests,
  setIsReset,
}) {
  const dispatch = useDispatch();

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <Grid container className="cardHeader pb-s mb-m border-b-2 border-primary-500">
      <Grid item>
        <TdsIcon classes="text-primary-500" icon="manage_accounts" variant="outlined" size="xlarge" />
      </Grid>
      <Grid item>
        <EmwTypography classes="text-primary-500" fontWeight="bold">
          View Guests/Power Guests
        </EmwTypography>
      </Grid>
      <Grid item ml="auto" display="flex">
        <EmwButton
          variant="outline"
          classes="mr-xs"
          size="small"
          id="btnBack"
          onClick={() => dispatch(changeView(ORGANIZATION_VIEW.DETAILS))}
        >
          Back
        </EmwButton>
        {canEdit && (
          <EmwButton
            variant="filled"
            size="small"
            title="Edit"
            id="btnEdit"
            classes="edit-button"
            onClick={handleClick}
          >
            <TdsIcon icon="edit" variant="outlined" size="small" />
            <EmwTypography>Edit</EmwTypography>
          </EmwButton>
        )}

        <GuestPowerGuestModal
          btnSave={btnSave}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          guests={guests}
          powerGuests={powerGuests}
          excoms={excoms}
          setExcoms={setExcoms}
          showExcoms={showExcoms}
          showGuests={showGuests}
          showPowerGuests={showPowerGuests}
          setPowerGuests={setPowerGuests}
          setGuests={setGuests}
          defaultUser={defaultUser}
          canEdit={canEdit}
          handleSave={handleSave}
          handleCancel={handleCancel}
          initialGuests={initialGuests}
          initialPowerGuests={initialPowerGuests}
          setIsReset={setIsReset}
        />
      </Grid>
    </Grid>
  );
}

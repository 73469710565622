import { Box, Collapse, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import EmwTypography from "../../../../../components/EmwTypography/EmwTypography";
import GuestPowerGuestListItem from "./GuestPowerGuestListitem";
import { TdsIcon } from "@tds/react";
import { G_PG } from "../../../constants";

export default function GuestPowerGuestList({ name, list }) {
  const [isOpen, setIsOpen] = useState(name === G_PG.EXCOMS.name ? false : true);
  const renderList = () => {
    return list.map(item => {
      return <GuestPowerGuestListItem item={item} name={item.name} key={item.id} />;
    });
  };
  return (
    <>
      <Grid container className="guest-sections">
        <EmwTypography classes="text-bluegrey-600 name" fontSize="8">
          {name}
        </EmwTypography>
        <Grid item ml={"auto"}>
          <Grid container>
            <IconButton
              title={`${isOpen ? "Collapse section" : "Expand section"}`}
              disableRipple
              size="small"
              id={`btnToggle${name}`}
              onClick={() => setIsOpen(!isOpen)}
              sx={{ marginLeft: "auto" }}
            >
              {isOpen ? (
                <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: "#4A4D65" }} />
              ) : (
                <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: "#4A4D65" }} />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpen} sx={{ width: "100%" }}>
        <Box>{renderList()}</Box>
      </Collapse>
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
} from "@mui/material";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";
import {
  StyledEEIReadMeTitle,
  StyledEEIReadMeHeaderCells,
  StyledEEIReadMeLeftCells,
  StyledEEIReadMeDescriptionCells,
  StyledWfbReadMeDescriptionCells,
  StyledEEIReadMeSubtitle,
} from "./styled";

export default function EEIReadMe({ readMeData, isWFB, isByCountry }) {
  const [title, setTitle] = useState("");
  const [rows, setRows] = useState([]);
  const [subtitle, setSubtitle] = useState("");

  const wfbTitle = "WFB (WorkForce Breakdown)";

  useEffect(() => {
    if (isWFB) {
      setTitle(wfbTitle);
      setRows(readMeData.rows);
    } else if (isByCountry) {
      const readMeRows = [];
      const rowsWithoutTitle = readMeData.slice(2);
      rowsWithoutTitle.map(row => {
        readMeRows.push(row.cells);
      });
      setTitle(readMeData[0].cells[0].value);
      setSubtitle(readMeData[1].cells[0].value);
      setRows(readMeRows);
    } else {
      setTitle(readMeData.readMeTitle);
      setSubtitle(readMeData.readMeSubtitle);
      setRows(readMeData.readMeRows);
    }
  }, []);

  isWFB &&
    rows &&
    rows.map((row, index) => {
      const cells = row.cellList;
      row.cellName = cells[0].name;
      row.description = cells[1].name;
      row.validation = cells[2].name;
    });

  isByCountry &&
    rows &&
    rows.map(row => {
      row.cellName = row[0] ? row[0].value : "";
      row.description = row[2] ? row[2].value : "";
      row.validation = row[4] ? row[4].value : "";
    });

  return (
    <>
      <StyledEEIReadMeTitle item className="flex">
        <EmwTypography fontSize={6} classes="">
          {title}
        </EmwTypography>
      </StyledEEIReadMeTitle>
      <StyledEEIReadMeSubtitle>
        <EmwTypography fontSize={8}>{subtitle}</EmwTypography>
      </StyledEEIReadMeSubtitle>
      <TableContainer component={Paper}>
        <Table stickyHeader size="small" sx={{ width: "835px" }}>
          {isWFB && (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Cells</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Validation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={index} sx={{ height: "30px" }}>
                      <StyledWfbReadMeDescriptionCells
                        colSpan={row.merged ? 3 : 1}
                        value={row.cellName}
                        issmallcell={true}
                      >
                        {row.cellName}
                      </StyledWfbReadMeDescriptionCells>
                      <StyledWfbReadMeDescriptionCells
                        colSpan={row.merged ? 3 : 1}
                        value={row.description}
                        issmallcell={true}
                      >
                        {row.description}
                      </StyledWfbReadMeDescriptionCells>
                      <StyledWfbReadMeDescriptionCells
                        colSpan={row.merged ? 3 : 1}
                        value={row.validation}
                      >
                        {row.validation}
                      </StyledWfbReadMeDescriptionCells>
                    </TableRow>
                  ))}
              </TableBody>
            </>
          )}
          {isByCountry && (
            <>
              <TableHead>
                <TableRow>
                  <StyledEEIReadMeHeaderCells>Cells</StyledEEIReadMeHeaderCells>
                  <StyledEEIReadMeHeaderCells>Description</StyledEEIReadMeHeaderCells>
                  <StyledEEIReadMeHeaderCells>Validation</StyledEEIReadMeHeaderCells>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={index}>
                      <StyledEEIReadMeLeftCells component="th" scope="row" value={row.cellName}>
                        {row.cellName}
                      </StyledEEIReadMeLeftCells>
                      <StyledEEIReadMeDescriptionCells value={row.description}>
                        {row.description}
                      </StyledEEIReadMeDescriptionCells>
                      <StyledEEIReadMeDescriptionCells value={row.validation}>
                        {row.validation}
                      </StyledEEIReadMeDescriptionCells>
                    </TableRow>
                  ))}
              </TableBody>
            </>
          )}
          {!isWFB && !isByCountry && (
            <>
              <TableHead>
                <TableRow>
                  <StyledEEIReadMeHeaderCells>Cells</StyledEEIReadMeHeaderCells>
                  <StyledEEIReadMeHeaderCells>Description</StyledEEIReadMeHeaderCells>
                  <StyledEEIReadMeHeaderCells>Validation</StyledEEIReadMeHeaderCells>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={index}>
                      <StyledEEIReadMeLeftCells component="th" scope="row">
                        {row.cellA}
                      </StyledEEIReadMeLeftCells>
                      <StyledEEIReadMeDescriptionCells>{row.cellB}</StyledEEIReadMeDescriptionCells>
                      <StyledEEIReadMeDescriptionCells>{row.cellC}</StyledEEIReadMeDescriptionCells>
                    </TableRow>
                  ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

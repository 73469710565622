import React, { Component } from "react";
import { isUserAuthorized } from "../../../util/auth";
import { withRouter } from "react-router-dom";
import DefaultLayout from "../../../layout/DefaultLayout/DefaultLayout";
import PublicLayout from "../../../layout/PublicLayout/PublicLayout";

class Authenticated extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleConnection = this.handleConnection.bind(this);
  }

  handleConnection() {
    const Url = new URL(window.location.href);

    if (Url.pathname !== "/logout") {
      this.props.history.push("/");
    }
  }

  render() {
    if (localStorage.getItem("login.dev") && !isUserAuthorized()) {
      return (window.location.href = "/login");
    }
    if (!isUserAuthorized()) {
      this.handleConnection();
      return <div />;
    }
    const Url = new URL(window.location.href);
    if (Url.pathname === "/logout") {
      return <PublicLayout>{this.props.children}</PublicLayout>;
    }

    return <DefaultLayout>{this.props.children}</DefaultLayout>;
  }
}

export default withRouter(Authenticated);

import React, { cloneElement } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import "../style.css";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
  confirmCancel: false,
};
const defaultSteps = [
  {
    id: "welcome",
    text: [
      `
      This is your updates area. Here you will get notifications about changes in your organization, changes in team structure, and more.You can view them by clicking on “ View Updates “. Click next to continue.
      `,
    ],
    attachTo: { element: ".notification-box-highlight", on: "right" },
    classes: "",
    highlightClass: "highlightClass",
    buttons: [
      {
        type: "cancel",
        classes: "qtm-btn qtm-ghost qtm-neutral",
        text: "Cancel",
      },
      {
        type: "next",
        classes: "qtm-btn qtm-filled qtm-primary",
        text: "Next",
      },
    ],
  },
  {
    id: "user-tooltip",
    title: "",
    text: "Here you can view your personal information, such as TGI, email, and roles, by hovering over the name. Clicking on a specific role will take you to that organization.",
    attachTo: { element: ".user-tooltip", on: "bottom" },
    buttons: [
      {
        type: "cancel",
        classes: "qtm-btn qtm-ghost qtm-neutral",
        text: "Cancel",
      },
      {
        type: "back",
        classes: "qtm-btn qtm-outline qtm-primary previous",
        text: "Previous",
      },
      {
        type: "next",
        classes: "qtm-btn qtm-filled qtm-primary",
        text: "Next",
      },
    ],
  },
  {
    id: "trigger-menu",
    title: "",
    text: "By clicking here, you will open the menu.",
    attachTo: { element: ".trigger-menu", on: "bottom" },
    buttons: [
      {
        type: "cancel",
        classes: "qtm-btn qtm-ghost qtm-neutral",
        text: "Cancel",
      },
      {
        type: "back",
        classes: "qtm-btn qtm-outline qtm-primary previous",
        text: "Previous",
      },
      {
        type: "next",
        classes: "qtm-btn qtm-filled qtm-primary",
        text: "Next",
      },
    ],
  },
];
export default function LandingPageTour({ children }) {
  return (
    <ShepherdTour steps={defaultSteps} tourOptions={tourOptions}>
      {cloneElement(children, { tourContext: ShepherdTourContext })}
    </ShepherdTour>
  );
}

import React, { useEffect, useState } from "react";
import { ENGINEERING_COMPETITIVENESS, MOOD_AND_CONFIDENCE } from "../constants";
import TargetModal from "../components/TargetModal/TargetModal";
import { useDispatch, useSelector } from "react-redux";
import { alertHistoryModalSelector, commentHistoryModalSelector } from "../redux/selectors";
import AlertModal from "../components/AlertModal/AlertModal";
import usePowerBi from "./usePowerBi";
import HistoryModal from "../components/HistoryModal/HistoryModal";
import { EVENTS, subscribe, unsubscribe } from "../events";
import MyCommentsModal from "../components/MyCommentsModal/MyCommentsModal";
import CommentsHistoryModal from "../components/CommentsHistoryModal/CommentsHistoryModal";
import { setSelectedReport } from "../redux/actions";
import { isHzaSelector } from "../../footer/redux/selectors";

window.isTabClicked = false;
window.filtersPBIAreSet = false;
window.tdpReportLoaded = false;
export default function useModals(kpiName, kpiCharts, showAlerts, setShowAlerts, iframeRef) {
  const dispatch = useDispatch();
  const historyModal = useSelector(alertHistoryModalSelector);
  const commentsHistoryModal = useSelector(commentHistoryModalSelector);
  const powerBi = usePowerBi(iframeRef);
  const [showTargetsModal, setShowTargetsModal] = useState(false);
  const [showAlertsModal, setShowAlertsModal] = useState(false);
  const [showMyCommentsModal, setShowMyCommentsModal] = useState(false);
  const [selectedChart, setSelectedChart] = useState();
  const [chartIndex, setChartIndex] = useState();
  const isHza = useSelector(isHzaSelector);

  useEffect(() => {
    subscribe(EVENTS.TARGET_CONFIDENCE_TO_DELIVER, () => showForm(6, "target"));
    subscribe(EVENTS.TARGETS_DADV, () => showForm(5, "target"));
    subscribe(EVENTS.TARGETS_DOTD, () => showForm(1, "target"));
    subscribe(EVENTS.COMPETITIVENESS, () => {
      dispatch(setSelectedReport(29));
      changePageOperatingModels(EVENTS.COMPETITIVENESS);
    });
    subscribe(EVENTS.OPERATING_MODEL, () => {
      dispatch(setSelectedReport(35));
      changePageOperatingModels(EVENTS.OPERATING_MODEL);
    });
    subscribe(EVENTS.CRAFTMANSHIP_MODEL, () => {
      dispatch(setSelectedReport(33));
      changePageOperatingModels(EVENTS.CRAFTMANSHIP_MODEL);
    });
    subscribe(EVENTS.TALENTS_AND_CULTURE, () => {
      dispatch(setSelectedReport(31));
      changePageOperatingModels(EVENTS.TALENTS_AND_CULTURE);
    });
    subscribe(EVENTS.BROWSING, () => {
      dispatch(setSelectedReport(66));
      changePageOperatingModels(EVENTS.BROWSING);
    });
    subscribe(EVENTS.ANALYSIS, () => {
      dispatch(setSelectedReport(64));
      changePageOperatingModels(EVENTS.ANALYSIS);
    });
    subscribe(EVENTS.TRANSFORMATION, () => {
      changePageOperatingModels(EVENTS.TRANSFORMATION);
    });

    return () => {
      Object.keys(EVENTS).forEach(event => unsubscribe(event));
    };
  }, [kpiName]);

  const showForm = (chartIndex, type, sectionName) => {
    setShowTargetsModal(type === "target");
    setShowAlertsModal(type === "alert");
    setShowMyCommentsModal(type === "comment");

    if (sectionName === "Talents Culture_Default") {
      if (chartIndex === 1) {
      }
      setSelectedChart(kpiCharts[chartIndex]);
      setChartIndex(chartIndex);
    } else {
      setSelectedChart(kpiCharts[chartIndex]);
      setChartIndex(chartIndex);
    }
  };

  const getPageMap = () => {
    const pageMap = [];
    //pageMap["OPERATING MODEL"] = "ReportSectionfa523654a26976a87575";
    pageMap["OPERATING MODEL"] = "ReportSection68599c270ff986aa3403";
    pageMap["CRAFTMANSHIP MODEL"] = "ReportSection7c5f6105adc65acc4d7f";
    pageMap["TALENTS & CULTURE"] = "ReportSection966676f274e368f511af";
    pageMap["COMPETITIVENESS"] = "ReportSectionf445ac6e2fc5f81534b1";
    pageMap["JOB FAMILY BROWSING"] = "ReportSection27d68bf0023d76e62190";
    pageMap["JOB FAMILY ANALYSIS"] = "ReportSection9adae67190764c68478e";
    pageMap["MIGRATION TRANSFORMATION"] = "ReportSection21d7f3e0099e9e5377ce";
    return pageMap;
  };

  const changePageOperatingModels = item => {
    if (item === EVENTS.OPERATING_MODEL) {
      return;
    }
    async function changePage(item) {
      window.isTabClicked = true;
      // if (item !== 'OPERATING MODEL') {
      // if (item !== 'COMPETITIVENESS' && item !== 'OPERATING MODEL'){
      try {
        await window.report.setPage(getPageMap()[item]);
      } catch (e) {
        console.warn(e);
      }
      // }
      // } else {

      //  }
    }
    if (window.report) {
      changePage(item);
    } else {
      try {
        const iframeContent =
          document.querySelector(".embed-container iframe").contentWindow.document;
        switch (item) {
          case "COMPETITIVENESS":
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 29,
            });
            break;
          case "OPERATING MODEL":
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 35,
            });
            break;
          case "CRAFTMANSHIP MODEL":
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 33,
            });
            break;
          case "TALENTS & CULTURE":
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 31,
            });
            break;
          case "JOB FAMILY BROWSING":
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 66,
            });
            break;
          case "JOB FAMILY ANALYSIS":
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 67,
            });
            break;
          case "MIGRATION TRANSFORMATION":
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 68,
            });
            break;
        }
      } catch (e) {
        console.warn("iframe content not yet loaded");
      }
    }
  };

  /**
   *
   * @param chartIndex the index from the redux state chart array
   * @param formType alert|target|comment
   * @param visualSectionIndex the visual section index from the document.explorationNavigation.legacyService group
   * @param sectionName the section name from the document.explorationNavigation.legacyService
   * @returns {JSX.Element}
   */
  const generateHiddenBtn = (chartIndex, formType, visualSectionIndex, sectionName) => {
    return (
      <button
        onClick={() => showForm(chartIndex, formType, sectionName)}
        id={`${formType}${visualSectionIndex}${sectionName}`}
      >
        Alert mood
      </button>
    );
  };

  const renderHiddenButtons = () => {
    if (kpiName === MOOD_AND_CONFIDENCE) {
      return (
        <div className="hidden">
          {generateHiddenBtn(0, "alert", 7, "Mood Confidence to deliver Default")}
          {generateHiddenBtn(0, "target", 7, "Mood Confidence to deliver Default")}
          {generateHiddenBtn(0, "comment", 7, "Mood Confidence to deliver Default")}

          {generateHiddenBtn(1, "alert", 8, "Mood Confidence to deliver Default")}
          {generateHiddenBtn(1, "target", 8, "Mood Confidence to deliver Default")}
          {generateHiddenBtn(1, "comment", 8, "Mood Confidence to deliver Default")}
        </div>
      );
    }

    if (kpiName === ENGINEERING_COMPETITIVENESS) {
      return (
        <div className="hidden">
          <button onClick={() => changePageOperatingModels("COMPETITIVENESS")}>
            COMPETITIVENESS
          </button>
          <button onClick={() => changePageOperatingModels("OPERATING MODEL")}>
            OPERATING MODEL
          </button>
          <button onClick={() => changePageOperatingModels("CRAFTMANSHIP MODEL")}>
            CRAFTMANSHIP
          </button>
          <button onClick={() => changePageOperatingModels("TALENTS & CULTURE")}>
            TALENTS & CULTURE
          </button>

          {/* DOTD buttons */}
          {generateHiddenBtn(0, "alert", 7, "Competitiveness_Default")}
          {generateHiddenBtn(0, "target", 7, "Competitiveness_Default")}
          {generateHiddenBtn(0, "comment", 7, "Competitiveness_Default")}

          {/* DAVD buttons */}
          {generateHiddenBtn(2, "alert", 11, "Competitiveness_Default")}
          {generateHiddenBtn(2, "target", 11, "Competitiveness_Default")}
          {generateHiddenBtn(2, "comment", 11, "Competitiveness_Default")}

          {/* Confidence to deliver buttons */}
          {generateHiddenBtn(1, "alert", 10, "Competitiveness_Default")}
          {generateHiddenBtn(1, "target", 10, "Competitiveness_Default")}
          {generateHiddenBtn(1, "comment", 10, "Competitiveness_Default")}

          {/*Mood*/}
          {generateHiddenBtn(0, "alert", 11, "Talents Culture_Default")}
          {generateHiddenBtn(0, "target", 11, "Talents Culture_Default")}
          {generateHiddenBtn(0, "comment", 11, "Talents Culture_Default")}

          {/* Key Profile Fit */}

          {generateHiddenBtn(1, "alert", 10, "Talents Culture_Default")}
          {generateHiddenBtn(1, "target", 10, "Talents Culture_Default")}
          {generateHiddenBtn(1, "comment", 10, "Talents Culture_Default")}

          {/* Skill Coverage */}
          {generateHiddenBtn(2, "alert", 7, "Talents Culture_Default")}
          {generateHiddenBtn(2, "target", 7, "Talents Culture_Default")}
          {generateHiddenBtn(2, "comment", 7, "Talents Culture_Default")}
        </div>
      );
    }

    return null;
  };

  const renderModals = () => {
    return (
      <>
        {showTargetsModal && selectedChart && (
          <TargetModal
            isOpen={showTargetsModal}
            setIsOpen={setShowTargetsModal}
            selectedChart={selectedChart}
            onButtonCancelClick={() => setShowTargetsModal(false)}
          />
        )}

        {showAlertsModal && selectedChart && (
          <AlertModal
            isOpen={showAlertsModal}
            setIsOpen={setShowAlertsModal}
            charts={kpiCharts}
            selectedChart={selectedChart}
            generalFilters={powerBi.getGeneralFilters()}
            timeframeFilters={powerBi.getTimeframeFilters()}
            showAlerts={showAlerts}
            setShowAlerts={setShowAlerts}
            chartIndex={chartIndex}
          />
        )}

        {showMyCommentsModal && selectedChart && (
          <MyCommentsModal
            isOpen={showMyCommentsModal}
            setIsOpen={setShowMyCommentsModal}
            selectedChart={selectedChart}
          />
        )}

        {historyModal.isOpen && <HistoryModal />}

        {commentsHistoryModal.isOpen && <CommentsHistoryModal selectedChart={selectedChart} />}
      </>
    );
  };

  return {
    renderHiddenButtons,
    renderModals,
  };
}

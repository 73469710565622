import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { getZoom } from "../../../../utils/sizeUtils";

export const StyledOrganizationHeader = styled(Grid)(() => {
  let zoom = getZoom();

  return {
    backgroundColor: "#F7F7F9",

    ".qtm-btn": {
      // zoom: `${zoom}%`,
      padding: "0 19.5px",
      fontSize: "14px",
    },

    ".tree-header-actions": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
      backgroundColor: "#F7F7F9",
      borderRight: "1px solid #dedee0",
    },
    ".header-perspective-buttons": {
      padding: "10px 5px",
      width: "213px",
    },
    ".tour-search": {
      padding: "10px 3px",
    },
    ".filter-cc-types-dropdown": {
      ".qtm-text-input > input": {
        height: "30px",
        paddingRight: "0px",
      },
    },
  };
});

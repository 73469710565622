import React, { useState } from "react";
import { Collapse, Divider, Grid } from "@mui/material";
import { TdsIcon } from "@tds/react";
import { EmwButton } from "../../../../../lib/common";
import FavoriteIconBtn from "./FavoriteIconBtn";
import DiagramLink from "../Diagram/components/DiagramLink/DiagramLink";
import EmwTypography from "../../../../components/EmwTypography/EmwTypography";

const expandCircleDownCss = {};
const expandCircleUpCss = {
  transform: "rotate(-180deg)",
};

export default function FavoriteCardItems({ items, title, hasIcon, id }) {
  const [toggled, setToggled] = useState(true);

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ borderBottom: "2px solid #041295" }}>
        <EmwTypography classes="heading" component="body-1">
          {title}
        </EmwTypography>

        <EmwButton variant="ghost" size="small" id={id} className="toggle-btn" onClick={() => setToggled(!toggled)}>
          <TdsIcon
            icon={"expand_circle_down"}
            title={"Collapse favorite"}
            size="small"
            variant="outlined"
            style={toggled ? expandCircleUpCss : expandCircleDownCss}
          ></TdsIcon>
        </EmwButton>
      </Grid>

      <Collapse in={toggled}>
        <Grid container direction="column" justifyContent="space-between">
          {items.map(item => {
            return (
              <React.Fragment key={item.id}>
                <Grid item className="favorite-list-item">
                  <DiagramLink item={item}>
                    <EmwTypography component="body-2" classes="list-item-link">
                      {item.name}
                    </EmwTypography>
                  </DiagramLink>

                  {hasIcon && <FavoriteIconBtn item={item} />}
                </Grid>
                <Divider />
              </React.Fragment>
            );
          })}
        </Grid>
      </Collapse>
    </Grid>
  );
}

import { Dialog, styled, Grid } from "@mui/material";
import HotTable from "@handsontable/react";

export const StyledEmwWebTable = styled(Dialog)(({ theme }) => ({
  "#form-dialog-header": {
    height: "60px",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },

  "#webExcel .handsontable thead th:not(:first-child)": {
    backgroundColor: "#3e6caf !important",
    color: "white",
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "11px",
    paddingTop: "6px",
  },

  "#webExcel .handsontable td .htCheckboxRendererInput  ": {
    width: "100%",
  },

  "#webExcel .handsontable .red-border": {
    border: "1px solid red",
  },

  ".handsontable.htInvalid.htAutocomplete": {
    backgroundColor: "transparent !important",
  },

  ".loading-wrapper": {
    display: "flex",
    justifyContent: "center",
  },

  "#eeiHotTable .handsontable .read-only-data": {
    backgroundColor: "#aac0de !important",
    color: "black",
  },

  "#eeiHotTable .handsontable .editable-data": {
    backgroundColor: "#ffffff !important",
    color: "black",
  },

  "#eeiHotTable .handsontable .red-border": {
    border: "1px solid red",
  },

  "#eeiHotTable .handsontable .htCommentCell:after": {
    borderTop: "6px solid #FF0707",
  },
}));

export const StyledHotTable = styled(HotTable)(({ theme }) => ({
  "thead th:not(:first-child)": {
    backgroundColor: "#041295 !important",
    color: "white",
    fontFamily: "Roboto",
    fontSize: "11px",
    paddingTop: "6px",
  },
}));

// TODO: remove zoom after implementing a responsive design
export const getZoom = () => {
  let ignoreZoom;

  if (navigator.cookieEnabled) {
    ignoreZoom = localStorage.getItem("ignoreZoom");
  }

  if (ignoreZoom === "true") {
    return 100;
  }

  let zoom = (window.innerHeight / 860) * 100;

  if (zoom > 100) {
    return 100;
  }

  return zoom;
};

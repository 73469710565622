import { Grid, styled } from "@mui/material";

export const StyledAlertsList = styled(Grid)(({ theme }) => ({
  ".alert-list": {
    height: "30px",
    button: {
      height: "30px",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  ".alert-item": {
    height: "38px",
  },

  "tds-tag": {
    ".qtm-tag": {
      width: "54px",
      height: "24px",
    },
  },
}));

import React, { useEffect, useState } from "react";
import CellText from "../CellText/CellText";
import CellEngineer from "../CellEngineer/CellEngineer";
import { getStyle } from "../../utils";

/**
 * @todo Add the rest of the types
 * @type {{DROPDOWN_LIST: string, ENGINEER: string, TEXT: string}}
 */
const CELL_TYPE = {
  TEXT: "text",
  ENGINEER: "engineerComponent",
  DROPDOWN_LIST: "",
};
export default function CellRenderer({ cellData, columnData, dataKey, rowData, rowIndex }) {
  if (cellData.valueType === CELL_TYPE.TEXT) {
    return (
      <CellText
        cell={cellData}
        style={getStyle(cellData)}
        className="text-scale-8 cellType__text"
      />
    );
  }

  if (cellData.valueType === "engineerComponent") {
    return (
      <CellEngineer
        cell={cellData}
        style={getStyle(cellData)}
        className="text-scale-8 px-xxs py-xxs cellType__engineer"
      />
    );
  }

  return null;
}

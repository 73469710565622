import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { TdsIcon, TdsProgressCircle } from "@tds/react";
import cls from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  changeView,
  clearGuestDetails,
  setHighlightedTgi,
  setOrgDetails,
  changeCCType,
  setExpandPath,
} from "../../../../redux/actions";
import { perspectiveSelector, requestedOrgIdSelector } from "../../../../redux/selectors";
import {
  ORGANIZATION_PERSPECTIVE,
  ORGANIZATION_TYPE,
  ORGANIZATION_VIEW,
} from "../../../../constants";
import * as api from "../../../../redux/api";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";

export default function DiagramNode({ node, handleClick }) {
  const dispatch = useDispatch();
  const perspective = useSelector(perspectiveSelector);
  const requestedId = useSelector(requestedOrgIdSelector);
  const [hasStar, setHasStar] = useState(false);
  const [hasGreyStar, setHasGreyStar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const className = cls({
    selected: node.selected,
    unknown: node.unknown,
    greyed: !node.childUnderMyResponsability,
    hasStar: node.underMyResponsability,
    hasAllocation: node.hasAllocation,
    dcc: node.ccType === "DCC",
    dpt: node.ccType === "DPT",
    cc: node.ccType === "CC",
    other: node.ccType === "Other",
    notSet: node.ccType === null && !node.unknown && node.type === "CC",
  });

  useEffect(() => {
    dispatch(clearGuestDetails({ list: {}, error: null, loading: false }));
    setHasStar(() => {
      return node.administrativeAction;
    });
    setHasGreyStar(() => {
      return !node.administrativeAction && node.underMyResponsability;
    });
  }, []);

  useEffect(() => {
    setIsLoading(requestedId === node.id);
  }, [requestedId]);

  const onClick = async () => {
    dispatch(setHighlightedTgi(""));
    dispatch(setExpandPath([]));
    const payload = { id: node.id, type: node.type, parentCountryId: node.parentCountryId };
    setIsLoading(true);

    if (node.type === "CC") {
      payload.blCode = node.parentBl;
    }

    if (node.type === "REGION") {
      payload.region = node.name;
    }

    if (node.type === "GROUP" && perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      payload.type = "GROUP_COUNTRY";
    }
    const org = await api.getOrganizationDetailsApi(payload);
    dispatch(setOrgDetails({ list: org, error: null, loading: false }));
    const nodes = org.data.childs;
    const currenNode = {
      ...org.data.cardDetails,
      ...node,
    };

    handleClick(currenNode, nodes);
    setIsLoading(false);
    if (node.name === "FAVORITES") {
      dispatch(changeCCType([]));
      dispatch(changeView(ORGANIZATION_VIEW.FAVORITES));
    } else {
      dispatch(changeView(ORGANIZATION_VIEW.DETAILS));
    }
  };
  const getNodeType = node => {
    if (node.type !== "CC") return;

    if (!node.ccType && !node.unknown) {
      return "NOT SET";
    }

    if (node.ccType) {
      return node.ccType.toUpperCase();
    }
  };
  const cardHoverInfo = () => {
    if (node.abbreviation && !node.description) {
      return node.abbreviation;
    }
    if (node.description && !node.abbreviation) {
      return node.description;
    }
    if (node.abbreviation && node.description) {
      return `${node.abbreviation}\n${node.description}`;
    } else {
      return node.name;
    }
  };

  return (
    <>
      <Grid item id="tooltip-cc-definition" sm={3} className="diagram-node" title={cardHoverInfo()}>
        <Box
          className={`diagram-node-link ${className}`}
          onClick={onClick}
          id={`${node.code ? node.code : node.name}`}
        >
          <Grid container className="items-center justify-between">
            {hasStar && (
              <Box className="node-star">
                <TdsIcon icon="star" size={"small"} style={{ color: "white" }} />
              </Box>
            )}

            {hasGreyStar && (
              <Box className="node-grey-star">
                <TdsIcon icon="star" size={"small"} style={{ color: "grey" }} />
              </Box>
            )}
            {/* leave this empty box for now until we refactor*/}
            <Box></Box>

            {isLoading && (
              <Box ml="auto">
                <TdsProgressCircle color={node.selected ? "white" : "primary"} />
              </Box>
            )}
            <EmwTypography
              classes={`${
                node.selected ? "text-white-100" : "text-black-500"
              } diagram-node-cc-type text-scale-9 font-bold mr-3xs `}
            >
              {getNodeType(node)}
            </EmwTypography>
          </Grid>
        </Box>
        <Grid container className="diagram-node-info">
          <EmwTypography classes={`diagram-node-name ${className}`}>
            {[ORGANIZATION_TYPE.CC, ORGANIZATION_TYPE.REGION, ORGANIZATION_TYPE.COUNTRY].includes(
              node.type
            )
              ? node.name
              : node.code}
          </EmwTypography>

          {node.hasAllocation && (
            <Box className="node-allocated">
              <TdsIcon icon="multiple_stop" size="small" />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import useUser from "../../../../auth/useUser";
import EmwTypography from "../../../../components/EmwTypography/EmwTypography";
import OrgRole from "./components/OrgRole/OrgRole";
import EmwTooltip from "../../../../components/EmwToggleMenu/EmwTooltip";
import EmwIcon from "../../../../components/EmwIcon/EmwIcon";

export default function UserDataTooltip() {
  const user = useUser();

  const title = () => {
    return (
      <div>
        <p className="text-scale-8 font-black">{user.email}</p>
        <p className="text-scale-8 mb-xs">{"TGI:" + user.tgi}</p>
        <div>
          {user.orgRoles.map((orgRole, index) => (
            <OrgRole
              orgRole={orgRole}
              index={index}
              key={`${orgRole.email}-${index}`}
              user={user}
              canSegregate={user.orgRoles.length > 1}
            />
          ))}
        </div>
        {user.orgRoles.length > 1 && (
          <div className="flex justify-between items-center">
            <EmwIcon
              icon="warning_amber"
              size="xlarge"
              variant="outlined"
              classes="text-dark-orange-400 mr-xs"
            />
            <p className="text-scale-9" style={{ textAlign: "justify" }}>
              Choosing one role will apply only to the Engineering Organization and not for Mood &
              Confidence to deliver Focus and Skills Focus.
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <EmwTooltip title={title()}>
      <EmwTypography fontSize="7" fontWeight="bold" classes="text-primary-500 pl-xs">
        {user.firstName} {user.lastName}
      </EmwTypography>
    </EmwTooltip>
  );
}

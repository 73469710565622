import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { useDispatch, useSelector } from "react-redux";
import { setEmbeddedUrl, setSelectedReport } from "../../../powerBi/redux/actions";
import { ENVS } from "../../../../../modules/landingpage/constants";
import { Grid } from "@mui/material";
import { TdsTypography } from "@tds/react";
import { Card, CardContent, TitleTypography } from "./styled";
import { biPropsSelector } from "../../../header/redux/selectors";
import useHzaSync from "../../../hzaSync/useHzaSync";
import { embeddedUrlSelector } from "../../../powerBi/redux/selectors";

export default function CardItem({ headerImg, title, subTitle, path, hoverText, id, env }) {
  const history = useNavigate();
  const hzaSync = useHzaSync();
  const biProps = useSelector(biPropsSelector);
  const embeddedUrl = useSelector(embeddedUrlSelector);
  const [showText, setShowText] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (window.report === null) {
      if (id === "enineeringCompetitiveness") {
        dispatch(setSelectedReport(29));
      }
      if (env === ENVS.DEV) {
        return history(path);
      }
      if (env === ENVS.DEV || env === ENVS.DIGITAL) {
        if (id === "engineeringOrganisation") {
          document.querySelector("#myFrameHZA").style.height = "2px";
          return history(path);
        } else {
          if (["moodAndConfidence", "jobFamilyFocus", "enineeringCompetitiveness"].includes(id)) {
            document.querySelector("#myFrameHZA").style.height = document.body.clientHeight + "px";
            return document.getElementById("myFrameHZA").contentWindow.postMessage(path, "*");
          }
        }
      }

      if (env === ENVS.CLOUD) {
        if (id === "engineeringOrganisation") {
          return window.parent.postMessage(path, "*");
        } else {
          if (["moodAndConfidence", "jobFamilyFocus", "enineeringCompetitiveness"].includes(id)) {
            return history(path);
          }
        }
      }

      if (env === ENVS.DIGITAL) {
        if (id === "engineeringOrganisation") {
          return history(path);
        } else {
          if (["moodAndConfidence", "jobFamilyFocus", "enineeringCompetitiveness"].includes(id)) {
            return document.getElementById("myFrameHZA").contentWindow.postMessage(path, "*");
          }
        }
      }
    }
    window.isTabClicked = false;
    history(path);

    if (id === "enineeringCompetitiveness") {
      // dispatch(setEmbeddedUrl(biProps.list.data.wfbC2URL));
      // hzaSync.showBiC3("engineeringCompetitivnessC3Url");
    }

    if (id === "moodAndConfidence") {
      if (embeddedUrl !== biProps.list.data.moodAndConfidenceC2Url) {
        dispatch(setEmbeddedUrl(biProps.list.data.moodAndConfidenceC2Url));
      }
    }
  };

  return (
    <Card
      container
      id={id}
      alignItems="center"
      className={`card-${path}`}
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
      onClick={handleClick}
    >
      <Grid
        item
        sm={12}
        sx={{ padding: "10px 10px 10px 10px", display: "flex", justifyContent: "center" }}
      >
        <img src={headerImg} style={{ height: "210px" }} alt="Card Img" />
      </Grid>

      <Grid item sm={12} mt={"auto"}>
        <CardContent container justifyContent="center" alignItems="center">
          {!showText && (
            <Grid item sm={10}>
              <TitleTypography component="body-1" fontWeight="bold" classes={["text-black"]}>
                {title}
              </TitleTypography>
            </Grid>
          )}

          <Grid item sm={12}>
            <Grid container justifyContent="center">
              {!showText && (
                <TdsTypography component="body-2" fontWeight="thin" classes={["text-black"]}>
                  {subTitle}
                </TdsTypography>
              )}

              {showText && (
                <TdsTypography component="body-2" fontWeight="thin" classes={["text-black"]}>
                  {hoverText}
                </TdsTypography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
    </Card>
  );
}

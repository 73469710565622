import moment from "moment";

export const MODULE_NAME = "organization";

export const GENERIC_GROUP_ID = "00053af6-7fba-4db8-8572-2d7e23332b6e";
export const GENERIC_FAVORITE_ID = "0033a03c-91d0-43ab-90b5-824c6951d42a";
export const ORGANIZATION_PERSPECTIVE = {
  BY_GBU: "BY_GBU",
  BY_COUNTRY: "BY_COUNTRY",
};
export const ORGANIZATION_VIEW = {
  DETAILS: "DETAILS",
  FAVORITES: "FAVORITES",
  GUEST: "GUEST",
  EMPTY: "EMPTY",
};
export const ORGANIZATION_TYPE = {
  DOMAIN: "Domain",
  GROUP_COUNTRY: "GROUP_COUNTRY",
  GROUP: "GROUP",
  REGION: "REGION",
  COUNTRY: "COUNTRY",
  CBU: "CBU",
  GBU: "GBU",
  BU: "BU",
  BL: "BL",
  CC: "CC",
};

export const ORGANIZATION_CARD_MODAL_TYPE = {
  EDIT: "EDIT",
  CREATE: "CREATE",
};

export const DATE_TIME_FORMAT = "yyyy-MM-DDTHH:mm:ss";
export const DATE_FORMAT = "DD/MM/yyyy";

export const ROLES_TYPE = ["Effective", "Acting", "External"];
export const NO_RESOURCE_ALLOCATED = "No resource allocated";

export const ORGANIZATION_WARNING = {
  roleName: "",
  key: "",
};

export const ORGANIZATION_FIELD = {
  value: null,
  enabled: true,
  visible: true,
};

export const ORGANISATION_FORM_ELEMENT = {
  id: null,
  key: "",
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  type: {
    ...ORGANIZATION_FIELD,
    value: null,
  },
  startDate: { ...ORGANIZATION_FIELD },
  comment: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  effective: { ...ORGANIZATION_FIELD },
  visible: true,
};

export const ORGANISATION_FORM_ELEMENT_WITHOUT_TYPE = {
  id: null,
  key: "",
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  startDate: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  effective: { ...ORGANIZATION_FIELD },
  visible: true,
};

export const ORGANIZATION_CARD_DETAILS = {
  id: null,
  name: null,
  inEditMode: true,
  blCode: null,
  buCode: null,
  originBlCode: null,
  blId: null,
  type: "CC",
  countryCode: null,
  parentBlsId: null,
  parentBlsCode: null,
  parentBusId: null,
  parentBusCode: null,
};

export const ORGANIZATION_CARD_WPM = {
  id: null,
  key: "",
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  startDate: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  effective: { ...ORGANIZATION_FIELD },
  visible: true,
  leader: {
    ...ORGANISATION_FORM_ELEMENT,
  },
};

export const ORGANIZATION_CARD_SOLUTION = {
  id: null,
  key: "",
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  startDate: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  effective: { ...ORGANIZATION_FIELD },
  visible: true,
  leader: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  pm: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  da: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  pda: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  wpms: [{ ...ORGANIZATION_CARD_WPM }],
};

export const ORGANIZATION_CARD_DELIVERY = {
  ...ORGANISATION_FORM_ELEMENT_WITHOUT_TYPE,
  leader: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  solutions: [
    {
      ...ORGANIZATION_CARD_SOLUTION,
    },
  ],
};

export const ORGANIZATION_CARD_DISCIPLINE = {
  id: null,
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  comment: { ...ORGANIZATION_FIELD },
  baseName: "",
  visible: true,
  leader: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  engineers: [
    // {
    //   ...ORGANISATION_FORM_ELEMENT,
    // },
  ],
};

export const ORGANIZATION_SECTION_1 = {
  visible: true,
  gPgEnabled: true,
  baseName: "",
  prefixName: "",
  id: null,
  btnSave: { ...ORGANIZATION_FIELD },
  btnUndo: { ...ORGANIZATION_FIELD },
  btnDelete: {
    ...ORGANIZATION_FIELD,
    visible: false,
  },
  btnPublish: {
    ...ORGANIZATION_FIELD,
    visible: false,
  },
  btnGuests: { ...ORGANIZATION_FIELD },
  btnFavorite: { ...ORGANIZATION_FIELD },
  btnPrint: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  btnBack: { ...ORGANIZATION_FIELD },
  countries: {
    value: [],
    enabled: true,
    visible: true,
  },
  name: { ...ORGANIZATION_FIELD },
  date: {
    ...ORGANIZATION_FIELD,
    value: moment(Date.now()).format(DATE_TIME_FORMAT),
  },
  endDate: { ...ORGANIZATION_FIELD },
  hcDeclared: { ...ORGANIZATION_FIELD },
  hardware: {
    ...ORGANIZATION_FIELD,
    value: false,
  },
  software: {
    ...ORGANIZATION_FIELD,
    value: false,
  },
  system: {
    ...ORGANIZATION_FIELD,
    value: false,
  },
  industry: {
    ...ORGANIZATION_FIELD,
    value: false,
  },
  hc: { ...ORGANIZATION_FIELD },
  ems: [{ ...ORGANISATION_FORM_ELEMENT }],
  hoEOs: [{ ...ORGANISATION_FORM_ELEMENT }],
  kpis: [{ ...ORGANISATION_FORM_ELEMENT }],
  mobfr: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  eql: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  dr: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  dp: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  uxdr: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  ecodr: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  cybsr: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  type: {
    ...ORGANIZATION_FIELD,
    value: 0,
  },
  // warnings: [
  //     ORGANIZATION_WARNING
  // ]
};

export const ORGANIZATION_SECTION_2 = {
  visible: true,
  deliveries: [{ ...ORGANIZATION_CARD_DELIVERY }],
};

export const ORGANIZATION_CARD_SECTION_3 = {
  visible: true,
  disciplines: [{ ...ORGANIZATION_CARD_DISCIPLINE }],
};

//TODO: Should be listed from DB - TBD
export const ENGINEER_TYPE = {
  EM: { label: "EM", title: "Engineering Manager" },
  KPI: { label: "KPI Steward", title: "KPI Steward" },
  CER: { label: "CER", title: "Country Engineering Representatives" },
  HoEO: { label: "HoEO", title: "Head of Engineering Operations" },

  HoD: { label: "HoD", title: "Head of Discipline" },
  EDM: { label: "EDM", title: "Engineering Delivery Manager" },
  WPM: { label: "WPM", title: "Work Package Manager" },
  HoED: { label: "HoED", title: "Head of Engineering Delivery" },

  DATARRef: { label: "Data", title: "Data Referent" },
  CYBSRRef: { label: "Cybersecurity", title: "Cybersecurity Referent" },
  ECODRRef: { label: "Ecodesign", title: "Ecodesign Referent" },
  UXDRRef: { label: "UX Design", title: "UX Design Referent" },
  DPRef: { label: "Data Protection", title: "Data Protection Referent" },
  MOBFRef: { label: "Make or Buy & Footprint", title: "Make or Buy & Footprint Referent" },
  EQ: { label: "Engineering Quality", title: "Engineering Quality Leader" },
  OTHERRef: { label: "Other", title: "Other Referents" },

  DA: { label: "DA", title: "Design Authority" },
  PDA: { label: "PDA", title: "Product Design Authority" },
};

export const G_PG = {
  GUESTS: { name: "Guests" },
  POWER_GUESTS: { name: "Power Guests" },
  EXCOMS: { name: "EXCOM Members" },
};

export const HeadCount = {
  EJF_HC: { label: "EJF-HC", title: "Engineering Job Family Head Count" },
  E_HC: { label: "E-HC", title: "Engineering Head Count" },
  DEC_HC: { label: "Dec-HC", title: "Declared Head Count" },
};

export const ORGANIZATION_CC_FILTER = [
  {
    value: "ALL",
    label: "Select All",
  },
  {
    value: "CC",
    label: "CC",
  },
  {
    value: "DCC",
    label: "DCC",
  },
  {
    value: "DPT",
    label: "DPT",
  },
  {
    value: "Other",
    label: "Other",
  },
  {
    value: null,
    label: "Not Set",
  },
];

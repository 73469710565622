import React from "react";
import { EmwButton } from "../../../../../../../lib/common";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { TdsIcon } from "@tds/react";
import { changeView } from "../../../../redux/actions";
import { ORGANIZATION_VIEW } from "../../../../constants";

const defaultUser = {
  id: null,
  effective: { value: null, enabled: true, visible: true },
  type: { value: null, enabled: true, visible: true },
  startDate: { value: null, enabled: true, visible: true },
  endDate: { value: null, enabled: true, visible: true },
  name: { value: null, enabled: true, visible: true },
  btnAdd: { value: null, enabled: true, visible: true },
  btnEdit: { value: null, enabled: true, visible: true },
};

export default function AddGuestPowerGuest({ btn, data }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(changeView(ORGANIZATION_VIEW.GUEST));
  };

  return (
    <Box>
      <EmwButton
        variant="ghost"
        size="small"
        disabled={!btn.enabled}
        onClick={handleClick}
        id="btnGuests"
        title="Manage Guests/Power Guests"
      >
        <TdsIcon icon="manage_accounts" variant="outlined" size="large" />
      </EmwButton>
    </Box>
  );
}

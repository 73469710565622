import React, { useContext, useEffect, useState } from "react";
import { Box, Collapse } from "@mui/material";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";
import FormItem from "../FormItem/FormItem";
import {
  NO_RESOURCE_ALLOCATED,
  ORGANIZATION_CARD_MODAL_TYPE,
  ORGANIZATION_TYPE,
} from "../../../../../../constants";
import SolutionsPanel from "./SolutionsPanel";
import { ENGINEER_TYPE } from "../../../../../../constants";
import TourElement from "../../../../../../../../components/Tour/TourElement/TourElement";
import cls from "classnames";
import { dateIsExpired } from "../../utils";
import EdmPanel from "./EdmPanel";
import WpmPanel from "./WpmPanel";

export default function Delivery({ delivery, index, color, targetField, openNewDelivery }) {
  const context = useContext(OrganizationCardContext);
  const [isOpen, setIsOpen] = useState(delivery.isOpen);
  const [name, setName] = useState(delivery.name.value || `Delivery ${index + 1}`);
  const [values, setValues] = useState(delivery);
  const [showSolutions, setShowSolutions] = useState(false);
  const [showEdms, setShowEdms] = useState(false);
  const [showWpms, setShowWpms] = useState(false);
  const [organizationType, setOrganizationType] = useState("");
  const [isDropdownAlreadyOpen, setIsDropdownAlreadyOpen] = useState(isOpen);
  const rootId = `root-delivery-${index}`;
  const [forceOpen, setForceOpen] = useState(false);

  const className = cls("my-xs mt-xss root-item", {
    "bg-green-300 px-xxs py-xxs rounded-md": false, //rootId === context.focusedItem
  });

  useEffect(() => {
    if (!isOpen && !forceOpen) {
      const shouldForceOpen = context.shouldBeOpen(delivery, targetField.key);
      setIsOpen(shouldForceOpen);
      setForceOpen(shouldForceOpen);
      return;
    }

    if (delivery.isNew) {
      context.setFocusedItem(rootId);
    }

    if (delivery.hasOwnProperty("managers") && delivery.managers.length > 0) {
      if (delivery.managers[0].visible) {
        setShowEdms(true);
      }

      if (delivery.managers[1].visible) {
        setShowWpms(true);
      }
    }

    setValues(delivery);
    setName(delivery.name.value || `Delivery ${index + 1}`);

    for (let i = 0; i < delivery.solutions.length; i++) {
      if (delivery.solutions[i].visible) {
        setShowSolutions(true);
        break;
      }
    }

    setOrganizationType(context.data.cardDetails.type);
  }, [delivery, isOpen]);

  useEffect(() => {
    if (isOpen && delivery.isOpen) {
      return;
    }
    if (!isOpen && !delivery.isOpen) {
      return;
    }

    handleIsMainObjOpen();
  }, [isOpen]);

  const handleIsMainObjOpen = open => {
    const _delivery = {
      ...delivery,
      isOpen: open,
    };
    context.updateDelivery(_delivery, index);
  };

  const handleIsMainObjInEditMode = isEdit => {
    const _delivery = {
      ...delivery,
      isEdit: isEdit,
    };
    context.updateDelivery(_delivery, index);
  };

  const handleUpdate = (objectValues, key) => {
    const updatedValues = { ...values };

    if (key) {
      updatedValues[key] = objectValues;
      updatedValues.solutions = context.data.section2.deliveries[index].solutions;
      updatedValues.isOpen = delivery.isOpen;
      updatedValues.isEdit = delivery.isEdit;
      context.updateDelivery(updatedValues, index);
      setValues(updatedValues);
    } else {
      objectValues.solutions = context.data.section2.deliveries[index].solutions;
      objectValues.leader = context.data.section2.deliveries[index].leader;
      objectValues.isOpen = true;
      objectValues.isEdit = false;
      context.updateDelivery(objectValues, index);
      setValues(objectValues);
    }
  };

  const handleEditToggle = () => {
    setIsOpen(true);
  };

  const setWarningBorder = () => {
    let hasBorder = false;
    const regExp = /^Delivery [0-9]+$/;
    if (!values.leader.name.value || values.leader.name.value === NO_RESOURCE_ALLOCATED) {
      hasBorder = true;
    } else if (values.name.value && regExp.test(values.name.value)) {
      hasBorder = true;
    } else if (dateIsExpired(values.leader.endDate.value)) {
      hasBorder = true;
    }
    return hasBorder;
  };

  return (
    <div className={className} id={rootId}>
      <TourElement id={`tour-orgCard-DeliveryFormItem-${index}`}>
        <FormItem
          text="delivery"
          isNew={false}
          type={null}
          customName={name}
          item={values}
          handleUpdate={handleUpdate}
          showArrows={false}
          color={color}
          isOpen={isOpen}
          isEditOpen={delivery.isEdit}
          setIsOpen={value => {
            setIsOpen(value);
            setIsDropdownAlreadyOpen(value);
            handleIsMainObjOpen(value);
          }}
          collapsable={true}
          isTextField={true}
          targetKey={targetField.key}
          id={`delivery-${index}`}
          handleEditToggle={handleEditToggle}
          noResource={setWarningBorder()}
          beforeEditClick={() => {
            context.setFocusedItem(rootId);
            if (!isDropdownAlreadyOpen) {
              setIsOpen(true);
            }
            handleIsMainObjInEditMode(true);
          }}
          beforeCancelClick={() => {
            context.setFocusedItem("");
            if (!isDropdownAlreadyOpen) {
              setIsOpen(false);
            }
            handleIsMainObjInEditMode(false);
          }}
          dropdownClickOnEdit={!isOpen ? true : false}
        />
      </TourElement>
      <Collapse className="toggle-collapse" in={isOpen}>
        <FormItem
          isNew={false}
          type="HoED"
          item={values.leader}
          handleUpdate={handleUpdate}
          showArrows={false}
          index={index}
          propKey="leader"
          targetKey={targetField.key}
          id={`delivery-${index}-HoED`}
          title={ENGINEER_TYPE.HoED.title}
          toggleChildrens={setIsOpen}
          beforeEditClick={() => {}}
          beforeCancelClick={() => {}}
        />

        {showEdms &&
          (context.type === ORGANIZATION_CARD_MODAL_TYPE.CREATE ||
            organizationType !== ORGANIZATION_TYPE.BL) && (
            <TourElement id={`tour-orgCard-edm-${index}`}>
              <Box mt={1}>
                <EdmPanel
                  edms={delivery.managers[0]}
                  deliveryIndex={index}
                  targetField={targetField}
                  title={"Engineering Delivery Managers"}
                />
              </Box>
            </TourElement>
          )}

        {showWpms &&
          (context.type === ORGANIZATION_CARD_MODAL_TYPE.CREATE ||
            organizationType !== ORGANIZATION_TYPE.BL) && (
            <TourElement id={`tour-orgCard-wpm-${index}`}>
              <Box mt={1}>
                <WpmPanel
                  wpms={delivery.managers[1]}
                  deliveryIndex={index}
                  targetField={targetField}
                  title={"Work Package Managers"}
                />
              </Box>
            </TourElement>
          )}

        {showSolutions &&
          (context.type === ORGANIZATION_CARD_MODAL_TYPE.CREATE ||
            organizationType !== ORGANIZATION_TYPE.BL) && (
            <TourElement id={`tour-orgCard-SolutionFormItem-${index}`}>
              <Box mt={1}>
                <SolutionsPanel
                  solutions={delivery.solutions}
                  deliveryIndex={index}
                  targetField={targetField}
                />
              </Box>
            </TourElement>
          )}
      </Collapse>
      <Box sx={{ borderBottom: "2px solid #041295" }} mt={1}></Box>
    </div>
  );
}

import React, { useContext, useState } from "react";
import { EmwButton } from "../../../../../../../../../lib/common";
import { Grid } from "@mui/material";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";
import EmwDialog from "../../../../../../../../components/EmwDialog/EmwDialog";

export default function CloseBtn({ name }) {
  const context = useContext(OrganizationCardContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleModalClose = async () => {
    if (context.noChanges()) {
      return context.setFormModalOpen(false);
    }
    context.setFormModalOpen(true);
    setIsOpen(true);
  };

  const handleClose = async () => {
    await context.reset();
    setIsOpen(false);
    context.setFormModalOpen(!isOpen);
  };

  return (
    <Grid item ml={0.5}>
      <EmwButton variant="outline" size="small" title="Close" id="btnCloseModal" onClick={handleModalClose}>
        Close
      </EmwButton>
      {context.formModalOpen && (
        <EmwDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          callback={handleClose}
          actionButton="Close"
          title={`Do you want to close the ${
            name === "G/PG"
              ? ""
              : name === null
              ? "Engineering Organization Card?"
              : `Engineering Organization Card: ${name}?`
          }`}
          text="Your changes will be lost if you close the form."
          icon="warning_amber"
          classes="close-icon"
        />
      )}
    </Grid>
  );
}

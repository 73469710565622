import React, { useContext, useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import FormItem from "../FormItem/FormItem";
import { ENGINEER_TYPE, NO_RESOURCE_ALLOCATED } from "../../../../../../constants";
import { dateIsExpired } from "../../utils";
import { OrganizationCardContext } from "../../../../context/OrganizationCardContextProvider";

export default function Edm({
  edms,
  deliveryIndex,
  targetField,
  type,
  childrenIndex,
  propKey,
  isNew,
}) {
  const [values, setValues] = useState(cloneDeep(edms));
  const [item, setItem] = useState(edms);
  const context = useContext(OrganizationCardContext);
  useEffect(() => {
    setItem(edms);
  }, [edms]);

  const handleUpdate = (objectValues, key) => {
    setItem(objectValues);
    setValues(objectValues);
    context.updateDeliveryManager(objectValues, deliveryIndex, 0, childrenIndex, isNew);
  };
  const setWarningBorder = () => {
    let hasBorder = false;
    if (values.name.value === NO_RESOURCE_ALLOCATED) {
      hasBorder = true;
    } else if (values.name.value) {
      hasBorder = false;
    } else if (dateIsExpired(values.endDate.value)) {
      hasBorder = true;
    }
    return hasBorder;
  };

  return (
    <>
      <FormItem
        section={"section2"}
        isNew={false}
        childrenIndex={childrenIndex}
        index={deliveryIndex}
        type={type}
        item={item}
        handleUpdate={handleUpdate}
        showArrows={false}
        isTextField={false}
        propKey={propKey}
        targetKey={targetField.key}
        id={`delivery-${deliveryIndex}-edm-${childrenIndex}`}
        noResource={setWarningBorder()}
        title={ENGINEER_TYPE.EDM.title}
      />
    </>
  );
}

import PublicLayout from "../../../../layout/PublicLayout/PublicLayout";
import { StyledEmwLogo, StyledLoginContainer, StyledLoginForm, StyledThalesLogo } from "../../../auth/styled";
import { Grid } from "@mui/material";
import ThalesLogo from "../../../../styles/images/icon/Thales_logo.svg";
import EMWLogo from "../../../../styles/images/icon/EMW_logo-01.svg";
import EmwTypography from "../../../components/EmwTypography/EmwTypography";
import MoreOptions from "../../../../roots/MoreOptions";
import React from "react";

export default function RestrictedPage({ message }) {
  return (
    <PublicLayout>
      <StyledLoginContainer id="login-container">
        <Grid container alignItems="center" justifyContent="center">
          <StyledThalesLogo src={ThalesLogo} alt="Thales" />
        </Grid>
        <StyledLoginForm item id="login-form" className="mx-md px-xs text-center flex flex-col items-center pb-l">
          <div id="login-form-wrapper" className="flex flex-col" style={{ height: "370px" }}>
            <Grid container alignItems="center" justifyContent="center">
              <StyledEmwLogo src={EMWLogo} alt="EMW Logo" />
            </Grid>

            <Grid
              container
              alignItems="center"
              justifyContent="center"
              fontWeight="normal"
              style={{ marginBottom: "36px" }}
            >
              <Grid item sm={7}>
                <EmwTypography fontSize="6" fontWeight="normal">
                  {message}
                </EmwTypography>
              </Grid>
            </Grid>

            <MoreOptions />
          </div>
        </StyledLoginForm>
      </StyledLoginContainer>
    </PublicLayout>
  );
}

import React, { useEffect, useState } from "react";
import { getMonthlyUsersListApi } from "../../../redux/api";
import EmwProgressCircle from "../../../../../components/EmwProgressCircle/EmwProgressCircle";
import EmwWebExcel from "../../../../../components/EmwWebExcel/EmwWebExcel";

export default function UsageMonitoringDrillToDetail({ payload, stateData }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    try {
      getMonthlyUsersListApi(payload).then(response => {
        if (response && response.data) {
          setData(response.data);
        }
      });
    } catch (err) {
      console.log("Error getting users list", err);
    }
  }, [stateData]);

  return (
    <>
      {data ? (
        <EmwWebExcel
          title="Active unique users per period"
          tooltipInfo="Active unique users per selected period(connected users with their connection number & rank per period)"
          data={data}
          isDrillToDetails={true}
          getApi={getMonthlyUsersListApi}
          payload={payload}
          id="drillToDetailsTable"
        />
      ) : (
        <div className="flex items-center justify-center">
          <EmwProgressCircle size="small" />
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom-v5-compat";
import HzaContextProvider from "../../V2/features/hzaSync/context/HzaContext";
import Header from "../../V2/features/header/components/Header";
import PowerBi from "../../V2/features/powerBi/components/PowerBi";
import Footer from "../../V2/features/footer/components/Footer";

const Wrapper = styled("div")(() => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

const Main = styled("main")(() => ({
  display: "flex",
  backgroundColor: "white!important",
  overflow: "auto!important",
  flexGrow: 1,
  flexDirection: "column",
}));

export default function DefaultLayout({ children }) {
  return (
    <HzaContextProvider>
      <Wrapper className="wrapper" id="wrapper">
        <Header />
        <Main className="main" id="main">
          {children}
          <PowerBi />
          {/* Make sure the PowerBi component is always loaded through out the whole app */}
        </Main>
        <Footer />
      </Wrapper>
    </HzaContextProvider>
  );
}

import ExcelJS from "exceljs";
import { ccExcelFormColumns } from "./CCExcelFormColumns";
import { getAllCcsApi } from "../../../../../../../organization/redux/api";

export const ccExcelFormExport = async fileName => {
  const alphaStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet 1", {
    views: [{ state: "frozen", xSplit: 5 }],
  });
  const columns = [];

  const transformColHeader = str => {
    return str.replace(/<br>/g, "").trim();
  };

  const loadData = async () => {
    const response = await getAllCcsApi();
    if (response && response.data) {
      return response.data;
    }
  };

  const data = await loadData();

  const createColumns = ccExcelFormColumns => {
    ccExcelFormColumns.forEach(column => {
      columns.push({
        header: transformColHeader(column.title),
        key: column.data,
        width: column.excelWidth,
      });
    });
    return columns;
  };

  const fixedColumns = [
    "gbuCode",
    "blCode",
    "countryCode",
    "ccName",
    "type",
    "gbuEmName",
    "blEmName",
    "ccEmName",
  ];

  const setCellAlignment = key => {
    if (["countryCode", "acceleratorLevel2", "engOrgUName"].includes(key)) {
      return { wrapText: true, vertical: "middle", horizontal: "center" };
    } else {
      return { vertical: "middle", horizontal: "center" };
    }
  };

  const componentToHex = c => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  const rgbToHex = rgb => {
    const [r, g, b] = rgb.replace("rgb(", "").replace(")", "").split(",").map(Number);
    return componentToHex(r) + componentToHex(g) + componentToHex(b);
  };

  const styleColumns = () => {
    worksheet.columns.forEach((column, i) => {
      const row = worksheet.getRow(1);
      const cellName = `${alphaStr[i]}1`;
      const cell = worksheet.getCell(cellName);

      row.height = 42.5;

      cell.alignment = setCellAlignment(column._key);
      cell.font = {
        color: { argb: "00008b" },
        bold: true,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "808080" } },
        left: { style: "thin", color: { argb: "808080" } },
        bottom: { style: "thin", color: { argb: "808080" } },
        right: { style: "thin", color: { argb: "808080" } },
      };
    });
  };

  const addCells = () => {
    data.forEach((row, i) => {
      const xRow = worksheet.addRow(row);
      xRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (["gbuCode", "blCode"].includes(cell._column._key)) {
          cell.font = {
            bold: true,
          };
        }
        if (fixedColumns.includes(cell._column._key)) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "66FF66" },
          };
        }
        if (colNumber > 8) {
          cell.border = {
            top: { style: "thin", color: { argb: "dedede" } },
            bottom: { style: "thin", color: { argb: "dedede" } },
            right: { style: "thin", color: { argb: "dedede" } },
          };
        }
        if (row.style) {
          const rowIndex = data.findIndex(item => item.style === row.style);
          const cellStyles = JSON.parse(row.style).cellStyles;
          cellStyles.forEach(item => {
            if (item) {
              // force the cell because of differences between indexes in form and excel
              // row 0 in excel is represented as 1 in the library, but 1 is header so we need to add 2
              const formattedCell = worksheet.getCell(
                `${String.fromCharCode(64 + item.column + 1)}${rowIndex + 2}`
              );
              formattedCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: rgbToHex(item.backgroundColor) },
              };
              formattedCell.font = {
                color: { argb: rgbToHex(item.fontColor) },
                bold: item.fontWeight !== "400",
                italic: item.fontStyle !== "normal",
                underline: item.textDecoration !== "none",
              };
            }
          });
        }
        if (row.comment) {
          const rowIndex = data.findIndex(item => item.comment === row.comment);
          const comments = JSON.parse(row.comment);
          comments.forEach(comm => {
            if (comm) {
              const col = comments.indexOf(comm);
              const cellWithComm = worksheet.getCell(
                `${String.fromCharCode(64 + col + 1)}${rowIndex + 2}`
              );

              cellWithComm.note = comm;
            }
          });
        }
        if (cell._column._key === "type" && (cell.value === null || cell.value === "0")) {
          xRow.getCell(colNumber).value = "N/A";
        }
      });
    });
  };

  const addColumns = () => {
    worksheet.columns = createColumns(ccExcelFormColumns);
    styleColumns();
  };

  const downloadXcel = async (data, fileName) => {
    addColumns();
    addCells(data);

    worksheet.autoFilter = {
      from: "A1",
      to: "Y1",
    };

    setTimeout(async () => {
      // Move execution to a separate process to not freeze the page
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    }, 100);
  };

  return downloadXcel(data, fileName);
};

import React from "react";
import { TdsModal, TdsTypography, TdsModalBody, TdsModalDivider, TdsModalFooter, TdsButton } from "@tds/react";
import EMWLogo from "./../../styles/images/icon/EMW_logo-01.svg";

export default function EmwPopup(props) {
  const {
    text2,
    text1,
    open,
    onClosePopup,
    showOkButton,
    button1Text,
    button2Text,
    onButton1Click,
    onButton2Click,
    onButtonOkClick,
    className,
  } = props;

  return (
    <TdsModal open={open} size="small" overlay="true" onCloseModal={onClosePopup} className={className}>
      <div style={{ margin: "0 auto" }}>
        <img style={{ margin: "3rem 0 3rem" }} src={EMWLogo} alt="EMW logo" width={175} />
      </div>

      <TdsModalBody>
        <TdsTypography classes="popupTextFirst" fontSize={5} fontWeight="bold">
          <div>{text1}</div>
        </TdsTypography>
        <TdsTypography classes="popupTextSecond" fontSize={6} fontWeight="bold">
          <div>{text2}</div>
        </TdsTypography>
      </TdsModalBody>

      <TdsModalDivider />

      <TdsModalFooter classes="flex-col space-y-xs">
        {/* <TdsButton variant="ghost" color="primary" classes="w-full justify-center">
        Cancel
      </TdsButton>
      <TdsButton variant="filled" color="primary"  classes="w-full justify-center">
        Confirm
      </TdsButton> */}
        {showOkButton && (
          <TdsButton variant="outline" classes="w-full justify-center" color="primary" onClick={onButtonOkClick}>
            {" "}
            Ok{" "}
          </TdsButton>
        )}
        {button1Text && (
          <TdsButton variant="outline" classes="w-full justify-center" color="primary" onClick={onButton1Click}>
            {" "}
            {button1Text}{" "}
          </TdsButton>
        )}
        {button2Text && (
          <TdsButton variant="outline" classes="w-full justify-center" color="primary" onClick={onButton2Click}>
            {" "}
            {button2Text}{" "}
          </TdsButton>
        )}
      </TdsModalFooter>
    </TdsModal>
  );
}

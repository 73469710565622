import React, { useEffect, useState } from "react";
import { TdsIcon } from "@tds/react";
import { Box, Grid, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { goToNode } from "../../../../redux/actions";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";
import { getZoom } from "../../../../../../utils/sizeUtils";

export default function CardName({ data }) {
  const zoom = getZoom();
  const dispatch = useDispatch();
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = event => {
    positionRef.current = { x: event.clientX, y: event.clientY };
    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const renderContent = () => {
    const dataclone = [...data.section1.btnBelongsTo.value];
    dataclone.sort((a, b) => {
      const keyA = a.name;
      const keyB = b.name;
      if (keyA > keyB) {
        return 1;
      }
      if (keyA < keyB) {
        return -1;
      }
      return 0;
    });

    return dataclone.map(nodeLink => {
      return (
        <Grid container justifyContent="space-between" flexWrap="nowrap">
          <Grid item sm={10}>
            <EmwTypography>Also belongs to: {nodeLink.name}</EmwTypography>
          </Grid>

          <Grid item sm={2} className="list-item-link" marginLeft={6}>
            <Grid
              id={`btnGoToNode${data.section1.name.value}`}
              container
              flexWrap="nowrap"
              alignItems="center"
              onClick={() => dispatch(goToNode(nodeLink))}
            >
              <Grid item>View</Grid>
              <Grid item>{` >>`}</Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };


  const cardHoverInfo = () => {
    if(data.section1.abbreviation.value && !data.section1.description.value){
      return data.section1.abbreviation.value;
    }
    if(data.section1.description.value && !data.section1.abbreviation.value){
      return data.section1.description.value;
    }
    if(data.section1.abbreviation.value && data.section1.description.value){
      return `${data.section1.abbreviation.value}\n${data.section1.description.value}`;
    }
    else {
      return data.section1.name.value
    }
  }


  return (
    <Grid container alignItems="center">
      <Box id="tooltip-cc-definition" title={cardHoverInfo()}>
        <EmwTypography classes="text-primary-500 card-name" fontWeight="bold" component="body-1">
          <>{data.section1.name.value}</>
        </EmwTypography>
      </Box>

      {data.section1.btnBelongsTo.value && data.section1.btnBelongsTo.value.length > 0 && (
        <Tooltip
          placement="bottom"
          arrow
          leaveDelay={300}
          title={renderContent()}
          PopperProps={{
            sx: () => {
              if (zoom < 100) {
                return {
                  marginTop: "-20px!important",
                };
              }

              return {
                marginTop: "10px!important",
              };
            },
            popperRef,
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(positionRef.current.x, areaRef.current.getBoundingClientRect().y, 0, 0);
              },
            },
          }}
        >
          <Box ref={areaRef} onMouseMove={handleMouseMove}>
            <TdsIcon
              id={`btnGoToNode${data.section1.name.value}`}
              icon="multiple_stop"
              style={{ color: "#041295", cursor: "pointer" }}
              title="Also belongs to"
            />
          </Box>
        </Tooltip>
      )}
    </Grid>
  );
}

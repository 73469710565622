import React from "react";
import EmwProgressCircle from "../../../../../../../../components/EmwProgressCircle/EmwProgressCircle";
import EmwButton from "../../../../../../../../components/EmwButton/EmwButton";
import { TdsIcon } from "@tds/react";
import clx from "classnames";

export default function ExportBtn({ classes, id, variant, text, onClick, loading, title }) {
  const className = clx("text-left", { ...classes });

  return (
    <EmwButton
      classes={className}
      variant={variant}
      title={title}
      id={id}
      onClick={onClick}
      disabled={loading}
    >
      <p>{text}</p>
      {!loading && <TdsIcon icon="arrow_forward" />}
      {loading && <EmwProgressCircle size="small" />}
    </EmwButton>
  );
}

import * as constants from "./constants";

export const REQUEST_PENDING = `${constants.MODULE_NAME}/REQUEST_PENDING`;
export const SNACKBAR_MESSAGE = `${constants.MODULE_NAME}/SNACKBAR_MESSAGE`;

export const requestPending = value => {
  return {
    type: REQUEST_PENDING,
    payload: value,
  };
};

export const snackbarMessage = message => {
  return {
    type: SNACKBAR_MESSAGE,
    payload: message,
  };
};

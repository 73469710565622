import React from "react";
import { QtmDropdownSelect } from "@qtm/react";

export default function EmwDropdownSelect({
  inputId,
  classes,
  disabled,
  onValueChanged,
  placeholder,
  placement,
  value,
  size,
  children,
}) {
  return (
    <QtmDropdownSelect
      inputId={inputId}
      classes={classes}
      disabled={disabled}
      placeholder={placeholder}
      placement={placement}
      value={value}
      onValueChanged={onValueChanged}
      size={size}
    >
      {children}
    </QtmDropdownSelect>
  );
}

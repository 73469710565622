import { buildStep } from "../tour";

export const buildThalesNodeSteps = (dinamycStep, tour) => {
  dinamycStep.push(
    buildStep(tour, null, {
      id: "organization-diagram-nodes",
      title: "Thales Group Node",
      text: `This node will open the Card that belongs to the Thales Group organization, and the next column of the tree unfolds, revealing the list of GBU’s that can be selected.
      The nodes that have a darker gray colour represent "Intuitive Path" - they lead to nodes that are under your responsibility. 
      The nodes that have a star symbol are under your responsibility.
      Click Next to continue.`,
      element: ".tour-diagram-body",
      on: "right-start",
    })
  );

  dinamycStep.push(
    buildStep(tour, null, {
      id: "orgCard",
      title: "Thales Group Card",
      text: `By selecting the "Thales Group" node, we will be able to visualize the corresponding card.`,
      element: ".organization-card-container",
      on: "left-start",
    })
  );
};

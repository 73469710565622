import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import { TdsProgressCircle } from "@tds/react";
import EmwTypography from "../../../../../../components/EmwTypography/EmwTypography";
import MailOutlined from "../../../../../../../lib/icons/MailOutlinedIcon";
import MicrosoftTeams from "../../../../../../../lib/icons/MicrosoftTeamsIcon";
import EmwToggleSwitch from "../../../../../../components/EmwToggleSwitch/EmwToggleSwitch";
import {
  changePerspective,
  getOrganizationDetails,
  goToNode,
  setDisabledEngineersList,
} from "../../../../redux/actions";
import { ORGANIZATION_PERSPECTIVE } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  disabledEngListSelector,
  orgDetailsSelector,
  perspectiveSelector,
} from "../../../../redux/selectors";
import { setEngineerActivityApi } from "../../../../redux/api";
import useAppSnackbar from "../../../../../../hooks/useAppSnankbar";
import EmwDialog from "../../../../../../components/EmwDialog/EmwDialog";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

export default function TooltipActionContent({
  em,
  data,
  hasData,
  noDataMessage,
  isEngineer,
  setIsEngineer,
  parentId,
  orgId,
  reloadParent,
  reload,
  setChildren,
}) {
  const disabledEngineers = useSelector(disabledEngListSelector);
  const orgDetails = useSelector(orgDetailsSelector);
  const perspective = useSelector(perspectiveSelector);
  const dispatch = useDispatch();
  const snackbar = useAppSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(!isEngineer);
  const [showEngActivityBtn, setShowEngActivityBtn] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (em) {
      const btnEngineerActivity = orgDetails.list.data.section1.btnEngineerActivity.visible;
      const canChangeEngineerActivity = em.canChangeEngineerActivity;
      setShowEngActivityBtn(btnEngineerActivity && canChangeEngineerActivity);
    }
  }, [orgDetails, em]);

  const checkPage = () => {
    if (location.pathname !== "organization") {
      navigate("/organization");
    }
  };

  const handleCerGoToNode = async orgRole => {
    checkPage();
    if (perspective === "BY_COUNTRY") {
      dispatch(getOrganizationDetails({ id: null, type: "GROUP" }));
      dispatch(changePerspective(ORGANIZATION_PERSPECTIVE.BY_GBU));
    }
    setTimeout(async () => {
      await dispatch(goToNode({ ...orgRole.additionalOrgDetails, code: orgRole.code }));
    }, 500);
  };

  const handleGoToNode = orgRole => {
    checkPage();
    const orgDetails = orgRole.orgDetails;
    if (!orgRole.orgDetails.hasOwnProperty("unId") && orgRole.hasOwnProperty("unId")) {
      orgDetails.unId = orgRole.unId;
    }
    dispatch(goToNode({ ...orgDetails, code: orgRole.code }));
  };
  const handleMessageClick = data => {
    if (data.email) {
      createA(data.email);
    }
  };

  const handleMailClick = data => {
    if (data.email) {
      const a = document.createElement("a");
      a.href = `mailto:${data.email}`;
      document.body.appendChild(a);
      a.click();
    }
  };

  const createA = email => {
    const url = `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.rel = "noopener noreferer";
    document.body.appendChild(a);
    a.click();
  };

  const handleToggleCheck = async () => {
    return setOpen(true);
  };

  const handleOk = async () => {
    const _checked = !checked;
    setLoading(true);
    try {
      await setEngineerActivityApi(em.id, !_checked);
      setChecked(_checked);
      if (_checked) {
        await reload("/false");

        clearHeadCounts();
      } else {
        await reloadParent(parentId, orgId);
      }
      setIsEngineer(!_checked);

      let _disabledEngineers = [...disabledEngineers];

      if (_checked) {
        _disabledEngineers.push(em.id);
      } else {
        _disabledEngineers = _disabledEngineers.filter(emId => emId !== em.id);
      }

      dispatch(setDisabledEngineersList(_disabledEngineers));
    } catch (reason) {
      snackbar.show(reason.message, "error");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const clearHeadCounts = () => {
    const headCountsSelector = ["#E_HC", "#EJF_HC", "#DEC_HC"];

    document.querySelectorAll(headCountsSelector.join(",")).forEach(htmlNode => {
      htmlNode.innerText = "...";
    });
  };

  if (!hasData) {
    return (
      <Grid item>
        <TdsProgressCircle size="small" color="white" />
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      flexWrap={"nowrap"}
      className="px-xxs"
      sx={{ maxHeight: "400px", overflow: "auto" }}
    >
      {!data ? (
        <EmwTypography>{noDataMessage}</EmwTypography>
      ) : (
        <>
          <div>
            {data.firstName && data.lastName && (
              <EmwTypography fontWeight="black" fontSize={7}>
                {data.firstName} {data.lastName}
              </EmwTypography>
            )}
            {data.jobProfile && (
              <EmwTypography fontWeight="medium">{data.jobProfile}</EmwTypography>
            )}
            <EmwTypography>TGI: {data.tgi}</EmwTypography>
          </div>
          <div className="flex-1 flex-col overflow-auto shadow-m-light">
            <div className="flex-1 flex-col">
              {data.rolesInfo.map((role, i) => (
                <React.Fragment key={i}>
                  <Box mt={2}>
                    <EmwTypography fontWeight="black">Role {i + 1}:</EmwTypography>
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      return role.code === "CER" ? handleCerGoToNode(role) : handleGoToNode(role);
                    }}
                  >
                    <EmwTypography classes="list-item-link">{role.name}</EmwTypography>
                  </Box>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div
            className="flex items-center my-m pb-s gap-s"
            style={{ borderBottom: "2px solid white" }}
          >
            <div style={{ border: "2px solid white", borderRadius: "2px" }}>
              <IconButton
                color="inherit"
                onClick={() => handleMailClick(data)}
                title="Send an Email"
                disableRipple
                id="btnSendMail"
              >
                <MailOutlined width="24" height="24" isWhite />
              </IconButton>
            </div>
            <div style={{ border: "2px solid white", borderRadius: "2px" }}>
              <IconButton
                color="inherit"
                onClick={() => handleMessageClick(data)}
                title="Chat"
                disableRipple
                id="btnSendMessage"
              >
                <MicrosoftTeams width="24" height="24" />
              </IconButton>
            </div>
          </div>

          {showEngActivityBtn && (
            <div className="flex items-center justify-between">
              <p className="text-scale-9 mr-m">Set as Non-engineering</p>
              <EmwToggleSwitch
                handleChecked={handleToggleCheck}
                checked={checked}
                textScale={9}
                disabled={loading}
              />
            </div>
          )}
          {open && (
            <EmwDialog
              isOpen={open}
              setIsOpen={setOpen}
              callback={handleOk}
              actionButton="Ok"
              title={`Do you want to set this worker as <br />      
               ${isEngineer ? "Non-engineering" : "Engineering"}?`}
              text={`The user will ${isEngineer ? "not appear" : "appear"} in searches or exports.`}
              icon="warning_amber"
              classes="reset-icon"
              loading={loading}
            />
          )}
        </>
      )}
    </Grid>
  );
}

import moment from "moment";
const outputFormatDateTime = "DD/MM/YYYY hh:mm:ss";
const outputFormatDate = "DD/MM/YYYY";
const formatStringToDateTimeString = input => {
  return input ? moment(input).format(outputFormatDateTime) : "";
};

const formatDateTimeForCompare = input => {
  if (input) {
    const formated = input.split(" ");
    const date = formated[0].split("/");
    const time = formated[1].split(":");
    return [date[2], date[1], date[0], ...time];
  }
  return "";
};

const formatDateForCompare = input => {
  if (input) {
    const date = input.split("/");
    return [date[2], date[1], date[0]];
  }
  return "";
};

const formatStringToDateString = input => {
  return input ? moment(input).format(outputFormatDate) : "";
};

const formatDate = (input, output) => {
  return input ? moment(input.toIsoString()).format(output) : "";
};

const formatToDate = input => {
  input
    ? new Date(parseInt(input.split("/")[2]), parseInt(input.split("/")[1]) - 1, parseInt(input.split("/")[0]))
    : new Date();
};

export {
  formatStringToDateString,
  formatStringToDateTimeString,
  formatDate,
  formatToDate,
  formatDateTimeForCompare,
  formatDateForCompare,
};

import React, { useState } from "react";
import { EmwButton } from "../../../lib/common";
import { TdsIcon, TdsProgressCircle } from "@tds/react";
import { computeURL, fetchDownload, fetchOptions } from "../../../util/fetch";
import { styled } from "@mui/material/styles";
import EmwTypography from "../../../V2/components/EmwTypography/EmwTypography";

const StyledExport = styled("div")(() => ({
  ".qtm-btn.qtm-ghost:not([disabled])": {
    color: "#131319",
    padding: "0px",
  },
  ".qtm-btn.qtm-ghost:hover:not([disabled])": {
    backgroundColor: "transparent !important",
    color: "#131319",
  },
  ".file-download": {
    backgroundColor: "transparent !important",
    padding: "0px",
  },
}));

const exportFromEdge = (fileName, blob) => {
  window.navigator.msSaveBlob(new Blob([blob], { type: "data:application/vnd.ms-excel" }), fileName);
  return {};
};

export const exportFile = (fileName, blob) => {
  if (window.navigator.userAgent.match(/Edge/)) {
    return exportFromEdge(fileName, blob);
  }
  if (blob.byteLength < 1000) {
    return JSON.parse(new TextDecoder("utf-8").decode(blob));
  }
  const newBlob = new Blob([blob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = data;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 500);
  return {};
};

export const exportData = async () =>
  fetchDownload(
    computeURL("organization", "exportAllCc"),
    fetchOptions({
      method: "put",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/octet-stream",
        "Accept-Encoding": "gzip, deflate, br",
      },
    })
  );

export default function ExportBtn({ setIsFirstLoading, isFirstLoading }) {
  const handleClick = async () => {
    setIsFirstLoading(true);

    const response = await exportData();
    let fileName = response.headers.get("Content-Disposition")
      ? response.headers.get("Content-Disposition")
      : "All_competence_center.xlsx";
    fileName = fileName.replace('attachment; filename="', "").replace('"', "");
    response.arrayBuffer().then(function (buffer) {
      exportFile(fileName, buffer);
    });

    setIsFirstLoading(false);
  };

  return (
    <StyledExport>
      <EmwButton
        classes="file-download"
        variant="ghost"
        title="Export Engineering Competence Center"
        id="exportBtn"
        onClick={handleClick}
        disabled={isFirstLoading}
      >
        <EmwTypography classes="text-black-100 mr-xs">Engineering Competence Center List</EmwTypography>
        {isFirstLoading && <TdsProgressCircle />}
      </EmwButton>
    </StyledExport>
  );
}

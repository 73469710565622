import React from "react";
import { StyledBarChart } from "../../../../components/EmwCharts/components/BarChart/styled";
import ChartTooltip from "../../../../components/EmwCharts/components/ChartTooltip/ChartTooltip";
import EmwTable from "../../../../components/EmwTable/EmwTable";

export default function Status({ data, title, tooltipInfo }) {
  return (
    <StyledBarChart className="flex flex-col bg-white-100 rounded p-s shadow-s-strong relative ">
      <div className="flex flex-row justify-between">
        <p className="text-scale-7 font-bold ml-s">{title}</p>
        <ChartTooltip title={tooltipInfo} />
      </div>
      {data && <EmwTable data={data} />}
    </StyledBarChart>
  );
}

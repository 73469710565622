import { fetch, fetchOptions, computeURL } from "./../../util/fetch";
import { SECURITY_API_ENDPOINT } from "./../../constants";

export const getUser = () => fetch(computeURL("user", "shortUser"), fetchOptions());

// export const getNotifications = () => fetch(computeURL("user", "getNotifications"), fetchOptions());

export const getNotifications = async payload => {
  const url = computeURL("user", "getNotifications");

  return fetch(url, fetchOptions());
};

export const setUserDate = payload =>
  fetch(computeURL("data", "setWorkDate"), fetchOptions({ method: "PUT", body: JSON.stringify(payload) }));

export const setUser = payload =>
  fetch(computeURL("user", "setUser"), fetchOptions({ method: "PUT", body: JSON.stringify(payload) }));

export const getTreeData = () => fetch(computeURL("data", "allTreesNodes"), fetchOptions());

export const exportAllCC = async () =>
  fetch(computeURL("organization", "exportAllCc"), fetchOptions({ method: "PUT" }));

export const deleteLogin = () => {
  const removeToken = () => {
    if (window.localStorage.getItem("authorization")) {
      window.localStorage.removeItem("authorization");
    }
  };

  return fetch(`${SECURITY_API_ENDPOINT}/logout`, fetchOptions(), removeToken);
};

export const getBiProps = () => fetch(computeURL("app", "getBiProps"), fetchOptions());

import React from "react";
import KPIModal from "./KPIModal";

export default function KPISuccessModal({ openSuccess, setOpenSuccess, handleOpenSuccess, text }) {
  return (
    <KPIModal
      successDialog
      isOpen={openSuccess}
      setIsOpen={setOpenSuccess}
      callback={handleOpenSuccess}
      title="Successfully Imported"
      text={text}
      icon="info"
      classes="close-icon"
    />
  );
}

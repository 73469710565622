/**
 * Rounds coordinates
 */
const roundPoint = point => [Math.floor(point[0]), Math.floor(point[1])];

/**
 * Given a source point and an output point produces the SVG path between them
 */
const makeSvgPath = (endPoint, startPoint, options = { type: "curve" }) => {
  if (!startPoint || !endPoint) {
    return "";
  }
  const roundedStart = roundPoint(startPoint);
  const roundedEnd = roundPoint(endPoint);

  const startF = `${roundedStart[0]}, ${roundedStart[1]}`;
  const endF = `${roundedEnd[0]}, ${roundedEnd[1]}`;

  if (roundedStart[1] === roundedEnd[1]) {
    return `M ${startF} L ${endF}`;
  }

  const middleX = Math.floor((roundedStart[0] + roundedEnd[0]) / 2);
  let middleY1 = roundedStart[1] + 15;
  let middleY2 = roundedEnd[1] - 15;

  if (roundedStart[1] > roundedEnd[1]) {
    middleY1 = roundedStart[1] - 15;
    middleY2 = roundedEnd[1] + 15;
  }

  const Q1 = `${middleX} ${roundedStart[1]} ${middleX} ${middleY1}`;
  const L1 = `${middleX} ${middleY2}`;
  const Q2 = `${middleX} ${roundedEnd[1]} ${endF}`;

  return `M ${startF} Q ${Q1} L ${L1} Q ${Q2}`;
};

export default makeSvgPath;

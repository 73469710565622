import React from "react";
import { Grid } from "@mui/material";

export default function DiagramColumnSVGPath({ nodes, height }) {
  return (
    <Grid item className="diagram-node-svg">
      {nodes.map(node => {
        return (
          <svg key={node.id} style={{ height: `${height}px`, zIndex: node.selected ? 1 : 0 }}>
            <g>
              <path d={node.svgPath} style={{ stroke: `${node.svgColor}` }}></path>
            </g>
          </svg>
        );
      })}
    </Grid>
  );
}

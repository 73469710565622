import React, { useEffect, useState } from "react";
import { TdsToggleSwitch } from "@tds/react";
import clx from "classnames";
import EmwDatePicker from "../../../../../../../lib/commonv2/EmwDatePicker";
import moment from "moment/moment";
import { DATE_TIME_FORMAT } from "../../../../../organization/constants";
import EmwDropdownSelect from "../../../../../../components/EmwDropdownSelect/EmwDropdownSelect";
import EmwDropdownSelectOption from "../../../../../../components/EmwDropdownSelect/EmwDropdownSelectOption";
import { StyledTimeframe } from "./styled";
import { getUsageMonitoringPeriodFiltersApi } from "../../../../redux/api";
/**
 * @deprecated
 */
export default function TimeFrame({ data, setData, isUsageMonitoring }) {
  const periodOptions = [
    { key: "12", value: "12 months", active: false },
    { key: "6", value: "6 months", active: false },
    { key: "3", value: "3 months", active: false },
  ];
  const [checked, setChecked] = useState(true);
  const [isDefault, setIsDefault] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [startDate, setStartDate] = useState();
  const [period, setPeriod] = useState(data.timeframe.period);
  const [periodFilters, setPeriodFilters] = useState([]);
  const [dateFilters, setDateFilters] = useState([]);
  const [date, setDate] = useState(data.timeframe.date);

  const className = clx("text-scale-7", {
    "text-primary-500 font-bold": isDefault,
    "text-bluegrey-200": !isDefault,
  });

  const classNameCustom = clx("text-scale-7", {
    "text-primary-500 font-bold": isCustom,
    "text-bluegrey-200": !isCustom,
  });

  const getPeriodFilters = async () => {
    const response = await getUsageMonitoringPeriodFiltersApi();
    if (response && response.data) {
      return response.data;
    }
  };

  useEffect(() => {
    if (isUsageMonitoring) {
      getPeriodFilters().then(data => {
        const mappedPeriods = data.periodFilter.map((value, index) => {
          return { key: index.toString(), value: value, active: false };
        });
        const mappedDates = data.dateFilter.map((value, index) => {
          return { key: index.toString(), value: value, active: false };
        });
        setPeriodFilters(mappedPeriods);
        setDateFilters(mappedDates);
      });
    } else {
      setPeriodFilters(periodOptions);
    }
  }, []);

  useEffect(() => {
    setIsDefault(checked);
    setIsCustom(!checked);
  }, [checked]);

  const onValueChanged = event => {
    setChecked(event.detail.checked);
    setData({
      ...data,
      timeframe: {
        ...data.timeframe,
        default: event.detail.checked,
      },
    });
  };

  const handlePeriodChange = event => {
    setPeriod(event.detail.valueChanged);
    setData({
      ...data,
      timeframe: {
        ...data.timeframe,
        period: event.detail.valueChanged,
      },
    });
    console.log(event);
  };

  const handleMonthChange = event => {
    setDate(event.detail.valueChanged);
    setData({
      ...data,
      timeframe: {
        ...data.timeframe,
        date: event.detail.valueChanged,
      },
    });
    console.log(event);
  };

  const handleDateChange = date => {
    setData({
      ...data,
      timeframe: {
        ...data.timeframe,
        date: moment(date).format(DATE_TIME_FORMAT),
      },
    });
  };

  return (
    <StyledTimeframe className="flex items-center ml-auto">
      <p className="text-scale-9 mr-xs">TIMEFRAME</p>
      <p className={className}>Default</p>
      <label className="flex items-center mr-xs" id="toggle">
        <TdsToggleSwitch
          labelPosition="left"
          checked={checked}
          onValueChanged={onValueChanged}
          classes="toggle"
        />
        <p className={classNameCustom}>Custom</p>
      </label>
      <label className="flex items-center mr-xs">
        <p className=" text-scale-9 text-blugrey-500 mr-xs">DATE</p>
        {isUsageMonitoring && (
          <EmwDropdownSelect
            inputId="date"
            // disabled={isDefault}
            options={periodOptions}
            onValueChanged={handleMonthChange}
            value={date}
            size="small"
          >
            {periodOptions.map(option => (
              <EmwDropdownSelectOption
                key={option.key}
                value={option.value}
                active={option.active}
              />
            ))}
          </EmwDropdownSelect>
        )}
        {!isUsageMonitoring && (
          <EmwDatePicker
            // disabled={isDefault}
            inputId="date"
            date={startDate}
            disableInput
            handleChange={handleDateChange}
            maxDate={moment(new Date()).format(DATE_TIME_FORMAT)}
          />
        )}
      </label>

      <label className="flex items-center">
        <p className="text-scale-9 text-blugrey-500 mr-xs">PERIOD</p>
        <EmwDropdownSelect
          inputId="period"
          // disabled={isDefault}
          options={periodOptions}
          onValueChanged={handlePeriodChange}
          value={period}
          size="small"
        >
          {periodOptions.map(option => (
            <EmwDropdownSelectOption key={option.key} value={option.value} active={option.active} />
          ))}
        </EmwDropdownSelect>
      </label>
    </StyledTimeframe>
  );
}

import React from "react";
import { EmwButton } from "../../../../../lib/common";

export default function PerspectiveBtn({ text, name, perspective, handleClick }) {
  return (
    <EmwButton
      variant={perspective === name ? "filled" : "outline"}
      id={`organization-${name}-btn`}
      size="small"
      onClick={handleClick}
    >
      {text}
    </EmwButton>
  );
}

import { styled } from "@mui/material/styles";
import { getZoom } from "../../../utils/sizeUtils";

export const StyledEmwGeneralFilters = styled("div")(({ theme }) => {
  const zoom = getZoom();
  return {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    input: {
      border: "none",
    },

    ".generalFilters__toggleHeader": {
      cursor: "pointer",
    },

    ".isSelected": {
      backgroundColor: "#f3f2f1",
    },

    ".listItem": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    ".generalFilters__mainSearch": {
      border: "1px solid #B3B0AD",
      borderRadius: "2px",

      input: {
        width: "100%",
        height: "29px",
        fontSize: "12px",
        "&:focus": {
          outline: "none",
        },
      },
    },

    ".reverse-mirror": {
      "-moz-transform": "scale(-1, 1)",
      "-webkit-transform": "scale(-1, 1)",
      "-o-transform": "scale(-1, 1)",
      "-ms-transform": "scale(-1, 1)",
      transform: "scale(-1, 1)",
    },

    ".generalFilters__filtersWrapper": {
      flexGrow: "1",
      borderTop: "1px solid #B3B0AD",
      "*": {
        color: "#252423!important",
      },
      ".generalFilters__moreOptions": {
        cursor: "pointer",
        marginRight: "8px",
      },
      ".generalFilters__clearAll": {
        cursor: "pointer",
        marginRight: "3px",
      },
      ".generalFilters__filterCard": {
        border: "1px solid #B3B0AD",
        padding: "8px 4px 4px 8px",
        borderRadius: "2px",
        cursor: "pointer",

        select: {
          border: "1px solid black",
          fontSize: "12px",
          height: "25px",
          width: "100%",

          "*": {
            border: "1px solid red",
          },
        },

        i: {
          color: "#B3B0AD!important",
        },

        ".generalFilters__filtertype-search": {
          backgroundColor: "#FFFFFF",
          borderBottom: "1px solid #B3B0AD",
          marginTop: "5px",

          input: {
            fontSize: "12px",
            height: "25px",
            "&:focus": {
              outline: "none",
            },
            width: "50%",
          },
        },

        ".generalFilters__filterCard__list": {
          overflow: "hidden",
          "&.visible": {
            height: "225px",
          },
          "&.hidden": {
            height: 0,
          },
        },
      },
    },
  };
});

import React from "react";
import { StyledEmwTag } from "./styled";
import { QtmTag } from "@qtm/react";
import TooltipAction from "../../features/organization/components/OrganizationCard/components/Section1/TooltipAction";
import clx from "classnames";
import { useSelector } from "react-redux";
import { highlightedTgiSelector } from "../../features/organization/redux/selectors";
const VARIANT = {
  STANDARD: "STANDARD",
  COMMENT_HISTORY: "COMMENT_HISTORY",
  CUSTOMIZED: "CUSTOMIZED",
};

export default function EmwTag({
  classes,
  size,
  dismissible,
  text,
  color,
  variant = VARIANT.COMMENT_HISTORY,
  referent,
  employeeId,
  isEngineer,
  setIsEngineer,
  parentId,
  orgId,
  reloadParent,
  reload,
  setChildren,
}) {
  const highlightedTgi = useSelector(highlightedTgiSelector);
  const className = clx("emw-tag-name flex flex-1 rounded-xl bg-bluegrey-50 pl-xs", {
    highlighted: referent && referent.unId === highlightedTgi,
  });

  if (variant === VARIANT.STANDARD) {
    return (
      <>
        <QtmTag
          classes={classes}
          size={size}
          dismissible={dismissible}
          label={text}
          color={color}
        ></QtmTag>
      </>
    );
  }

  if (variant === VARIANT.CUSTOMIZED) {
    return (
      <StyledEmwTag className={className}>
        {text}
        <div>
          <TooltipAction
            em={referent}
            employeeId={employeeId}
            isEngineer={isEngineer}
            setIsEngineer={setIsEngineer}
            parentId={parentId}
            orgId={orgId}
            reloadParent={reloadParent}
            reload={reload}
            setChildren={setChildren}
          />
        </div>
      </StyledEmwTag>
    );
  }

  return (
    <StyledEmwTag className="emw-tag" color={color}>
      <div className="left-corner"></div>
      <div className="bullet"></div>
      <div className="type">{text}</div>
    </StyledEmwTag>
  );
}

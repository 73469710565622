import React, { useState } from "react";
import EmsListItem from "./EmsListItem";
import { StyledEmList } from "./styled";
import { nanoid } from "nanoid";
import { expandPathSelector } from "../../../../redux/selectors";
import { useSelector } from "react-redux";

export default function EmsList({
  list,
  label,
  overwriteLabel,
  overwriteTitle,
  title,
  isParent,
  showAll,
  section,
  type,
  parentId,
  parent,
  reloadParent,
  parentIsDisabled,
  reload,
}) {
  const expandPath = useSelector(expandPathSelector);

  return (
    <StyledEmList>
      {list.map((em, index) => {
        let id;
        const emExpandPath = expandPath && expandPath.includes(em.tgi) ? expandPath : [];

        if (type) {
          id = `${type}-${index}`;
        }

        return (
          <>
            <EmsListItem
              key={`${nanoid()}`}
              id={id}
              em={em}
              label={label}
              index={index}
              title={title}
              isParent={isParent}
              showAll={showAll}
              section={section}
              overwriteLabel={overwriteLabel}
              overwriteTitle={overwriteTitle}
              parentId={parentId}
              parent={parent}
              reloadParent={reloadParent}
              reload={reload}
              parentIsDisabled={parentIsDisabled}
              emExpandPath={emExpandPath}
            />
          </>
        );
      })}
    </StyledEmList>
  );
}

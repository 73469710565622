import React, { useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useLocation } from "react-router-dom";
import "../../../styles/globals.css";

import { Snackbar } from "./../../../lib/common";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  backgroundColor: theme.palette.lightGreyColor,
  flex: "1 auto",
  display: "flex",
  flexDirection: "column",
}));

const App = props => {
  const { snackbarMessage, requestPending, children, deleteLogin } = props;
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/login") {
      deleteLogin();
    }
  }, [location]);

  return (
    <Root>
      {requestPending && <LinearProgress />}
      <Snackbar message={snackbarMessage} />
      {children}
    </Root>
  );
};

export default App;

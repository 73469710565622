import "../../../styles/globals.css";
import { MsalProvider } from "@azure/msal-react";
import Pages from "../../routes/Pages";
import { SnackbarProvider } from "notistack";
import store from "../../../store";
import { Provider } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";
import { CustomNavigationClient } from "../../auth/utils/NavigationClient";
import styled from "@emotion/styled";

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantWarning {
    background-color: #e07900;
  }
`;
function App({ msalInstance }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <StyledSnackbarProvider
          autoHideDuration={3000}
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Pages />
        </StyledSnackbarProvider>
      </Provider>
    </MsalProvider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { createFilterOptions, TextField } from "@mui/material";
import { EmwCheckbox } from "../../../../../../../../../lib/common";
import { StyledAutocomplete } from "./styled";
import { QtmTag } from "@qtm/react";
import CustomPopper from "../../../../../../../../components/CustomPopper/CustomPopper";

export default function FilterDropdown({ options, onChange, disabled, placeholder, type }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const filter = createFilterOptions();
  const allSelected = options.length === selectedOptions.length;

  useEffect(() => {
    setSelectedOptions([]);
  }, [options]);

  const handleToggleOption = selectedOptions => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => {
    onChange([]);
    setSelectedOptions([]);
  };
  const getOptionLabel = option => option.code;
  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };
  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };
  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find(option => option.id === "select-all")) {
        handleToggleSelectAll();
        let result = [];
        result = options.filter(el => el.id !== "select-all");
        return onChange(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps = option.id === "select-all" ? { checked: allSelected } : {};

    return (
      <li {...props} key={props.id}>
        <EmwCheckbox checked={selected} onChange={onChange} {...selectAllProps} />
        {option.code}
      </li>
    );
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    return [{ code: "Select all", id: "select-all" }, ...filtered];
  };

  const renderTags = (tagValue, getTagProps) => {
    return <QtmTag size="small" label={`${tagValue.length} selected`} dismissible={false} />;
  };

  const renderInput = params => {
    return (
      <TextField
        {...params}
        placeholder={placeholder}
        size="small"
        sx={{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "16px",
        }}
      />
    );
  };

  return (
    <StyledAutocomplete
      multiple
      size="small"
      id={`dropdown-list-${type}`}
      options={options}
      value={selectedOptions}
      disabled={disabled}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      PopperComponent={CustomPopper}
      onChange={handleChange}
      filterOptions={filterOptions}
      renderTags={renderTags}
      renderOption={optionRenderer}
      renderInput={renderInput}
    />
  );
}
